import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { isBefore } from "date-fns";
import { event } from "event";
import jwtDecode from "jwt-decode";

const cache = new InMemoryCache();

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_API_URL || window?.appEnvironment?.API_URL,
	fetch: (...args) => fetch(...args),
});

const authLink = setContext((_, { headers }) => {
	const token = window.environment?.anonymousToken;
	const SECOND = 1000;

	if (token) {
		try {
			const { exp } = jwtDecode<{ exp: number }>(token);
			const isExpired: boolean = isBefore(exp * SECOND, new Date());
			if (isExpired) {
				event.emit("tokenExpired");
				localStorage.removeItem("token");
			}
		} catch (error) {
			localStorage.removeItem("token");
			window.location.reload();
		}
	}

	const extendedHeaders = {
		...headers,
	};

	if (!headers || !headers.authorization) {
		extendedHeaders.authorization = `Bearer ${token}`;
	}

	return {
		headers: extendedHeaders,
	};
});

export const client = new ApolloClient({
	cache,
	link: authLink.concat(httpLink),
	name: "ik-high-five-app",
	version: "1.1.2",
});

// All 3 properties must be set in order for the defaultOptions to work.
// https://github.com/apollographql/apollo-client/issues/2555#issuecomment-449871538
client.defaultOptions = {
	watchQuery: {
		fetchPolicy: process.env.REACT_APP_ENVIRONMENT === "local" ? "network-only" : "cache-and-network",
		errorPolicy: "all",
	},
	query: {
		fetchPolicy: process.env.REACT_APP_ENVIRONMENT === "local" ? "network-only" : "cache-first",
		errorPolicy: "all",
	},
	mutate: {
		errorPolicy: "all",
	},
};
