import { PageComponent } from "@aptus/frontend-core";
import { useAuthentication } from "domains";
import { RequestPasswordResetForm } from "./components/requestPasswordResetForm/requestPasswordResetForm";
import styles from "./requestPasswordResetPage.module.css";

export const RequestPasswordResetPage: PageComponent = () => {
	const {
		requestPasswordReset, requestPasswordResetSchema, defaultRequestPasswordResetInput, isLoading,
	} = useAuthentication();

	return (
		<div className={styles.page}>
			<RequestPasswordResetForm
				defaultValues={defaultRequestPasswordResetInput}
				isLoading={isLoading}
				schema={requestPasswordResetSchema}
				submit={requestPasswordReset}
			/>
		</div>
	);
};
