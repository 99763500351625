import { UseCases } from "@aptus/frontend-core";
import { flatten } from "flat";
import { Routes } from "hooks/routes/models/routes";
import { useTranslation } from "react-i18next";
import { useEvent } from "utils/useEvent";
import { PageTitles } from "./pageTitles";

interface Props {
	routes: Routes;
}

interface Result {
	routePageTitles: PageTitles;

	setPageTitle: (pageTitle: string) => void;
	trySetRoutePageTitle: (path: string) => void;
}

export const usePageTitleUseCases: UseCases<Props, Result> = (props: Props) => {
	const { t } = useTranslation();

	const routePageTitles: Result["routePageTitles"] = {
		index: t("highFive"),
		styleguide: t("pageTitles.styleguide"),
		frequentlyAskedQuestions: {
			index: t("pageTitles.frequentlyAskedQuestions.index"),
			categoryOne: `${t("pageTitles.frequentlyAskedQuestions.index")} | ${t("pageTitles.frequentlyAskedQuestions.categoryOne")}`,
			categoryTwo: `${t("pageTitles.frequentlyAskedQuestions.index")} | ${t("pageTitles.frequentlyAskedQuestions.categoryTwo")}`,
			categoryThree: `${t("pageTitles.frequentlyAskedQuestions.index")} | ${t("pageTitles.frequentlyAskedQuestions.categoryThree")}`,
			categoryFour: `${t("pageTitles.frequentlyAskedQuestions.index")} | ${t("pageTitles.frequentlyAskedQuestions.categoryFour")}`,
			categoryFive: `${t("pageTitles.frequentlyAskedQuestions.index")} | ${t("pageTitles.frequentlyAskedQuestions.categoryFive")}`,
		},
		whatIsHighFive: t("pageTitles.whatIsHighFive"),
		whereToHighFive: t("pageTitles.whereToHighFive"),
		login: t("pageTitles.login"),
		passwordReset: t("pageTitles.passwordReset"),
		requestPasswordReset: t("pageTitles.requestPasswordReset"),
		register: {
			index: t("pageTitles.register.index"),
			introduction: `${t("pageTitles.register.index")} | ${t("pageTitles.register.introduction")}`,
			stepOne: `${t("pageTitles.register.index")} | ${t("pageTitles.register.stepOne")}`,
			stepTwo: `${t("pageTitles.register.index")} | ${t("pageTitles.register.stepTwo")}`,
			stepThree: `${t("pageTitles.register.index")} | ${t("pageTitles.register.stepThree")}`,
			stepFour: `${t("pageTitles.register.index")} | ${t("pageTitles.register.stepFour")}`,
			stepFive: `${t("pageTitles.register.index")} | ${t("pageTitles.register.stepFive")}`,
			stepSix: `${t("pageTitles.register.index")} | ${t("pageTitles.register.stepSix")}`,
			done: `${t("pageTitles.register.index")} | ${t("pageTitles.register.done")}`,
		},
		pupilPlatform: t("pageTitles.pupilPlatform"),
		jobstudent: t("pageTitles.jobstudent"),
		bijbaan: t("pageTitles.bijbaan"),
		cookiePolicy: t("pageTitles.cookiePolicy"),
		privacyPolicy: t("pageTitles.privacyPolicy"),
		webinar: t("pageTitles.webinar"),
		forgotTagId: t("pageTitles.forgotTagId"),
		safetyDays: t("pageTitles.safetyDays"),
	};

	const setPageTitle: Result["setPageTitle"] = (pageTitle: string) => {
		document.title = pageTitle;
	};

	const getPathKey: (path: string) => string = (path) => {
		const { routes } = props;
		const flattenedRoutes: Record<string, string> = flatten(routes);

		const matchKeys = Object.keys(flattenedRoutes).filter((key) => {
			const value = flattenedRoutes[key];

			if (value === path) {
				return true;
			}

			return false;
		});

		if (matchKeys && matchKeys.length) {
			return matchKeys[0];
		}

		return "";
	};

	const trySetRoutePageTitle: Result["trySetRoutePageTitle"] = (path: string) => {
		const pathKey = getPathKey(path);

		if (!pathKey) {
			setPageTitle(routePageTitles.index);
			return;
		}

		const title = pathKey
			.split(/[.[\]]/)
			.filter(Boolean)
			.reduce((value, key) => (value as any)?.[key], routePageTitles as any);

		if (title) {
			setPageTitle(title);
			return;
		}

		setPageTitle(routePageTitles.index);
	};

	return {
		routePageTitles,

		setPageTitle,
		trySetRoutePageTitle,
	};
};

export const usePageTitleEvents = (pageTitles: ReturnType<typeof usePageTitleUseCases>) => {
	useEvent("routeChanged", pageTitles.trySetRoutePageTitle);
};
