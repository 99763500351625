export interface FormatImageUrlOptions {
	width?: number;
	height?: number;
}

export const formatImageUrl = (imageUrl: string, options?: FormatImageUrlOptions) => {
	let width = 250;
	let height = 250;

	if (!options || !options.width || !options.height) {
		if (window.visualViewport) {
			if (!options || !options.width) {
				width = window.visualViewport.width;
			}

			if (!options || !options.height) {
				height = window.visualViewport.height;
			}
		}
	}

	/* width and height are times two for retina displays */
	width *= window.devicePixelRatio;
	height *= window.devicePixelRatio;

	const finalOptions = {
		width: Math.round(width),
		height: Math.round(height),
		enlarge: false,
		resizeType: "fit",
		gravity: "ce",
	};

	return `https://www.ikhighfive.be/imgproxy/rs:${finalOptions.resizeType}:${finalOptions.width}:${finalOptions.height}:${
		finalOptions.enlarge ? "1" : "0"
	}/g:${finalOptions.gravity}/${btoa(imageUrl)}`;
};
