import { event, Events } from "event";
import { useEffect } from "react";

export const useEvent = <Event extends keyof Events>(
	eventName: Event,
	listener: Events[Event],
): void => {
	useEffect(() => {
		event.on(eventName, listener);

		return () => {
			event.removeListener(eventName, listener);
		};
	}, [event, listener]);
};
