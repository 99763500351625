import { PageComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { useRoutes } from "domains";
import { Trans, useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { formatImageUrl } from "utils/formatImageUrl";
import styles from "./faqPage.module.css";

export const FaqPage: PageComponent = () => {
	const { t } = useTranslation();
	const { routes, currentPathname } = useRoutes();

	return (
		<>
			<div className={styles.header}>
				<h2>{t("frequentlyAskedQuestions")}</h2>
				<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/flying-five.svg")} alt="five" />
			</div>
			<nav className={styles.tags}>
				<A
					type="button"
					className={`tab ${currentPathname === routes.frequentlyAskedQuestions.categoryOne ? "active" : ""}`}
					href={routes.frequentlyAskedQuestions.categoryOne}
				>
					{t("faq.category1.title")}
				</A>
				<A
					type="button"
					className={`tab ${currentPathname === routes.frequentlyAskedQuestions.categoryTwo ? "active" : ""}`}
					href={routes.frequentlyAskedQuestions.categoryTwo}
				>
					{t("faq.category2.title")}
				</A>
				<A
					type="button"
					className={`tab ${currentPathname === routes.frequentlyAskedQuestions.categoryThree ? "active" : ""}`}
					href={routes.frequentlyAskedQuestions.categoryThree}
				>
					{t("faq.category3.title")}
				</A>
				<A
					type="button"
					className={`tab ${currentPathname === routes.frequentlyAskedQuestions.categoryFour ? "active" : ""}`}
					href={routes.frequentlyAskedQuestions.categoryFour}
				>
					{t("faq.category4.title")}
				</A>
				<A
					type="button"
					className={`tab ${currentPathname === routes.frequentlyAskedQuestions.categoryFive ? "active" : ""}`}
					href={routes.frequentlyAskedQuestions.categoryFive}
				>
					{t("faq.category5.title")}
				</A>
			</nav>
			<Outlet />
			<p className={styles.footer}>
				<Trans i18nKey="faq.answerNotFound" components={{ italic: <A href="mailto:hallo@ikhighfive.be" /> }} />
			</p>
		</>
	);
};
