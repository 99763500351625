import { UIComponent, UILayout } from "@aptus/frontend-core";
import { A } from "components/a";
import { Input } from "components/input/input";
import { LoadingButton } from "components/loadingButton/loadingButton";
import { Tooltip } from "components/tooltip/tooltip";
import { useRoutes } from "domains";
import { StartFormInput } from "hooks/authentication/models/authenticationInput";
import { useTranslation } from "react-i18next";
import { FormLayoutProps, FormProps, useForm } from "utils/useForm";
import styles from "./startForm.module.css";

interface Common {
	className: string;
	loginUrl: string;
}

type LayoutProps = Common & FormLayoutProps<StartFormInput>;
type Props = Common & FormProps<StartFormInput>;

const Layout: UILayout<LayoutProps> = ({
	className, errors, submit, register, isLoading,
}) => {
	const { t } = useTranslation();
	const { routes } = useRoutes();

	return (
		<form
			className={`${className} ${styles.container}`}
			onSubmit={submit}
		>
			<Tooltip
				title={t("startFrom.tooltip.title")}
				body="startFrom.tooltip.body"
				id="tagId"
			>
				<Input
					{...register("tagId")}
					className={styles.tagId}
					onChange={(event) => {
						if (event.currentTarget.value.indexOf("O") >= 0 || event.currentTarget.value.indexOf("o") >= 0) {
							// eslint-disable-next-line no-param-reassign
							event.currentTarget.value = event.currentTarget.value.toUpperCase().replace(/O/g, "0");
						}

						if (event.currentTarget.value.length === 8) {
							event.currentTarget.blur();
							document.getElementById("submitButton")?.focus();
						}
					}}
					type="text"
					id="tagId"
					placeholder={t("startFrom.placeholder")}
					label={t("startFrom.tagId")}
					error={errors.tagId?.message}
				/>
			</Tooltip>
			<A href={routes.forgotTagId}>{t("forgotTagId")}</A>
			<LoadingButton
				type="submit"
				id="submitButton"
				isLoading={isLoading}
				disabled={false}
			>
				{t("startFrom.start")}
			</LoadingButton>
		</form>
	);
};

export const StartForm: UIComponent<Props> = (props) => (
	<Layout {...props} {...useForm(props)} />
);
