export const setCookie = (name: string, value: string | boolean | number | undefined) => {
	if (value === null || value === undefined) {
		const cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
		document.cookie = cookie;

		return;
	}

	const expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString();

	const stringifiedValue = JSON.stringify(value);
	const cookie = `${name}=${stringifiedValue}; expires=${expiryDate}; path=/`;

	document.cookie = cookie;
};
