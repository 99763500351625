import { UILogic } from "@aptus/frontend-core";
import {
	ReactNode, RefObject, useEffect, useRef, useState,
} from "react";

interface Common {
	title?: string;
	body: string;
	children?: ReactNode;
}

interface Props {
	id: string;
}

interface Result {
	tooltipRef: RefObject<HTMLDivElement>;
	isVisible?: boolean;
	isInitialRender?: boolean;
	id: string;
}

export type TooltipProps = Common & Props;
export type TooltipLayoutProps = Common & Result;

export const useTooltip: UILogic<Props, Result> = ({ id }) => {
	const tooltipRef = useRef<HTMLDivElement>(null);
	const [isVisible, setIsVisible] = useState<Result["isVisible"]>(false);
	const [isInitialRender, setIsInitialRender] = useState<Result["isInitialRender"]>(true);

	const isInputElement = (element?: Element | null): element is HTMLInputElement => (
		element?.tagName.toLowerCase() === "input"
	);

	useEffect(() => {
		const input = document.getElementById(id);

		setTimeout(() => {
			setIsInitialRender(false);
		}, 500);

		if (isInputElement(input)) {
			input.addEventListener("focus", () => setIsVisible(true));
			input.addEventListener("blur", () => setIsVisible(false));
		}

		return () => {
			if (isInputElement(input)) {
				input.removeEventListener("focus", () => setIsVisible(true));
				input.removeEventListener("blur", () => setIsVisible(false));
			}
		};
	}, [tooltipRef]);

	return {
		tooltipRef,
		isVisible,
		isInitialRender,
		id,
	};
};
