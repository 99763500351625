/* eslint-disable */
import * as Types from '../../../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSchoolsByProjectIdVariablesDTO = Types.Exact<{
  projectId: Types.Scalars['String'];
}>;


export type GetSchoolsByProjectIdDTO = { __typename?: 'Query', schools: Array<{ __typename?: 'School', id: string, title: string }> };


export const GetSchoolsByProjectIdDocumentDTO = gql`
    query getSchoolsByProjectId($projectId: String!) {
  schools(
    filter: {schoolyear: {project: {equals: $projectId}}}
    sort: [{field: "title", order: ASC}]
  ) {
    id
    title
  }
}
    `;

/**
 * __useGetSchoolsByProjectId__
 *
 * To run a query within a React component, call `useGetSchoolsByProjectId` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsByProjectId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsByProjectId({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetSchoolsByProjectId(baseOptions: Apollo.QueryHookOptions<GetSchoolsByProjectIdDTO, GetSchoolsByProjectIdVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolsByProjectIdDTO, GetSchoolsByProjectIdVariablesDTO>(GetSchoolsByProjectIdDocumentDTO, options);
      }
export function useGetSchoolsByProjectIdLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolsByProjectIdDTO, GetSchoolsByProjectIdVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolsByProjectIdDTO, GetSchoolsByProjectIdVariablesDTO>(GetSchoolsByProjectIdDocumentDTO, options);
        }
export type GetSchoolsByProjectIdHookResult = ReturnType<typeof useGetSchoolsByProjectId>;
export type GetSchoolsByProjectIdLazyQueryDTOHookResult = ReturnType<typeof useGetSchoolsByProjectIdLazyQueryDTO>;
export type GetSchoolsByProjectIdQueryResultDTO = Apollo.QueryResult<GetSchoolsByProjectIdDTO, GetSchoolsByProjectIdVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    