/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProjectUrlVariablesDTO = Types.Exact<{ [key: string]: never; }>;


export type GetProjectUrlDTO = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', url: string }> };


export const GetProjectUrlDocumentDTO = gql`
    query getProjectUrl {
  projects {
    url
  }
}
    `;

/**
 * __useGetProjectUrl__
 *
 * To run a query within a React component, call `useGetProjectUrl` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectUrl({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectUrl(baseOptions?: Apollo.QueryHookOptions<GetProjectUrlDTO, GetProjectUrlVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectUrlDTO, GetProjectUrlVariablesDTO>(GetProjectUrlDocumentDTO, options);
      }
export function useGetProjectUrlLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectUrlDTO, GetProjectUrlVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectUrlDTO, GetProjectUrlVariablesDTO>(GetProjectUrlDocumentDTO, options);
        }
export type GetProjectUrlHookResult = ReturnType<typeof useGetProjectUrl>;
export type GetProjectUrlLazyQueryDTOHookResult = ReturnType<typeof useGetProjectUrlLazyQueryDTO>;
export type GetProjectUrlQueryResultDTO = Apollo.QueryResult<GetProjectUrlDTO, GetProjectUrlVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    