import { DeepPartial, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { t } from "i18next";
import { ClassDTO, QueryDTO, SchoolDTO } from "models/schema";
import { Classroom, School } from "./models/school";
import { SchoolsAPI } from "./useSchoolsUseCases";

export type SchoolsAPIDTO = ApolloAPI<QueryDTO, "schools">;

interface Mapper {
	toAPI: ToAPI<SchoolsAPIDTO, SchoolsAPI>;
	toSchool: ToInternal<DeepPartial<SchoolDTO>, School>;
}

export class SchoolMapper implements Mapper {
	private toClass = (classroom: DeepPartial<ClassDTO>): Classroom => ({
		id: classroom.id || "",
		name: classroom.title || t("domain.school.noClassroom"),
	});

	public toSchool: Mapper["toSchool"] = (school) => ({
		id: school.id,
		title: school.title,
		classrooms: (school?.classes || []).map(this.toClass),
		canHighFive: school.highFiveRegistrationsEnabled ?? false,
		canBikeScan: school.bikeRegistrationsEnabled ?? false,
		requireClassNumber: school.classNumberEnabled ?? false,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: SchoolsAPIDTO["data"]): DeepPartial<SchoolDTO>[] => {
			if (data?.schools) return data?.schools;
			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toSchool),
		};
	};
}
