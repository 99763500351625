import { UIComponent } from "@aptus/frontend-core";
import { LoadingButton } from "components/loadingButton/loadingButton";
import { DetailedHTMLProps, InputHTMLAttributes, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./usernameInput.module.css";

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	isLoading?: boolean;
	username?: string;
	generate: () => void;
}

export const UsernameInput: UIComponent<Props> = ({
	isLoading, username, generate,
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		if (!username) generate();
	}, []);

	return (
		<label htmlFor="username" className={`${styles.container} ${isLoading ? styles.isLoading : ""}`}>
			{t("usernameInput.label")}
			<h2>{username || t("usernameInput.noUsername")}</h2>
			<LoadingButton type="button" onClick={generate} isLoading={isLoading} className="icon" disabled={false}>
				<i>redo</i>
				<span>{` ${t("usernameInput.regenerate")}`}</span>
			</LoadingButton>
		</label>
	);
};
