import { t } from "i18next";
import { FieldError, Resolver } from "react-hook-form";
import { createSchemaValidator } from "../createSchemaValidator";
import { DeepRecord } from "../models/deepRecord";
import { Rule } from "../models/rule";
import { HTMLValidationSchema, HTMLValidationSchemaGenerator } from "../models/validationSchema";

const isGenerator = <Input>(schema: HTMLValidationSchema<Input> | HTMLValidationSchemaGenerator<Input>): schema is HTMLValidationSchemaGenerator<Input> => (
	typeof schema === "function"
);

const validateRule = <Value extends string | number | undefined>(value: Value, rule: Rule): FieldError | undefined => {
	if (rule.required && !value) {
		return { type: "required", message: rule.valueMissing || t("error.valueMissing") };
	}

	if (value) {
		if (typeof value === "string") {
			if (rule.minLength && value.length < rule.minLength) {
				return { type: "minLength", message: rule.tooShort || t("error.tooShort") };
			}

			if (rule.maxLength && value.length > rule.maxLength) {
				return { type: "maxLength", message: rule.tooLong || t("error.tooLong") };
			}

			if (rule.pattern && !value.match(rule.pattern)) {
				return { type: "pattern", message: rule.patternMismatch || t("error.patternMismatch") };
			}
		}

		if (rule.min && value < rule.min) {
			return { type: "rangeUnderflow", message: rule.rangeUnderflow || t("error.rangeUnderflow") };
		}

		if (rule.max && value > rule.max) {
			return { type: "rangeOverflow", message: rule.rangeOverflow || t("error.rangeOverflow") };
		}
	}

	return undefined;
};

const toErrors = <Input>(errors: DeepRecord<Input, FieldError | undefined>): Awaited<ReturnType<Resolver<Input>>>["errors"] => {
	const entries = Object.entries(errors as any);

	const isNotUndefined = <Value>(value: Value | undefined): boolean => (
		value !== undefined
	);

	const entryIsNotUndefined = <Value>([, value]: [string, Value | undefined]): boolean => (
		value !== undefined
	);

	const flatten = <Value>([key, value]: [string, Value | Value[]]): [string, Value] => (Array.isArray(value)
		? [key, value.filter(isNotUndefined)[0]]
		: [key, value]);

	return Object.fromEntries(entries
		.map(flatten)
		.filter(entryIsNotUndefined));
};

/**
 * Resolver to validate a 'HTMLValidationSchema' object with 'react-hook-form'.
 * @example
 * const form = useForm({
 *   resolver: HTMLValidationSchemaResolver(schema)
 * });
 */
export const HTMLValidationSchemaResolver = <Input, Schema extends HTMLValidationSchema<Input> | HTMLValidationSchemaGenerator<Input>>(schema: Schema): Resolver<Input> => (input) => {
	const validateSchema = createSchemaValidator(validateRule);
	const newSchema: HTMLValidationSchema<Input> = isGenerator<Input>(schema) ? schema(input) : schema as HTMLValidationSchema<Input>;

	return {
		values: input,
		errors: toErrors(validateSchema(input, newSchema)),
	};
};
