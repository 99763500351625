import { UIComponent } from "@aptus/frontend-core";
import { FaqListLayoutProps, FaqListProps, useFaqList } from "./useFaqList";

const Layout: UIComponent<FaqListLayoutProps> = ({ children }) => (
	<>{children}</>
);

export const FaqList: UIComponent<FaqListProps> = (props) => (
	<Layout {...props} {...useFaqList(props)} />
);
