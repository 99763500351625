import { Rule } from "./models/rule";
import { DeepRecord } from "./models/deepRecord";
import { HTMLValidationSchema } from "./models/validationSchema";

const isDate = (date: unknown): date is Date => (
	Object.prototype.toString.call(date) === "[object Date]"
);

const isArray = <Type>(arr: Type | Type[]): arr is Type[] => (
	Array.isArray(arr)
);

const isObject = (obj: unknown): obj is Record<string, unknown> => (
	typeof obj === "object" && !isArray(obj) && !isDate(obj) && obj !== null
);

const isNotEmpty: any = (entry: any): boolean => (
	entry[1] && Object.keys(entry[1]).length !== 0
);

/**
 * Returns a new `validateSchema` function which validates any input based on the given `validateRule` parameter.
 * Useful if you need a custom `Error` object.
 *
 * @example
 * const validateSchema = createSchemaValidator(customValidateRule);
 * const errors = validateSchema(input, schema);
 */
export const createSchemaValidator = <Error = string | undefined>(
	validateRule: <Value extends string | number | undefined>(value: Value, rule: Rule) => Error,
) => {
	const validateSchema = <Input extends Object>(input: Input, schema: HTMLValidationSchema<Input>): DeepRecord<Input, Error> => {
		const entries: [keyof Input, Rule][] = Object.entries(schema) as [keyof Input, Rule][];
		const isPrimitive = <I>(value: I): boolean => !(isArray(value) || isObject(value));

		const validate = <I extends Object>(_input: I, _rule: Rule, _schema: HTMLValidationSchema<I>) => (isPrimitive(_input)
			? validateRule(_input as any, _rule)
			: validateSchema(_input, _schema));

		const flattenArrayIfEmpty = (validated: any): any => {
			if (Array.isArray(validated) && validated.every((value) => value === undefined)) {
				return undefined;
			}

			return validated;
		};

		const newEntries = entries.map(([key, rule]) => {
			const newInput = input[key];
			const newSchema = (schema as any)[key];

			const validated = isArray(newSchema) && isArray(newInput)
				? newSchema.map((value, index) => validate(newInput[index] as any, (rule as Rule[])[index], value))
				: validate(newInput as any, rule, newSchema);

			return [key, flattenArrayIfEmpty(validated)];
		});

		return Object.fromEntries(newEntries.filter(isNotEmpty));
	};

	return validateSchema;
};
