import { ApolloError } from "@apollo/client";
import {
	DeepPartial, IOMap, ToAPI, ToInternal,
} from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { t } from "i18next";
import {
	PolicyDTO, PolicyTypeDTO,
	PupilParentInputDTO, PupilTagInputDTO, QueryDTO, UserCommunicationChannelDTO, UserCommunicationPreferenceCommunicationTypeDTO,
} from "models/schema.d";
import { RegisterInput } from "./models/authenticationInput";
import { TriggerForgotTagIdEmailMutationFnDTO } from "./models/forgotTagId";
import { GenerateUsernameMutationFnDTO } from "./models/generateUsername";
import { LoginMutationFnDTO } from "./models/login";
import { Policy, PolicyType } from "./models/policy";
import { RegisterMutationFnDTO } from "./models/register";
import { RequestPasswordResetMutationFnDTO } from "./models/requestPasswordReset";
import { ResetPasswordMutationFnDTO } from "./models/resetPassword";
import { TagType } from "./models/tagType";
import { ValidateClassNumberMutationFnDTO } from "./models/validateClassNumber";
import { ValidateTagMutationFnDTO } from "./models/validateTag";
import {
	GenerateUsernameMutation, LoginMutation, PoliciesAPI, RegisterMutation, RequestPasswordResetMutation, ResetPasswordMutation, TriggerForgotTagIdEmailMutation, ValidateClassNumberMutation, ValidateTagMutation,
} from "./useAuthenticationUseCases";

enum TagTypeDTO {
	Wristband = "HIGH_FIVE",
	BikeTag = "BIKE",
}

export type PoliciesAPIDTO = ApolloAPI<QueryDTO, "policies">;

interface Props {
	// Token used to reset the password.
	token: string;
	api?: PoliciesAPIDTO;
}

interface Mapper {
	toAPI: ToAPI<PoliciesAPIDTO, PoliciesAPI>;
	toGenerateUsernameMutation: ToInternal<GenerateUsernameMutationFnDTO, GenerateUsernameMutation>;
	toRegisterMutation: ToInternal<RegisterMutationFnDTO, RegisterMutation>;
	toLoginMutation: ToInternal<LoginMutationFnDTO, LoginMutation>;
	toRequestPasswordResetMutation: ToInternal<RequestPasswordResetMutationFnDTO, RequestPasswordResetMutation>;
	toResetPasswordMutation: ToInternal<ResetPasswordMutationFnDTO, ResetPasswordMutation>;
	toValidateTagMutation: ToInternal<ValidateTagMutationFnDTO, ValidateTagMutation>;
	toValidateClassNumberMutation: ToInternal<ValidateClassNumberMutationFnDTO, ValidateClassNumberMutation>;
	toTriggerForgotTagIdEmailMutation: ToInternal<TriggerForgotTagIdEmailMutationFnDTO, TriggerForgotTagIdEmailMutation>;
}

export class AuthenticationMapper implements Mapper {
	private token: Props["token"] = "";

	private api: Props["api"] = undefined;

	constructor(props: Props) {
		this.token = props.token;
		this.api = props.api;
	}

	private toPolicyTypeDTO = (type: PolicyType): PolicyTypeDTO => {
		switch (type) {
			case PolicyType.PrivacyPolicy: return PolicyTypeDTO.PrivacyPolicyDTO;
			case PolicyType.DataProcessingAgreement: return PolicyTypeDTO.DataProcessingAgreementDTO;
			default: return PolicyTypeDTO.UnknownDTO;
		}
	};

	private toPolicyType = (type?: PolicyTypeDTO): PolicyType => {
		switch (type) {
			case PolicyTypeDTO.PrivacyPolicyDTO: return PolicyType.PrivacyPolicy;
			case PolicyTypeDTO.DataProcessingAgreementDTO: return PolicyType.DataProcessingAgreement;
			default: throw new Error("invalid policy type");
		}
	};

	private toTagTypeDTO = (type: TagType): TagTypeDTO => {
		switch (type) {
			case TagType.Wristband: return TagTypeDTO.Wristband;
			case TagType.BikeTag: return TagTypeDTO.BikeTag;
			default: throw new Error("invalid tag type");
		}
	};

	private toPolicy: ToInternal<DeepPartial<PolicyDTO>, Policy> = (policy) => ({
		id: policy.id || "",
		type: this.toPolicyType(policy?.type),
		url: policy.url || "",
		required: policy.required || false,
	});

	private isPolicyType = (policy: Policy, type: PolicyType): boolean => (
		policy.type === type
	);

	private toParentInput = (email: string, acceptRewardAndUpdateNotifications: boolean, acceptedPolicies: RegisterInput["acceptPolicies"]): PupilParentInputDTO => {
		const policies = this.toAPI(this.api).data;
		const isPrivacyPolicy = (policy: Policy): boolean => this.isPolicyType(policy, PolicyType.PrivacyPolicy);
		const isDataProcessingAgreement = (policy: Policy): boolean => this.isPolicyType(policy, PolicyType.DataProcessingAgreement);

		const privacyPolicy = policies.find(isPrivacyPolicy);
		const dataProcessingAgreement = policies.find(isDataProcessingAgreement);

		return {
			email,
			communicationPreferences: [{
				allow: acceptRewardAndUpdateNotifications,
				channel: UserCommunicationChannelDTO.EmailDTO,
				type: UserCommunicationPreferenceCommunicationTypeDTO.RewardsAndUpdatesForChildDTO,
			}],
			acceptedPolicies: [
				..."privacyPolicy" in acceptedPolicies && privacyPolicy
					? [{ accepted: acceptedPolicies.privacyPolicy, id: privacyPolicy.id, type: this.toPolicyTypeDTO(privacyPolicy.type) }]
					: [],
				..."dataProcessingAgreement" in acceptedPolicies && dataProcessingAgreement
					? [{ accepted: acceptedPolicies.dataProcessingAgreement, id: dataProcessingAgreement.id, type: this.toPolicyTypeDTO(dataProcessingAgreement.type) }]
					: [],
			],
		};
	};

	private toPupilTagInput = (code: string, type?: TagType): PupilTagInputDTO => ({
		type: this.toTagTypeDTO(type || TagType.Wristband),
		tag: code,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: PoliciesAPIDTO["data"]): DeepPartial<PolicyDTO>[] => {
			if (data?.policies) return data.policies;
			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toPolicy),
		};
	};

	public toGenerateUsernameMutation: Mapper["toGenerateUsernameMutation"] = (mutation) => {
		const map: IOMap<GenerateUsernameMutationFnDTO, GenerateUsernameMutation> = {
			toInput: () => ({
				variables: {},
			}),
			toOutput: (output) => {
				if (output.errors) {
					throw new Error(t("domain.authentication.error.usernameGenerationFailed"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
				}

				return output.data?.generateOneUsername
					? output.data.generateOneUsername
					: undefined;
			},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toRegisterMutation: Mapper["toRegisterMutation"] = (mutation) => {
		const map: IOMap<RegisterMutationFnDTO, RegisterMutation> = {
			toInput: (input) => {
				const wristband = this.toPupilTagInput(input.wristband, TagType.Wristband);
				const biketag = this.toPupilTagInput(input.biketag, TagType.BikeTag);
				const emails = input.emails.split(/,|\s+/).filter((v) => !!v);

				return {
					variables: {
						input: {
							classNumber: input.requireClassNumber ? parseInt(input.classNumber!, 10) : undefined,
							class: input.classroom,
							usernameId: input.usernameId,
							password: input.password,
							parents: emails.map((email) => this.toParentInput(email, input.acceptRewardAndUpdateNotifications, input.acceptPolicies)),
							pupilTags: [
								...input.hasNoBiketag ? [] : [biketag],
								...input.hasNoWristband ? [] : [wristband],
							],
						},
					},
				};
			},
			toOutput: (output) => {
				if (output.errors) {
					switch (output.errors[0].message) {
						case "Can't assign tags that are already in use":
							throw new Error(t("domain.authentication.error.tagsAreAlreadyInUse"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
						case "Can't assign class number that is already in use":
							throw new Error(t("domain.authentication.error.classNumberAlreadyExists"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
						case output.errors[0].message.match(/Parent(s) didn't accept the required policies/)?.input:
							throw new Error(t("domain.authentication.error.policiesNotAccepted"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
						default:
							throw new Error(t("domain.authentication.error.registrationFailed"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
					}
				}
			},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toLoginMutation: Mapper["toLoginMutation"] = (mutation) => {
		const map: IOMap<LoginMutationFnDTO, LoginMutation> = {
			toInput: (input) => ({
				variables: {
					nfcTagId: input.nfcTagId,
					nfcTagType: "HIGH_FIVE",
					password: input.password,
				},
			}),
			toOutput: (output) => {
				if (output.errors) {
					throw new Error(t("domain.authentication.error.loginFailed"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
				}

				return output.data?.nfcPasswordPupilLogin.token;
			},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toRequestPasswordResetMutation: Mapper["toRequestPasswordResetMutation"] = (mutation) => {
		const map: IOMap<RequestPasswordResetMutationFnDTO, RequestPasswordResetMutation> = {
			toInput: (input) => ({
				variables: {
					nfcTagId: input.nfcTagId,
					nfcTagType: "HIGH_FIVE",
				},
			}),
			toOutput: (output) => {
				if (output.errors) {
					throw new Error(t("domain.authentication.error.requestPasswordResetFailed"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
				}
			},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toResetPasswordMutation: Mapper["toResetPasswordMutation"] = (mutation) => {
		const map: IOMap<ResetPasswordMutationFnDTO, ResetPasswordMutation> = {
			toInput: (input) => ({
				variables: {
					password: input.password,
					token: this.token,
				},
			}),
			toOutput: (output) => {
				if (output.errors) {
					throw new Error(t("domain.authentication.error.resetPasswordFailed"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
				}
			},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toValidateTagMutation: Mapper["toValidateTagMutation"] = (mutation) => {
		const map: IOMap<ValidateTagMutationFnDTO, ValidateTagMutation> = {
			toInput: (input) => ({
				variables: {
					tag: this.toPupilTagInput(input.tag),
				},
			}),
			toOutput: (output) => {
				if (output.errors) {
					throw new Error(t("domain.authentication.error.tagAlreadyExists"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
				}

				return output.data?.validatePupilTag.success || false;
			},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toValidateClassNumberMutation: Mapper["toValidateClassNumberMutation"] = (mutation) => {
		const map: IOMap<ValidateClassNumberMutationFnDTO, ValidateClassNumberMutation> = {
			toInput: (input) => ({
				variables: input,
			}),
			toOutput: (output) => {
				if (output.errors) {
					throw new Error(t("domain.authentication.error.classNumberAlreadyExists"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
				}

				return output.data?.validatePupilClassNumber.success || false;
			},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toTriggerForgotTagIdEmailMutation: Mapper["toTriggerForgotTagIdEmailMutation"] = (mutation) => {
		const map: IOMap<TriggerForgotTagIdEmailMutationFnDTO, TriggerForgotTagIdEmailMutation> = {
			toInput: (input) => ({
				variables: {
					email: input.email,
				},
			}),
			toOutput: (output) => {
				if (output.errors) {
					throw new Error(t("domain.authentication.error.triggerForgotTagIdEmailFailed"), { cause: new ApolloError({ graphQLErrors: output.errors }) });
				}

				return output.data?.forgotPupilData.success || false;
			},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
