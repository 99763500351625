import { UIComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { ConfirmationLink } from "components/confirmationLink/confirmationLink";
import { ConfirmationLinkProps } from "components/confirmationLink/useConfirmationLink";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styles from "./stepSection.module.css";

interface Common {
	isValid?: boolean;
}

interface StepSectionProps {
	className?: string;
	children: ReactNode
}

interface FirstStepFooterProps extends Common {
	cancelHref: string;
	currentStepHref: string;
	nextStepHref: string;
}

interface MiddleStepFooterProps extends Common {
	previousStepHref: string;
	currentStepHref: string;
	nextStepHref: string;
}

interface LastStepFooterProps extends Common {
	previousStepHref: string;
}

type ConfirmStepFooterProps = MiddleStepFooterProps & Omit<ConfirmationLinkProps, "children">;

export const StepSection: UIComponent<StepSectionProps> = ({ className, children }) => (
	<section className={`${styles.container} ${className}`}>
		{children}
	</section>
);

export const FirstStepFooter: UIComponent<FirstStepFooterProps> = ({
	cancelHref, currentStepHref, nextStepHref, isValid,
}) => {
	const { t } = useTranslation();

	return (
		<footer>
			<A href={cancelHref} type="button" className="secondary">
				{t("stepSection.cancel")}
			</A>
			<A href={isValid ? nextStepHref : currentStepHref} type="button" aria-disabled={!isValid}>
				{t("stepSection.nextStep")}
			</A>
		</footer>
	);
};

export const MiddleStepFooter: UIComponent<MiddleStepFooterProps> = ({
	currentStepHref, nextStepHref, previousStepHref, isValid,
}) => {
	const { t } = useTranslation();

	return (
		<footer>
			<A href={previousStepHref} type="button" className="secondary">
				{t("stepSection.previousStep")}
			</A>
			<A href={isValid ? nextStepHref : currentStepHref} type="button" aria-disabled={!isValid}>
				{t("stepSection.nextStep")}
			</A>
		</footer>
	);
};

export const LastStepFooter: UIComponent<LastStepFooterProps> = ({ previousStepHref, isValid }) => {
	const { t } = useTranslation();

	return (
		<footer>
			<A href={previousStepHref} type="button" className="secondary">
				{t("stepSection.previousStep")}
			</A>
			<button type="submit" disabled={!isValid}>
				{t("stepSection.submit")}
			</button>
		</footer>
	);
};

export const ConfirmStepFooter: UIComponent<ConfirmStepFooterProps> = ({
	currentStepHref, nextStepHref, previousStepHref, isValid, ...confirmationLinkProps
}) => {
	const { t } = useTranslation();

	return (
		<footer>
			<A href={previousStepHref} type="button" className="secondary">
				{t("stepSection.previousStep")}
			</A>
			<ConfirmationLink href={isValid ? nextStepHref : currentStepHref} type="button" aria-disabled={!isValid} {...confirmationLinkProps}>
				{t("stepSection.nextStep")}
			</ConfirmationLink>
		</footer>
	);
};
