import { PageComponent } from "@aptus/frontend-core";
import { Header } from "components/header/header";
import { useRoutes } from "domains";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { A } from "components/a";

interface Props {
	applyOwnMargin?: boolean;
}

export const HeaderPage: PageComponent<Props> = ({ applyOwnMargin }) => {
	const { routes } = useRoutes();
	const { t } = useTranslation();

	return (
		<>
			<Header href={routes.index} applyOwnMargin={applyOwnMargin}>
				<A href={routes.whatIsHighFive}>{t("whatIsHighFiveLink")}</A>
				<A href={routes.whereToHighFive}>{t("whereToHighFive")}</A>
				<A href={routes.frequentlyAskedQuestions.index}>{t("frequentlyAskedQuestions")}</A>
				<A href={routes.login} type="button" className="secondary">{t("login")}</A>
			</Header>
			<main>
				<Outlet />
			</main>
		</>
	);
};
