import { InputProps } from "components/input/input";
import { forwardRef } from "react";
import styles from "./tagInput.module.css";

export const TagInput = forwardRef<HTMLInputElement, InputProps>(({
	label, error, className, ...rest
}, ref) => (
	<label htmlFor={rest.id} className={`${className} ${rest.disabled ? "disabled" : ""} ${styles.container}`} aria-invalid={!!error}>
		{label}
		<input aria-invalid={!!error} {...rest} ref={ref} aria-errormessage={error} />
		<div className={styles.errorContainer}>
			<figure className={`message ${(error) ? "error" : ""}`}>
				<figcaption>{error}</figcaption>
			</figure>
		</div>
	</label>
));
