import { UILogic } from "@aptus/frontend-core";
import {
	AnchorHTMLAttributes, Children, cloneElement, isValidElement, ReactNode, ReducerWithoutAction, useReducer,
} from "react";

interface Props {
	children: ReactNode;
}

interface Result {
	children: ReactNode;
	isOpen: boolean;
	toggle: () => void;
}

export type HamburgerProps = Props;
export type HamburgerLayoutProps = Result;

export const useHamburger: UILogic<Props, Result> = ({ children: _children }) => {
	const reducer: ReducerWithoutAction<Result["isOpen"]> = (state) => !state;
	const [isOpen, toggle] = useReducer(reducer, false);

	const toChildWithOnClick = (child: ReactNode): ReactNode => (isValidElement(child)
		? cloneElement<AnchorHTMLAttributes<HTMLAnchorElement>>(child, { onClick: toggle })
		: child);

	return {
		isOpen,
		toggle,
		children: Children.map(_children, toChildWithOnClick),
	};
};
