import { API, Mutation, UseCases } from "@aptus/frontend-core";
import { event } from "event";
import { t } from "i18next";
import { useState } from "react";
import { FieldPath } from "react-hook-form";
import { tracker } from "utils/Tracker";
import { HTMLValidationSchema, isValid, validateSchema } from "utils/html-validation-schema";
import { HTMLValidationSchemaGenerator } from "utils/html-validation-schema/models/validationSchema";
import { useIsLoading } from "utils/useIsLoading";
import {
	ForgotTagIdInput,
	LoginInput, RegisterInput, RequestPasswordResetInput, ResetPasswordInput, StartInput, TriggerForgotTagIdEmailMutationInput, ValidateClassNumberInput, ValidateTagInput,
} from "./models/authenticationInput";
import { useGetProjectUrlLazyQueryDTO } from "./models/getProjectUrl";
import { Policy } from "./models/policy";
import { TagType } from "./models/tagType";

export type PoliciesAPI = API<Policy[]>;

export type GenerateUsernameMutation = Mutation<void, { username: string, id: string }>;
export type LoginMutation = Mutation<LoginInput, string>;
export type RegisterMutation = Mutation<RegisterInput, void>;
export type RequestPasswordResetMutation = Mutation<RequestPasswordResetInput, void>;
export type ResetPasswordMutation = Mutation<ResetPasswordInput, void>;
export type ValidateTagMutation = Mutation<ValidateTagInput, boolean>;
export type ValidateClassNumberMutation = Mutation<ValidateClassNumberInput, boolean>;
export type TriggerForgotTagIdEmailMutation = Mutation<TriggerForgotTagIdEmailMutationInput, boolean>;

interface Props {
	api: PoliciesAPI;
	generateUsernameMutation: GenerateUsernameMutation;
	loginMutation: LoginMutation;
	registerMutation: RegisterMutation;
	requestPasswordResetMutation: RequestPasswordResetMutation;
	resetPasswordMutation: ResetPasswordMutation;
	validateTagMutation: ValidateTagMutation;
	validateClassNumberMutation: ValidateClassNumberMutation;
	triggerForgotTagIdEmailMutation: TriggerForgotTagIdEmailMutation;
}

interface Result {
	policies: Policy[];
	isLoading: boolean;
	username?: { username: string, id: string };
	startSchema: HTMLValidationSchema<StartInput>;
	loginSchema: HTMLValidationSchema<LoginInput>;
	requestPasswordResetSchema: HTMLValidationSchema<RequestPasswordResetInput>;
	forgotTagIdSchema: HTMLValidationSchema<ForgotTagIdInput>;
	defaultLoginInput: LoginInput;
	defaultRegisterInput: RegisterInput;
	defaultRequestPasswordResetInput: RequestPasswordResetInput;
	defaultResetPasswordInput: ResetPasswordInput;
	defaultForgotTagIdInput: ForgotTagIdInput;
	defaultStartInput: StartInput;
	registerSchemaGenerator: HTMLValidationSchemaGenerator<RegisterInput>;
	resetPasswordSchemaGenerator: HTMLValidationSchemaGenerator<ResetPasswordInput>;
	forgotTagIdSchemaGenerator: HTMLValidationSchemaGenerator<ForgotTagIdInput>;
	isRegistrationStepValid: (fields: FieldPath<RegisterInput>[], input: RegisterInput) => boolean;
	start: (input: StartInput) => Promise<void>;
	login: (input: LoginInput) => Promise<void>;
	generateUsername: () => Promise<void>;
	register: (input: RegisterInput) => Promise<void>;
	requestPasswordReset: (input: RequestPasswordResetInput) => Promise<void>;
	resetPassword: (input: ResetPasswordInput) => Promise<void>;
	validateTag: (input: RegisterInput & ValidateTagInput) => Promise<string>;
	validateClassNumber: (input: ValidateClassNumberInput) => Promise<string>;
	forgotTagId: (input: ForgotTagIdInput) => Promise<void>;
}

export interface AuthenticationEvents {
	tokenExpired: () => void;
	registrationStarted: () => void;
	loggedIn: (schoolUrl: string, token: string, targetPath?: string) => void;
	pupilRegistered: () => void;
	passwordReset: () => void;
	/* Temporary events until we have a decent system for centralized async form validation. */
	validationErrorOcurred: (field: string, message: string) => void;
	loginStarted: () => void;
}

export const useAuthenticationUseCases: UseCases<Props, Result> = ({
	api, generateUsernameMutation, loginMutation, registerMutation, requestPasswordResetMutation, resetPasswordMutation, validateClassNumberMutation, validateTagMutation, triggerForgotTagIdEmailMutation,
}) => {
	const [isLoading, setIsLoading] = useIsLoading(api.isLoading);
	const [username, setUsername] = useState<Result["username"]>(undefined);

	const [getProjectUrl] = useGetProjectUrlLazyQueryDTO();

	const policies: Policy[] = api?.data;
	const emailPattern: string = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((?!(ikhighfive\.be|ikhighfive\.nl)$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/.source;

	const startSchema: Result["startSchema"] = {
		tagId: {
			required: true,
			minLength: 8,
			maxLength: 8,
			pattern: /[0-9a-fA-F]{8}/.source,
			patternMismatch: t("domain.authentication.error.invalidWristband"),
		},
	};

	const loginSchema: Result["loginSchema"] = {
		nfcTagId: { required: true },
		nfcTagType: { required: true },
		password: { required: true },
	};

	const registerSchemaGenerator: Result["registerSchemaGenerator"] = (input) => {
		const emails = (input.emails || "").split(/,|\s+/).filter((v) => !!v);
		const areEmailsValid = (_emails: string[]) => _emails.every((email) => email.match(emailPattern));

		return {
			wristband: {
				required: !input.hasNoWristband,
				minLength: input.hasNoWristband ? undefined : 8,
				maxLength: 8,
				pattern: input.hasNoWristband ? undefined : /[0-9a-fA-F]{8}/.source,
				patternMismatch: t("domain.authentication.error.invalidWristband"),
			},
			biketag: {
				required: !input.hasNoBiketag,
				minLength: input.hasNoBiketag ? undefined : 8,
				maxLength: 14,
				pattern: input.hasNoBiketag ? undefined : /^([mM][dD][0-9]{12})$|^([0-9a-fA-F]{8})$/.source,
				patternMismatch: t("domain.authentication.error.invalidBiketag"),
			},
			hasNoBiketag: { required: false },
			hasNoWristband: { required: false },
			classroom: { required: true },
			requireClassNumber: { required: false },
			classNumber: { required: input.requireClassNumber, min: "1" },
			project: { required: true },
			school: { required: true },
			username: { required: true },
			usernameId: { required: true },
			password: { required: true },
			passwordConfirmation: { required: true, pattern: input.password !== input.passwordConfirmation ? /$^/.source : undefined, patternMismatch: t("domain.authentication.error.passwordConfirmationMustMatch") },
			emails: { required: true, pattern: areEmailsValid(emails) ? undefined : /$^/.source, patternMismatch: t("domain.authentication.error.invalidEmail") },
			acceptRewardAndUpdateNotifications: { required: false },
			acceptPolicies: policies.reduce((acc, policy) => ({ ...acc, [policy.type]: { required: policy.required } }), []) as any,
			dataIsVerified: { required: true },
		};
	};

	const requestPasswordResetSchema: Result["requestPasswordResetSchema"] = {
		nfcTagId: { required: true },
		nfcTagType: { required: true },
	};

	const forgotTagIdSchema: Result["forgotTagIdSchema"] = {
		email: { required: true, pattern: emailPattern },
	};

	const resetPasswordSchemaGenerator: Result["resetPasswordSchemaGenerator"] = (input) => ({
		password: { required: true },
		passwordConfirmation: { required: true, pattern: new RegExp(input.password).source, patternMismatch: t("domain.authentication.error.passwordConfirmationMustMatch") },
	});

	const forgotTagIdSchemaGenerator: Result["forgotTagIdSchemaGenerator"] = () => ({
		email: { required: true, pattern: emailPattern },
	});

	const validateTagSchemaGenerator = ({ tag, type, ...input }: ValidateTagInput & RegisterInput): HTMLValidationSchema<ValidateTagInput> => ({
		tag: type === TagType.Wristband
			? registerSchemaGenerator(input).wristband
			: registerSchemaGenerator(input).biketag,
		type: { required: false },
	});

	const validateClassNumberSchema: HTMLValidationSchema<ValidateClassNumberInput> = {
		classroom: { required: true },
		classNumber: { required: true, min: "1" },
	};

	const defaultLoginInput: Result["defaultLoginInput"] = {
		nfcTagId: localStorage.getItem("tag") || "",
		nfcTagType: "HIGH_FIVE",
		password: "",
	};

	const defaultRegisterInput: Result["defaultRegisterInput"] = {
		biketag: "",
		classroom: "",
		requireClassNumber: false,
		school: "",
		project: "",
		hasNoBiketag: false,
		username: "",
		usernameId: "",
		wristband: localStorage.getItem("tag") || "",
		hasNoWristband: false,
		password: "",
		passwordConfirmation: "",
		emails: "",
		acceptRewardAndUpdateNotifications: false,
		acceptPolicies: policies.reduce((acc, policy) => ({ ...acc, [policy.type]: false }), []) as any,
		dataIsVerified: false,
	};

	const defaultRequestPasswordResetInput: Result["defaultRequestPasswordResetInput"] = {
		nfcTagId: localStorage.getItem("tag") || "",
		nfcTagType: "HIGH_FIVE",
	};

	const defaultResetPasswordInput: Result["defaultResetPasswordInput"] = {
		password: "",
		passwordConfirmation: "",
	};

	const defaultForgotTagIdInput: Result["defaultForgotTagIdInput"] = {
		email: "",
	};

	const defaultStartInput: Result["defaultStartInput"] = {
		tagId: localStorage.getItem("tag") || "",
	};

	const isRegistrationStepValid: Result["isRegistrationStepValid"] = (fields, input) => {
		const errors = validateSchema(input, registerSchemaGenerator(input));
		const invalidFields = Object.entries(errors)
			.filter(([, message]) => message)
			.map(([field]) => field as keyof RegisterInput);

		return !invalidFields.some((field) => fields.includes(field));
	};

	const start: Result["start"] = async (input) => {
		try {
			if (isValid(input, startSchema)) {
				setIsLoading(true);
				localStorage.setItem("tag", input.tagId);
				const isTagValid = await validateTagMutation({
					tag: input.tagId,
					type: TagType.Wristband,
				});

				if (isTagValid) {
					event.emit("registrationStarted");
				} else {
					event.emit("loginStarted");
				}
			}
		} catch (error) {
			if (error instanceof Error) {
				tracker.captureException(error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const login: Result["login"] = async (input) => {
		try {
			if (isValid(input, loginSchema)) {
				setIsLoading(true);
				const token = await loginMutation(input);

				if (token) {
					localStorage.setItem("tag", input.nfcTagId);
					const { data } = await getProjectUrl({
						context: {
							headers: {
								authorization: `Bearer ${token}`,
							},
						},
					});
					if (data && data.projects && data.projects && data.projects.length) {
						const [project] = data.projects;
						event.emit("loggedIn", project.url, token, input.targetPath);
					}
				} else {
					throw new Error("domain.authentication.error.loginFailed");
				}
			}
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const generateUsername: Result["generateUsername"] = async () => {
		try {
			setIsLoading(true);
			setUsername(await generateUsernameMutation());
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}

		return undefined;
	};

	const register: Result["register"] = async (input) => {
		try {
			if (isValid(input, registerSchemaGenerator(input))) {
				setIsLoading(true);
				await registerMutation(input);
				event.emit("pupilRegistered");
			}
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const requestPasswordReset: Result["requestPasswordReset"] = async (input) => {
		try {
			if (isValid(input, requestPasswordResetSchema)) {
				setIsLoading(true);
				await requestPasswordResetMutation(input);
				event.emit("mutationSucceeded", t("domain.authentication.requestPasswordResetSuccessful"));
			}
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const resetPassword: Result["resetPassword"] = async (input) => {
		try {
			if (isValid(input, resetPasswordSchemaGenerator(input))) {
				setIsLoading(true);
				await resetPasswordMutation(input);
				event.emit("mutationSucceeded", t("domain.authentication.resetPasswordSuccessful"));
				event.emit("passwordReset");
			}
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const validateClassNumber: Result["validateClassNumber"] = async (input) => {
		try {
			if (isValid(input, validateClassNumberSchema)) {
				setIsLoading(true);
				const isClassNumberValid = await validateClassNumberMutation(input);
				if (!isClassNumberValid) throw new Error(t("domain.authentication.error.classNumberAlreadyExists"));
			} else {
				throw new Error(validateSchema(input, validateClassNumberSchema).classNumber);
			}

			return "";
		} catch (error) {
			if (error instanceof Error) {
				event.emit("validationErrorOcurred", "classNumber", error.message);
			}

			return await t("domain.authentication.error.classNumberAlreadyExists");
		} finally {
			setIsLoading(false);
		}
	};

	const validateTag: Result["validateTag"] = async (input) => {
		try {
			if (isValid(input, validateTagSchemaGenerator(input))) {
				setIsLoading(true);
				const isTagValid = await validateTagMutation(input);

				if (!isTagValid) {
					throw new Error(t("domain.authentication.error.tagAlreadyExists"));
				}
			} else {
				throw new Error(validateSchema(input, validateTagSchemaGenerator(input)).tag);
			}

			return "";
		} catch (error) {
			if (error instanceof Error) {
				event.emit("validationErrorOcurred", input.tag.length === 14 ? "biketag" : "wristband", error.message);
			}

			return await t("domain.authentication.error.tagAlreadyExists");
		} finally {
			setIsLoading(false);
		}
	};

	const forgotTagId: Result["forgotTagId"] = async (input) => {
		try {
			if (isValid(input, forgotTagIdSchemaGenerator(input))) {
				setIsLoading(true);
				const success = await triggerForgotTagIdEmailMutation(input);

				if (success) {
					event.emit("mutationSucceeded", t("domain.authentication.triggerForgotTagIdEmailSuccessful"));
				} else {
					event.emit("mutationFailed", t("domain.authentication.error.triggerForgotTagIdEmailFailed"));
				}
				setIsLoading(false);
			}
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return {
		...api,
		policies: api.data,
		isLoading,
		username,
		startSchema,
		loginSchema,
		generateUsername,
		registerSchemaGenerator,
		defaultLoginInput,
		defaultRegisterInput,
		isRegistrationStepValid,
		start,
		login,
		register,
		defaultRequestPasswordResetInput,
		defaultResetPasswordInput,
		requestPasswordResetSchema,
		requestPasswordReset,
		resetPassword,
		resetPasswordSchemaGenerator,
		defaultStartInput,
		validateTag,
		forgotTagIdSchemaGenerator,
		defaultForgotTagIdInput,
		forgotTagIdSchema,
		forgotTagId,
		validateClassNumber,
	};
};

export const useAuthenticationEffects = (): void => { };
