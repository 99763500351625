import { PageComponent } from "@aptus/frontend-core";
import { useAuthentication } from "domains";
import { ResetPasswordForm } from "./components/resetPasswordForm";
import styles from "./passwordResetPage.module.css";

export const PasswordResetPage: PageComponent = () => {
	const {
		resetPassword, resetPasswordSchemaGenerator, defaultResetPasswordInput, isLoading,
	} = useAuthentication();

	return (
		<div className={styles.page}>
			<ResetPasswordForm
				defaultValues={defaultResetPasswordInput}
				schema={resetPasswordSchemaGenerator}
				submit={resetPassword}
				isLoading={isLoading}
			/>
		</div>
	);
};
