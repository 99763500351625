import { t } from "i18next";
import { Rule } from "./models/rule";

export const validateRule = <Value extends string | number | undefined>(value: Value, rule: Rule): string | undefined => {
	if (rule.required && !value) {
		return rule.valueMissing || t("error.valueMissing");
	}

	if (value) {
		if (typeof value === "string") {
			if (rule.minLength && value.length < rule.minLength) {
				return rule.tooShort || t("error.tooShort");
			}

			if (rule.maxLength && value.length > rule.maxLength) {
				return rule.tooLong || t("error.tooLong");
			}

			if (rule.pattern && !value.match(rule.pattern)) {
				return rule.patternMismatch || t("error.patternMismatch");
			}
		}

		if (rule.min && value < rule.min) {
			return rule.rangeUnderflow || t("error.rangeUnderflow");
		}

		if (rule.max && value > rule.max) {
			return rule.rangeOverflow || t("error.rangeOverflow");
		}
	}

	return undefined;
};
