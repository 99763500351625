import { ApolloProvider } from "@apollo/client";
import { client } from "client";
import { CookieModal } from "components/cookieModal/cookieModal";
import { LocationRedirectModal } from "components/locationRedirectModal/locationRedirectModal";
import { Notification } from "components/notification/notification";
import { useCookies, useLocationRedirect, useNotifications } from "domains";
import { useNotificationsEffects } from "hooks/notifications/useNotificationsUseCases";
import "i18n";
import { FunctionComponent, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { tracker } from "utils/Tracker";
import { Content } from "./Routes";
import { reportWebVitals } from "./reportWebVitals";
import "./style/index.css";

/*
// Set `onUnhandledRequest` to `warn` to log unhandled requests to the console.
if (process.env.REACT_APP_ENVIRONMENT === "local") {
	worker.start({
		onUnhandledRequest: "bypass",
	});
}
*/

tracker.init();

if (!Object.fromEntries) {
	Object.fromEntries = (entries: any[]) => {
		if (!entries || !entries[Symbol.iterator]) { throw new Error("Object.fromEntries() requires a single iterable argument"); }
		const obj: any = {};
		entries.forEach(([key, value]) => {
			obj[key] = value;
		});
		return obj;
	};
}

const App: FunctionComponent = () => {
	const notifications = useNotifications();
	useNotificationsEffects(notifications);
	const { notification, closeNotification } = notifications;

	const cookies = useCookies();
	const {
		shouldShowCookieModal,
		acceptedAllCookies,
		acceptedFunctionalCookies,
	} = cookies;

	const {
		shouldShowLocationRedirectModal,
		mostRelevantLocaleConfig,
		currentLocaleConfig,
		changeToMostRelevantSiteForLanguage,
		stayOnSite,
		neverAskAgain,
	} = useLocationRedirect();

	return (
		<StrictMode>
			<ApolloProvider client={client}>
				<BrowserRouter>
					<Content />
					{notification && <Notification {...notification} close={closeNotification} />}
					{shouldShowCookieModal && <CookieModal acceptAllCookies={acceptedAllCookies} acceptFunctionalCookies={acceptedFunctionalCookies} />}
					{shouldShowLocationRedirectModal && <LocationRedirectModal changeToMostRelevantSiteForLanguage={changeToMostRelevantSiteForLanguage} stayOnSite={stayOnSite} mostRelevantLocaleConfig={mostRelevantLocaleConfig} currentLocaleConfig={currentLocaleConfig} neverAskAgain={neverAskAgain} /> }
				</BrowserRouter>
			</ApolloProvider>
		</StrictMode>
	);
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
