/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateUsernameVariablesDTO = Types.Exact<{ [key: string]: never; }>;


export type GenerateUsernameDTO = { __typename?: 'Mutation', generateOneUsername: { __typename?: 'Username', id: string, username: string } };


export const GenerateUsernameDocumentDTO = gql`
    mutation generateUsername {
  generateOneUsername {
    id
    username
  }
}
    `;
export type GenerateUsernameMutationFnDTO = Apollo.MutationFunction<GenerateUsernameDTO, GenerateUsernameVariablesDTO>;

/**
 * __useGenerateUsername__
 *
 * To run a mutation, you first call `useGenerateUsername` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUsername` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUsername, { data, loading, error }] = useGenerateUsername({
 *   variables: {
 *   },
 * });
 */
export function useGenerateUsername(baseOptions?: Apollo.MutationHookOptions<GenerateUsernameDTO, GenerateUsernameVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateUsernameDTO, GenerateUsernameVariablesDTO>(GenerateUsernameDocumentDTO, options);
      }
export type GenerateUsernameHookResult = ReturnType<typeof useGenerateUsername>;
export type GenerateUsernameMutationResultDTO = Apollo.MutationResult<GenerateUsernameDTO>;
export type GenerateUsernameMutationOptionsDTO = Apollo.BaseMutationOptions<GenerateUsernameDTO, GenerateUsernameVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    