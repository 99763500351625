import { UILogic } from "@aptus/frontend-core";
import { ForgotTagIdInput } from "hooks/authentication/models/authenticationInput";
import { FormEvent } from "react";
import {
	FieldErrors, FieldPath, UseFormRegisterReturn, useForm,
} from "react-hook-form";
import { HTMLValidationSchema } from "utils/html-validation-schema";
import { HTMLValidationSchemaResolver } from "utils/html-validation-schema/compat/HTMLValidationSchemaResolver";

interface Common {
	isLoading: boolean;
}

interface Props {
	submit: (input: ForgotTagIdInput) => void;
	schema: HTMLValidationSchema<ForgotTagIdInput>;
	defaultValues: ForgotTagIdInput;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>) => void;
	register: (field: FieldPath<ForgotTagIdInput>) => UseFormRegisterReturn;
	errors: FieldErrors<ForgotTagIdInput>;
}

export type ForgotTagIdInputProps = Common & Props;
export type ForgotTagIdInputLayoutProps = Common & Result;

export const useForgotTagIdForm: UILogic<Props, Result> = ({
	submit, schema, defaultValues,
}) => {
	const {
		handleSubmit, register, formState,
	} = useForm<ForgotTagIdInput>({
		mode: "onBlur",
		defaultValues,
		resolver: HTMLValidationSchemaResolver(schema),
	});

	return {
		submit: handleSubmit(submit),
		register,
		errors: formState.errors,
	};
};
