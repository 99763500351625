import { PageComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { useRoutes } from "domains";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import styles from "./introductionPage.module.css";

export const IntroductionPage: PageComponent = () => {
	const { routes, goToRegister } = useRoutes();
	const { t } = useTranslation();

	return (
		<section className={styles.page}>
			<YouTube videoId="p3ffFlkWDq0" onEnd={goToRegister} className={styles.video} />
			<A href={routes.register.stepOne} type="button">{t("skipIntroduction")}</A>
		</section>
	);
};
