import { UIComponent, UILayout } from "@aptus/frontend-core";
import { A } from "components/a";
import { useTranslation } from "react-i18next";
import styles from "./stepper.module.css";
import {
	StepperLayoutProps, StepperProps, StepProps, useStepper,
} from "./useStepper";

const Layout: UILayout<StepperLayoutProps> = ({
	mobileClassName = "", desktopClassName = "", children, activeStep, nextStep, activeStepIndex, stepsCount, progressElementRef,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={`${styles.mobile} ${mobileClassName}`}>
				<div className={styles.progressContainer}>
					<svg className={styles.progressSvg}>
						<circle cx="30" cy="30" r="30" className={`${styles.circle} ${styles.progressCircle}`} ref={progressElementRef} />
						<circle cx="30" cy="30" r="30" className={`${styles.circle} ${styles.backgroundCircle}`} />
					</svg>
					<p className={styles.progressText}>
						<strong>{t("stepper.progress", { progress: activeStepIndex + 1, total: stepsCount })}</strong>
					</p>
				</div>
				<div>
					<h3>{activeStep}</h3>
					<p className="medium">{t("stepper.next", { next: nextStep })}</p>
				</div>
			</div>
			<div className={`${styles.stepper} ${desktopClassName}`}>
				{children}
			</div>
		</>
	);
};

export const Stepper: UIComponent<StepperProps> = (props) => (
	<Layout {...props} {...useStepper(props)} />
);

export const Step: UIComponent<StepProps> = ({
	icon, href, isActive, isDone, children,
}) => (
	<A
		type="button"
		href={href}
		className={`
			step 
			${isDone && !isActive ? "done" : ""}
			${isActive ? "active" : ""}
		}`}
	>
		<i>{icon}</i>
		{children}
	</A>
);
