import { UIComponent, UILayout } from "@aptus/frontend-core";
import { HamburgerLayoutProps, HamburgerProps, useHamburger } from "./useHamburger";
import styles from "./hamburger.module.css";

const Layout: UILayout<HamburgerLayoutProps> = ({ isOpen, toggle, children }) => (
	<>
		<button type="button" className={`${styles.hamburger} text`} onClick={toggle}>
			{isOpen ? <i>close</i> : <i>menu</i>}
		</button>
		<nav className={`${styles.navigation} ${isOpen ? styles.isOpen : ""}`}>
			{children}
		</nav>
	</>
);

export const Hamburger: UIComponent<HamburgerProps> = (props) => (
	<Layout {...props} {...useHamburger(props)} />
);
