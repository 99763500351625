/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPoliciesVariablesDTO = Types.Exact<{ [key: string]: never; }>;


export type GetPoliciesDTO = { __typename?: 'Query', policies: Array<{ __typename?: 'Policy', id: string, type: Types.PolicyTypeDTO, url: string, required: boolean }> };


export const GetPoliciesDocumentDTO = gql`
    query getPolicies {
  policies {
    id
    type
    url
    required
  }
}
    `;

/**
 * __useGetPolicies__
 *
 * To run a query within a React component, call `useGetPolicies` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicies` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicies({
 *   variables: {
 *   },
 * });
 */
export function useGetPolicies(baseOptions?: Apollo.QueryHookOptions<GetPoliciesDTO, GetPoliciesVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPoliciesDTO, GetPoliciesVariablesDTO>(GetPoliciesDocumentDTO, options);
      }
export function useGetPoliciesLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetPoliciesDTO, GetPoliciesVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPoliciesDTO, GetPoliciesVariablesDTO>(GetPoliciesDocumentDTO, options);
        }
export type GetPoliciesHookResult = ReturnType<typeof useGetPolicies>;
export type GetPoliciesLazyQueryDTOHookResult = ReturnType<typeof useGetPoliciesLazyQueryDTO>;
export type GetPoliciesQueryResultDTO = Apollo.QueryResult<GetPoliciesDTO, GetPoliciesVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    