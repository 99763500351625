/* eslint-disable jsx-a11y/anchor-has-content */
import { UIComponent } from "@aptus/frontend-core";
import { AnchorHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { isExternalURL } from "utils/isExternalURL";

type Props = AnchorHTMLAttributes<HTMLAnchorElement>;

export const A: UIComponent<Props> = ({ href = "#", ...rest }) => (isExternalURL(href)
	? <a href={href} {...rest} target="_blank" rel="noreferrer" />
	: <Link to={href} {...rest} />);
