import { UIComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { Notification as NotificationType } from "hooks/notifications/models/notification";
import { Trans } from "react-i18next";
import styles from "./notification.module.css";

type Props = NotificationType & {
	close: () => void;
};

export const Notification: UIComponent<Props> = ({ message, type, close }) => (
	<figure className={`message ${type} ${styles.container}`}>
		<button type="button" onClick={close} className="text">
			<i>close</i>
		</button>
		<figcaption>
			{message}
			{type === "error" && (
				<>
					<br />
					<Trans i18nKey="notification.contact" components={{ italic: <A href="mailto:hallo@ikhighfive.be" /> }} />
				</>
			)}
		</figcaption>
	</figure>
);
