import { UIComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { Trans } from "react-i18next";
import { formatImageUrl } from "utils/formatImageUrl";
import styles from "./cookieModal.module.css";

interface Props {
	acceptAllCookies: () => void;
	acceptFunctionalCookies: () => void;
}

export const CookieModal: UIComponent<Props> = ({ acceptAllCookies, acceptFunctionalCookies }) => (
	<div className={`${styles["cookie-modal"]}`}>
		<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/five-cookies.png")} alt="Five Cookies" />
		<div className={`${styles.text}`}>
			<p>
				<Trans i18nKey="cookies.explanation" components={{ italic: <A href="/cookie-policy" /> }} />
			</p>
			<button onClick={acceptAllCookies} type="button">
				<Trans i18nKey="cookies.acceptAll" />
			</button>
			<button onClick={acceptFunctionalCookies} type="button" className="secondary">
				<Trans i18nKey="cookies.acceptFunctional" />
			</button>
		</div>
	</div>
);
