import { PageComponent } from "@aptus/frontend-core";
import { isAfter, isBefore } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { formatImageUrl } from "utils/formatImageUrl";
import styles from "./webinarPage.module.css";

export interface Webinar {
	title: string;
	time: string;
	link: string;
	visibility: { from: string, to: string };
	highlighting: { from: string, to: string };
}

export const WebinarPage: PageComponent = () => {
	const { t } = useTranslation();
	const webinars = t("webinar.webinars", { returnObjects: true }) as Webinar[];
	const filteredWebinars = webinars
		.filter((webinar) => (!webinar.visibility || !webinar.visibility.from || isAfter(new Date(), new Date(webinar.visibility.from))))
		.filter((webinar) => (!webinar.visibility || !webinar.visibility.to || isBefore(new Date(), new Date(webinar.visibility.to))))
		.map((webinar) => {
			const highlightingEnabled = webinar.highlighting && (webinar.highlighting.from || webinar.highlighting.to);
			const shouldHighlight = highlightingEnabled
				&& (!webinar.highlighting || !webinar.highlighting.from || isAfter(new Date(), new Date(webinar.highlighting.from)))
				&& (!webinar.highlighting || !webinar.highlighting.from || isAfter(new Date(), new Date(webinar.highlighting.from)));

			return {
				...webinar,
				shouldHighlight,
			};
		});

	return (
		<>
			<div className={styles.header}>
				<h1>{t("webinar.title")}</h1>
			</div>
			<div className={styles.content}>
				<div className={styles.text}>
					<p>
						<Trans i18nKey="webinar.intro" components={{ br: <br /> }} />
					</p>
					{
						filteredWebinars && filteredWebinars.length ? (
							<p>
								<Trans i18nKey="webinar.explanation" components={{ br: <br /> }} />
							</p>
						) : null
					}
					{
						filteredWebinars
							.map((webinar) => (
								<a href={webinar.link} target="_blank" rel="noreferrer">
									<button type="button" className={!webinar.shouldHighlight ? "secondary" : ""}>
										{`${webinar.title}: ${webinar.time}`}
									</button>
								</a>
							))
					}
				</div>
			</div>
			<div>
				<iframe width="420" height="315" src="https://www.youtube.com/embed/80ec6enG_3E" title="Webinar High-Five" />
			</div>
			<div className={styles.vibe}>
				<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/laying_five.png")} alt="High-Five Webinars" />
			</div>
		</>
	);
};
