import { UIComponent } from "@aptus/frontend-core";
import { ReactNode } from "react";
import styles from "./listSection.module.css";

interface Props {
	icon: string;
	title: string;
	children: ReactNode;
}

export const ListSection: UIComponent<Props> = ({ icon, title, children }) => (
	<section className={styles.container}>
		<h3>
			<i>{icon}</i>
			{` ${title}`}
		</h3>
		<ul>
			{children}
		</ul>
	</section>
);
