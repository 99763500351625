export enum PolicyType {
	PrivacyPolicy = "privacyPolicy",
	DataProcessingAgreement = "dataProcessingAgreement",
}

export interface Policy {
	id: string;
	type: PolicyType;
	url: string;
	required: boolean;
}
