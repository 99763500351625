/* eslint-disable */
import * as Types from '../../../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetClassroomsBySchoolVariablesDTO = Types.Exact<{
  schoolId: Types.Scalars['String'];
}>;


export type GetClassroomsBySchoolDTO = { __typename?: 'Query', classes: Array<{ __typename?: 'Class', id: string, title: string }> };


export const GetClassroomsBySchoolDocumentDTO = gql`
    query getClassroomsBySchool($schoolId: String!) {
  classes(
    filter: {school: {id: {equals: $schoolId}}}
    sort: [{field: "title", order: ASC}]
  ) {
    id
    title
  }
}
    `;

/**
 * __useGetClassroomsBySchool__
 *
 * To run a query within a React component, call `useGetClassroomsBySchool` and pass it any options that fit your needs.
 * When your component renders, `useGetClassroomsBySchool` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassroomsBySchool({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetClassroomsBySchool(baseOptions: Apollo.QueryHookOptions<GetClassroomsBySchoolDTO, GetClassroomsBySchoolVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassroomsBySchoolDTO, GetClassroomsBySchoolVariablesDTO>(GetClassroomsBySchoolDocumentDTO, options);
      }
export function useGetClassroomsBySchoolLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetClassroomsBySchoolDTO, GetClassroomsBySchoolVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassroomsBySchoolDTO, GetClassroomsBySchoolVariablesDTO>(GetClassroomsBySchoolDocumentDTO, options);
        }
export type GetClassroomsBySchoolHookResult = ReturnType<typeof useGetClassroomsBySchool>;
export type GetClassroomsBySchoolLazyQueryDTOHookResult = ReturnType<typeof useGetClassroomsBySchoolLazyQueryDTO>;
export type GetClassroomsBySchoolQueryResultDTO = Apollo.QueryResult<GetClassroomsBySchoolDTO, GetClassroomsBySchoolVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    