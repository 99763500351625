import { UIComponent } from "@aptus/frontend-core";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

interface Props {
	isAuthorized: boolean;
	redirectTo: string;
	children: ReactElement | null;
}

export const Private: UIComponent<Props> = ({ isAuthorized, redirectTo, children }) => (
	isAuthorized
		? children
		: <Navigate to={redirectTo} />
);
