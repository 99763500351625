import { Domain } from "@aptus/frontend-core";
import { AuthenticationMapper, PoliciesAPIDTO } from "hooks/authentication/AuthenticationMapper";
import { useTriggerForgotTagIdEmail } from "hooks/authentication/models/forgotTagId";
import { useGenerateUsername } from "hooks/authentication/models/generateUsername";
import { useLogin } from "hooks/authentication/models/login";
import { useRegister } from "hooks/authentication/models/register";
import { useRequestPasswordReset } from "hooks/authentication/models/requestPasswordReset";
import { useResetPassword } from "hooks/authentication/models/resetPassword";
import { useValidateClassNumber } from "hooks/authentication/models/validateClassNumber";
import { useValidateTag } from "hooks/authentication/models/validateTag";
import { useAuthenticationUseCases } from "hooks/authentication/useAuthenticationUseCases";
import { ClassRoomMapper, ClassRoomsAPIDTO } from "hooks/classRoom/ClassRoomMapper";
import { useClassRoomsUseCases } from "hooks/classRoom/useClassRoomUseCases";
import { useCookiesUseCases } from "hooks/cookies/useCookiesUseCases";
import { useLocationRedirectUseCases } from "hooks/locationRedirect/useLocationRedirectUseCases";
import { useNotificationsUseCases } from "hooks/notifications/useNotificationsUseCases";
import { usePageTitleUseCases } from "hooks/pageTitle/models/usePageTitleUseCases";
import { ProjectMapper, ProjectsAPIDTO } from "hooks/projects/ProjectMapper";
import { useProjectsUseCases } from "hooks/projects/useProjectsUseCases";
import { useRoutesUseCases } from "hooks/routes/useRoutesUseCases";
import { SchoolMapper, SchoolsAPIDTO } from "hooks/schools/SchoolMapper";
import { useSchoolsUseCases } from "hooks/schools/useSchoolsUseCases";

export const useRoutes: Domain<undefined, typeof useRoutesUseCases> = useRoutesUseCases;
export const useNotifications: Domain<undefined, typeof useNotificationsUseCases> = useNotificationsUseCases;
export const useCookies: Domain<undefined, typeof useCookiesUseCases> = useCookiesUseCases;
export const useLocationRedirect: Domain<undefined, typeof useLocationRedirectUseCases> = useLocationRedirectUseCases;
export const usePageTitles: Domain<undefined, typeof usePageTitleUseCases> = () => {
	const { routes } = useRoutes();

	return usePageTitleUseCases({
		routes,
	});
};

export const useAuthentication: Domain<PoliciesAPIDTO, typeof useAuthenticationUseCases> = (api) => {
	const { getQuery } = useRoutes();
	const map = new AuthenticationMapper({ token: getQuery("token"), api });
	const [generateUsernameMutation] = useGenerateUsername();
	const [registerMutation] = useRegister();
	const [loginMutation] = useLogin();
	const [requestPasswordResetMutation] = useRequestPasswordReset();
	const [resetPasswordMutation] = useResetPassword();
	const [ValidateClassNumberMutation] = useValidateClassNumber();
	const [validateTagMutation] = useValidateTag();
	const [triggerForgotTagIdEmail] = useTriggerForgotTagIdEmail();

	return useAuthenticationUseCases({
		api: map.toAPI(api),
		generateUsernameMutation: map.toGenerateUsernameMutation(generateUsernameMutation),
		registerMutation: map.toRegisterMutation(registerMutation),
		loginMutation: map.toLoginMutation(loginMutation),
		requestPasswordResetMutation: map.toRequestPasswordResetMutation(requestPasswordResetMutation),
		resetPasswordMutation: map.toResetPasswordMutation(resetPasswordMutation),
		validateClassNumberMutation: map.toValidateClassNumberMutation(ValidateClassNumberMutation),
		validateTagMutation: map.toValidateTagMutation(validateTagMutation),
		triggerForgotTagIdEmailMutation: map.toTriggerForgotTagIdEmailMutation(triggerForgotTagIdEmail),
	});
};

export const useClassRooms: Domain<ClassRoomsAPIDTO, typeof useClassRoomsUseCases> = (api) => {
	const map = new ClassRoomMapper();

	return useClassRoomsUseCases({
		api: map.toAPI(api),
		defaultClassRoom: map.toClassRoom({}),
	});
};

export const useSchools: Domain<SchoolsAPIDTO, typeof useSchoolsUseCases> = (api) => {
	const map = new SchoolMapper();

	return useSchoolsUseCases({
		api: map.toAPI(api),
		defaultSchool: map.toSchool({}),
	});
};

export const useProjects: Domain<ProjectsAPIDTO, typeof useProjectsUseCases> = (api) => {
	const map = new ProjectMapper();

	return useProjectsUseCases({
		api: map.toAPI(api),
		defaultProject: map.toProject({}),
	});
};
