import { PageComponent } from "@aptus/frontend-core";
import { useAuthentication, useRoutes } from "domains";
import { Trans } from "react-i18next";
import { formatImageUrl } from "utils/formatImageUrl";
import { StartForm } from "./components/startForm/startForm";
import styles from "./homePage.module.css";

export const HomePage: PageComponent = () => {
	const { routes } = useRoutes();
	const {
		startSchema,
		start,
		isLoading,
		defaultStartInput,
	} = useAuthentication();

	return (
		<div className={styles.page}>
			<section>
				<h1><Trans i18nKey="welcomeFiver" components={{ bold: <strong /> }} /></h1>
				<StartForm
					className={styles.tagForm}
					loginUrl={routes.login}
					submit={start}
					isLoading={isLoading}
					schema={startSchema}
					defaultValues={defaultStartInput}
				/>
			</section>
			<img className={styles.five} src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/skating-five.svg", { width: 616, height: 625 })} alt="Five" width="616" height="625" />
		</div>
	);
};
