import { PageComponent } from "@aptus/frontend-core";
import classes from "./styleguide.module.css";

export const StyleguidePage: PageComponent = () => (
	<div className={classes.layout}>
		<h1>Typography</h1>

		<section>
			<h2>
				Headings
				{" "}
				<p>in Typography</p>
			</h2>
			<figure>
				<h1>On primary</h1>
				<figcaption>On primary</figcaption>
			</figure>
			<figure className={classes.neutral}>
				<h1 className="on-neutral">On neutral</h1>
				<figcaption>On neutral</figcaption>
			</figure>
			<figure>
				<h1><strong>Strong</strong></h1>
				<figcaption>Strong</figcaption>
			</figure>
		</section>

		<section>
			<figure>
				<h2>On primary</h2>
				<figcaption>On primary</figcaption>
			</figure>
			<figure className={classes.neutral}>
				<h2 className="on-neutral">On neutral</h2>
				<figcaption>On neutral</figcaption>
			</figure>
			<figure>
				<h2><strong>Strong</strong></h2>
				<figcaption>Strong</figcaption>
			</figure>
		</section>

		<section>
			<figure>
				<h3>On primary</h3>
				<figcaption>On primary</figcaption>
			</figure>
			<figure className={classes.neutral}>
				<h3 className="on-neutral">On neutral</h3>
				<figcaption>On neutral</figcaption>
			</figure>
			<figure>
				<h3><strong>Strong</strong></h3>
				<figcaption>Strong</figcaption>
			</figure>
		</section>

		<section>
			<h2>
				Body
				{" "}
				<p>in Typography</p>
			</h2>
			<figure>
				<p>Dark</p>
				<figcaption>Dark</figcaption>
			</figure>
			<figure>
				<p className="medium">Medium</p>
				<figcaption>Medium</figcaption>
			</figure>
			<figure>
				<p className="light">Light</p>
				<figcaption>Light</figcaption>
			</figure>
			<figure>
				<p><strong>Strong</strong></p>
				<figcaption>Strong</figcaption>
			</figure>
			<figure className={classes.neutral}>
				<p className="on-neutral">Dark</p>
				<figcaption>Dark</figcaption>
			</figure>
			<figure className={classes.neutral}>
				<p className="on-neutral medium">Medium</p>
				<figcaption>Medium</figcaption>
			</figure>
			<figure className={classes.neutral}>
				<p className="on-neutral light">Light</p>
				<figcaption>Light</figcaption>
			</figure>
			<figure className={classes.neutral}>
				<p className="on-neutral"><strong>Strong</strong></p>
				<figcaption>Strong</figcaption>
			</figure>
		</section>

		<section>
			<h2>
				Label
				{" "}
				<p>in Typography</p>
			</h2>
			<figure>
				<label htmlFor="input">
					On primary
					<input type="hidden" />
				</label>
				<figcaption>On primary</figcaption>
			</figure>
			<figure className={classes.neutral}>
				<label htmlFor="input" className="on-neutral">
					On neutral
					<input type="hidden" />
				</label>
				<figcaption>On neutral</figcaption>
			</figure>
		</section>

		<h1>Inputs</h1>

		<section>
			<h2>
				States
				{" "}
				<p>in Inputs</p>
			</h2>
			<figure>
				<label htmlFor="disabled">
					Label
					<input placeholder="Placeholder" id="input" />
				</label>
				<figcaption>Empty</figcaption>
			</figure>
			<figure>
				<label htmlFor="disabled">
					Label
					<input placeholder="Placeholder" value="Placeholder" />
				</label>
				<figcaption>Filled</figcaption>
			</figure>
			<figure>
				<label htmlFor="disabled" aria-invalid>
					Label
					<input placeholder="Placeholder" pattern="[a-z]" value="Placeholder" aria-invalid />
				</label>
				<figcaption>Error</figcaption>
			</figure>
			<figure>
				<label htmlFor="disabled" className="disabled">
					Label
					<input placeholder="Placeholder" disabled />
				</label>
				<figcaption>Disabled</figcaption>
			</figure>
		</section>

		<section>
			<figure>
				<label htmlFor="disabled" className="select">
					Label
					<select>
						<option value="" disabled selected>Placeholder</option>
						<option value="option">Option</option>
					</select>
				</label>
				<figcaption>Empty</figcaption>
			</figure>
			<figure>
				<label htmlFor="disabled" className="select">
					Label
					<select>
						<option value="option">Option</option>
					</select>
				</label>
				<figcaption>Filled</figcaption>
			</figure>
			<figure>
				<label htmlFor="disabled" className="select" aria-invalid>
					Label
					<select required aria-invalid>
						<option value="" disabled selected>Placeholder</option>
						<option value="option">Option</option>
					</select>
				</label>
				<figcaption>Error</figcaption>
			</figure>
			<figure>
				<label htmlFor="disabled" className="select disabled">
					Label
					<select disabled>
						<option value="option">Option</option>
					</select>
				</label>
				<figcaption>Disabled</figcaption>
			</figure>
		</section>

		<section>
			<figure>
				<label htmlFor="disabled" className="checkbox">
					<input type="checkbox" />
					Label
				</label>
				<figcaption>Empty</figcaption>
			</figure>
			<figure>
				<label htmlFor="disabled" className="checkbox">
					<input type="checkbox" checked />
					Label
				</label>
				<figcaption>Checked</figcaption>
			</figure>
			<figure>
				<label htmlFor="disabled" className="checkbox" aria-invalid>
					<input type="checkbox" required aria-invalid />
					Label
				</label>
				<figcaption>Error</figcaption>
			</figure>
			<figure>
				<label htmlFor="disabled" className="checkbox disabled">
					<input type="checkbox" disabled />
					Label
				</label>
				<figcaption>Disabled</figcaption>
			</figure>
		</section>

		<section>
			<h2>
				Suffix
				{" "}
				<p>in Inputs</p>
			</h2>
			<figure>
				<label htmlFor="suffix">
					Label
					<input type="text" id="suffix" placeholder="Placeholder" />
					<i className="suffix">person</i>
				</label>
				<figcaption>Icon empty</figcaption>
			</figure>
			<figure>
				<label htmlFor="suffix">
					Label
					<input type="text" id="suffix" placeholder="Placeholder" value="Placeholder" />
					<i className="suffix">person</i>
				</label>
				<figcaption>Icon filled</figcaption>
			</figure>
			<figure>
				<label htmlFor="suffix" aria-invalid>
					Label
					<input type="text" id="suffix" placeholder="Placeholder" value="Placeholder" pattern="[a-z]" aria-invalid />
					<i className="suffix">person</i>
				</label>
				<figcaption>Icon error</figcaption>
			</figure>
			<figure>
				<label htmlFor="suffix" className="disabled">
					Label
					<input type="text" id="suffix" placeholder="Placeholder" disabled />
					<i className="suffix">person</i>
				</label>
				<figcaption>Icon disabled</figcaption>
			</figure>
		</section>

		<section>
			<figure>
				<label htmlFor="suffix">
					Label
					<input type="text" id="suffix" placeholder="Placeholder" />
					<span className="suffix">Unit</span>
				</label>
				<figcaption>Unit empty</figcaption>
			</figure>
			<figure>
				<label htmlFor="suffix">
					Label
					<input type="text" id="suffix" placeholder="Placeholder" value="Placeholder" />
					<span className="suffix">Unit</span>
				</label>
				<figcaption>Unit filled</figcaption>
			</figure>
			<figure>
				<label htmlFor="suffix" aria-invalid>
					Label
					<input type="text" id="suffix" placeholder="Placeholder" value="Placeholder" pattern="[a-z]" aria-invalid />
					<span className="suffix">Unit</span>
				</label>
				<figcaption>Unit error</figcaption>
			</figure>
			<figure>
				<label htmlFor="suffix" className="disabled">
					Label
					<input type="text" id="suffix" placeholder="Placeholder" disabled />
					<span className="suffix">Unit</span>
				</label>
				<figcaption>Unit disabled</figcaption>
			</figure>
		</section>

		<h1>Icons</h1>

		<section>
			<figure>
				<i>backpack</i>
				<figcaption>Backpack</figcaption>
			</figure>
			<figure>
				<i>bike</i>
				<figcaption>Bike</figcaption>
			</figure>
			<figure>
				<i>check</i>
				<figcaption>Check</figcaption>
			</figure>
			<figure>
				<i>close</i>
				<figcaption>Close</figcaption>
			</figure>
			<figure>
				<i>eye</i>
				<figcaption>Eye</figcaption>
			</figure>
			<figure>
				<i>eye open</i>
				<figcaption>Eye open</figcaption>
			</figure>
			<figure>
				<i>menu</i>
				<figcaption>Menu</figcaption>
			</figure>
			<figure>
				<i>number-select</i>
				<figcaption>Number select</figcaption>
			</figure>
			<figure>
				<i>paper</i>
				<figcaption>Paper</figcaption>
			</figure>
			<figure>
				<i>person</i>
				<figcaption>Person</figcaption>
			</figure>
			<figure>
				<i>redo</i>
				<figcaption>Redo</figcaption>
			</figure>
			<figure>
				<i>chevron-down</i>
				<figcaption>Chevron down</figcaption>
			</figure>
			<figure>
				<i>wristband</i>
				<figcaption>Wristband</figcaption>
			</figure>
		</section>

		<h1>Buttons</h1>

		<section>
			<h2>
				States
				{" "}
				<p>in Buttons</p>
			</h2>
			<figure>
				<button type="button">Button</button>
				<figcaption>Default</figcaption>
			</figure>
			<figure>
				<button type="button" disabled>Button</button>
				<figcaption>Disabled</figcaption>
			</figure>
			<figure>
				<input type="button" id="button" value="Input" />
				<figcaption>Default</figcaption>
			</figure>
			<figure>
				<input type="button" id="button" value="Input" disabled />
				<figcaption>Disabled</figcaption>
			</figure>
		</section>

		<section>
			<figure>
				<button type="button" className="secondary">Button</button>
				<figcaption>Default</figcaption>
			</figure>
			<figure>
				<button type="button" className="secondary" disabled>Button</button>
				<figcaption>Disabled</figcaption>
			</figure>
			<figure>
				<input type="button" id="button" value="Input" className="secondary" />
				<figcaption>Default</figcaption>
			</figure>
			<figure>
				<input type="button" id="button" value="Input" className="secondary" disabled />
				<figcaption>Disabled</figcaption>
			</figure>
		</section>

		<section>
			<figure>
				<button type="button" className="text">Button</button>
				<figcaption>Default</figcaption>
			</figure>
			<figure>
				<button type="button" className="text" disabled>Button</button>
				<figcaption>Disabled</figcaption>
			</figure>
			<figure>
				<a href="/styleguide">Link</a>
				<figcaption>a tag</figcaption>
			</figure>
			<figure>
				<a type="button" href="/styleguide">Link</a>
				<figcaption>a tag type=button</figcaption>
			</figure>
		</section>

		<section>
			<h2>
				Prefixes / Suffixes
				{" "}
				<p>in Buttons</p>
			</h2>
			<figure>
				<button type="button" className="icon">
					<i>person</i>
					{" "}
					Button
				</button>
				<figcaption>Icon prefix</figcaption>
			</figure>
			<figure>
				<button type="button" className="icon">
					Button
					{" "}
					<i>person</i>
				</button>
				<figcaption>Icon suffix</figcaption>
			</figure>
			<figure>
				<button type="button" className="icon" disabled>
					<i>person</i>
					{" "}
					Button
				</button>
				<figcaption>Icon prefix</figcaption>
			</figure>
			<figure>
				<button type="button" className="icon" disabled>
					Button
					{" "}
					<i>person</i>
				</button>
				<figcaption>Icon suffix</figcaption>
			</figure>
		</section>

		<section>
			<figure>
				<button type="button" className="secondary icon">
					<i>person</i>
					{" "}
					Button
				</button>
				<figcaption>Icon prefix</figcaption>
			</figure>
			<figure>
				<button type="button" className="secondary icon">
					Button
					{" "}
					<i>person</i>
				</button>
				<figcaption>Icon suffix</figcaption>
			</figure>
			<figure>
				<button type="button" className="secondary icon" disabled>
					<i>person</i>
					{" "}
					Button
				</button>
				<figcaption>Icon prefix</figcaption>
			</figure>
			<figure>
				<button type="button" className="secondary icon" disabled>
					Button
					{" "}
					<i>person</i>
				</button>
				<figcaption>Icon suffix</figcaption>
			</figure>
		</section>

		<section>
			<figure>
				<button type="button" className="text icon">
					<i>person</i>
					{" "}
					Button
				</button>
				<figcaption>Icon prefix</figcaption>
			</figure>
			<figure>
				<button type="button" className="text icon">
					Button
					{" "}
					<i>person</i>
				</button>
				<figcaption>Icon suffix</figcaption>
			</figure>
			<figure>
				<button type="button" className="text icon" disabled>
					<i>person</i>
					{" "}
					Button
				</button>
				<figcaption>Icon prefix</figcaption>
			</figure>
			<figure>
				<button type="button" className="text icon" disabled>
					Button
					{" "}
					<i>person</i>
				</button>
				<figcaption>Icon suffix</figcaption>
			</figure>
		</section>

		<h1>List</h1>

		<section>
			<figure>
				<ul>
					<li>
						<p className="medium"><strong>Left</strong></p>
						<p>Right</p>
					</li>
					<li>
						<p className="medium"><strong>Left</strong></p>
						<p>Right</p>
					</li>
				</ul>
				<figcaption>List</figcaption>
			</figure>
		</section>

		<h1>Custom elements</h1>

		<section>
			<h2>
				Message
				{" "}
				<p>in Custom elements</p>
			</h2>

			<figure>
				<figure className="message error">
					<figcaption>Message</figcaption>
				</figure>
				<figcaption>Error</figcaption>
			</figure>
		</section>

		<section>
			<h2>
				Stepper
				{" "}
				<p>in Custom elements</p>
			</h2>

			<figure>
				<button type="button" className="step active">
					<i>person</i>
					{" "}
					Step
				</button>
				<figcaption>Active</figcaption>
			</figure>
			<figure>
				<button type="button" className="step">
					<i>person</i>
					{" "}
					Step
				</button>
				<figcaption>Inactive</figcaption>
			</figure>
			<figure>
				<button type="button" className="step done">
					<i>person</i>
					{" "}
					Step
				</button>
				<figcaption>Done</figcaption>
			</figure>
		</section>

		<section>
			<h2>
				Tooltip
				{" "}
				<p>in Custom elements</p>
			</h2>
			<figure>
				<div className="tooltip">
					<h3>Title</h3>
					<p>Lorem ipsum dolor sit amet consectetur adiphising elit.</p>
				</div>
				<figcaption>Tooltip</figcaption>
			</figure>
		</section>
	</div>
);
