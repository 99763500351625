import { PageComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { Input } from "components/input/input";
import { Loader } from "components/loader/loader";
import { LastStepFooter, StepSection } from "components/stepSection/stepSection";
import {
	useAuthentication,
	useClassRooms,
	useProjects,
	useRoutes,
	useSchools,
} from "domains";
import { RegisterInput } from "hooks/authentication/models/authenticationInput";
import { PolicyType } from "hooks/authentication/models/policy";
import { useGetPolicies } from "pages/register/models/getPolicies";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { isValid } from "utils/html-validation-schema";
import { useGetSchoolById } from "pages/register/models/getSchoolById";
import { ListSection } from "./components/listSection/listSection";
import { ListSectionItem } from "./components/listSection/listSectionItem";
import { useGetClassroomById } from "./models/getClassroomById";
import { useGetProjectById } from "./models/getProjectById";

export const OverviewStep: PageComponent = () => {
	const { routes } = useRoutes();
	const { registerSchemaGenerator, policies } = useAuthentication(useGetPolicies());
	const { getValues, register, formState } = useFormContext<RegisterInput>();
	const { t } = useTranslation();

	const { project, isLoading: isProjectLoading } = useProjects(useGetProjectById({
		variables: { id: getValues("project") },
		skip: !getValues("project"),
	}));

	const { school, isLoading: isSchoolLoading } = useSchools(useGetSchoolById({
		variables: { id: getValues("school") },
		skip: !getValues("school"),
	}));

	const { classRoom, isLoading: isClassRoomLoading } = useClassRooms(useGetClassroomById({
		variables: { id: getValues("classroom") },
		skip: !getValues("classroom"),
	}));

	return (
		<Loader isLoading={isProjectLoading || isSchoolLoading || isClassRoomLoading}>
			<StepSection>
				<h2>{t("overview")}</h2>

				<ListSection icon="wristband" title={t("tag", { count: school.canHighFive ? 2 : 1 })}>
					{school.canHighFive && (
						<ListSectionItem
							title={t("wristbandCode")}
							href={routes.register.stepOne}
							value={getValues("wristband").toUpperCase()}
						/>
					)}
					{school.canBikeScan && (
						<ListSectionItem
							title={t("biketagCode")}
							href={routes.register.stepTwo}
							value={getValues("hasNoBiketag")
								? t("childHasNoBiketag")
								: getValues("biketag").toUpperCase()}
						/>
					)}
				</ListSection>

				<ListSection icon="school" title={t("yourSchool")}>
					<ListSectionItem
						title={t("project")}
						href={routes.register.stepThree}
						value={isProjectLoading ? t("isLoading") : project.title}
					/>
					<ListSectionItem
						title={t("school")}
						href={routes.register.stepThree}
						value={isSchoolLoading ? t("isLoading") : school.title}
					/>
				</ListSection>

				<ListSection icon="backpack" title={t("yourClassroom")}>
					<ListSectionItem
						title={t("classroom")}
						href={routes.register.stepFour}
						value={isClassRoomLoading ? t("isLoading") : classRoom.title}
					/>
					{school && school.requireClassNumber ? (
						<ListSectionItem
							title={t("classNumber")}
							href={routes.register.stepFour}
							value={getValues("classNumber")}
						/>
					) : null}
				</ListSection>

				<ListSection icon="person" title={t("profile")}>
					<ListSectionItem
						title={t("username")}
						href={routes.register.stepFive}
						value={getValues("username")}
					/>
					<ListSectionItem
						title={t("emails")}
						href={routes.register.stepFive}
						value={getValues("emails")}
					/>
				</ListSection>

				{"privacyPolicy" in getValues().acceptPolicies && (
					<Input
						{...register("acceptPolicies.privacyPolicy")}
						id="acceptPolicies.acceptPrivacyPolicy"
						type="checkbox"
						error={formState.errors.acceptPolicies?.privacyPolicy?.message}
						label={(
							<p>
								<Trans i18nKey="acceptPrivacyPolicyInput.label" components={{ italic: <A href={policies.find(({ type }) => type === PolicyType.PrivacyPolicy)?.url} /> }} />
							</p>
						)}
					/>
				)}
				{"dataProcessingAgreement" in getValues().acceptPolicies && (
					<Input
						{...register("acceptPolicies.dataProcessingAgreement")}
						id="acceptPolicies.dataProcessingAgreement"
						type="checkbox"
						error={formState.errors.acceptPolicies?.dataProcessingAgreement?.message}
						label={(
							<p>
								<Trans i18nKey="acceptDataProcessingAgreementInput.label" components={{ italic: <A href={policies.find(({ type }) => type === PolicyType.DataProcessingAgreement)?.url} /> }} />
							</p>
						)}
					/>
				)}
				<Input
					{...register("dataIsVerified")}
					id="dataIsVerified"
					type="checkbox"
					label={<p>{t("dataIsVerified.label")}</p>}
					error={formState.errors.dataIsVerified?.message}
				/>
				<LastStepFooter
					previousStepHref={routes.register.stepFive}
					isValid={isValid(getValues(), registerSchemaGenerator(getValues()))}
				/>
			</StepSection>
		</Loader>
	);
};
