import { UseCases } from "@aptus/frontend-core";
import { Events } from "event";
import { useEffect, useState } from "react";
import { getCookie } from "utils/GetCookie";
import { setCookie } from "utils/SetCookie";
import { FUNCTIONAL_COOKIES_KEY, GOOGLE_ANALYTICS_COOKIES_KEY, HOTJAR_COOKIES_KEY } from "utils/Tracker";

interface Result {
	shouldShowCookieModal: boolean;

	tryShowCookieModal: Events["showCookieModal"];

	acceptedAllCookies: Events["acceptedAllCookies"];
	acceptedFunctionalCookies: Events["acceptedFunctionalCookies"];
}

export const useCookiesUseCases: UseCases<undefined, Result> = () => {
	const [shouldShowCookieModal, setShouldShowCookieModal] = useState<Result["shouldShowCookieModal"]>(false);

	const cookiesNotSet: () => boolean = () => {
		const googleAnalyticsCookie = getCookie(GOOGLE_ANALYTICS_COOKIES_KEY);
		const hotjarCookie = getCookie(HOTJAR_COOKIES_KEY);

		const functionalCookie = getCookie(FUNCTIONAL_COOKIES_KEY);

		return (googleAnalyticsCookie === null || googleAnalyticsCookie === undefined) || (hotjarCookie === null || hotjarCookie === undefined) || (functionalCookie === null || functionalCookie === undefined);
	};

	const tryShowCookieModal: Result["tryShowCookieModal"] = () => {
		setShouldShowCookieModal(cookiesNotSet());
	};

	const acceptedAllCookies: Result["acceptedAllCookies"] = () => {
		setShouldShowCookieModal(false);

		setCookie(FUNCTIONAL_COOKIES_KEY, true);
		setCookie(GOOGLE_ANALYTICS_COOKIES_KEY, true);
		setCookie(HOTJAR_COOKIES_KEY, true);

		// Cookies aren't available immediately, causing google Analytics to not be set correctly
		// eslint-disable-next-line no-restricted-globals
		location.reload();
	};

	const acceptedFunctionalCookies: Events["acceptedFunctionalCookies"] = () => {
		setShouldShowCookieModal(false);

		setCookie(FUNCTIONAL_COOKIES_KEY, true);
		setCookie(GOOGLE_ANALYTICS_COOKIES_KEY, false);
		setCookie(HOTJAR_COOKIES_KEY, false);
	};

	useEffect(() => {
		tryShowCookieModal();
	}, []);

	return {
		shouldShowCookieModal,
		tryShowCookieModal,
		acceptedAllCookies,
		acceptedFunctionalCookies,
	};
};
