import { PageComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { Trans, useTranslation } from "react-i18next";
import { formatImageUrl } from "utils/formatImageUrl";
import styles from "./bjibaanPage.module.css";

export const BijbaanPage: PageComponent = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className={styles.header}>
				<h1>{t("bijbaan.title")}</h1>
			</div>
			<div className={styles.cta}>
				<h2>{t("bijbaan.cta")}</h2>
				<h3>{t("bijbaan.pay")}</h3>
			</div>
			<div className={styles.content}>
				<div className={styles.vibe}>
					<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/jobstudent-vibe.png")} alt="The vibe of High-Five" />
				</div>
				<div className={styles.text}>
					<h4>{t("bijbaan.content.titles.aboutHighFive")}</h4>
					<p>
						<Trans i18nKey="bijbaan.content.body.aboutHighFive" components={{ br: <br /> }} />
					</p>

					<h4>{t("bijbaan.content.titles.aboutTheJob")}</h4>
					<p>
						<Trans i18nKey="bijbaan.content.body.aboutTheJob" components={{ br: <br /> }} />
					</p>

					<h4>{t("bijbaan.content.titles.aboutYou")}</h4>
					<p>
						<Trans i18nKey="bijbaan.content.body.aboutYou" components={{ br: <br /> }} />
					</p>
					<p>
						<Trans i18nKey="bijbaan.content.body.plus" components={{ br: <br />, ul: <ul />, li: <li /> }} />
					</p>
					<p>{t("bijbaan.content.body.ready")}</p>

					<p className={styles.mailto}>
						<Trans i18nKey="bijbaan.content.body.sendEmailOrClickButton" components={{ br: <br />, italic: <A href={t("bijbaan.content.body.mailto")} /> }} />
					</p>

					<a href={t("bijbaan.content.body.mailto")}>
						<button type="button">
							{t("bijbaan.content.body.sendInfo")}
						</button>
					</a>
				</div>
			</div>
		</>
	);
};
