import { PageComponent } from "@aptus/frontend-core";
import { FormEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { formatImageUrl } from "utils/formatImageUrl";
import styles from "./whatIsHighFivePage.module.css";

export const WhatIsHighFivePage: PageComponent = () => {
	const { t } = useTranslation();

	const [formMessage, setFormMessage] = useState<string>();

	const onContactSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// eslint-disable-next-line no-restricted-globals
		location.href = `mailto:authier@ikhighfive.be?subject=Interesse In High-Five&body=${formMessage}`;
	};

	return (
		<div className={styles.content}>
			<div className={styles.mission}>
				<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/five-with-planet.png")} alt="Five on space bike" />
				<div>
					<h1>{t("whatIsHighFive.mission.title")}</h1>
					<h2><Trans i18nKey="whatIsHighFive.mission.subtitle" components={{ bold: <strong /> }} /></h2>
				</div>
			</div>
			<div className={`${styles.how} ${styles.negative}`}>
				<div>
					<h1><Trans i18nKey="whatIsHighFive.how.title" components={{ bold: <strong /> }} /></h1>
					<h2><Trans i18nKey="whatIsHighFive.how.subtitle" components={{ bold: <strong /> }} /></h2>
				</div>
				<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/child-high-fiveing.png")} alt="Child giving a High-Five to a High-Five module" />
			</div>
			<div className={styles.platform}>
				<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/platform-in-tablet.png")} alt="High-Five platform in tablet view" />
				<div>
					<h1><Trans i18nKey="whatIsHighFive.platform.title" components={{ bold: <strong /> }} /></h1>
					<h2><Trans i18nKey="whatIsHighFive.platform.subtitle" components={{ bold: <strong /> }} /></h2>
				</div>
			</div>
			<div className={styles.rewards}>
				<div>
					<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/trampoline.png")} alt="Trampoline" />
					<p>{t("whatIsHighFive.rewards.reward1")}</p>
				</div>
				<div>
					<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/rollercoaster.png")} alt="Roller coaster" />
					<p>{t("whatIsHighFive.rewards.reward2")}</p>
				</div>
				<div>
					<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/socks.png")} alt="Socks" />
					<p>{t("whatIsHighFive.rewards.reward3")}</p>
				</div>
			</div>
			<div className={`${styles.pitch} ${styles.negative}`}>
				<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/authier.png")} alt="Authier" />
				<div>
					<p><Trans i18nKey="whatIsHighFive.pitch.quote" components={{ bold: <strong /> }} /></p>
					<h2><Trans i18nKey="whatIsHighFive.pitch.representative" components={{ bold: <strong /> }} /></h2>
				</div>
			</div>
			<div className={styles.numbers}>
				<div>
					<h1><Trans i18nKey="whatIsHighFive.numbers.title" components={{ bold: <strong /> }} /></h1>
					<h2><Trans i18nKey="whatIsHighFive.numbers.subtitle" components={{ bold: <strong /> }} /></h2>
					<a href="https://www.hln.be/wichelen/78-meer-fietsen-in-wichelse-scholen-dankzij-superheld-five~af77645a/" target="_blank" rel="noreferrer">
						<button type="button" className={styles.clickable}>{t("whatIsHighFive.numbers.showResults")}</button>
					</a>
				</div>
				<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/article.webp")} alt="News article about High-Five" />
			</div>
			<div className={`${styles.map} ${styles.negative}`}>
				<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/high-five-2022-map.webp")} alt="A map of the High-Five projects in 2022" />
				<div>
					<h2>{t("whatIsHighFive.map.title")}</h2>
					<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/waving-five.webp")} alt="Waving Five" />
				</div>
			</div>
			<div className={styles.footer}>
				<div>
					<a href="https://www.youtube.com/watch?v=7Kt4VcCvd1s" target="_blank" rel="noreferrer" className={styles.overdrive}>
						<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/five-overdrive-cover.webp")} alt="Cover art of the Overdrive song by Five" />
					</a>
					<div className={styles.socials}>
						<a href="https://www.tiktok.com/@ikhighfive" target="_blank" rel="noreferrer" className={`${styles.clickable} ${styles.tiktok}`}>TikTok</a>
						<a href="https://www.instagram.com/ikhighfive/" target="_blank" rel="noreferrer" className={`${styles.instagram}`}>Instagram</a>
						<a href="https://www.facebook.com/ikhighfive" target="_blank" rel="noreferrer" className={`${styles.facebook}`}>facebook</a>
					</div>
				</div>
				<div>
					<h2>{t("whatIsHighFive.footer.cta")}</h2>
					<form onSubmit={onContactSubmit}>
						<label htmlFor="message">
							{t("whatIsHighFive.footer.form.message")}
							<textarea id="message" name="message" onChange={(event) => { setFormMessage(event.target.value); }} />
						</label>
						<button type="submit">{t("whatIsHighFive.footer.form.submit")}</button>
					</form>
				</div>
			</div>
		</div>
	);
};
