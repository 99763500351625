import { PageComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { Trans, useTranslation } from "react-i18next";
import { FaqList } from "../components/faqList/faqList";

export const CategoryOnePage: PageComponent = () => {
	const { t } = useTranslation();

	return (
		<FaqList>
			<details>
				<summary>{t("faq.category1.question1")}</summary>
				{t("faq.category1.answer1")}
			</details>
			<details>
				<summary>{t("faq.category1.question3")}</summary>
				{t("faq.category1.answer3")}
			</details>
			<details>
				<summary>{t("faq.category1.question4")}</summary>
				<p>
					<Trans i18nKey="faq.category1.answer4" components={{ italic: <A href="mailto:hallo@ikhighfive.be" /> }} />
				</p>
			</details>
		</FaqList>
	);
};
