import { useRef, useEffect, useCallback } from "react";

export const useSyncRefs = <T>(
	...refs: (
		| React.MutableRefObject<T | null>
		| ((instance: T) => void)
		| null
	)[]
) => {
	const cache = useRef(refs);

	useEffect(() => {
		cache.current = refs;
	}, [refs]);

	return useCallback(
		(value: T) => {
			cache.current.forEach((ref) => {
				if (ref != null) {
					if (typeof ref === "function") {
						ref(value);
					} else {
						// eslint-disable-next-line no-param-reassign
						ref.current = value;
					}
				}
			});
		},
		[cache],
	);
};
