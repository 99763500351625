import { PageComponent } from "@aptus/frontend-core";
import { useTranslation } from "react-i18next";
import { FaqList } from "../components/faqList/faqList";

export const CategoryTwoPage: PageComponent = () => {
	const { t } = useTranslation();

	return (
		<FaqList>
			<details>
				<summary>{t("faq.category2.question1")}</summary>
				{t("faq.category2.answer1")}
			</details>
			<details>
				<summary>{t("faq.category2.question2")}</summary>
				{t("faq.category2.answer2")}
			</details>
			<details>
				<summary>{t("faq.category2.question3")}</summary>
				{t("faq.category2.answer3")}
			</details>
			<details>
				<summary>{t("faq.category2.question4")}</summary>
				{t("faq.category2.answer4")}
			</details>
			<details>
				<summary>{t("faq.category2.question5")}</summary>
				{t("faq.category2.answer5")}
			</details>
		</FaqList>
	);
};
