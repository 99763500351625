import { UseCases } from "@aptus/frontend-core";
import { Events } from "event";
import { useEffect, useState } from "react";
import { LocaleConfig } from "../../..";

interface Result {
	shouldShowLocationRedirectModal: boolean;
	mostRelevantLocaleConfig: LocaleConfig | null;
	currentLocaleConfig: LocaleConfig | null;

	tryShowLocationModal: Events["showLocationRedirectModal"];
	changeToMostRelevantSiteForLanguage: Events["changeToMostRelevantSiteForLanguage"];
	stayOnSite: Events["stayOnSite"];
	neverAskAgain: Events["neverAskAgain"];
}

export const useLocationRedirectUseCases: UseCases<undefined, Result> = () => {
	const [shouldShowLocationRedirectModal, setShouldShowLocationRedirectModal] = useState<Result["shouldShowLocationRedirectModal"]>(false);
	const [mostRelevantLocaleConfig, setMostRelevantLocaleConfig] = useState<Result["mostRelevantLocaleConfig"]>(null);
	const [currentLocaleConfig, setCurrentLocaleConfig] = useState<Result["currentLocaleConfig"]>(null);

	const getLocaleConfigForLanguage: (language: string) => LocaleConfig | null = (language) => {
		const localeConfigs = window?.environment?.localeConfigs;

		if (!localeConfigs || !localeConfigs.length) {
			return null;
		}

		const localeConfigForLanguage = localeConfigs.find((localeConfig) => (localeConfig.locale === language));
		return localeConfigForLanguage || null;
	};

	const getMostRelevantLocaleConfigForLanguage: () => LocaleConfig | null = () => {
		const userLanguages = navigator.languages;

		const localeConfigForLanguage = userLanguages
			.map(getLocaleConfigForLanguage)
			.find((localeConfig) => (localeConfig !== null && localeConfig !== undefined));

		return localeConfigForLanguage || null;
	};

	const getMostRelevantLocaleConfigForTimezone: () => LocaleConfig | null = () => {
		const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		const localeConfigs = window?.environment?.localeConfigs;

		if (!localeConfigs || !localeConfigs.length) {
			return null;
		}

		const localeConfigForTimezone = localeConfigs.find((localeConfig) => (localeConfig.timezone === userTimezone));
		return localeConfigForTimezone || null;
	};

	const getCurrentLocaleConfigForHost: () => LocaleConfig | null = () => {
		const localeConfigs = window?.environment?.localeConfigs;
		if (!localeConfigs || !localeConfigs.length) {
			return null;
		}

		const currentHost = window.location.host;

		if (!currentHost) {
			return null;
		}

		return localeConfigs.find((localeConfig) => (localeConfig.siteURL === currentHost)) || null;
	};

	const tryShowLocationModal: Result["tryShowLocationModal"] = () => {
		const previouslyDenied = !!JSON.parse(localStorage.getItem("deniedShowLocationModal") || "false");

		if (previouslyDenied) {
			setShouldShowLocationRedirectModal(false);
			return;
		}

		const currentLocaleConfigForHost = getCurrentLocaleConfigForHost();
		setCurrentLocaleConfig(currentLocaleConfigForHost);

		const mostRelevantLocaleConfigForLanguage = getMostRelevantLocaleConfigForLanguage();
		const mostRelevantLocaleConfigForTimezone = getMostRelevantLocaleConfigForTimezone();

		if (!mostRelevantLocaleConfigForLanguage && mostRelevantLocaleConfigForTimezone) {
			setMostRelevantLocaleConfig(mostRelevantLocaleConfigForTimezone);
			setShouldShowLocationRedirectModal(mostRelevantLocaleConfigForTimezone.siteURL !== window.location.host);
			return;
		}

		if (mostRelevantLocaleConfigForLanguage && mostRelevantLocaleConfigForTimezone) {
			if (mostRelevantLocaleConfigForLanguage.siteURL !== mostRelevantLocaleConfigForTimezone.siteURL) {
				setShouldShowLocationRedirectModal(false);
				return;
			}

			setMostRelevantLocaleConfig(mostRelevantLocaleConfigForTimezone);
			setShouldShowLocationRedirectModal(mostRelevantLocaleConfigForTimezone.siteURL !== window.location.host);
			return;
		}

		setShouldShowLocationRedirectModal(false);
	};

	const changeToMostRelevantSiteForLanguage: Result["changeToMostRelevantSiteForLanguage"] = () => {
		setShouldShowLocationRedirectModal(false);

		if (!mostRelevantLocaleConfig || !mostRelevantLocaleConfig.siteURL) {
			return;
		}

		window.location.replace(`https://${mostRelevantLocaleConfig.siteURL}${window.location.pathname}`);
	};

	const stayOnSite: Result["stayOnSite"] = () => {
		setShouldShowLocationRedirectModal(false);
	};

	const neverAskAgain: Result["neverAskAgain"] = () => {
		setShouldShowLocationRedirectModal(false);
		localStorage.setItem("deniedShowLocationModal", "true");
	};

	useEffect(() => {
		tryShowLocationModal();
	}, []);

	return {
		shouldShowLocationRedirectModal,
		mostRelevantLocaleConfig,
		currentLocaleConfig,
		tryShowLocationModal,
		changeToMostRelevantSiteForLanguage,
		stayOnSite,
		neverAskAgain,
	};
};
