import { UILogic } from "@aptus/frontend-core";
import { RefObject, useRef } from "react";
import { CommonInputFieldProps } from "./models/inputProps";

interface Common extends CommonInputFieldProps {
	multiple: true;
}

type Props = Pick<CommonInputFieldProps, "onChange" | "onBlur" | "id">;

interface Result {
	inputRef: RefObject<HTMLInputElement>;
	pills: string[];
	removePillByIndex: (index: number) => void;
}

export type MultipleInputFieldProps = Common & Props;
export type MultipleInputFieldLayoutProps = Common & Result;

export const useMultipleInput: UILogic<Props, Result> = ({
	id, onChange, onBlur,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const isNotEmptyString = (value: string) => value !== "";
	const pills = inputRef.current?.value.split(/,|\s+/).filter(isNotEmptyString) || [];

	const isInputElement = (element?: Element | null): element is HTMLInputElement => (
		element?.tagName.toLowerCase() === "input"
	);

	const dispatchEvent = (handler: (e: any) => void, value: string): void => {
		if (id) {
			const input = document.getElementById(id);
			const event = new Event("input", { bubbles: true });

			if (isInputElement(input)) {
				input.value = value;
				handler({ ...event, target: input, currentTarget: input });
			}
		}
	};

	const removePillByIndex: Result["removePillByIndex"] = (index) => {
		pills.splice(index, 1);
		const value = pills.filter(isNotEmptyString).join(", ");

		if (onChange) dispatchEvent(onChange, value);
		if (onBlur) dispatchEvent(onBlur, value);
	};

	return {
		inputRef,
		pills,
		removePillByIndex,
	};
};
