import { UILogic } from "@aptus/frontend-core";
import { LoginInput } from "hooks/authentication/models/authenticationInput";
import { FormEvent } from "react";
import { FieldErrors, UseFormRegister, useForm } from "react-hook-form";
import { HTMLValidationSchema } from "utils/html-validation-schema";
import { HTMLValidationSchemaResolver } from "utils/html-validation-schema/compat/HTMLValidationSchemaResolver";

type Input = Omit<LoginInput, "schoolUrl">;

interface Common {
	registerUrl: string;
	isLoading: boolean;
	requestPasswordResetHref: string;
}

interface Props {
	defaultValues: Input;
	submit: (input: Input) => void;
	schema: HTMLValidationSchema<Input>;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>) => void;
	register: UseFormRegister<Input>;
	errors: FieldErrors<Input>;
}

export type LoginFormProps = Common & Props;
export type LoginFormLayoutProps = Common & Result;

export const useLoginForm: UILogic<Props, Result> = ({
	defaultValues,
	submit,
	schema,
}) => {
	const {
		handleSubmit, register, formState,
	} = useForm<Input>({
		mode: "onBlur",
		defaultValues,
		resolver: HTMLValidationSchemaResolver(schema),
	});

	return {
		submit: handleSubmit(submit),
		register,
		errors: formState.errors,
	};
};
