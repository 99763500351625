import { API, UseCases } from "@aptus/frontend-core";
import { Project } from "./models/project";

export type ProjectsAPI = API<Project[]>;

interface Props {
	api: ProjectsAPI;
	defaultProject: Project;
}

interface Result {
	projects: Project[];
	project: Project;
	isLoading: boolean;
	error?: Error;
}

export const useProjectsUseCases: UseCases<Props, Result> = ({ api, defaultProject }) => ({
	...api,
	projects: api.data,
	project: api.data?.[0] ?? defaultProject,
});
