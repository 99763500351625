import { PageComponent } from "@aptus/frontend-core";
import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import styles from "./backgroundPage.module.css";

interface Props {
	backgroundImage: string;
	showYellowLine?: boolean;
}

export const BackgroundPage: PageComponent<Props> = ({ backgroundImage, showYellowLine }) => {
	const page = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (page.current) {
			page.current.style.setProperty("--background-image", `url(${backgroundImage})`);
		}
	}, [page.current, backgroundImage]);

	return (
		<div className={`${styles.page} ${showYellowLine ? styles.yellowLine : ""}`} ref={page}>
			<div className={styles.content}>
				<Outlet />
			</div>
		</div>
	);
};
