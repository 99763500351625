import { PageComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { Trans, useTranslation } from "react-i18next";
import styles from "./cookiePolicyPage.module.css";

export const CookiePolicyPage: PageComponent = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.header}>
			<h1>{t("cookiePolicy.title")}</h1>
			<p>
				<Trans
					i18nKey="cookiePolicy.content"
					components={{
						br: <br />,
						strong: <strong />,
						em: <em />,
						a: <A />,
					}}
				/>
			</p>
		</div>
	);
};
