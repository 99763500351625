import { PageComponent } from "@aptus/frontend-core";
import { useAuthentication } from "domains";
import { ForgotTagIdForm } from "./components/forgotTagIdForm/forgotTagIdForm";
import styles from "./forgotTagId.module.css";

export const ForgotTagIdPage: PageComponent = () => {
	const {
		forgotTagId, forgotTagIdSchema, defaultForgotTagIdInput, isLoading,
	} = useAuthentication();

	return (
		<div className={styles.page}>
			<ForgotTagIdForm
				defaultValues={defaultForgotTagIdInput}
				schema={forgotTagIdSchema}
				submit={forgotTagId}
				isLoading={isLoading}
			/>
		</div>
	);
};
