import { API, UseCases } from "@aptus/frontend-core";
import { ClassRoom } from "./models/classRoom";

export type ClassRoomsAPI = API<ClassRoom[]>;

interface Props {
	api: ClassRoomsAPI;
	defaultClassRoom: ClassRoom;
}

interface Result {
	classRooms: ClassRoom[];
	classRoom: ClassRoom;
	isLoading: boolean;
	error?: Error;
}

export const useClassRoomsUseCases: UseCases<Props, Result> = ({ api, defaultClassRoom }) => ({
	...api,
	classRooms: api.data,
	classRoom: api.data?.[0] ?? defaultClassRoom,
});
