export const getCookie = (name: string): string | boolean | number | undefined => {
	const matches = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
	const value = matches?.pop();

	if (value) {
		return JSON.parse(value);
	}

	return undefined;
};
