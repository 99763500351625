import { PageComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { Trans, useTranslation } from "react-i18next";
import { FaqList } from "../components/faqList/faqList";

export const CategoryThreePage: PageComponent = () => {
	const { t } = useTranslation();

	return (
		<FaqList>
			<details>
				<summary>{t("faq.category3.question1")}</summary>
				{t("faq.category3.answer1")}
			</details>
			<details>
				<summary>{t("faq.category3.question2")}</summary>
				{t("faq.category3.answer2")}
			</details>
			<details>
				<summary>{t("faq.category3.question3")}</summary>
				{t("faq.category3.answer3")}
			</details>
			<details>
				<summary>{t("faq.category3.question4")}</summary>
				{t("faq.category3.answer4")}
			</details>
			<details>
				<summary>{t("faq.category3.question5")}</summary>
				{t("faq.category3.answer5")}
			</details>
			<details>
				<summary>{t("faq.category3.question6")}</summary>
				<p>
					<Trans i18nKey="faq.category3.answer6" components={{ italic: <A href="mailto:hallo@ikhighfive.be" /> }} />
				</p>
			</details>
		</FaqList>
	);
};
