import { PageComponent } from "@aptus/frontend-core";
import {
	useAuthentication,
	useRoutes,
} from "domains";
import { useTranslation } from "react-i18next";
import { LoginForm } from "./components/loginForm/loginForm";
import styles from "./loginPage.module.css";

export const LoginPage: PageComponent = () => {
	const {
		login, loginSchema, isLoading, defaultLoginInput,
	} = useAuthentication();
	const { routes } = useRoutes();
	const { t } = useTranslation();

	return (
		<div className={styles.page}>
			<div className={styles.form}>
				<h1>{t("login")}</h1>
				<LoginForm
					defaultValues={defaultLoginInput}
					submit={(input) => login({ ...input })}
					schema={{ nfcTagId: loginSchema.nfcTagId, nfcTagType: loginSchema.nfcTagType, password: loginSchema.password }}
					isLoading={isLoading}
					registerUrl={routes.index}
					requestPasswordResetHref={routes.requestPasswordReset}
				/>
			</div>
		</div>
	);
};
