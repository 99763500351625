import { Pill } from "components/pills/pill";
import { Pills } from "components/pills/pills";
import { DetailedHTMLProps, forwardRef, SelectHTMLAttributes } from "react";
import { useSyncRefs } from "utils/useSyncRefs";
import { CommonInputProps, SingleInputFieldProps } from "./models/inputProps";
import { MultipleInputFieldLayoutProps, MultipleInputFieldProps, useMultipleInput } from "./useMultipleInput";
import styles from "./input.module.css";
import { PasswordInputLayoutProps, usePasswordInput } from "./usePasswordInput";

export type InputProps = SingleInputFieldProps | MultipleInputFieldProps;

const InputField = forwardRef<HTMLInputElement, SingleInputFieldProps>(({
	label, error, className, ...rest
}, ref) => (
	<label htmlFor={rest.id} className={`${className} ${rest.disabled ? "disabled" : ""}`} aria-invalid={!!error}>
		{label}
		<input aria-invalid={!!error} {...rest} ref={ref} aria-errormessage={error} />
		{error && <p className="error">{error}</p>}
	</label>
));

const Checkbox = forwardRef<HTMLInputElement, SingleInputFieldProps>(({
	label, error, className, ...rest
}, ref) => (
	<label htmlFor={rest.id} className={`${className} checkbox ${rest.disabled ? "disabled" : ""}`} aria-invalid={!!error}>
		<input aria-invalid={!!error} {...rest} ref={ref} aria-errormessage={error} />
		{label}
		{error && <p className="error">{error}</p>}
	</label>
));

const Password = forwardRef<HTMLInputElement, PasswordInputLayoutProps>(({
	label, error, className, isVisible, toggleVisibility, ...rest
}, ref) => (
	<label htmlFor={rest.id} className={`${className} ${rest.disabled ? "disabled" : ""}`} aria-invalid={!!error}>
		{label}
		<div className={styles.passwordContainer}>
			<input aria-invalid={!!error} className={styles.input} {...rest} ref={ref} aria-errormessage={error} />
			<button type="button" className="text icon" disabled={rest.disabled} onClick={toggleVisibility}>
				<i>{isVisible ? "eye open" : "eye"}</i>
			</button>
		</div>
		{error && <p className="error">{error}</p>}
	</label>
));

const MultipleInputField = forwardRef<HTMLInputElement, MultipleInputFieldLayoutProps>(({
	label, error, className, pills, removePillByIndex, inputRef, ...rest
}, ref) => {
	const combinedRef = useSyncRefs(ref, inputRef);

	return (
		<label htmlFor={rest.id} className={`${className} ${rest.disabled ? "disabled" : ""} pills`} aria-invalid={!!error}>
			{label}
			<input aria-invalid={!!error} {...rest} ref={combinedRef} aria-errormessage={error} />
			{error && <p className="error">{error}</p>}
			<Pills>
				{pills.map((pill, index) => (
					<Pill index={index} onClick={removePillByIndex}>{pill}</Pill>
				))}
			</Pills>
		</label>
	);
});

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
	if (props.multiple) {
		return <MultipleInputField {...props} {...useMultipleInput(props)} ref={ref} />;
	}

	switch (props.type) {
		case "checkbox": return <Checkbox {...props} ref={ref} />;
		case "password": return <Password {...props} ref={ref} {...usePasswordInput(props)} />;
		default: return <InputField {...props} ref={ref} />;
	}
});

type SelectProps = CommonInputProps & DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

export const Select = forwardRef<HTMLSelectElement, SelectProps>(({
	label, error, className, children, ...rest
}, ref) => (
	<label htmlFor={rest.id} className={`${className} select ${rest.disabled ? "disabled" : ""}`} aria-invalid={!!error}>
		{label}
		<select aria-invalid={!!error} {...rest} ref={ref} aria-errormessage={error}>
			{children}
		</select>
		{error && <p className="error">{error}</p>}
	</label>
));

type DataListProps = CommonInputProps & DetailedHTMLProps<SelectHTMLAttributes<HTMLDataListElement>, HTMLDataListElement>;

export const DataList = forwardRef<HTMLDataListElement, DataListProps>(({
	label, error, className, children, ...rest
}, ref) => (
	<label htmlFor={rest.id} className={`${className} select ${rest.disabled ? "disabled" : ""}`} aria-invalid={!!error}>
		{label}
		<input list={rest.id} />
		<datalist id={rest.id} ref={ref}>
			{children}
		</datalist>
		{error && <p className="error">{error}</p>}
	</label>
));
