import { PageComponent } from "@aptus/frontend-core";
import { Input } from "components/input/input";
import { ConfirmStepFooter, StepSection } from "components/stepSection/stepSection";
import { Tooltip } from "components/tooltip/tooltip";
import { useAuthentication, useRoutes } from "domains";
import { RegisterInput } from "hooks/authentication/models/authenticationInput";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const PasswordStep: PageComponent = () => {
	const { routes } = useRoutes();
	const { isRegistrationStepValid } = useAuthentication();
	const {
		formState, register, getValues, setValue,
	} = useFormContext<RegisterInput>();
	const { t } = useTranslation();

	return (
		<StepSection>
			<Tooltip
				title={t("passwordInput.tooltipTitle")}
				body="passwordInput.tooltipText"
				id="password"
			>
				<Input
					{...register("password")}
					id="password"
					type="password"
					placeholder={t("passwordInput.placeholder")}
					label={t("passwordInput.label")}
					error={formState.errors.password?.message}
				/>
			</Tooltip>
			<Input
				{...register("passwordConfirmation")}
				id="passwordConfirmation"
				type="password"
				placeholder={t("passwordConfirmationInput.placeholder")}
				label={t("passwordConfirmationInput.label")}
				error={formState.errors.passwordConfirmation?.message}
			/>
			<Input
				{...register("emails")}
				id="emails"
				type="email"
				multiple
				placeholder={t("emailsInput.placeholder")}
				label={t("emailsInput.label")}
				error={formState.errors.emails?.message}
			/>
			<Input
				{...register("acceptRewardAndUpdateNotifications")}
				id="acceptRewardAndUpdateNotifications"
				type="checkbox"
				label={t("acceptRewardAndUpdateNotificationsInput.label")}
				error={formState.errors.acceptRewardAndUpdateNotifications?.message}
			/>
			<ConfirmStepFooter
				previousStepHref={routes.register.stepFour}
				currentStepHref={routes.register.stepFive}
				nextStepHref={routes.register.stepSix}
				isValid={isRegistrationStepValid(["password", "passwordConfirmation", "emails", "acceptRewardAndUpdateNotifications"], getValues())}
				confirmationText={t("areYouSureYouWantNoUpdates")}
				shouldAskForConfirmation={!getValues("acceptRewardAndUpdateNotifications")}
				onComply={() => setValue("acceptRewardAndUpdateNotifications", true)}
			/>
		</StepSection>
	);
};
