/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSchoolByIdVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetSchoolByIdDTO = { __typename?: 'Query', schools: Array<{ __typename?: 'School', id: string, title: string, highFiveRegistrationsEnabled: boolean, bikeRegistrationsEnabled: boolean, classNumberEnabled: boolean }> };


export const GetSchoolByIdDocumentDTO = gql`
    query getSchoolById($id: String!) {
  schools(filter: {id: {equals: $id}}) {
    id
    title
    highFiveRegistrationsEnabled
    bikeRegistrationsEnabled
    classNumberEnabled
  }
}
    `;

/**
 * __useGetSchoolById__
 *
 * To run a query within a React component, call `useGetSchoolById` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolById({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchoolById(baseOptions: Apollo.QueryHookOptions<GetSchoolByIdDTO, GetSchoolByIdVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolByIdDTO, GetSchoolByIdVariablesDTO>(GetSchoolByIdDocumentDTO, options);
      }
export function useGetSchoolByIdLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolByIdDTO, GetSchoolByIdVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolByIdDTO, GetSchoolByIdVariablesDTO>(GetSchoolByIdDocumentDTO, options);
        }
export type GetSchoolByIdHookResult = ReturnType<typeof useGetSchoolById>;
export type GetSchoolByIdLazyQueryDTOHookResult = ReturnType<typeof useGetSchoolByIdLazyQueryDTO>;
export type GetSchoolByIdQueryResultDTO = Apollo.QueryResult<GetSchoolByIdDTO, GetSchoolByIdVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    