import { UILogic } from "@aptus/frontend-core";
import {
	ReactNode, Children, isValidElement, cloneElement, useReducer, Reducer, MouseEvent, ReactElement,
} from "react";

interface Props {
	children: ReactNode;
}

interface Result {
	children: ReactNode;
}

export type FaqListProps = Props;
export type FaqListLayoutProps = Result;

const activeIndexReducer: Reducer<number | undefined, number> = (activeIndex, newIndex) => {
	if (activeIndex === newIndex) return undefined;
	return newIndex;
};

export const useFaqList: UILogic<Props, Result> = ({ children }) => {
	const [activeIndex, activate] = useReducer(activeIndexReducer, undefined);

	const preventDefault = (e: MouseEvent<HTMLDetailsElement>, callback: () => void) => {
		e.preventDefault();
		callback();
	};

	const getSummaryProps = (index: number) => ({
		onClick: (e: MouseEvent<HTMLDetailsElement>) => preventDefault(e, () => activate(index)),
	});

	const getDetailsProps = (details: ReactElement, index: number) => {
		const [summary, ...rest] = details.props.children;

		return {
			children: [cloneElement(summary, getSummaryProps(index)), ...rest],
			open: index === activeIndex,
		};
	};

	const toChildrenWithOnClick = (child: ReactNode, index: number): ReactNode => (isValidElement(child)
		? cloneElement<any>(child, getDetailsProps(child, index))
		: child);

	return {
		children: Children.map(children, toChildrenWithOnClick),
	};
};
