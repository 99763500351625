/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateTagVariablesDTO = Types.Exact<{
  tag: Types.PupilTagInputDTO;
}>;


export type ValidateTagDTO = { __typename?: 'Mutation', validatePupilTag: { __typename?: 'Success', success: boolean } };


export const ValidateTagDocumentDTO = gql`
    mutation validateTag($tag: PupilTagInput!) {
  validatePupilTag(data: {tag: $tag}) {
    success
  }
}
    `;
export type ValidateTagMutationFnDTO = Apollo.MutationFunction<ValidateTagDTO, ValidateTagVariablesDTO>;

/**
 * __useValidateTag__
 *
 * To run a mutation, you first call `useValidateTag` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateTag` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateTag, { data, loading, error }] = useValidateTag({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useValidateTag(baseOptions?: Apollo.MutationHookOptions<ValidateTagDTO, ValidateTagVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateTagDTO, ValidateTagVariablesDTO>(ValidateTagDocumentDTO, options);
      }
export type ValidateTagHookResult = ReturnType<typeof useValidateTag>;
export type ValidateTagMutationResultDTO = Apollo.MutationResult<ValidateTagDTO>;
export type ValidateTagMutationOptionsDTO = Apollo.BaseMutationOptions<ValidateTagDTO, ValidateTagVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    