import { PageComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { useRoutes } from "domains";
import { Trans, useTranslation } from "react-i18next";
import { FaqList } from "../components/faqList/faqList";

export const CategoryFourPage: PageComponent = () => {
	const { t } = useTranslation();
	const { routes } = useRoutes();

	return (
		<FaqList>
			<details>
				<summary>{t("faq.category4.question1")}</summary>
				{t("faq.category4.answer1")}
			</details>
			<details>
				<summary>{t("faq.category4.question7")}</summary>
				<p>
					<Trans i18nKey="faq.category4.answer7" components={{ italic: <A href="mailto:hallo@ikhighfive.be" /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category4.question8")}</summary>
				<p>
					<Trans i18nKey="faq.category4.answer8" components={{ italic: <A href="mailto:hallo@ikhighfive.be" /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category4.question3")}</summary>
				<p>
					<Trans i18nKey="faq.category4.answer3" components={{ italic: <A href="mailto:hallo@ikhighfive.be" /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category4.question4")}</summary>
				<p>
					<Trans i18nKey="faq.category4.answer4" components={{ italic: <A href={routes.requestPasswordReset} /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category4.question9")}</summary>
				<p>
					<Trans i18nKey="faq.category4.answer9" components={{ italic: <A href={routes.forgotTagId} /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category4.question5")}</summary>
				{t("faq.category4.answer5")}
			</details>
			<details>
				<summary>{t("faq.category4.question6")}</summary>
				<p>
					<Trans i18nKey="faq.category4.answer6" components={{ italic: <A href="mailto:hallo@ikhighfive.be" /> }} />
				</p>
			</details>
		</FaqList>
	);
};
