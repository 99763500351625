import { UILogic } from "@aptus/frontend-core";
import { HTMLProps, useEffect, useState } from "react";

interface Common extends HTMLProps<HTMLAnchorElement> {
	confirmationText: string;
}

interface Props {
	shouldAskForConfirmation?: boolean;
	onComply: () => void;
}

interface Result {
	isInitialRender: boolean;
	isVisible: boolean;
	askForConfirmation?: () => void;
	comply: () => void;
}

export type ConfirmationLinkProps = Common & Props;
export type ConfirmationLinkLayoutProps = Common & Result;

export const useConfirmationLink: UILogic<Props, Result> = ({ shouldAskForConfirmation, onComply }) => {
	const [isInitialRender, setIsInitialRender] = useState<Result["isInitialRender"]>(true);
	const [isVisible, setIsVisible] = useState<Result["isVisible"]>(false);

	const askForConfirmationCallback: Result["askForConfirmation"] = () => {
		setIsVisible(true);
	};

	const comply: Result["comply"] = () => {
		onComply();
		setIsVisible(false);
	};

	useEffect(() => {
		setTimeout(() => {
			setIsInitialRender(false);
		}, 500);
	}, []);

	return {
		isInitialRender,
		isVisible,
		askForConfirmation: shouldAskForConfirmation ? askForConfirmationCallback : undefined,
		comply,
	};
};
