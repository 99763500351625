import { UIComponent } from "@aptus/frontend-core";
import { ReactNode } from "react";
import styles from "./pills.module.css";

interface Props {
	onClick: (index: number) => void;
	index: number;
	children?: ReactNode;
}

export const Pill: UIComponent<Props> = ({ onClick, index, children }) => (
	<div className={styles.pill}>
		{children}
		<button
			type="button"
			className={styles.button}
			onClick={() => onClick(index)}
		>
			<i>close</i>
		</button>
	</div>
);
