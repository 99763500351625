import { UIComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { Trans, useTranslation } from "react-i18next";
import styles from "./locationRedirectModal.module.css";
import { LocaleConfig } from "../../..";

interface Props {
	mostRelevantLocaleConfig: LocaleConfig | null;
	currentLocaleConfig: LocaleConfig | null;

	changeToMostRelevantSiteForLanguage: () => void;
	stayOnSite: () => void;
	neverAskAgain: () => void;
}

export const LocationRedirectModal: UIComponent<Props> = ({
	mostRelevantLocaleConfig,
	currentLocaleConfig,
	changeToMostRelevantSiteForLanguage,
	stayOnSite,
	neverAskAgain,
}) => {
	const { t } = useTranslation();

	return (
		<div className={styles["location-redirect-modal"]}>
			<h2>{t("locationRedirect.title")}</h2>
			<p>
				<Trans i18nKey="locationRedirect.body" components={{ bold: <strong />, italic: <A target="_self" href={`https://${mostRelevantLocaleConfig?.siteURL}` || ""} />, br: <br /> }} values={{ site: mostRelevantLocaleConfig?.siteURL, emoji: mostRelevantLocaleConfig?.emoji }} />
			</p>
			<div className={styles["actions-wrapper"]}>
				<button onClick={stayOnSite} type="button" className="secondary">{t("locationRedirect.stay", { emoji: currentLocaleConfig?.emoji })}</button>
				<button onClick={changeToMostRelevantSiteForLanguage} type="button">{t("locationRedirect.leave", { emoji: mostRelevantLocaleConfig?.emoji })}</button>
			</div>
			<button onClick={neverAskAgain} type="button" className={`secondary ${styles["never-ask-again"]}`}>{t("locationRedirect.neverAskAgain")}</button>
		</div>
	);
};
