import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Trans } from "react-i18next";
import styles from "./tooltip.module.css";
import { TooltipLayoutProps, TooltipProps, useTooltip } from "./useTooltip";

const Layout: UILayout<TooltipLayoutProps> = ({
	title, body, isVisible, isInitialRender, tooltipRef, id, children,
}) => (
	<div className={styles.container}>
		<div
			ref={tooltipRef}
			className={`${styles.tooltip} tooltip ${id ? styles.hasLabel : ""} ${isInitialRender ? "initial" : ""} ${isVisible ? "" : "hidden"}`}
		>
			<h3>{title}</h3>
			<p><Trans i18nKey={body} components={{ bold: <strong /> }} /></p>
		</div>
		{children}
	</div>
);

export const Tooltip: UIComponent<TooltipProps> = (props) => (
	<Layout {...props} {...useTooltip(props)} />
);
