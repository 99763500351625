import { UILogic } from "@aptus/frontend-core";
import confetti from "canvas-confetti";

const DURATION = 1 * 1000;

export const useConfetti: UILogic<unknown, void> = () => {
	const animationEnd = Date.now() + DURATION;
	const colors = ["#f4c14b", "#256be9", "#49edaa", "#6112af"];
	// eslint-disable-next-line no-restricted-globals
	const particleCount = screen.width / 1000;

	(function frame() {
		confetti({
			particleCount,
			angle: 110,
			spread: 30,
			origin: { x: 1, y: 1 },
			colors: [colors[Math.floor(Math.random() * colors.length)]],
			startVelocity: 100,
		});

		confetti({
			particleCount,
			angle: 70,
			spread: 30,
			origin: { x: 0, y: 1 },
			colors: [colors[Math.floor(Math.random() * colors.length)]],
			startVelocity: 100,
		});

		if (Date.now() < animationEnd) {
			requestAnimationFrame(frame);
		}
	}());
};
