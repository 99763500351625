import { PageComponent } from "@aptus/frontend-core";
import { useAuthentication, useRoutes } from "domains";
import { LoginForm } from "pages/login/components/loginForm/loginForm";
import { useTranslation } from "react-i18next";
import styles from "./safetyDays.module.css";

export const SafetyDaysPage: PageComponent = () => {
	const { t } = useTranslation();
	const {
		defaultLoginInput, login, loginSchema, isLoading,
	} = useAuthentication();
	const { routes } = useRoutes();

	return (
		<div className={styles.page}>
			<div className={styles.form}>
				<h1>{t("login")}</h1>
				<LoginForm
					defaultValues={defaultLoginInput}
					submit={(input) => login({ ...input, targetPath: "quiz/clq3wkokn000008i21rvm6r1e" })}
					schema={{ nfcTagId: loginSchema.nfcTagId, nfcTagType: loginSchema.nfcTagType, password: loginSchema.password }}
					isLoading={isLoading}
					registerUrl={routes.index}
					requestPasswordResetHref={routes.requestPasswordReset}
				/>
			</div>
		</div>
	);
};
