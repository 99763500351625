import { PageComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { useRoutes } from "domains";
import { Trans, useTranslation } from "react-i18next";
import { formatImageUrl } from "utils/formatImageUrl";
import { useConfetti } from "./components/confetti/useConfetti";
import styles from "./registrationDonePage.module.css";

export const RegistrationDonePage: PageComponent = () => {
	const { routes } = useRoutes();
	const { t } = useTranslation();
	useConfetti({});

	return (
		<div className={styles.page}>
			<section className={styles.container}>
				<h1><Trans i18nKey="registrationDone" components={{ bold: <strong /> }} /></h1>
				<div className={styles.buttons}>
					<A type="button" href={routes.pupilPlatform}>
						{t("goToPupilPlatform")}
					</A>
					<A type="button" href={routes.register.index} className="secondary">
						{t("registerAnotherChild")}
					</A>
				</div>
			</section>
			<img className={styles.five} src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/party-five.svg")} alt="Five" width="595" height="842" />
		</div>
	);
};
