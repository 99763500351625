import { PageComponent } from "@aptus/frontend-core";
import { useAuthentication, useRoutes, useSchools } from "domains";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { FieldPath, FormProvider, useForm } from "react-hook-form";
import { RegisterInput } from "hooks/authentication/models/authenticationInput";
import { HTMLValidationSchemaResolver } from "utils/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { HTMLValidationSchemaGenerator } from "utils/html-validation-schema/models/validationSchema";
import { useEvent } from "utils/useEvent";
import { useEffect } from "react";
import { Loader } from "components/loader/loader";
import { Step, Stepper } from "./components/stepper/stepper";
import styles from "./registerPage.module.css";
import { useGetPolicies } from "./models/getPolicies";
import { useGetSchoolById } from "./models/getSchoolById";

export const RegisterPage: PageComponent = () => {
	const policiesAPI = useGetPolicies();
	const {
		register: registerCb, isRegistrationStepValid, defaultRegisterInput, registerSchemaGenerator,
	} = useAuthentication(policiesAPI);
	const { handleSubmit, ...methods } = useForm<RegisterInput, HTMLValidationSchemaGenerator<RegisterInput>>({
		mode: "onBlur",
		defaultValues: defaultRegisterInput,
		resolver: HTMLValidationSchemaResolver(registerSchemaGenerator),
	});
	const { activeRegisterStep, routes } = useRoutes();
	const { school, isLoading } = useSchools(useGetSchoolById({
		variables: { id: methods.getValues("school") },
		skip: !methods.getValues("school"),
	}));
	const { t } = useTranslation();

	const isStepDone = (...fields: FieldPath<RegisterInput>[]): boolean => (
		isRegistrationStepValid(fields, methods.watch())
	);

	/**
	 * In theory, the UI layer should not need direct access to Domain Events. This line is temporary until we have a more
	 * robust and permanent solution to centralized async form validation on the Interaction layer.
	*/
	useEvent("validationErrorOcurred", (field, message) => methods.setError(field as any, { message }));

	useEffect(() => {
		methods.setValue("requireClassNumber", school.requireClassNumber);
		if (school.requireClassNumber === false) {
			methods.setValue("classNumber", undefined);
			methods.clearErrors("classNumber");
		}
	}, [school.requireClassNumber]);

	useEffect(() => {
		/* Merged useEffects as one could reset the form after (important) changes were made in the other and to prevent duplicating code */
		methods.reset({ ...defaultRegisterInput });
	}, [policiesAPI.data]);

	return (
		<Loader isLoading={isLoading}>
			<div className={styles.page}>
				<Stepper mobileClassName={styles.stepperMobile} desktopClassName={styles.stepperDesktop}>
					<Step
						href={routes.register.stepOne}
						isActive={activeRegisterStep === 1}
						isDone={isStepDone("wristband")}
						icon="wristband"
					>
						{t("wristband")}
					</Step>
					<Step
						href={routes.register.stepTwo}
						isActive={activeRegisterStep === 2}
						isDone={isStepDone("biketag")}
						icon="bike"
					>
						{t("biketag")}
					</Step>
					<Step
						href={routes.register.stepThree}
						isActive={activeRegisterStep === 3}
						isDone={isStepDone("project", "school")}
						icon="school"
					>
						{t("yourSchool")}
					</Step>
					<Step
						href={routes.register.stepFour}
						isActive={activeRegisterStep === 4}
						isDone={isStepDone("classroom", "classNumber", "username", "usernameId")}
						icon="backpack"
					>
						{t("yourClassroom")}
					</Step>
					<Step
						href={routes.register.stepFive}
						isActive={activeRegisterStep === 5}
						isDone={isStepDone("password", "passwordConfirmation", "emails", "acceptRewardAndUpdateNotifications")}
						icon="person"
					>
						{t("password")}
					</Step>
					<Step
						href={routes.register.stepSix}
						isActive={activeRegisterStep === 6}
						icon="paper"
					>
						{t("overview")}
					</Step>
				</Stepper>
				<section className={styles.container}>
					<FormProvider<RegisterInput> handleSubmit={handleSubmit} {...methods}>
						<form
							onSubmit={handleSubmit(registerCb)}
							className={styles.content}
						>
							<Outlet />
						</form>
					</FormProvider>
				</section>
			</div>
		</Loader>
	);
};
