import { UIComponent } from "@aptus/frontend-core";
import { Private } from "components/private";
import {
	usePageTitles,
	useRoutes,
} from "domains";
import { useAuthenticationEffects } from "hooks/authentication/useAuthenticationUseCases";
import { usePageTitleEvents } from "hooks/pageTitle/models/usePageTitleUseCases";
import { useRoutesEffects } from "hooks/routes/useRoutesUseCases";
import { BackgroundPage } from "pages/background/backgroundPage";
import { BijbaanPage } from "pages/bijbaan/bijbaanPage";
import { CookiePolicyPage } from "pages/cookiePolicy/cookiePolicyPage";
import { FaqPage } from "pages/faq/faqPage";
import { CategoryFivePage } from "pages/faq/pages/categoryFivePage";
import { CategoryFourPage } from "pages/faq/pages/categoryFourPage";
import { CategoryOnePage } from "pages/faq/pages/categoryOnePage";
import { CategoryThreePage } from "pages/faq/pages/categoryThreePage";
import { CategoryTwoPage } from "pages/faq/pages/categoryTwoPage";
import { ForgotTagIdPage } from "pages/forgotTagId/forgotTagId";
import { HeaderPage } from "pages/header/headerPage";
import { IntroductionPage } from "pages/introduction/introductionPage";
import { JobstudentPage } from "pages/jobstudent/jobstudentPage";
import { LoginPage } from "pages/login/loginPage";
import { PasswordResetPage } from "pages/passwordReset/passwordResetPage";
import { PrivacyPolicyPage } from "pages/privacyPolicy/privacyPolicyPage";
import { BiketagStep } from "pages/register/pages/biketagStep/biketagStep";
import { ClassroomStep } from "pages/register/pages/classroomStep/classroomStep";
import { OverviewStep } from "pages/register/pages/overviewStep/overviewStep";
import { PasswordStep } from "pages/register/pages/passwordStep/passwordStep";
import { SchoolStep } from "pages/register/pages/schoolStep/schoolStep";
import { WristbandStep } from "pages/register/pages/wristbandStep/wristbandStep";
import { RegisterPage } from "pages/register/registerPage";
import { RegistrationDonePage } from "pages/registrationDone/registrationDonePage";
import { RequestPasswordResetPage } from "pages/requestPasswordReset/requestPasswordResetPage";
import { SafetyDaysPage } from "pages/safetyDays/safetyDaysPage";
import { StyleguidePage } from "pages/styleguide/styleguidePage";
import { WebinarPage } from "pages/webinar/webinarPage";
import { WhatIsHighFivePage } from "pages/whatIsHighFive/whatIsHighFivePage";
import { WhereToHighFivePage } from "pages/whereToHighFive/whereToHighFivePage";
import { Navigate, Route, Routes } from "react-router-dom";
import { formatImageUrl } from "utils/formatImageUrl";
import { HomePage } from "./pages/home/homePage";

/**
 * This is the router of the application.
 * It handles everything that is based on the "Router state" (URL).
 * This enforces consistency on how URLs are handled throughout the application.
 */

export const Content: UIComponent = () => {
	const pageTitles = usePageTitles();
	usePageTitleEvents(pageTitles);

	const { routes } = useRoutes();
	useRoutesEffects(useRoutes());
	useAuthenticationEffects();

	const isAuthorized = !!(window.environment?.anonymousToken);

	return (
		<Routes>
			<Route path="*" element={<Navigate to={routes.index} />} />
			<Route element={<BackgroundPage backgroundImage={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/background-illustration.png")} />}>
				<Route element={<HeaderPage />}>
					<Route path={routes.index} element={<HomePage />} />
					<Route path={routes.register.done} element={<RegistrationDonePage />} />
				</Route>
			</Route>
			<Route>
				<Route element={<HeaderPage applyOwnMargin />}>
					<Route path={routes.whatIsHighFive} element={<WhatIsHighFivePage />} />
				</Route>
			</Route>
			<Route element={<BackgroundPage backgroundImage={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/background.png")} />}>
				<Route element={<HeaderPage />}>
					<Route path={routes.login} element={<LoginPage />} />
					<Route
						path={routes.register.introduction}
						element={(
							<Private isAuthorized={isAuthorized} redirectTo={routes.index}>
								<IntroductionPage />
							</Private>
						)}
					/>
					<Route path={routes.passwordReset} element={<PasswordResetPage />} />
					<Route path={routes.whereToHighFive} element={<WhereToHighFivePage />} />
					<Route path={routes.safetyDays} element={<SafetyDaysPage />} />
					<Route path={routes.requestPasswordReset} element={<RequestPasswordResetPage />} />
					<Route path={routes.frequentlyAskedQuestions.index} element={<FaqPage />}>
						<Route index element={<Navigate to={routes.frequentlyAskedQuestions.categoryOne} />} />
						<Route path={routes.frequentlyAskedQuestions.categoryOne} element={<CategoryOnePage />} />
						<Route path={routes.frequentlyAskedQuestions.categoryTwo} element={<CategoryTwoPage />} />
						<Route path={routes.frequentlyAskedQuestions.categoryThree} element={<CategoryThreePage />} />
						<Route path={routes.frequentlyAskedQuestions.categoryFour} element={<CategoryFourPage />} />
						<Route path={routes.frequentlyAskedQuestions.categoryFive} element={<CategoryFivePage />} />
					</Route>
					<Route
						path={routes.register.index}
						element={(
							<Private isAuthorized={isAuthorized} redirectTo={routes.index}>
								<RegisterPage />
							</Private>
						)}
					>
						<Route index element={<Navigate to={routes.register.stepOne} />} />
						<Route path={routes.register.stepOne} element={<WristbandStep />} />
						<Route path={routes.register.stepTwo} element={<BiketagStep />} />
						<Route path={routes.register.stepThree} element={<SchoolStep />} />
						<Route path={routes.register.stepFour} element={<ClassroomStep />} />
						<Route path={routes.register.stepFive} element={<PasswordStep />} />
						<Route path={routes.register.stepSix} element={<OverviewStep />} />
					</Route>
					<Route path={routes.jobstudent} element={<JobstudentPage />} />
					<Route path={routes.bijbaan} element={<BijbaanPage />} />
					<Route path={routes.cookiePolicy} element={<CookiePolicyPage />} />
					<Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
					<Route path={routes.webinar} element={<WebinarPage />} />
					<Route path={routes.forgotTagId} element={<ForgotTagIdPage />} />
					<Route
						path={routes.styleguide}
						element={(
							<Private isAuthorized={process.env.REACT_APP_ENVIRONMENT === "local"} redirectTo={routes.index}>
								<StyleguidePage />
							</Private>
						)}
					/>
				</Route>
			</Route>
		</Routes>
	);
};
