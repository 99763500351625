import { UIComponent } from "@aptus/frontend-core";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import styles from "./loadingButton.module.css";

interface Props extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	isLoading?: boolean;
}

export const LoadingButton: UIComponent<Props> = ({
	isLoading, disabled, children, ...rest
}) => (
	// eslint-disable-next-line react/button-has-type
	<button disabled={isLoading || disabled} {...rest}>
		{isLoading
			? <i className={styles.spinner}>spinner</i>
			: children}
	</button>
);
