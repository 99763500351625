import { UseCases } from "@aptus/frontend-core";
import { event } from "event";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { tracker } from "utils/Tracker";
import { useEvent } from "utils/useEvent";
import { Routes } from "./models/routes";

interface Result {
	routes: Routes;
	currentPathname: string;
	activeRegisterStep?: number;

	getQuery: (parameter: string) => string;
	goToLogin: () => void;
	goToIntroduction: () => void;
	goToRegister: () => void;
	goToRegisterDone: () => void;
	goToSchoolHomepage: (schoolUrl: string, token: string, targetPath?: string) => void;
	goToSchoolWhereToHighFive: (schoolUrl: string, token: string) => void;
}

export const useRoutesUseCases: UseCases<undefined, Result> = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const routes: Result["routes"] = {
		index: "/",
		styleguide: "/styleguide",
		frequentlyAskedQuestions: {
			index: "/veelgestelde-vragen",
			categoryOne: "/veelgestelde-vragen/we-gaan-van-start",
			categoryTwo: "/veelgestelde-vragen/mijn-eerste-high-five",
			categoryThree: "/veelgestelde-vragen/mijn-eerste-beloningen",
			categoryFour: "/veelgestelde-vragen/help",
			categoryFive: "/veelgestelde-vragen/privacy",
		},
		whatIsHighFive: "/wat-is-high-five",
		whereToHighFive: "/locaties",
		login: "/aanmelden",
		// passwordReset URL is tied to the password reset mail.
		passwordReset: "/resetpassword",
		requestPasswordReset: "/wachtwoord-herstellen",
		register: {
			index: "/registreer",
			introduction: "/introductie",
			stepOne: "/registreer/1",
			stepTwo: "/registreer/2",
			stepThree: "/registreer/3",
			stepFour: "/registreer/4",
			stepFive: "/registreer/5",
			stepSix: "/registreer/6",
			done: "/registratie-voltooid",
		},
		pupilPlatform: "/aanmelden",
		jobstudent: "/jobstudent",
		bijbaan: "/bijbaan",
		cookiePolicy: "/cookie-policy",
		privacyPolicy: "/privacy-policy",
		webinar: "/webinar",
		forgotTagId: "/tag-id-vergeten",
		safetyDays: "/wezienjou",
	};

	const getQuery: Result["getQuery"] = (parameter) => new URLSearchParams(location.search).get(parameter) || "";

	const activeRegisterStep: Result["activeRegisterStep"] = location.pathname.includes(routes.register.index)
		? Number(location.pathname.match(/\d/))
		: undefined;

	const goToLogin: Result["goToLogin"] = () => {
		navigate(routes.login);
	};

	const goToIntroduction: Result["goToIntroduction"] = () => {
		navigate(routes.register.introduction);
	};

	const goToRegister: Result["goToRegister"] = () => {
		navigate(routes.register.stepOne);
	};

	const goToRegisterDone: Result["goToRegisterDone"] = () => {
		navigate(routes.register.done);
	};

	const goToSchoolHomepage: Result["goToSchoolHomepage"] = (schoolUrl, token, targetPath) => {
		const path = targetPath || "mijnpagina";

		window.location.replace(`https://${schoolUrl}/${path}?jwt=${token}`);
	};

	const goToSchoolWhereToHighFive: Result["goToSchoolWhereToHighFive"] = (schoolUrl, token) => {
		window.location.replace(`https://${schoolUrl}/map?jwt=${token}`);
	};

	useEffect(() => {
		if (location) {
			const pageUrl = `${location.pathname}${location.search || ""}`;
			tracker.set({ page: pageUrl });
			tracker.pageView(pageUrl);

			event.emit("routeChanged", location.pathname);
		}
	}, [location]);

	return {
		routes,
		currentPathname: location.pathname,
		activeRegisterStep,

		getQuery,
		goToLogin,
		goToIntroduction,
		goToRegister,
		goToRegisterDone,
		goToSchoolHomepage,
		goToSchoolWhereToHighFive,
	};
};

export const useRoutesEffects = (routes: ReturnType<typeof useRoutesUseCases>) => {
	useEvent("registrationStarted", routes.goToIntroduction);
	useEvent("pupilRegistered", routes.goToRegisterDone);
	useEvent("loggedIn", routes.goToSchoolHomepage);
	useEvent("passwordReset", routes.goToLogin);
	useEvent("loginStarted", routes.goToLogin);
};
