/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateClassNumberVariablesDTO = Types.Exact<{
  classroom: Types.Scalars['String'];
  classNumber: Types.Scalars['Int'];
}>;


export type ValidateClassNumberDTO = { __typename?: 'Mutation', validatePupilClassNumber: { __typename?: 'Success', success: boolean } };


export const ValidateClassNumberDocumentDTO = gql`
    mutation validateClassNumber($classroom: String!, $classNumber: Int!) {
  validatePupilClassNumber(data: {class: $classroom, classNumber: $classNumber}) {
    success
  }
}
    `;
export type ValidateClassNumberMutationFnDTO = Apollo.MutationFunction<ValidateClassNumberDTO, ValidateClassNumberVariablesDTO>;

/**
 * __useValidateClassNumber__
 *
 * To run a mutation, you first call `useValidateClassNumber` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateClassNumber` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateClassNumber, { data, loading, error }] = useValidateClassNumber({
 *   variables: {
 *      classroom: // value for 'classroom'
 *      classNumber: // value for 'classNumber'
 *   },
 * });
 */
export function useValidateClassNumber(baseOptions?: Apollo.MutationHookOptions<ValidateClassNumberDTO, ValidateClassNumberVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateClassNumberDTO, ValidateClassNumberVariablesDTO>(ValidateClassNumberDocumentDTO, options);
      }
export type ValidateClassNumberHookResult = ReturnType<typeof useValidateClassNumber>;
export type ValidateClassNumberMutationResultDTO = Apollo.MutationResult<ValidateClassNumberDTO>;
export type ValidateClassNumberMutationOptionsDTO = Apollo.BaseMutationOptions<ValidateClassNumberDTO, ValidateClassNumberVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    