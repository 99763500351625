import { UIComponent } from "@aptus/frontend-core";
import { ReactNode } from "react";
import styles from "./pills.module.css";

interface Props {
	children?: ReactNode
}

export const Pills: UIComponent<Props> = ({ children }) => (
	<div className={styles.outer}>
		<div className={styles.inner}>
			{children}
		</div>
	</div>
);
