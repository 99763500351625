import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Input } from "components/input/input";
import { LoadingButton } from "components/loadingButton/loadingButton";
import { useTranslation } from "react-i18next";
import { ForgotTagIdInputLayoutProps, ForgotTagIdInputProps, useForgotTagIdForm } from "./useForgotTagIdForm";

const Layout: UILayout<ForgotTagIdInputLayoutProps> = ({
	errors, register, submit, isLoading,
}) => {
	const { t } = useTranslation();

	return (
		<form onSubmit={submit}>
			<Input
				{...register("email")}
				id="wristband"
				placeholder={t("forgotTagIdForm.placeholder")}
				label={t("forgotTagIdForm.label")}
				error={errors.email?.message}
			/>
			<LoadingButton type="submit" isLoading={isLoading} disabled={false}>{t("forgotTagIdForm.submit")}</LoadingButton>
		</form>
	);
};

export const ForgotTagIdForm: UIComponent<ForgotTagIdInputProps> = (props) => (
	<Layout {...props} {...useForgotTagIdForm(props)} />
);
