import { PageComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { Trans, useTranslation } from "react-i18next";
import { FaqList } from "../components/faqList/faqList";

export const CategoryFivePage: PageComponent = () => {
	const { t } = useTranslation();

	return (
		<FaqList>
			<details>
				<summary>{t("faq.category5.question1")}</summary>
				<p>
					<Trans i18nKey="faq.category5.answer1" components={{ italic: <A href="mailto:hallo@ikhighfive.be" />, br: <br /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category5.question2")}</summary>
				<p>
					<Trans
						i18nKey="faq.category5.answer2"
						components={{
							italic: <A href="mailto:hallo@ikhighfive.be" />,
							br: <br />,
							li: <li />,
							ul: <ul />,
						}}
					/>
				</p>
			</details>
			<details>
				<summary>{t("faq.category5.question3")}</summary>
				<p>
					<Trans i18nKey="faq.category5.answer3" components={{ italic: <A href="mailto:hallo@ikhighfive.be" />, br: <br /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category5.question4")}</summary>
				<p>
					<Trans i18nKey="faq.category5.answer4" components={{ italic: <A href="mailto:hallo@ikhighfive.be" />, br: <br /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category5.question5")}</summary>
				<p>
					<Trans i18nKey="faq.category5.answer5" components={{ italic: <A href="mailto:hallo@ikhighfive.be" />, br: <br /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category5.question6")}</summary>
				<p>
					<Trans i18nKey="faq.category5.answer6" components={{ italic: <A href="mailto:hallo@ikhighfive.be" />, br: <br /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category5.question7")}</summary>
				<p>
					<Trans i18nKey="faq.category5.answer7" components={{ italic: <A href="mailto:hallo@ikhighfive.be" />, br: <br /> }} />
				</p>
			</details>
			<details>
				<summary>{t("faq.category5.question8")}</summary>
				<p>
					<Trans i18nKey="faq.category5.answer8" components={{ italic: <A href="mailto:hallo@ikhighfive.be" />, br: <br /> }} />
				</p>
			</details>

			<details>
				<summary>{t("faq.category5.question9")}</summary>
				<p>
					<Trans i18nKey="faq.category5.answer9" components={{ italic: <A href="mailto:hallo@ikhighfive.be" />, br: <br /> }} />
				</p>
			</details>
		</FaqList>
	);
};
