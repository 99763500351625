import { UIComponent } from "@aptus/frontend-core";
import { ReactNode } from "react";
import styles from "./loader.module.css";

interface Props {
	isLoading?: boolean;
	children: ReactNode;
}

export const Loader: UIComponent<Props> = ({ isLoading, children }) => (isLoading
	? <div className={styles.spinner}><i>spinner</i></div>
	: <>{children}</>);
