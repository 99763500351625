import { PageComponent } from "@aptus/frontend-core";
import { Input, Select } from "components/input/input";
import { MiddleStepFooter, StepSection } from "components/stepSection/stepSection";
import {
	useAuthentication,
	useClassRooms,
	useRoutes,
	useSchools,
} from "domains";
import { RegisterInput } from "hooks/authentication/models/authenticationInput";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Tooltip } from "components/tooltip/tooltip";
import { ClassRoom } from "hooks/classRoom/models/classRoom";
import { useGetSchoolById } from "pages/register/models/getSchoolById";
import { UsernameInput } from "./components/usernameInput";
import { useGetClassroomsBySchool } from "./models/getClassroomsBySchool";

export const ClassroomStep: PageComponent = () => {
	const { routes } = useRoutes();
	const {
		generateUsername, username, isLoading: isUsernameLoading, isRegistrationStepValid, validateClassNumber, registerSchemaGenerator,
	} = useAuthentication();
	const {
		register, formState, setValue, getValues, watch, setError, clearErrors,
	} = useFormContext<RegisterInput>();
	const { t } = useTranslation();

	const { classRooms, isLoading: isClassRoomLoading } = useClassRooms(useGetClassroomsBySchool({ variables: { schoolId: getValues("school") }, skip: !getValues("school") }));
	const { school, isLoading: isSchoolLoading } = useSchools(useGetSchoolById({
		variables: { id: getValues("school") },
		skip: !getValues("school"),
	}));

	const isLoading = isClassRoomLoading || isSchoolLoading;

	const toOption = ({ id, title }: ClassRoom) => (
		<option key={id} value={id} selected={getValues("classroom") === id}>{title}</option>
	);

	const onClassNumberBlur = async (): Promise<void> => {
		const classNumberValue = getValues("classNumber");

		if (classNumberValue === undefined || classNumberValue === "") {
			clearErrors("classNumber");
		}

		const error = await validateClassNumber({
			classroom: getValues("classroom"),
			classNumber: parseInt(classNumberValue!, 10),
		});

		if (error) {
			setError("classNumber", { message: error });
		} else {
			clearErrors("classNumber");
		}
	};

	useEffect(() => {
		if (username?.id && getValues("usernameId") !== username?.id) {
			setValue("usernameId", username?.id || "");
		}

		if (username?.username && getValues("username") !== username.username) {
			setValue("username", username?.username || "");
		}
	}, [username]);

	return (
		<StepSection>
			<Select
				{...register("classroom", {
					onChange: onClassNumberBlur,
				})}
				id="classroom"
				placeholder={t("classroomInput.placeholder")}
				disabled={isLoading || !classRooms || !classRooms.length}
				label={t("classroomInput.label")}
				error={formState.errors.classroom?.message}
			>
				<option selected style={{ display: "none" }}>{t("classroomInput.defaultOption")}</option>
				{classRooms.map(toOption)}
			</Select>
			{school && school.requireClassNumber ? (
				<Tooltip
					title={t("classNumberInput.tooltipTitle")}
					body="classNumberInput.tooltipText"
					id="classNumber"
				>
					<Input
						{...register("classNumber", {
							onBlur: onClassNumberBlur,
							onChange: (event) => {
								// eslint-disable-next-line no-param-reassign
								event.target.value = Math.round(event.target.value.replace(/\D/g, ""));
							},
						})}
						type="number"
						id="classNumber"
						placeholder={t("classNumberInput.placeholder")}
						label={t("classNumberInput.label")}
						error={formState.errors.classNumber?.message}
						step={1}
						{...registerSchemaGenerator(getValues()).classNumber}
					/>
				</Tooltip>
			) : null}
			<UsernameInput
				aria-invalid={!!formState.errors.username}
				isLoading={isUsernameLoading}
				username={watch("username")}
				generate={generateUsername}
			/>
			<p className="error">{formState.errors.username?.message}</p>
			<MiddleStepFooter
				previousStepHref={routes.register.stepThree}
				currentStepHref={routes.register.stepFour}
				nextStepHref={routes.register.stepFive}
				isValid={!isLoading && (school && school.requireClassNumber ? !formState.errors.classNumber : true) && !isUsernameLoading && isRegistrationStepValid(["classroom", "classNumber", "username", "usernameId"], watch())}
			/>
		</StepSection>
	);
};
