/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  Time: any;
  Upload: any;
};

export type AcceptedPolicyDTO = {
  __typename?: 'AcceptedPolicy';
  accepted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  policy: PolicyDTO;
  user?: Maybe<UserDTO>;
};

export type AcceptedPolicyFilterDTO = {
  AND?: InputMaybe<Array<AcceptedPolicyFilterDTO>>;
  OR?: InputMaybe<Array<AcceptedPolicyFilterDTO>>;
  accepted?: InputMaybe<BooleanFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  policy?: InputMaybe<PolicyFilterDTO>;
  user?: InputMaybe<UserFilterDTO>;
};

export type AcceptedPolicyInputDTO = {
  accepted: Scalars['Boolean'];
  id: Scalars['String'];
  type: PolicyTypeDTO;
};

export type AchievementDTO = {
  __typename?: 'Achievement';
  achievementProgresses: Array<AchievementProgressDTO>;
  achievementsUnlocked: Array<AchievementUnlockedDTO>;
  badgeImage: Scalars['String'];
  coinsToEarn: Scalars['Int'];
  description: Scalars['String'];
  goal: Scalars['Int'];
  id: Scalars['String'];
  progress: Scalars['Float'];
  recurring: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
  unlocked: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export type AchievementCreateInputDTO = {
  badgeImage: Scalars['String'];
  coinsToEarn: Scalars['Int'];
  description: Scalars['String'];
  goal: Scalars['Int'];
  recurring: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
  visible: Scalars['Boolean'];
};

export type AchievementElementCreateInputDTO = {
  assetUrl: Scalars['String'];
  possibleColors: Array<Scalars['String']>;
  type: AvatarElementTypeDTO;
  visibilityType: VisibilityTypeDTO;
};

export type AchievementElementUpdateInputDTO = {
  assetUrl?: InputMaybe<Scalars['String']>;
  possibleColors?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<AvatarElementTypeDTO>;
  visibilityType?: InputMaybe<VisibilityTypeDTO>;
};

export type AchievementFilterDTO = {
  AND?: InputMaybe<Array<AchievementFilterDTO>>;
  OR?: InputMaybe<Array<AchievementFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
  visible?: InputMaybe<BooleanFilterDTO>;
};

export type AchievementLevelDTO = {
  __typename?: 'AchievementLevel';
  achievement: AchievementDTO;
  backgrounds: Array<AchievementLevelBackgroundDTO>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  fromProgress: Scalars['Float'];
  id: Scalars['String'];
  status?: Maybe<AchievementLevelPupilStatusDTO>;
  stepStones: Array<AchievementLevelStepStoneDTO>;
  title: Scalars['String'];
  toProgress: Scalars['Float'];
};

export type AchievementLevelBackgroundDTO = {
  __typename?: 'AchievementLevelBackground';
  achievementLevel: AchievementLevelDTO;
  assetUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
};

export type AchievementLevelBackgroundCreateInputDTO = {
  achievementLevelId: Scalars['String'];
  assetUrl: Scalars['String'];
};

export type AchievementLevelBackgroundFilterDTO = {
  AND?: InputMaybe<Array<AchievementLevelBackgroundFilterDTO>>;
  OR?: InputMaybe<Array<AchievementLevelBackgroundFilterDTO>>;
  achievementLevel?: InputMaybe<AchievementLevelFilterDTO>;
  assetUrl?: InputMaybe<StringFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
};

export type AchievementLevelBackgroundUpdateInputDTO = {
  achievementLevelId?: InputMaybe<Scalars['String']>;
  assetUrl?: InputMaybe<Scalars['String']>;
};

export type AchievementLevelCreateInputDTO = {
  achievementId: Scalars['String'];
  descriptions: Array<MultiLanguageTextInputDTO>;
  fromProgress: Scalars['Float'];
  titles: Array<MultiLanguageTextInputDTO>;
  toProgress: Scalars['Float'];
};

export type AchievementLevelFilterDTO = {
  AND?: InputMaybe<Array<AchievementLevelFilterDTO>>;
  OR?: InputMaybe<Array<AchievementLevelFilterDTO>>;
  achievement?: InputMaybe<AchievementFilterDTO>;
  fromProgress?: InputMaybe<FloatFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  toProgress?: InputMaybe<FloatFilterDTO>;
};

export type AchievementLevelPupilStatusDTO = {
  __typename?: 'AchievementLevelPupilStatus';
  achievementLevel: AchievementLevelDTO;
  active: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  progress: Scalars['Float'];
  pupil: PupilDTO;
  startedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type AchievementLevelPupilStatusFilterDTO = {
  AND?: InputMaybe<Array<AchievementLevelPupilStatusFilterDTO>>;
  OR?: InputMaybe<Array<AchievementLevelPupilStatusFilterDTO>>;
  achievementLevel?: InputMaybe<AchievementLevelFilterDTO>;
  active?: InputMaybe<BooleanFilterDTO>;
  completedAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  progress?: InputMaybe<FloatFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
  startedAt?: InputMaybe<DateTimeFilterDTO>;
};

export type AchievementLevelStepStoneDTO = {
  __typename?: 'AchievementLevelStepStone';
  achievementLevel: AchievementLevelDTO;
  assetUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
};

export type AchievementLevelStepStoneCreateInputDTO = {
  achievementLevelId: Scalars['String'];
  assetUrl: Scalars['String'];
};

export type AchievementLevelStepStoneFilterDTO = {
  AND?: InputMaybe<Array<AchievementLevelStepStoneFilterDTO>>;
  OR?: InputMaybe<Array<AchievementLevelStepStoneFilterDTO>>;
  achievementLevel?: InputMaybe<AchievementLevelFilterDTO>;
  assetUrl?: InputMaybe<StringFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
};

export type AchievementLevelStepStoneUpdateInputDTO = {
  achievementLevelId?: InputMaybe<Scalars['String']>;
  assetUrl?: InputMaybe<Scalars['String']>;
};

export type AchievementLevelUpdateInputDTO = {
  achievementId?: InputMaybe<Scalars['String']>;
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  fromProgress?: InputMaybe<Scalars['Float']>;
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  toProgress?: InputMaybe<Scalars['Float']>;
};

export type AchievementMilestoneDTO = {
  __typename?: 'AchievementMilestone';
  achievement: AchievementDTO;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  progress: Scalars['Float'];
  rewards: Array<AchievementMilestoneRewardDTO>;
  status?: Maybe<AchievementMilestonePupilStatusDTO>;
  title: Scalars['String'];
};

export type AchievementMilestoneCreateInputDTO = {
  achievementId: Scalars['String'];
  descriptions: Array<MultiLanguageTextInputDTO>;
  progress: Scalars['Float'];
  titles: Array<MultiLanguageTextInputDTO>;
};

export type AchievementMilestoneFilterDTO = {
  AND?: InputMaybe<Array<AchievementMilestoneFilterDTO>>;
  OR?: InputMaybe<Array<AchievementMilestoneFilterDTO>>;
  achievement?: InputMaybe<AchievementFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  progress?: InputMaybe<FloatFilterDTO>;
};

export type AchievementMilestoneGiftRewardDTO = {
  __typename?: 'AchievementMilestoneGiftReward';
  achievementMilestoneReward: AchievementMilestoneRewardDTO;
  coins: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  gameAttribute?: Maybe<GameAttributeDTO>;
  gameAttributeDecreaseAmount?: Maybe<Scalars['Int']>;
  gameAttributeIncreaseAmount?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
};

export type AchievementMilestoneGiftRewardCreateInputDTO = {
  achievementMilestoneRewardId: Scalars['String'];
  coins?: InputMaybe<Scalars['Int']>;
  gameAttributeDecreaseAmount?: InputMaybe<Scalars['Int']>;
  gameAttributeId?: InputMaybe<Scalars['String']>;
  gameAttributeIncreaseAmount?: InputMaybe<Scalars['Int']>;
};

export type AchievementMilestoneGiftRewardFilterDTO = {
  AND?: InputMaybe<Array<AchievementMilestoneGiftRewardFilterDTO>>;
  OR?: InputMaybe<Array<AchievementMilestoneGiftRewardFilterDTO>>;
  achievementMilestone?: InputMaybe<AchievementMilestoneFilterDTO>;
  coins?: InputMaybe<IntFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  gameAttribute?: InputMaybe<GameAttributeFilterDTO>;
  gameAttributeDecreaseAmount?: InputMaybe<IntFilterDTO>;
  gameAttributeIncreaseAmount?: InputMaybe<IntFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
};

export type AchievementMilestoneGiftRewardUpdateInputDTO = {
  achievementMilestoneRewardId?: InputMaybe<Scalars['String']>;
  coins?: InputMaybe<Scalars['Int']>;
  gameAttributeDecreaseAmount?: InputMaybe<Scalars['Int']>;
  gameAttributeId?: InputMaybe<Scalars['String']>;
  gameAttributeIncreaseAmount?: InputMaybe<Scalars['Int']>;
};

export type AchievementMilestonePupilStatusDTO = {
  __typename?: 'AchievementMilestonePupilStatus';
  achievementMilestone: AchievementMilestoneDTO;
  active: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AchievementMilestonePupilStatusFilterDTO = {
  AND?: InputMaybe<Array<AchievementMilestonePupilStatusFilterDTO>>;
  OR?: InputMaybe<Array<AchievementMilestonePupilStatusFilterDTO>>;
  achievementMilestone?: InputMaybe<AchievementMilestoneFilterDTO>;
  active?: InputMaybe<BooleanFilterDTO>;
  completedAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
};

export type AchievementMilestoneRewardDTO = {
  __typename?: 'AchievementMilestoneReward';
  achievementMilestone: AchievementMilestoneDTO;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  type: Scalars['String'];
};

export type AchievementMilestoneRewardCreateInputDTO = {
  achievementMilestoneId: Scalars['String'];
  type: Scalars['String'];
};

export type AchievementMilestoneRewardFilterDTO = {
  AND?: InputMaybe<Array<AchievementMilestoneRewardFilterDTO>>;
  OR?: InputMaybe<Array<AchievementMilestoneRewardFilterDTO>>;
  achievementMilestone?: InputMaybe<AchievementMilestoneFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export type AchievementMilestoneRewardUpdateInputDTO = {
  achievementMilestoneId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AchievementMilestoneUnlockableRewardDTO = {
  __typename?: 'AchievementMilestoneUnlockableReward';
  achievementMilestoneReward: AchievementMilestoneRewardDTO;
  avatarElement: AvatarElementDTO;
  contentItem: ContentItemDTO;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
};

export type AchievementMilestoneUnlockableRewardCreateInputDTO = {
  achievementMilestoneRewardId: Scalars['String'];
  avatarElementId?: InputMaybe<Scalars['String']>;
  contentItemId?: InputMaybe<Scalars['String']>;
};

export type AchievementMilestoneUnlockableRewardFilterDTO = {
  AND?: InputMaybe<Array<AchievementMilestoneUnlockableRewardFilterDTO>>;
  OR?: InputMaybe<Array<AchievementMilestoneUnlockableRewardFilterDTO>>;
  achievementMilestoneReward?: InputMaybe<AchievementMilestoneRewardFilterDTO>;
  avatarElement?: InputMaybe<AvatarElementFilterDTO>;
  contentItem?: InputMaybe<ContentItemFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
};

export type AchievementMilestoneUnlockableRewardUpdateInputDTO = {
  achievementMilestoneRewardId?: InputMaybe<Scalars['String']>;
  avatarElementId?: InputMaybe<Scalars['String']>;
  contentItemId?: InputMaybe<Scalars['String']>;
};

export type AchievementMilestoneUpdateInputDTO = {
  achievementId?: InputMaybe<Scalars['String']>;
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  progress?: InputMaybe<Scalars['Float']>;
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
};

export type AchievementProgressDTO = {
  __typename?: 'AchievementProgress';
  achievement: AchievementDTO;
  id: Scalars['String'];
  progress: Scalars['Int'];
  pupil: PupilDTO;
};

export type AchievementProgressFilterDTO = {
  AND?: InputMaybe<Array<AchievementProgressFilterDTO>>;
  OR?: InputMaybe<Array<AchievementProgressFilterDTO>>;
  achievement?: InputMaybe<AchievementFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  progress?: InputMaybe<IntFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
};

export type AchievementUnlockedDTO = {
  __typename?: 'AchievementUnlocked';
  achievement: AchievementDTO;
  id: Scalars['String'];
  pupil: PupilDTO;
  unlockedOn: Scalars['DateTime'];
};

export type AchievementUnlockedFilterDTO = {
  AND?: InputMaybe<Array<AchievementUnlockedFilterDTO>>;
  OR?: InputMaybe<Array<AchievementUnlockedFilterDTO>>;
  achievement?: InputMaybe<AchievementFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  unlockedOn?: InputMaybe<DateTimeFilterDTO>;
};

export type AchievementUpdateInputDTO = {
  badgeImage?: InputMaybe<Scalars['String']>;
  coinsToEarn?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  goal?: InputMaybe<Scalars['Int']>;
  recurring?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type AirQualityDTO = {
  __typename?: 'AirQuality';
  airQuality: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  location: LocationDTO;
};

export type AirQualityFilterDTO = {
  AND?: InputMaybe<Array<AirQualityFilterDTO>>;
  OR?: InputMaybe<Array<AirQualityFilterDTO>>;
  airQuality?: InputMaybe<IntFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  location?: InputMaybe<BasicStringFilterDTO>;
};

export type AnonymousTokenInputDTO = {
  projectCode?: InputMaybe<Scalars['String']>;
  schoolCode?: InputMaybe<Scalars['String']>;
};

export type AssetDTO = {
  __typename?: 'Asset';
  fileName: Scalars['String'];
  fileSizeBytes: Scalars['Int'];
  id: Scalars['String'];
  url: Scalars['String'];
};

export type AssetUploadInputDTO = {
  contentBase64: Scalars['String'];
  contentType?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
};

export type AssignedShopItemDTO = {
  __typename?: 'AssignedShopItem';
  availableStock: Scalars['Int'];
  canOrder: Scalars['Boolean'];
  canOrderDetails: AssignedShopItemCanOrderDetailsDTO;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image: AssetDTO;
  instructions?: Maybe<Scalars['String']>;
  orderRestrictions: ShopItemOrderRestrictionsDTO;
  price: Scalars['Int'];
  publishOn?: Maybe<Scalars['DateTime']>;
  publishUntil?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  type: ShopItemTypeDTO;
};

export type AssignedShopItemCanOrderDetailsDTO = {
  __typename?: 'AssignedShopItemCanOrderDetails';
  isSoldOut: Scalars['Boolean'];
  sufficientCoins: Scalars['Boolean'];
  validOrderRestrictions: Scalars['Boolean'];
};

export type AssignedShopItemFilterDTO = {
  AND?: InputMaybe<Array<ShopItemFilterDTO>>;
  OR?: InputMaybe<Array<ShopItemFilterDTO>>;
  class?: InputMaybe<ClassFilterDTO>;
  description?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  instructions?: InputMaybe<StringFilterDTO>;
  price?: InputMaybe<IntFilterDTO>;
  project?: InputMaybe<ProjectFilterDTO>;
  publishOn?: InputMaybe<DateTimeFilterDTO>;
  publishUntil?: InputMaybe<DateTimeFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type AvatarDTO = {
  __typename?: 'Avatar';
  eyebrows: Scalars['String'];
  face: Scalars['String'];
  hair: Scalars['String'];
  hairColor: Scalars['String'];
  head: Scalars['String'];
  id: Scalars['String'];
  nose: Scalars['String'];
  skinColor: Scalars['String'];
  sweater: Scalars['String'];
};

export type AvatarCreateInputDTO = {
  eyebrows: Scalars['String'];
  face: Scalars['String'];
  hair: Scalars['String'];
  hairColor: Scalars['String'];
  head: Scalars['String'];
  nose: Scalars['String'];
  skinColor: Scalars['String'];
  sweater: Scalars['String'];
};

export type AvatarElementDTO = {
  __typename?: 'AvatarElement';
  assetUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  possibleColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  type: AvatarElementTypeDTO;
  visibilityType: VisibilityTypeDTO;
};

export type AvatarElementFilterDTO = {
  AND?: InputMaybe<Array<AvatarElementFilterDTO>>;
  OR?: InputMaybe<Array<AvatarElementFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
  visibilityType?: InputMaybe<StringFilterDTO>;
};

export enum AvatarElementTypeDTO {
  AccessoryDTO = 'ACCESSORY',
  BackgroundDTO = 'BACKGROUND',
  EyebrowsDTO = 'EYEBROWS',
  EyesDTO = 'EYES',
  FaceDTO = 'FACE',
  HairDTO = 'HAIR',
  HeadDTO = 'HEAD',
  MouthDTO = 'MOUTH',
  NoseDTO = 'NOSE',
  SweaterDTO = 'SWEATER'
}

export type BasicStringArrayFilterDTO = {
  containsAll?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  containsSome?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
};

export type BasicStringFilterDTO = {
  equals?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
};

export type BooleanFilterDTO = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
};

export type CampaignDTO = {
  __typename?: 'Campaign';
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  checkSummary?: Maybe<Array<Maybe<CampaignCheckSummaryDTO>>>;
  checks?: Maybe<Array<Maybe<CampaignCheckDTO>>>;
  classes?: Maybe<Array<ClassDTO>>;
  coinEarnModel: CampaignCoinEarnModelDTO;
  coinsToEarn: Scalars['Int'];
  id: Scalars['String'];
  title: Scalars['String'];
};


export type CampaignCheckSummaryArgsDTO = {
  filter?: InputMaybe<CampaignCheckFilterDTO>;
  groupBy: CampaignCheckSummaryGroupByDTO;
};


export type CampaignChecksArgsDTO = {
  filter?: InputMaybe<CampaignCheckFilterDTO>;
};

export type CampaignCheckDTO = {
  __typename?: 'CampaignCheck';
  date: Scalars['DateTime'];
  pupil: PupilDTO;
};

export type CampaignCheckCreateManyInputDTO = {
  campaign: Scalars['String'];
  date: Scalars['DateTime'];
  pupils: Array<Scalars['String']>;
};

export type CampaignCheckFilterDTO = {
  date?: InputMaybe<DateTimeFilterDTO>;
  pupil?: InputMaybe<PupilReferenceFilterDTO>;
};

export type CampaignCheckSummaryDTO = {
  __typename?: 'CampaignCheckSummary';
  class?: Maybe<ClassDTO>;
  count: Scalars['Int'];
  project?: Maybe<ProjectDTO>;
  pupil?: Maybe<PupilDTO>;
  school?: Maybe<SchoolDTO>;
  schoolyear?: Maybe<SchoolyearDTO>;
};

export enum CampaignCheckSummaryGroupByDTO {
  ClassDTO = 'CLASS',
  ProjectDTO = 'PROJECT',
  PupilDTO = 'PUPIL',
  SchoolDTO = 'SCHOOL',
  SchoolyearDTO = 'SCHOOLYEAR'
}

export enum CampaignCoinEarnModelDTO {
  EveryDayDTO = 'EVERY_DAY',
  OnceDTO = 'ONCE'
}

export type CampaignCreateInputDTO = {
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  classes: Array<Scalars['String']>;
  coinEarnModel: CampaignCoinEarnModelDTO;
  coinsToEarn: Scalars['Int'];
  title: Scalars['String'];
};

export type CampaignFilterDTO = {
  AND?: InputMaybe<Array<CampaignFilterDTO>>;
  OR?: InputMaybe<Array<CampaignFilterDTO>>;
  activeFrom?: InputMaybe<DateTimeFilterDTO>;
  activeTo?: InputMaybe<DateTimeFilterDTO>;
  class?: InputMaybe<BasicStringArrayFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  project?: InputMaybe<BasicStringArrayFilterDTO>;
  school?: InputMaybe<BasicStringArrayFilterDTO>;
  schoolyear?: InputMaybe<BasicStringArrayFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type CampaignUpdateInputDTO = {
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  activeTo?: InputMaybe<Scalars['DateTime']>;
  classes?: InputMaybe<Array<Scalars['String']>>;
  coinEarnModel?: InputMaybe<CampaignCoinEarnModelDTO>;
  coinsToEarn?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ChallengeDTO = {
  __typename?: 'Challenge';
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  classes: Array<ClassDTO>;
  description: Scalars['String'];
  goal: Scalars['Int'];
  id: Scalars['String'];
  progress: Array<ChallengeProgressDTO>;
  school: SchoolDTO;
  scoringType: Scalars['String'];
  title: Scalars['String'];
};

export type ChallengeCreateInputDTO = {
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  classes?: InputMaybe<Array<Scalars['String']>>;
  description: Scalars['String'];
  goal: Scalars['Int'];
  school: Scalars['String'];
  scoringType: Scalars['String'];
  title: Scalars['String'];
};

export type ChallengeFilterDTO = {
  AND?: InputMaybe<Array<ChallengeFilterDTO>>;
  OR?: InputMaybe<Array<ChallengeFilterDTO>>;
  activeFrom?: InputMaybe<DateTimeFilterDTO>;
  activeTo?: InputMaybe<DateTimeFilterDTO>;
  class?: InputMaybe<BasicStringArrayFilterDTO>;
  description?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type ChallengeProgressDTO = {
  __typename?: 'ChallengeProgress';
  classId?: Maybe<Scalars['String']>;
  progress: Scalars['Int'];
};

export type ChallengeSearchItemDTO = SearchItemDTO & {
  __typename?: 'ChallengeSearchItem';
  challenge: ChallengeDTO;
  type: SearchTypeDTO;
};

export type ChallengeUpdateInputDTO = {
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  activeTo?: InputMaybe<Scalars['DateTime']>;
  classes?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  goal?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<Scalars['String']>;
  scoringType?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ClassDTO = {
  __typename?: 'Class';
  classTotal: ClassTotalDTO;
  id: Scalars['String'];
  moduleRegistrationStatistics: ModuleRegistrationStatisticsDTO;
  moduleRegistrationsCount: Scalars['Int'];
  pupils?: Maybe<Array<PupilDTO>>;
  pupilsCount: Scalars['Int'];
  school: SchoolDTO;
  title: Scalars['String'];
};


export type ClassModuleRegistrationStatisticsArgsDTO = {
  filter?: InputMaybe<ModuleRegistrationReferenceFilterDTO>;
};


export type ClassModuleRegistrationsCountArgsDTO = {
  filter?: InputMaybe<ModuleRegistrationReferenceFilterDTO>;
};

export type ClassCreateInputDTO = {
  school: Scalars['String'];
  title: Scalars['String'];
};

export type ClassExerciseDTO = {
  __typename?: 'ClassExercise';
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  class: ClassDTO;
  exercise: ExerciseDTO;
  id: Scalars['String'];
  link?: Maybe<ClassExerciseLinkDTO>;
};

export type ClassExerciseCreateInputDTO = {
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  class: Scalars['String'];
  exercise: Scalars['String'];
  link?: InputMaybe<ClassExerciseLinkCreateInputDTO>;
};

export type ClassExerciseCreateManyExerciseInputDTO = {
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  id: Scalars['String'];
};

export type ClassExerciseCreateManyInputDTO = {
  classIds: Array<Scalars['String']>;
  exercises: Array<ClassExerciseCreateManyExerciseInputDTO>;
};

export type ClassExerciseFilterDTO = {
  AND?: InputMaybe<Array<ClassExerciseFilterDTO>>;
  OR?: InputMaybe<Array<ClassExerciseFilterDTO>>;
  activeFrom?: InputMaybe<DateTimeFilterDTO>;
  activeTo?: InputMaybe<DateTimeFilterDTO>;
  class?: InputMaybe<BasicStringFilterDTO>;
  exercise?: InputMaybe<ExerciseFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  link?: InputMaybe<ClassExerciseLinkFilterDTO>;
};

export type ClassExerciseForExerciseCreateInputDTO = {
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  class: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<ClassExerciseLinkCreateInputDTO>;
};

export type ClassExerciseLinkDTO = {
  __typename?: 'ClassExerciseLink';
  classLibrary: ClassLibraryDTO;
  id: Scalars['String'];
  linkedBy?: Maybe<Scalars['String']>;
  linkedByName?: Maybe<Scalars['String']>;
};

export type ClassExerciseLinkCreateInputDTO = {
  classLibrary: Scalars['String'];
  linkedBy?: InputMaybe<Scalars['String']>;
  linkedByName?: InputMaybe<Scalars['String']>;
};

export type ClassExerciseLinkFilterDTO = {
  AND?: InputMaybe<Array<ClassExerciseLinkFilterDTO>>;
  OR?: InputMaybe<Array<ClassExerciseLinkFilterDTO>>;
  class?: InputMaybe<ClassReferenceFilterDTO>;
  classLibrary?: InputMaybe<BasicStringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
};

export type ClassExerciseLinkReferenceFilterDTO = {
  AND?: InputMaybe<Array<ClassExerciseLinkReferenceFilterDTO>>;
  OR?: InputMaybe<Array<ClassExerciseLinkReferenceFilterDTO>>;
  class?: InputMaybe<ClassReferenceFilterDTO>;
  classLibrary?: InputMaybe<BasicStringFilterDTO>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<StringFilterDTO>;
};

export type ClassExerciseUpdateInputDTO = {
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  activeTo?: InputMaybe<Scalars['DateTime']>;
  class?: InputMaybe<Scalars['String']>;
  exercise?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<ClassExerciseLinkCreateInputDTO>;
};

export type ClassExerciseUpdateManyExerciseInputDTO = {
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  id: Scalars['String'];
};

export type ClassFilterDTO = {
  AND?: InputMaybe<Array<ClassFilterDTO>>;
  OR?: InputMaybe<Array<ClassFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type ClassLibraryDTO = {
  __typename?: 'ClassLibrary';
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  class: ClassDTO;
  exercise: ExerciseDTO;
  id: Scalars['String'];
  link?: Maybe<ClassExerciseLinkDTO>;
};

export type ClassLibraryCreateInputDTO = {
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  class: Scalars['String'];
  exercise: Scalars['String'];
  link: Scalars['String'];
};

export type ClassLibraryCreateManyExerciseInputDTO = {
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  id: Scalars['String'];
};

export type ClassLibraryCreateManyInputDTO = {
  classIds: Array<Scalars['String']>;
  exercises: Array<ClassLibraryCreateManyExerciseInputDTO>;
};

export type ClassLibraryFilterDTO = {
  AND?: InputMaybe<Array<ClassLibraryFilterDTO>>;
  OR?: InputMaybe<Array<ClassLibraryFilterDTO>>;
  activeFrom?: InputMaybe<DateTimeFilterDTO>;
  activeTo?: InputMaybe<DateTimeFilterDTO>;
  class?: InputMaybe<ClassFilterDTO>;
  exercise?: InputMaybe<ExerciseFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  link?: InputMaybe<ClassExerciseLinkFilterDTO>;
};

export type ClassLibraryUpdateInputDTO = {
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  activeTo?: InputMaybe<Scalars['DateTime']>;
  class?: InputMaybe<Scalars['String']>;
  exercise?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
};

export type ClassMessageDTO = {
  __typename?: 'ClassMessage';
  id: Scalars['String'];
  message: Scalars['String'];
  showFrom: Scalars['DateTime'];
  showTo: Scalars['DateTime'];
  title: Scalars['String'];
};

export type ClassReferenceFilterDTO = {
  AND?: InputMaybe<Array<ClassReferenceFilterDTO>>;
  OR?: InputMaybe<Array<ClassReferenceFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  school?: InputMaybe<SchoolReferenceFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type ClassSearchItemDTO = SearchItemDTO & {
  __typename?: 'ClassSearchItem';
  class: ClassDTO;
  type: SearchTypeDTO;
};

export type ClassTotalDTO = {
  __typename?: 'ClassTotal';
  class: ClassDTO;
  highestTotalCreditsOnOneDay: Scalars['Int'];
  id: Scalars['String'];
  totalCredits: Scalars['Int'];
  totalDistance: Scalars['Float'];
  totalRegistrations: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ClassTotalFilterDTO = {
  AND?: InputMaybe<Array<ClassTotalFilterDTO>>;
  OR?: InputMaybe<Array<ClassTotalFilterDTO>>;
  class?: InputMaybe<ClassFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  totalCredits?: InputMaybe<IntFilterDTO>;
  totalDistance?: InputMaybe<IntFilterDTO>;
  totalRegistrations?: InputMaybe<IntFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilterDTO>;
};

export type ClassUpdateInputDTO = {
  title?: InputMaybe<Scalars['String']>;
};

export type ContentBlockDTO = {
  __typename?: 'ContentBlock';
  content: Scalars['String'];
  id: Scalars['String'];
  menuTitle?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  schools: Array<SchoolDTO>;
  schoolyear: SchoolyearDTO;
  showAsMenuItem: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  visibility: ContentBlockVisibilityDTO;
};

export type ContentBlockCreateInputDTO = {
  content: Scalars['String'];
  menuTitle?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  schools?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  schoolyear: Scalars['String'];
  showAsMenuItem: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  visibility: ContentBlockVisibilityDTO;
};

export type ContentBlockFilterDTO = {
  AND?: InputMaybe<Array<ContentBlockFilterDTO>>;
  OR?: InputMaybe<Array<ContentBlockFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  published?: InputMaybe<BooleanFilterDTO>;
  schoolyear?: InputMaybe<BasicStringFilterDTO>;
  showAsMenuItem?: InputMaybe<BooleanFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
  url?: InputMaybe<StringFilterDTO>;
  visibility?: InputMaybe<ContentBlockVisibilityFilterDTO>;
};

export type ContentBlockUpdateInputDTO = {
  content?: InputMaybe<Scalars['String']>;
  menuTitle?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  schools?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showAsMenuItem?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<ContentBlockVisibilityDTO>;
};

export enum ContentBlockVisibilityDTO {
  PrivateDTO = 'PRIVATE',
  PublicDTO = 'PUBLIC'
}

export type ContentBlockVisibilityFilterDTO = {
  equals?: InputMaybe<ContentBlockVisibilityDTO>;
  in?: InputMaybe<Array<InputMaybe<ContentBlockVisibilityDTO>>>;
  not?: InputMaybe<ContentBlockVisibilityDTO>;
  notIn?: InputMaybe<Array<InputMaybe<ContentBlockVisibilityDTO>>>;
};

export type ContentItemDTO = {
  __typename?: 'ContentItem';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  tags: Array<ContentTagDTO>;
  thumbnail: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  visibilityType: Scalars['String'];
};

export type ContentItemCreateInputDTO = {
  descriptions: Array<MultiLanguageTextInputDTO>;
  tagIds: Array<Scalars['String']>;
  thumbnail: Scalars['String'];
  titles: Array<MultiLanguageTextInputDTO>;
  url: Scalars['String'];
  visibilityType: Scalars['String'];
};

export type ContentItemFilterDTO = {
  AND?: InputMaybe<Array<ContentItemFilterDTO>>;
  OR?: InputMaybe<Array<ContentItemFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  tag?: InputMaybe<ContentTagFilterDTO>;
  visibilityType?: InputMaybe<StringFilterDTO>;
};

export type ContentItemUpdateInputDTO = {
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  thumbnail?: InputMaybe<Scalars['String']>;
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  url?: InputMaybe<Scalars['String']>;
  visibilityType?: InputMaybe<Scalars['String']>;
};

export type ContentTagDTO = {
  __typename?: 'ContentTag';
  banner: Scalars['String'];
  createdAt: Scalars['DateTime'];
  icon: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
  type: ContentTypeDTO;
};

export type ContentTagCreateInputDTO = {
  banner: Scalars['String'];
  icon: Scalars['String'];
  titles: Array<MultiLanguageTextInputDTO>;
  typeId: Scalars['String'];
};

export type ContentTagFilterDTO = {
  AND?: InputMaybe<Array<ContentTagFilterDTO>>;
  OR?: InputMaybe<Array<ContentTagFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<ContentTypeFilterDTO>;
};

export type ContentTagUpdateInputDTO = {
  banner?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  typeId?: InputMaybe<Scalars['String']>;
};

export type ContentTypeDTO = {
  __typename?: 'ContentType';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  type: ContentTypeTypeDTO;
};

export type ContentTypeCreateInputDTO = {
  type: ContentTypeTypeDTO;
};

export type ContentTypeFilterDTO = {
  AND?: InputMaybe<Array<ContentTypeFilterDTO>>;
  OR?: InputMaybe<Array<ContentTypeFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export enum ContentTypeTypeDTO {
  CinemaDTO = 'CINEMA'
}

export type ContentTypeUpdateInputDTO = {
  type?: InputMaybe<ContentTypeTypeDTO>;
};

export type DateTimeFilterDTO = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type DecreasePupilCoinInputDTO = {
  amount: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
};

export type DecreasePupilEnergyInputDTO = {
  amount: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
};

export type DeviceDTO = {
  __typename?: 'Device';
  id: Scalars['String'];
  type: DeviceTypeDTO;
};

export type DeviceBatteryLogDTO = {
  __typename?: 'DeviceBatteryLog';
  batteryVoltage: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  device: DeviceDTO;
  id: Scalars['String'];
  rawModuleMessage: RawModuleMessageDTO;
};

export type DeviceBatteryLogFilterDTO = {
  AND?: InputMaybe<Array<DeviceBatteryLogFilterDTO>>;
  OR?: InputMaybe<Array<DeviceBatteryLogFilterDTO>>;
  batteryVoltage?: InputMaybe<FloatFilterDTO>;
  device?: InputMaybe<DeviceFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  rawModuleMessage?: InputMaybe<RawModuleMessageFilterDTO>;
};

export type DeviceCreateInputDTO = {
  id: Scalars['String'];
  type: DeviceTypeDTO;
};

export type DeviceFilterDTO = {
  AND?: InputMaybe<Array<DeviceFilterDTO>>;
  OR?: InputMaybe<Array<DeviceFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<DeviceTypeFilterDTO>;
};

export type DeviceTotalDTO = {
  __typename?: 'DeviceTotal';
  createdAt?: Maybe<Scalars['DateTime']>;
  device: DeviceDTO;
  id: Scalars['String'];
  totalMessages: Scalars['Int'];
  totalScans: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeviceTotalFilterDTO = {
  AND?: InputMaybe<Array<DeviceTotalFilterDTO>>;
  OR?: InputMaybe<Array<DeviceTotalFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  device?: InputMaybe<DeviceFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  totalMessages?: InputMaybe<IntFilterDTO>;
  totalScans?: InputMaybe<IntFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilterDTO>;
};

export enum DeviceTypeDTO {
  HttpDTO = 'http',
  IotcDTO = 'iotc',
  UdpDTO = 'udp'
}

export type DeviceTypeFilterDTO = {
  equals?: InputMaybe<DeviceTypeDTO>;
  in?: InputMaybe<Array<InputMaybe<DeviceTypeDTO>>>;
  not?: InputMaybe<DeviceTypeDTO>;
  notIn?: InputMaybe<Array<InputMaybe<DeviceTypeDTO>>>;
};

export type ExerciseDTO = {
  __typename?: 'Exercise';
  classExercises: Array<ClassExerciseDTO>;
  classExercisesCount: Scalars['Int'];
  course: ExerciseCourseDTO;
  createdAt: Scalars['DateTime'];
  creditsToEarn: Scalars['Int'];
  grade?: Maybe<ExerciseGradeDTO>;
  id: Scalars['String'];
  image: AssetDTO;
  library?: Maybe<Scalars['Boolean']>;
  owner: Scalars['String'];
  ownerName: Scalars['String'];
  questions: Array<ExerciseQuestionDTO>;
  results: Array<PupilExerciseResultDTO>;
  tags: Array<ExerciseTagDTO>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ExerciseAnswerDTO = {
  __typename?: 'ExerciseAnswer';
  answer: Scalars['String'];
  correct: Scalars['Boolean'];
  id: Scalars['String'];
  image?: Maybe<AssetDTO>;
  question: ExerciseQuestionDTO;
};

export type ExerciseAnswerCreateInputDTO = {
  answer: Scalars['String'];
  correct: Scalars['Boolean'];
  image?: InputMaybe<Scalars['String']>;
  question: Scalars['String'];
};

export type ExerciseAnswerForQuestionCreateInputDTO = {
  answer: Scalars['String'];
  correct: Scalars['Boolean'];
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
};

export type ExerciseAnswerUpdateInputDTO = {
  answer?: InputMaybe<Scalars['String']>;
  correct?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['String']>;
};

export type ExerciseCourseDTO = {
  __typename?: 'ExerciseCourse';
  classExercisesCount: Scalars['Int'];
  exercises?: Maybe<Array<Maybe<ExerciseDTO>>>;
  exercisesCount: Scalars['Int'];
  id: Scalars['String'];
  image: AssetDTO;
  title: Scalars['String'];
};

export type ExerciseCourseCreateInputDTO = {
  image: Scalars['String'];
  title: Scalars['String'];
};

export type ExerciseCourseFilterDTO = {
  id?: InputMaybe<StringFilterDTO>;
};

export type ExerciseCourseUpdateInputDTO = {
  image?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExerciseCreateInputDTO = {
  classExercises?: InputMaybe<Array<ClassExerciseForExerciseCreateInputDTO>>;
  course: Scalars['String'];
  creditsToEarn: Scalars['Int'];
  grade?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  library?: InputMaybe<Scalars['Boolean']>;
  questions?: InputMaybe<Array<ExerciseQuestionForExerciseCreateInputDTO>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: Scalars['String'];
};

export type ExerciseFilterDTO = {
  AND?: InputMaybe<Array<ExerciseFilterDTO>>;
  OR?: InputMaybe<Array<ExerciseFilterDTO>>;
  course?: InputMaybe<BasicStringFilterDTO>;
  grade?: InputMaybe<BasicStringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  library?: InputMaybe<BooleanFilterDTO>;
  owner?: InputMaybe<StringFilterDTO>;
  tags?: InputMaybe<BasicStringFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type ExerciseGradeDTO = {
  __typename?: 'ExerciseGrade';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type ExerciseGradeCreateInputDTO = {
  title: Scalars['String'];
};

export type ExerciseGradeFilterDTO = {
  id?: InputMaybe<StringFilterDTO>;
};

export type ExerciseGradeUpdateInputDTO = {
  title?: InputMaybe<Scalars['String']>;
};

export type ExerciseQuestionDTO = {
  __typename?: 'ExerciseQuestion';
  answers: Array<ExerciseAnswerDTO>;
  id: Scalars['String'];
  image?: Maybe<AssetDTO>;
  order: Scalars['Int'];
  question: Scalars['String'];
  type: ExerciseQuestionTypeDTO;
};

export type ExerciseQuestionCreateInputDTO = {
  answers?: InputMaybe<Array<ExerciseAnswerForQuestionCreateInputDTO>>;
  exercise: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  order: Scalars['Int'];
  question: Scalars['String'];
  type: ExerciseQuestionTypeDTO;
};

export type ExerciseQuestionFilterDTO = {
  id?: InputMaybe<StringFilterDTO>;
};

export type ExerciseQuestionForExerciseCreateInputDTO = {
  answers?: InputMaybe<Array<ExerciseAnswerForQuestionCreateInputDTO>>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  order: Scalars['Int'];
  question: Scalars['String'];
  type: ExerciseQuestionTypeDTO;
};

export enum ExerciseQuestionTypeDTO {
  ImageDTO = 'IMAGE',
  TextDTO = 'TEXT'
}

export type ExerciseQuestionUpdateInputDTO = {
  answers?: InputMaybe<Array<ExerciseAnswerForQuestionCreateInputDTO>>;
  image?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ExerciseQuestionTypeDTO>;
};

export type ExerciseReferenceFilterDTO = {
  AND?: InputMaybe<Array<ExerciseReferenceFilterDTO>>;
  OR?: InputMaybe<Array<ExerciseReferenceFilterDTO>>;
  course?: InputMaybe<BasicStringFilterDTO>;
  grade?: InputMaybe<BasicStringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  library?: InputMaybe<BooleanFilterDTO>;
  owner?: InputMaybe<StringFilterDTO>;
  tags?: InputMaybe<BasicStringFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type ExerciseSearchItemDTO = SearchItemDTO & {
  __typename?: 'ExerciseSearchItem';
  exercise: ExerciseDTO;
  type: SearchTypeDTO;
};

export type ExerciseTagDTO = {
  __typename?: 'ExerciseTag';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type ExerciseTagCreateInputDTO = {
  title: Scalars['String'];
};

export type ExerciseTagFilterDTO = {
  id?: InputMaybe<StringFilterDTO>;
};

export type ExerciseTagUpdateInputDTO = {
  title?: InputMaybe<Scalars['String']>;
};

export type ExerciseUpdateInputDTO = {
  classExercises?: InputMaybe<Array<ClassExerciseForExerciseCreateInputDTO>>;
  course?: InputMaybe<Scalars['String']>;
  creditsToEarn?: InputMaybe<Scalars['Int']>;
  grade?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  library?: InputMaybe<Scalars['Boolean']>;
  questions?: InputMaybe<Array<ExerciseQuestionForExerciseCreateInputDTO>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export enum FailedPupilLoginReasonDTO {
  InvalidNfcDomainDTO = 'INVALID_NFC_DOMAIN',
  InvalidNfcValidationCodeDTO = 'INVALID_NFC_VALIDATION_CODE',
  NoNfcRefererDTO = 'NO_NFC_REFERER',
  PasswordNotFoundDTO = 'PASSWORD_NOT_FOUND',
  PupilNotFoundDTO = 'PUPIL_NOT_FOUND',
  WrongPasswordDTO = 'WRONG_PASSWORD'
}

export enum FailedUserLoginReasonDTO {
  AlreadyLoggedInDTO = 'ALREADY_LOGGED_IN',
  NoResourceAccessDTO = 'NO_RESOURCE_ACCESS',
  NoUserRolesDTO = 'NO_USER_ROLES',
  PasswordNotFoundDTO = 'PASSWORD_NOT_FOUND',
  UserLockedDTO = 'USER_LOCKED',
  UserNotFoundDTO = 'USER_NOT_FOUND',
  WrongPasswordDTO = 'WRONG_PASSWORD'
}

export type FloatFilterDTO = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type ForgotPupilDataRequestDTO = {
  __typename?: 'ForgotPupilDataRequest';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  referer: Scalars['String'];
  user?: Maybe<UserDTO>;
  userAgent: Scalars['String'];
};

export type ForgotPupilDataRequestFilterDTO = {
  AND?: InputMaybe<Array<ForgotPupilDataRequestFilterDTO>>;
  OR?: InputMaybe<Array<ForgotPupilDataRequestFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  referer?: InputMaybe<StringFilterDTO>;
  user?: InputMaybe<UserFilterDTO>;
  userAgent?: InputMaybe<StringFilterDTO>;
};

export type GameDTO = {
  __typename?: 'Game';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type GameAttributeDTO = {
  __typename?: 'GameAttribute';
  createdAt: Scalars['DateTime'];
  game: GameDTO;
  id: Scalars['String'];
  level?: Maybe<GameAttributeLevelDTO>;
  status?: Maybe<GameAttributeStatusDTO>;
  title?: Maybe<Scalars['String']>;
};

export type GameAttributeCreateInputDTO = {
  gameId: Scalars['String'];
  titles: Array<MultiLanguageTextInputDTO>;
};

export type GameAttributeFilterDTO = {
  AND?: InputMaybe<Array<GameAttributeFilterDTO>>;
  OR?: InputMaybe<Array<GameAttributeFilterDTO>>;
  gameId?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
};

export type GameAttributeLevelDTO = {
  __typename?: 'GameAttributeLevel';
  createdAt: Scalars['DateTime'];
  gameAttribute: GameAttributeDTO;
  id: Scalars['String'];
  level: Scalars['Int'];
};

export type GameAttributeLevelFilterDTO = {
  AND?: InputMaybe<Array<GameAttributeLevelFilterDTO>>;
  OR?: InputMaybe<Array<GameAttributeLevelFilterDTO>>;
  gameAttributeId?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
};

export type GameAttributeLevelInputDTO = {
  gameAttributeId: Scalars['String'];
  level: Scalars['Int'];
};

export type GameAttributePupilLevelDTO = {
  __typename?: 'GameAttributePupilLevel';
  gameAttribute: GameAttributeDTO;
  id: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  pupil: PupilDTO;
  updatedAt: Scalars['DateTime'];
};

export type GameAttributePupilLevelFilterDTO = {
  AND?: InputMaybe<Array<GameAttributePupilLevelFilterDTO>>;
  OR?: InputMaybe<Array<GameAttributePupilLevelFilterDTO>>;
  gameAttribute?: InputMaybe<GameAttributeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  level?: InputMaybe<IntFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilterDTO>;
};

export type GameAttributePupilLevelLogDTO = {
  __typename?: 'GameAttributePupilLevelLog';
  createdAt: Scalars['DateTime'];
  gameAttribute: GameAttributeDTO;
  id: Scalars['String'];
  level: Scalars['Int'];
  metadata?: Maybe<Scalars['JSON']>;
  pupil: PupilDTO;
  valid: Scalars['Boolean'];
};

export type GameAttributePupilLevelLogFilterDTO = {
  AND?: InputMaybe<Array<GameAttributePupilLevelLogFilterDTO>>;
  OR?: InputMaybe<Array<GameAttributePupilLevelLogFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  gameAttribute?: InputMaybe<GameAttributeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  level?: InputMaybe<IntFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  valid?: InputMaybe<BooleanFilterDTO>;
};

export type GameAttributePupilStatusDTO = {
  __typename?: 'GameAttributePupilStatus';
  active: Scalars['Boolean'];
  gameAttribute: GameAttributeDTO;
  id: Scalars['String'];
  pupil: PupilDTO;
  updatedAt: Scalars['DateTime'];
};

export type GameAttributePupilStatusFilterDTO = {
  AND?: InputMaybe<Array<GameAttributePupilStatusFilterDTO>>;
  OR?: InputMaybe<Array<GameAttributePupilStatusFilterDTO>>;
  active?: InputMaybe<BooleanFilterDTO>;
  gameAttribute?: InputMaybe<GameAttributeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilterDTO>;
};

export type GameAttributePupilStatusLogDTO = {
  __typename?: 'GameAttributePupilStatusLog';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  gameAttribute: GameAttributeDTO;
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  pupil: PupilDTO;
  valid: Scalars['Boolean'];
};

export type GameAttributePupilStatusLogFilterDTO = {
  AND?: InputMaybe<Array<GameAttributePupilStatusLogFilterDTO>>;
  OR?: InputMaybe<Array<GameAttributePupilStatusLogFilterDTO>>;
  active?: InputMaybe<BooleanFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  gameAttribute?: InputMaybe<GameAttributeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  valid?: InputMaybe<BooleanFilterDTO>;
};

export type GameAttributeStatusDTO = {
  __typename?: 'GameAttributeStatus';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  gameAttribute: GameAttributeDTO;
  id: Scalars['String'];
};

export type GameAttributeStatusFilterDTO = {
  AND?: InputMaybe<Array<GameAttributeStatusFilterDTO>>;
  OR?: InputMaybe<Array<GameAttributeStatusFilterDTO>>;
  gameAttributeId?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
};

export type GameAttributeStatusInputDTO = {
  active: Scalars['Boolean'];
  gameAttributeId: Scalars['String'];
};

export type GameAttributeUpdateInputDTO = {
  gameId?: InputMaybe<Scalars['String']>;
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
};

export type GameCreateInputDTO = {
  titles: Array<MultiLanguageTextInputDTO>;
};

export type GameFilterDTO = {
  AND?: InputMaybe<Array<GameFilterDTO>>;
  OR?: InputMaybe<Array<GameFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type GameHighScoreDTO = {
  __typename?: 'GameHighScore';
  createdAt: Scalars['DateTime'];
  game: GameDTO;
  id: Scalars['String'];
  score: Scalars['Int'];
};

export type GameHighScoreFilterDTO = {
  AND?: InputMaybe<Array<GameHighScoreFilterDTO>>;
  OR?: InputMaybe<Array<GameHighScoreFilterDTO>>;
  gameId?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
};

export type GameLeaderboardEntryDTO = {
  __typename?: 'GameLeaderboardEntry';
  createdAt: Scalars['DateTime'];
  game: GameDTO;
  id: Scalars['String'];
  position: Scalars['Int'];
  pupil: PupilDTO;
  score: Scalars['Int'];
};

export type GamePupilCoinLogDTO = {
  __typename?: 'GamePupilCoinLog';
  coins: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  pupil: PupilDTO;
  type: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type GamePupilCoinLogFilterDTO = {
  AND?: InputMaybe<Array<GamePupilCoinLogFilterDTO>>;
  OR?: InputMaybe<Array<GamePupilCoinLogFilterDTO>>;
  coins?: InputMaybe<IntFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
  valid?: InputMaybe<BooleanFilterDTO>;
};

export type GamePupilEnergyLogDTO = {
  __typename?: 'GamePupilEnergyLog';
  createdAt: Scalars['DateTime'];
  energy: Scalars['Int'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  pupil: PupilDTO;
  type: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type GamePupilEnergyLogFilterDTO = {
  AND?: InputMaybe<Array<GamePupilEnergyLogFilterDTO>>;
  OR?: InputMaybe<Array<GamePupilEnergyLogFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  energy?: InputMaybe<IntFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
  valid?: InputMaybe<BooleanFilterDTO>;
};

export type GamePupilHighScoreDTO = {
  __typename?: 'GamePupilHighScore';
  createdAt: Scalars['DateTime'];
  game: GameDTO;
  id: Scalars['String'];
  pupil: PupilDTO;
  score: Scalars['Int'];
};

export type GamePupilHighScoreFilterDTO = {
  AND?: InputMaybe<Array<GamePupilHighScoreFilterDTO>>;
  OR?: InputMaybe<Array<GamePupilHighScoreFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  game?: InputMaybe<GameFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  score?: InputMaybe<IntFilterDTO>;
};

export type GamePupilScoreLogDTO = {
  __typename?: 'GamePupilScoreLog';
  createdAt: Scalars['DateTime'];
  game: GameDTO;
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  score: Scalars['Int'];
  valid: Scalars['Boolean'];
};

export type GamePupilScoreLogFilterDTO = {
  AND?: InputMaybe<Array<GamePupilScoreLogFilterDTO>>;
  OR?: InputMaybe<Array<GamePupilScoreLogFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  gameId?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
  score?: InputMaybe<IntFilterDTO>;
  valid?: InputMaybe<BooleanFilterDTO>;
};

export type GamePupilTotalDTO = {
  __typename?: 'GamePupilTotal';
  coins: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  energy: Scalars['Int'];
  id: Scalars['String'];
  pupil: PupilDTO;
  updatedAt: Scalars['DateTime'];
};

export type GamePupilTotalFilterDTO = {
  AND?: InputMaybe<Array<GamePupilTotalFilterDTO>>;
  OR?: InputMaybe<Array<GamePupilTotalFilterDTO>>;
  coins?: InputMaybe<IntFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  energy?: InputMaybe<IntFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilterDTO>;
};

export type GameScoreClassLeaderboardFilterDTO = {
  AND?: InputMaybe<Array<GameScoreClassLeaderboardFilterDTO>>;
  OR?: InputMaybe<Array<GameScoreClassLeaderboardFilterDTO>>;
  class?: InputMaybe<ClassFilterDTO>;
  game?: InputMaybe<GameFilterDTO>;
  gameId?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
};

export type GameScoreSchoolLeaderboardFilterDTO = {
  AND?: InputMaybe<Array<GameScoreSchoolLeaderboardFilterDTO>>;
  OR?: InputMaybe<Array<GameScoreSchoolLeaderboardFilterDTO>>;
  game?: InputMaybe<GameFilterDTO>;
  gameId?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
};

export type GameUpdateInputDTO = {
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
};

export type GlobalRegimeInactivityInputDTO = {
  from: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  to: Scalars['DateTime'];
};

export type HazardousLocationDTO = {
  __typename?: 'HazardousLocation';
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  declinedAt?: Maybe<Scalars['DateTime']>;
  declinedBy?: Maybe<Scalars['String']>;
  declinedReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location: LocationDTO;
  reason: HazardousLocationReasonDTO;
  reportedBy: Scalars['String'];
  status?: Maybe<HazardousLocationStatusDTO>;
};

export type HazardousLocationCreateInputDTO = {
  location: LocationCreateInputDTO;
  reason: Scalars['String'];
  reportedBy: Scalars['String'];
  schoolyear: Scalars['String'];
};

export type HazardousLocationFilterDTO = {
  AND?: InputMaybe<Array<HazardousLocationFilterDTO>>;
  OR?: InputMaybe<Array<HazardousLocationFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  schoolyear?: InputMaybe<BasicStringFilterDTO>;
  status?: InputMaybe<HazardousLocationStatusFilterDTO>;
};

export type HazardousLocationReasonDTO = {
  __typename?: 'HazardousLocationReason';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type HazardousLocationReasonCreateInputDTO = {
  project: Scalars['String'];
  title: Scalars['String'];
};

export type HazardousLocationReasonFilterDTO = {
  AND?: InputMaybe<Array<HazardousLocationReasonFilterDTO>>;
  OR?: InputMaybe<Array<HazardousLocationReasonFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type HazardousLocationReasonUpdateInputDTO = {
  title?: InputMaybe<Scalars['String']>;
};

export enum HazardousLocationStatusDTO {
  ApprovedDTO = 'APPROVED',
  DeclinedDTO = 'DECLINED',
  ReportedDTO = 'REPORTED'
}

export type HazardousLocationStatusFilterDTO = {
  equals?: InputMaybe<HazardousLocationStatusDTO>;
  in?: InputMaybe<Array<InputMaybe<HazardousLocationStatusDTO>>>;
  not?: InputMaybe<HazardousLocationStatusDTO>;
  notIn?: InputMaybe<Array<InputMaybe<HazardousLocationStatusDTO>>>;
};

export type HazardousLocationUpdateInputDTO = {
  declinedReason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<HazardousLocationStatusDTO>;
};

export type IncreasePupilCoinInputDTO = {
  amount: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
};

export type IntFilterDTO = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum LanguageDTO {
  NlDTO = 'nl'
}

export type LastReportedDeviceInfoDTO = {
  __typename?: 'LastReportedDeviceInfo';
  batchId?: Maybe<Scalars['String']>;
  batteryVoltage?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  device: DeviceDTO;
  firmwareVersion?: Maybe<Scalars['String']>;
  humidity?: Maybe<Scalars['Float']>;
  iccId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastKeepAlive?: Maybe<Scalars['DateTime']>;
  lastTag?: Maybe<Scalars['DateTime']>;
  temperature?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type LastReportedDeviceInfoFilterDTO = {
  AND?: InputMaybe<Array<LastReportedDeviceInfoFilterDTO>>;
  OR?: InputMaybe<Array<LastReportedDeviceInfoFilterDTO>>;
  batchId?: InputMaybe<StringFilterDTO>;
  batteryVoltage?: InputMaybe<FloatFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  device?: InputMaybe<DeviceFilterDTO>;
  firmwareVersion?: InputMaybe<StringFilterDTO>;
  humidity?: InputMaybe<FloatFilterDTO>;
  iccId?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  lastKeepAlive?: InputMaybe<DateTimeFilterDTO>;
  lastTag?: InputMaybe<DateTimeFilterDTO>;
  temperature?: InputMaybe<FloatFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilterDTO>;
};

export type LocationDTO = {
  __typename?: 'Location';
  airQualities: Array<AirQualityDTO>;
  airQualitiesCount: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  postalCode?: Maybe<Scalars['String']>;
  schoolyear?: Maybe<SchoolyearDTO>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
};


export type LocationAirQualitiesArgsDTO = {
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};

export type LocationCreateInputDTO = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  friendlyName?: InputMaybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  postalCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type LocationFilterDTO = {
  AND?: InputMaybe<Array<LocationFilterDTO>>;
  OR?: InputMaybe<Array<LocationFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  schoolyear?: InputMaybe<BasicStringFilterDTO>;
};

export type LoginResultDTO = {
  __typename?: 'LoginResult';
  token: Scalars['String'];
};

export type MailTemplateDTO = {
  __typename?: 'MailTemplate';
  bcc?: Maybe<Array<Scalars['String']>>;
  body?: Maybe<Scalars['String']>;
  cc?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  from?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  type: MailTemplateTypeDTO;
};

export type MailTemplateCreateInputDTO = {
  bodies?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  froms?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  subjects?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  type: MailTemplateTypeDTO;
};

export type MailTemplateFilterDTO = {
  AND?: InputMaybe<Array<MailTemplateFilterDTO>>;
  OR?: InputMaybe<Array<MailTemplateFilterDTO>>;
  body?: InputMaybe<StringFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  from?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  subject?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export enum MailTemplateTypeDTO {
  ForgotPupilDataDTO = 'FORGOT_PUPIL_DATA',
  ResetPasswordDTO = 'RESET_PASSWORD',
  ResetPupilPasswordDTO = 'RESET_PUPIL_PASSWORD',
  RewardRequestParentDTO = 'REWARD_REQUEST_PARENT',
  RewardRequestSchoolDTO = 'REWARD_REQUEST_SCHOOL',
  ShopitemorderCanceledDTO = 'SHOPITEMORDER_CANCELED',
  ShopitemorderConfirmedDTO = 'SHOPITEMORDER_CONFIRMED',
  ShopitemvoucherGeneratedDTO = 'SHOPITEMVOUCHER_GENERATED',
  WelcomePupilParentDTO = 'WELCOME_PUPIL_PARENT'
}

export type MailTemplateUpdateInputDTO = {
  bodies?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  froms?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  subjects?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  type?: InputMaybe<MailTemplateTypeDTO>;
};

export type ModuleDTO = {
  __typename?: 'Module';
  deviceId: Scalars['String'];
  id: Scalars['String'];
  ignoreOnRoute: Scalars['Boolean'];
  location: LocationDTO;
  moduleRegistrations?: Maybe<Array<Maybe<ModuleRegistrationDTO>>>;
  moduleRegistrationsCount: Scalars['Int'];
  moduleTypes?: Maybe<Array<Maybe<ModuleTypeDTO>>>;
  reference?: Maybe<Scalars['String']>;
  regime: RegimeDTO;
  softDeleted: Scalars['Boolean'];
  title: Scalars['String'];
};


export type ModuleModuleRegistrationsArgsDTO = {
  filter?: InputMaybe<ModuleRegistrationReferenceFilterDTO>;
};


export type ModuleModuleRegistrationsCountArgsDTO = {
  filter?: InputMaybe<ModuleRegistrationReferenceFilterDTO>;
};

export type ModuleCreateInputDTO = {
  deviceId: Scalars['String'];
  ignoreOnRoute: Scalars['Boolean'];
  location: LocationCreateInputDTO;
  moduleTypes: Array<ModuleTypeCreateInputDTO>;
  reference?: InputMaybe<Scalars['String']>;
  regime: Scalars['String'];
  schoolyear: Scalars['String'];
  title: Scalars['String'];
};

export type ModuleFilterDTO = {
  AND?: InputMaybe<Array<ModuleFilterDTO>>;
  OR?: InputMaybe<Array<ModuleFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  location?: InputMaybe<BasicStringFilterDTO>;
  schoolyear?: InputMaybe<BasicStringFilterDTO>;
  softDeleted?: InputMaybe<BooleanFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringArrayFilterDTO>;
};

export type ModuleLocationLogDTO = {
  __typename?: 'ModuleLocationLog';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  location: LocationDTO;
  module: ModuleDTO;
  setByUser?: Maybe<UserDTO>;
};

export type ModuleLocationLogFilterDTO = {
  AND?: InputMaybe<Array<ModuleLocationLogFilterDTO>>;
  OR?: InputMaybe<Array<ModuleLocationLogFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  location?: InputMaybe<LocationFilterDTO>;
  module?: InputMaybe<ModuleFilterDTO>;
};

export type ModuleReferenceFilterDTO = {
  AND?: InputMaybe<Array<ModuleReferenceFilterDTO>>;
  OR?: InputMaybe<Array<ModuleReferenceFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  location?: InputMaybe<BasicStringFilterDTO>;
  schoolyear?: InputMaybe<BasicStringFilterDTO>;
  softDeleted?: InputMaybe<BooleanFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type ModuleReferenceLogDTO = {
  __typename?: 'ModuleReferenceLog';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  module: ModuleDTO;
  reference: Scalars['String'];
  setByUser?: Maybe<UserDTO>;
};

export type ModuleReferenceLogFilterDTO = {
  AND?: InputMaybe<Array<ModuleReferenceLogFilterDTO>>;
  OR?: InputMaybe<Array<ModuleReferenceLogFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  module?: InputMaybe<ModuleFilterDTO>;
  reference?: InputMaybe<StringFilterDTO>;
};

export type ModuleRegistrationDTO = {
  __typename?: 'ModuleRegistration';
  coinsEarned: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  distance: Scalars['Float'];
  id: Scalars['String'];
  identifier: Scalars['String'];
  module: ModuleDTO;
  pupil: PupilDTO;
  replaced: Scalars['Boolean'];
  transaction: PupilCreditDTO;
  type: Scalars['String'];
};

export type ModuleRegistrationFilterDTO = {
  AND?: InputMaybe<Array<ModuleRegistrationFilterDTO>>;
  OR?: InputMaybe<Array<ModuleRegistrationFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  module?: InputMaybe<ModuleFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  replaced?: InputMaybe<BooleanFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export type ModuleRegistrationReferenceFilterDTO = {
  AND?: InputMaybe<Array<ModuleRegistrationReferenceFilterDTO>>;
  OR?: InputMaybe<Array<ModuleRegistrationReferenceFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  module?: InputMaybe<ModuleReferenceFilterDTO>;
  pupil?: InputMaybe<PupilReferenceFilterDTO>;
  replaced?: InputMaybe<BooleanFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export type ModuleRegistrationSimulateInputDTO = {
  createdAt: Scalars['DateTime'];
  module: Scalars['String'];
  pupil: Scalars['String'];
  type: Scalars['String'];
};

export type ModuleRegistrationStatisticsDTO = {
  __typename?: 'ModuleRegistrationStatistics';
  totalDistance: Scalars['Float'];
};

export enum ModuleRegistrationTimeSeriesGroupByDTO {
  ClassDTO = 'CLASS',
  ModuleDTO = 'MODULE',
  PupilDTO = 'PUPIL',
  SchoolDTO = 'SCHOOL'
}

export type ModuleTotalDTO = {
  __typename?: 'ModuleTotal';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  module: ModuleDTO;
  registrations: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  validRegistrations: Scalars['Int'];
};

export type ModuleTotalFilterDTO = {
  AND?: InputMaybe<Array<ModuleTotalFilterDTO>>;
  OR?: InputMaybe<Array<ModuleTotalFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  module?: InputMaybe<ModuleFilterDTO>;
  registrations?: InputMaybe<IntFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilterDTO>;
  validRegistrations?: InputMaybe<IntFilterDTO>;
};

export type ModuleTypeDTO = {
  __typename?: 'ModuleType';
  coinsToEarn: Scalars['Int'];
  type: Scalars['String'];
};

export type ModuleTypeCreateInputDTO = {
  coinsToEarn: Scalars['Int'];
  type: Scalars['String'];
};

export type ModuleUpdateInputDTO = {
  deviceId?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationCreateInputDTO>;
  moduleTypes?: InputMaybe<Array<ModuleTypeCreateInputDTO>>;
  reference?: InputMaybe<Scalars['String']>;
  regime?: InputMaybe<Scalars['String']>;
};

export type MultiLanguageTextDTO = {
  __typename?: 'MultiLanguageText';
  language: LanguageDTO;
  text: Scalars['String'];
};

export type MultiLanguageTextFilterDTO = {
  language: LanguageDTO;
  text: StringFilterDTO;
};

export type MultiLanguageTextInputDTO = {
  language: LanguageDTO;
  text: Scalars['String'];
};

export type MultiplierDTO = {
  __typename?: 'Multiplier';
  endsAt: Scalars['DateTime'];
  id: Scalars['String'];
  multiplier: Scalars['Int'];
  school: SchoolDTO;
  startsAt: Scalars['DateTime'];
};

export type MultiplierCreateInputDTO = {
  endsAt: Scalars['DateTime'];
  multiplier: Scalars['Int'];
  school: Scalars['String'];
  startsAt: Scalars['DateTime'];
};

export type MultiplierFilterDTO = {
  AND?: InputMaybe<Array<MultiplierFilterDTO>>;
  OR?: InputMaybe<Array<MultiplierFilterDTO>>;
  endsAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  multiplier?: InputMaybe<IntFilterDTO>;
  school?: InputMaybe<BasicStringFilterDTO>;
  startsAt?: InputMaybe<DateTimeFilterDTO>;
};

export type MultiplierUpdateInputDTO = {
  endsAt?: InputMaybe<Scalars['DateTime']>;
  multiplier?: InputMaybe<Scalars['Int']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
};

export type MutationDTO = {
  __typename?: 'Mutation';
  cancelOneShopItemOrder: ShopItemOrderDTO;
  completeExercise: SubmitResultDTO;
  completeOneShopItemOrder: ShopItemOrderDTO;
  createManyCampaignChecks: SuccessDTO;
  createManyClassExercises: SuccessDTO;
  createManyClassLibraries: SuccessDTO;
  createOneAchievement: AchievementDTO;
  createOneAchievementLevel: AchievementLevelDTO;
  createOneAchievementLevelBackground: AchievementLevelBackgroundDTO;
  createOneAchievementLevelStepStone: AchievementLevelStepStoneDTO;
  createOneAchievementMilestone: AchievementMilestoneDTO;
  createOneAchievementMilestoneGiftReward: AchievementMilestoneGiftRewardDTO;
  createOneAchievementMilestoneReward: AchievementMilestoneRewardDTO;
  createOneAchievementMilestoneUnlockableReward: AchievementMilestoneUnlockableRewardDTO;
  createOneAvatarElement: AvatarElementDTO;
  createOneCampaign: CampaignDTO;
  createOneChallenge: ChallengeDTO;
  createOneClass: ClassDTO;
  createOneClassExercise: ClassExerciseDTO;
  createOneClassLibrary: ClassLibraryDTO;
  createOneContentBlock: ContentBlockDTO;
  createOneContentItem: ContentItemDTO;
  createOneContentTag: ContentTagDTO;
  createOneContentType: ContentTypeDTO;
  createOneDevice: DeviceDTO;
  createOneExercise: ExerciseDTO;
  createOneExerciseAnswer: ExerciseAnswerDTO;
  createOneExerciseCourse: ExerciseCourseDTO;
  createOneExerciseGrade: ExerciseGradeDTO;
  createOneExerciseQuestion: ExerciseQuestionDTO;
  createOneExerciseTag: ExerciseTagDTO;
  createOneGame: GameDTO;
  createOneGameAttribute: GameAttributeDTO;
  createOneHazardousLocation: HazardousLocationDTO;
  createOneHazardousLocationReason: HazardousLocationReasonDTO;
  createOneMailTemplate: MailTemplateDTO;
  createOneModule: ModuleDTO;
  createOneMultiplier: MultiplierDTO;
  createOneNotificationTypeChannelConfiguration: NotificationTypeChannelConfigurationDTO;
  createOnePolicy: PolicyDTO;
  createOneProject: ProjectDTO;
  createOnePupil: PupilDTO;
  createOnePupilCredit: PupilCreditDTO;
  createOnePupilParent: PupilParentDTO;
  createOneQuiz: QuizDTO;
  createOneQuizAnswer: QuizAnswerDTO;
  createOneQuizAnswerReward: QuizAnswerRewardDTO;
  createOneQuizPupilAnswer?: Maybe<QuizPupilAnswerDTO>;
  createOneQuizQuestion: QuizQuestionDTO;
  createOneQuizReward: QuizRewardDTO;
  createOneRegime: RegimeDTO;
  createOneReward: RewardDTO;
  createOneRewardQuantity: RewardQuantityDTO;
  createOneRewardRequest: RewardRequestDTO;
  createOneSchool: SchoolDTO;
  createOneSchoolTrophy: SchoolTrophyDTO;
  createOneSchoolyear: SchoolyearDTO;
  createOneShopItem: ShopItemDTO;
  createOneShopItemAssignment: ShopItemAssignmentDTO;
  createOneShopItemGlobalResponsible: ShopItemGlobalResponsibleDTO;
  createOneShopItemOrder: ShopItemOrderDTO;
  createOneShopItemSpecificResponsible: ShopItemSpecificResponsibleDTO;
  createOneShopItemVoucher: ShopItemVoucherDTO;
  createOneShopItemVoucherCode: ShopItemVoucherCodeDTO;
  createOneShopItemVoucherCodeTicket: ShopItemVoucherCodeTicketDTO;
  createOneUser: UserDTO;
  createOneUserCommunicationPreference: UserCommunicationPreferenceDTO;
  createOneUserResourceAccess: UserResourceAccessDTO;
  createOneUserRole: UserRoleDTO;
  createOneZone: ZoneDTO;
  decreasePupilCoin: SuccessDTO;
  decreasePupilEnergy: GamePupilTotalDTO;
  deleteClassExerciseLinks: SuccessDTO;
  deleteClassExercises: SuccessDTO;
  deleteClassLibraries: SuccessDTO;
  deleteOneAchievement: SuccessDTO;
  deleteOneAchievementLevel: SuccessDTO;
  deleteOneAchievementLevelBackground: SuccessDTO;
  deleteOneAchievementLevelStepStone: SuccessDTO;
  deleteOneAchievementMilestone: SuccessDTO;
  deleteOneAchievementMilestoneGiftReward: SuccessDTO;
  deleteOneAchievementMilestoneReward: SuccessDTO;
  deleteOneAchievementMilestoneUnlockableReward: SuccessDTO;
  deleteOneAvatarElement: SuccessDTO;
  deleteOneCampaign: SuccessDTO;
  deleteOneChallenge: SuccessDTO;
  deleteOneClass: SuccessDTO;
  deleteOneClassExercise: SuccessDTO;
  deleteOneClassLibrary: SuccessDTO;
  deleteOneContentBlock: SuccessDTO;
  deleteOneContentItem: SuccessDTO;
  deleteOneContentTag: SuccessDTO;
  deleteOneContentType: SuccessDTO;
  deleteOneDevice: SuccessDTO;
  deleteOneExercise: SuccessDTO;
  deleteOneExerciseAnswer: SuccessDTO;
  deleteOneExerciseCourse: SuccessDTO;
  deleteOneExerciseGrade: SuccessDTO;
  deleteOneExerciseQuestion: SuccessDTO;
  deleteOneExerciseTag: SuccessDTO;
  deleteOneGame: SuccessDTO;
  deleteOneGameAttribute: SuccessDTO;
  deleteOneHazardousLocation: SuccessDTO;
  deleteOneHazardousLocationReason: SuccessDTO;
  deleteOneMailTemplate: SuccessDTO;
  deleteOneMultiplier: SuccessDTO;
  deleteOneNotificationTypeChannelConfiguration: SuccessDTO;
  deleteOneProject: SuccessDTO;
  deleteOnePupil: SuccessDTO;
  deleteOnePupilCredit: SuccessDTO;
  deleteOnePupilParent: SuccessDTO;
  deleteOneQuiz: SuccessDTO;
  deleteOneQuizAnswer: SuccessDTO;
  deleteOneQuizAnswerReward: SuccessDTO;
  deleteOneQuizQuestion: SuccessDTO;
  deleteOneQuizReward: SuccessDTO;
  deleteOneRegime: SuccessDTO;
  deleteOneRegimeInactivity: SuccessDTO;
  deleteOneReward: SuccessDTO;
  deleteOneRewardQuantity: SuccessDTO;
  deleteOneSchool: SuccessDTO;
  deleteOneSchoolTrophy: SuccessDTO;
  deleteOneSchoolyear: SuccessDTO;
  deleteOneShopItemGlobalResponsible: SuccessDTO;
  deleteOneShopItemSpecificResponsible: SuccessDTO;
  deleteOneShopItemVoucher: SuccessDTO;
  deleteOneShopItemVoucherCode: SuccessDTO;
  deleteOneShopItemVoucherCodeTicket: SuccessDTO;
  deleteOneUser: SuccessDTO;
  deleteOneUserCommunicationPreference: SuccessDTO;
  deleteOneUserResourceAccess: SuccessDTO;
  deleteOneUserRole: SuccessDTO;
  deleteOneZone: SuccessDTO;
  forgotPupilData: SuccessDTO;
  generateOneUsername: UsernameDTO;
  getOneAnonymousToken: TokenResultDTO;
  increasePupilCoin: SuccessDTO;
  logGamePupilScore: SuccessDTO;
  login: LoginResultDTO;
  markOneRewardRequestAsReceived: RewardRequestDTO;
  nfcPasswordPupilLogin: LoginResultDTO;
  nfcPupilLogin: LoginResultDTO;
  pupilLogin: LoginResultDTO;
  pupilNfcPasswordReset: SuccessDTO;
  pupilPasswordReset: SuccessDTO;
  resetPassword: SuccessDTO;
  resetPasswordForPupil: PupilPasswordResetDTO;
  resetPasswordForUser: PasswordResetDTO;
  saveZoneForPupil: PupilDTO;
  setGameAttributeLevel: GameAttributeDTO;
  setGameAttributeStatus: GameAttributeDTO;
  setOnePupilAvatarElement: PupilAvatarElementDTO;
  simulateOneModuleRegistration: SuccessDTO;
  startOneQuiz: SuccessDTO;
  updateOneAchievement: AchievementDTO;
  updateOneAchievementLevel: AchievementLevelDTO;
  updateOneAchievementLevelBackground: AchievementLevelBackgroundDTO;
  updateOneAchievementLevelStepStone: AchievementLevelStepStoneDTO;
  updateOneAchievementMilestone: AchievementMilestoneDTO;
  updateOneAchievementMilestoneGiftReward: AchievementMilestoneGiftRewardDTO;
  updateOneAchievementMilestoneReward: AchievementMilestoneRewardDTO;
  updateOneAchievementMilestoneUnlockableReward: AchievementMilestoneUnlockableRewardDTO;
  updateOneAvatarElement: AvatarElementDTO;
  updateOneCampaign: CampaignDTO;
  updateOneChallenge: ChallengeDTO;
  updateOneClass: ClassDTO;
  updateOneClassExercise: ClassExerciseDTO;
  updateOneClassLibrary: ClassLibraryDTO;
  updateOneContentBlock: ContentBlockDTO;
  updateOneContentItem: ContentItemDTO;
  updateOneContentTag: ContentTagDTO;
  updateOneContentType: ContentTypeDTO;
  updateOneExercise: ExerciseDTO;
  updateOneExerciseAnswer: ExerciseAnswerDTO;
  updateOneExerciseCourse: ExerciseCourseDTO;
  updateOneExerciseGrade: ExerciseGradeDTO;
  updateOneExerciseQuestion: ExerciseQuestionDTO;
  updateOneExerciseTag: ExerciseTagDTO;
  updateOneGame: GameDTO;
  updateOneGameAttribute: GameAttributeDTO;
  updateOneHazardousLocation: HazardousLocationDTO;
  updateOneHazardousLocationReason: HazardousLocationReasonDTO;
  updateOneMailTemplate: MailTemplateDTO;
  updateOneModule: ModuleDTO;
  updateOneMultiplier: MultiplierDTO;
  updateOneNotificationTypeChannelConfiguration: NotificationTypeChannelConfigurationDTO;
  updateOnePolicy: PolicyDTO;
  updateOneProject: ProjectDTO;
  updateOnePupil: PupilDTO;
  updateOnePupilCredit: PupilCreditDTO;
  updateOnePupilNotificationLog: PupilNotificationLogDTO;
  updateOnePupilParent: PupilParentDTO;
  updateOneQuiz: QuizDTO;
  updateOneQuizAnswer: QuizAnswerDTO;
  updateOneQuizAnswerReward: QuizAnswerRewardDTO;
  updateOneQuizQuestion: QuizQuestionDTO;
  updateOneQuizReward: QuizRewardDTO;
  updateOneRegime: RegimeDTO;
  updateOneReward: RewardDTO;
  updateOneRewardQuantity: RewardQuantityDTO;
  updateOneSchool: SchoolDTO;
  updateOneSchoolTrophy: SchoolTrophyDTO;
  updateOneSchoolyear: SchoolyearDTO;
  updateOneShopItem: ShopItemDTO;
  updateOneShopItemAssignment: ShopItemAssignmentDTO;
  updateOneShopItemGlobalResponsible: ShopItemGlobalResponsibleDTO;
  updateOneShopItemSpecificResponsible: ShopItemSpecificResponsibleDTO;
  updateOneShopItemVoucher: ShopItemVoucherDTO;
  updateOneShopItemVoucherCode: ShopItemVoucherCodeDTO;
  updateOneShopItemVoucherCodeTicket: ShopItemVoucherCodeTicketDTO;
  updateOneUser: UserDTO;
  updateOneUserCommunicationPreference: UserCommunicationPreferenceDTO;
  updateOneUserResourceAccess: UserResourceAccessDTO;
  updateOneUserRole: UserRoleDTO;
  updateOneZone: ZoneDTO;
  updatePassword: SuccessDTO;
  updatePasswordAnonymous: SuccessDTO;
  updatePupilPassword: SuccessDTO;
  updatePupilPasswordAnonymous: SuccessDTO;
  uploadAsset: AssetDTO;
  usernamePupilLogin: LoginResultDTO;
  validatePupilClassNumber: SuccessDTO;
  validatePupilTag: SuccessDTO;
};


export type MutationCancelOneShopItemOrderArgsDTO = {
  dontUpdateAvailableStock?: InputMaybe<Scalars['Boolean']>;
  orderId: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationCompleteExerciseArgsDTO = {
  answers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['String'];
};


export type MutationCompleteOneShopItemOrderArgsDTO = {
  orderId: Scalars['String'];
};


export type MutationCreateManyCampaignChecksArgsDTO = {
  data: CampaignCheckCreateManyInputDTO;
};


export type MutationCreateManyClassExercisesArgsDTO = {
  data: ClassExerciseCreateManyInputDTO;
};


export type MutationCreateManyClassLibrariesArgsDTO = {
  data: ClassLibraryCreateManyInputDTO;
};


export type MutationCreateOneAchievementArgsDTO = {
  data: AchievementCreateInputDTO;
};


export type MutationCreateOneAchievementLevelArgsDTO = {
  data: AchievementLevelCreateInputDTO;
};


export type MutationCreateOneAchievementLevelBackgroundArgsDTO = {
  data: AchievementLevelBackgroundCreateInputDTO;
};


export type MutationCreateOneAchievementLevelStepStoneArgsDTO = {
  data: AchievementLevelStepStoneCreateInputDTO;
};


export type MutationCreateOneAchievementMilestoneArgsDTO = {
  data: AchievementMilestoneCreateInputDTO;
};


export type MutationCreateOneAchievementMilestoneGiftRewardArgsDTO = {
  data: AchievementMilestoneGiftRewardCreateInputDTO;
};


export type MutationCreateOneAchievementMilestoneRewardArgsDTO = {
  data: AchievementMilestoneRewardCreateInputDTO;
};


export type MutationCreateOneAchievementMilestoneUnlockableRewardArgsDTO = {
  data: AchievementMilestoneUnlockableRewardCreateInputDTO;
};


export type MutationCreateOneAvatarElementArgsDTO = {
  data: AchievementElementCreateInputDTO;
};


export type MutationCreateOneCampaignArgsDTO = {
  data: CampaignCreateInputDTO;
};


export type MutationCreateOneChallengeArgsDTO = {
  data: ChallengeCreateInputDTO;
};


export type MutationCreateOneClassArgsDTO = {
  data: ClassCreateInputDTO;
};


export type MutationCreateOneClassExerciseArgsDTO = {
  data: ClassExerciseCreateInputDTO;
};


export type MutationCreateOneClassLibraryArgsDTO = {
  data: ClassLibraryCreateInputDTO;
};


export type MutationCreateOneContentBlockArgsDTO = {
  data: ContentBlockCreateInputDTO;
};


export type MutationCreateOneContentItemArgsDTO = {
  data: ContentItemCreateInputDTO;
};


export type MutationCreateOneContentTagArgsDTO = {
  data: ContentTagCreateInputDTO;
};


export type MutationCreateOneContentTypeArgsDTO = {
  data: ContentTypeCreateInputDTO;
};


export type MutationCreateOneDeviceArgsDTO = {
  data: DeviceCreateInputDTO;
};


export type MutationCreateOneExerciseArgsDTO = {
  data: ExerciseCreateInputDTO;
};


export type MutationCreateOneExerciseAnswerArgsDTO = {
  data: ExerciseAnswerCreateInputDTO;
};


export type MutationCreateOneExerciseCourseArgsDTO = {
  data: ExerciseCourseCreateInputDTO;
};


export type MutationCreateOneExerciseGradeArgsDTO = {
  data: ExerciseGradeCreateInputDTO;
};


export type MutationCreateOneExerciseQuestionArgsDTO = {
  data: ExerciseQuestionCreateInputDTO;
};


export type MutationCreateOneExerciseTagArgsDTO = {
  data: ExerciseTagCreateInputDTO;
};


export type MutationCreateOneGameArgsDTO = {
  data: GameCreateInputDTO;
};


export type MutationCreateOneGameAttributeArgsDTO = {
  data: GameAttributeCreateInputDTO;
};


export type MutationCreateOneHazardousLocationArgsDTO = {
  data: HazardousLocationCreateInputDTO;
};


export type MutationCreateOneHazardousLocationReasonArgsDTO = {
  data: HazardousLocationReasonCreateInputDTO;
};


export type MutationCreateOneMailTemplateArgsDTO = {
  data: MailTemplateCreateInputDTO;
};


export type MutationCreateOneModuleArgsDTO = {
  data: ModuleCreateInputDTO;
};


export type MutationCreateOneMultiplierArgsDTO = {
  data: MultiplierCreateInputDTO;
};


export type MutationCreateOneNotificationTypeChannelConfigurationArgsDTO = {
  data: NotificationTypeChannelConfigurationCreateInputDTO;
};


export type MutationCreateOnePolicyArgsDTO = {
  data: PolicyCreateInputDTO;
};


export type MutationCreateOneProjectArgsDTO = {
  data: ProjectCreateInputDTO;
};


export type MutationCreateOnePupilArgsDTO = {
  data: PupilCreateInputDTO;
};


export type MutationCreateOnePupilCreditArgsDTO = {
  data: PupilCreditCreateInputDTO;
};


export type MutationCreateOnePupilParentArgsDTO = {
  data: PupilParentCreateInputDTO;
};


export type MutationCreateOneQuizArgsDTO = {
  data: QuizCreateInputDTO;
};


export type MutationCreateOneQuizAnswerArgsDTO = {
  data: QuizAnswerCreateInputDTO;
};


export type MutationCreateOneQuizAnswerRewardArgsDTO = {
  data: QuizAnswerRewardCreateInputDTO;
};


export type MutationCreateOneQuizPupilAnswerArgsDTO = {
  quizAnswerId: Scalars['String'];
  quizQuestionId: Scalars['String'];
};


export type MutationCreateOneQuizQuestionArgsDTO = {
  data: QuizQuestionCreateInputDTO;
};


export type MutationCreateOneQuizRewardArgsDTO = {
  data: QuizRewardCreateInputDTO;
};


export type MutationCreateOneRegimeArgsDTO = {
  data: RegimeCreateInputDTO;
};


export type MutationCreateOneRewardArgsDTO = {
  data: RewardCreateInputDTO;
};


export type MutationCreateOneRewardQuantityArgsDTO = {
  data: RewardQuantityCreateInputDTO;
};


export type MutationCreateOneRewardRequestArgsDTO = {
  reward: Scalars['String'];
};


export type MutationCreateOneSchoolArgsDTO = {
  data: SchoolCreateInputDTO;
};


export type MutationCreateOneSchoolTrophyArgsDTO = {
  data: SchoolTrophyCreateInputDTO;
};


export type MutationCreateOneSchoolyearArgsDTO = {
  data: SchoolyearCreateInputDTO;
};


export type MutationCreateOneShopItemArgsDTO = {
  input: ShopItemCreateInputDTO;
};


export type MutationCreateOneShopItemAssignmentArgsDTO = {
  input: ShopItemAssignmentCreateInputDTO;
};


export type MutationCreateOneShopItemGlobalResponsibleArgsDTO = {
  data: ShopItemGlobalResponsibleCreateInputDTO;
};


export type MutationCreateOneShopItemOrderArgsDTO = {
  assignedShopItemId: Scalars['String'];
};


export type MutationCreateOneShopItemSpecificResponsibleArgsDTO = {
  data: ShopItemSpecificResponsibleCreateInputDTO;
};


export type MutationCreateOneShopItemVoucherArgsDTO = {
  data: ShopItemVoucherCreateInputDTO;
};


export type MutationCreateOneShopItemVoucherCodeArgsDTO = {
  data: ShopItemVoucherCodeCreateInputDTO;
};


export type MutationCreateOneShopItemVoucherCodeTicketArgsDTO = {
  data: ShopItemVoucherCodeTicketCreateInputDTO;
};


export type MutationCreateOneUserArgsDTO = {
  data: UserCreateInputDTO;
};


export type MutationCreateOneUserCommunicationPreferenceArgsDTO = {
  data: UserCommunicationPreferenceCreateInputDTO;
};


export type MutationCreateOneUserResourceAccessArgsDTO = {
  data: UserResourceAccessCreateInputDTO;
};


export type MutationCreateOneUserRoleArgsDTO = {
  data: UserRoleCreateInputDTO;
};


export type MutationCreateOneZoneArgsDTO = {
  data: ZoneCreateInputDTO;
};


export type MutationDecreasePupilCoinArgsDTO = {
  data: DecreasePupilCoinInputDTO;
};


export type MutationDecreasePupilEnergyArgsDTO = {
  data: DecreasePupilEnergyInputDTO;
};


export type MutationDeleteClassExerciseLinksArgsDTO = {
  filter?: InputMaybe<ClassExerciseLinkFilterDTO>;
};


export type MutationDeleteClassExercisesArgsDTO = {
  filter?: InputMaybe<ClassExerciseFilterDTO>;
};


export type MutationDeleteClassLibrariesArgsDTO = {
  filter?: InputMaybe<ClassLibraryFilterDTO>;
};


export type MutationDeleteOneAchievementArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneAchievementLevelArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneAchievementLevelBackgroundArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneAchievementLevelStepStoneArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneAchievementMilestoneArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneAchievementMilestoneGiftRewardArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneAchievementMilestoneRewardArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneAchievementMilestoneUnlockableRewardArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneAvatarElementArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneCampaignArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneChallengeArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneClassArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneClassExerciseArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneClassLibraryArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneContentBlockArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneContentItemArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneContentTagArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneContentTypeArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneDeviceArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneExerciseArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneExerciseAnswerArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneExerciseCourseArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneExerciseGradeArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneExerciseQuestionArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneExerciseTagArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneGameArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneGameAttributeArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneHazardousLocationArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneHazardousLocationReasonArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneMailTemplateArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneMultiplierArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneNotificationTypeChannelConfigurationArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneProjectArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOnePupilArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOnePupilCreditArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOnePupilParentArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneQuizArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneQuizAnswerArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneQuizAnswerRewardArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneQuizQuestionArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneQuizRewardArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneRegimeArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneRegimeInactivityArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneRewardArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneRewardQuantityArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneSchoolArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneSchoolTrophyArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneSchoolyearArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneShopItemGlobalResponsibleArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneShopItemSpecificResponsibleArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneShopItemVoucherArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneShopItemVoucherCodeArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneShopItemVoucherCodeTicketArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneUserArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneUserCommunicationPreferenceArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneUserResourceAccessArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneUserRoleArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneZoneArgsDTO = {
  id: Scalars['String'];
};


export type MutationForgotPupilDataArgsDTO = {
  email: Scalars['String'];
};


export type MutationGetOneAnonymousTokenArgsDTO = {
  data: AnonymousTokenInputDTO;
};


export type MutationIncreasePupilCoinArgsDTO = {
  data: DecreasePupilCoinInputDTO;
};


export type MutationLogGamePupilScoreArgsDTO = {
  data: PupilGameScoreInputDTO;
};


export type MutationLoginArgsDTO = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationMarkOneRewardRequestAsReceivedArgsDTO = {
  id: Scalars['String'];
};


export type MutationNfcPasswordPupilLoginArgsDTO = {
  nfcTagId: Scalars['String'];
  nfcTagType: Scalars['String'];
  password: Scalars['String'];
};


export type MutationNfcPupilLoginArgsDTO = {
  nfcTagId: Scalars['String'];
  nfcTagType: Scalars['String'];
  validationCode: Scalars['String'];
};


export type MutationPupilLoginArgsDTO = {
  classId: Scalars['String'];
  classNumber: Scalars['Int'];
  password: Scalars['String'];
};


export type MutationPupilNfcPasswordResetArgsDTO = {
  nfcTagId: Scalars['String'];
  nfcTagType: Scalars['String'];
};


export type MutationPupilPasswordResetArgsDTO = {
  classId: Scalars['String'];
  classNumber: Scalars['Int'];
};


export type MutationResetPasswordArgsDTO = {
  email: Scalars['String'];
};


export type MutationResetPasswordForPupilArgsDTO = {
  input: PasswordResetForPupilInputDTO;
};


export type MutationResetPasswordForUserArgsDTO = {
  input: PasswordResetForUserInputDTO;
};


export type MutationSaveZoneForPupilArgsDTO = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  pupil: Scalars['String'];
};


export type MutationSetGameAttributeLevelArgsDTO = {
  data: GameAttributeLevelInputDTO;
};


export type MutationSetGameAttributeStatusArgsDTO = {
  data: GameAttributeStatusInputDTO;
};


export type MutationSetOnePupilAvatarElementArgsDTO = {
  input: SetPupilAvatarElementInputDTO;
};


export type MutationSimulateOneModuleRegistrationArgsDTO = {
  data: ModuleRegistrationSimulateInputDTO;
};


export type MutationStartOneQuizArgsDTO = {
  quizId: Scalars['String'];
};


export type MutationUpdateOneAchievementArgsDTO = {
  data: AchievementUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneAchievementLevelArgsDTO = {
  data: AchievementLevelUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneAchievementLevelBackgroundArgsDTO = {
  data: AchievementLevelBackgroundUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneAchievementLevelStepStoneArgsDTO = {
  data: AchievementLevelStepStoneUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneAchievementMilestoneArgsDTO = {
  data: AchievementMilestoneUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneAchievementMilestoneGiftRewardArgsDTO = {
  data: AchievementMilestoneGiftRewardUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneAchievementMilestoneRewardArgsDTO = {
  data: AchievementMilestoneRewardUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneAchievementMilestoneUnlockableRewardArgsDTO = {
  data: AchievementMilestoneUnlockableRewardUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneAvatarElementArgsDTO = {
  data: AchievementElementUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneCampaignArgsDTO = {
  data: CampaignUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneChallengeArgsDTO = {
  data: ChallengeUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneClassArgsDTO = {
  data: ClassUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneClassExerciseArgsDTO = {
  data: ClassExerciseUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneClassLibraryArgsDTO = {
  data: ClassLibraryUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneContentBlockArgsDTO = {
  data: ContentBlockUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneContentItemArgsDTO = {
  data: ContentItemUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneContentTagArgsDTO = {
  data: ContentTagUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneContentTypeArgsDTO = {
  data: ContentTypeUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneExerciseArgsDTO = {
  data: ExerciseUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneExerciseAnswerArgsDTO = {
  data: ExerciseAnswerUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneExerciseCourseArgsDTO = {
  data: ExerciseCourseUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneExerciseGradeArgsDTO = {
  data: ExerciseGradeUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneExerciseQuestionArgsDTO = {
  data: ExerciseQuestionUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneExerciseTagArgsDTO = {
  data: ExerciseTagUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneGameArgsDTO = {
  data: GameUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneGameAttributeArgsDTO = {
  data: GameAttributeUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneHazardousLocationArgsDTO = {
  data: HazardousLocationUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneHazardousLocationReasonArgsDTO = {
  data: HazardousLocationReasonUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneMailTemplateArgsDTO = {
  data: MailTemplateUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneModuleArgsDTO = {
  data: ModuleUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneMultiplierArgsDTO = {
  data: MultiplierUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneNotificationTypeChannelConfigurationArgsDTO = {
  data: NotificationTypeChannelConfigurationUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOnePolicyArgsDTO = {
  data: PolicyUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneProjectArgsDTO = {
  data: ProjectUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOnePupilArgsDTO = {
  data: PupilUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOnePupilCreditArgsDTO = {
  data: PupilCreditUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOnePupilNotificationLogArgsDTO = {
  data: PupilNotificationLogUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOnePupilParentArgsDTO = {
  data: PupilParentUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneQuizArgsDTO = {
  data: QuizUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneQuizAnswerArgsDTO = {
  data: QuizAnswerUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneQuizAnswerRewardArgsDTO = {
  data: QuizAnswerRewardUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneQuizQuestionArgsDTO = {
  data: QuizQuestionUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneQuizRewardArgsDTO = {
  data: QuizRewardUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneRegimeArgsDTO = {
  data: RegimeUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneRewardArgsDTO = {
  data: RewardUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneRewardQuantityArgsDTO = {
  data: RewardQuantityUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneSchoolArgsDTO = {
  data: SchoolUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneSchoolTrophyArgsDTO = {
  data: SchoolTrophyUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneSchoolyearArgsDTO = {
  data: SchoolyearUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneShopItemArgsDTO = {
  id: Scalars['String'];
  input: ShopItemUpdateInputDTO;
};


export type MutationUpdateOneShopItemAssignmentArgsDTO = {
  id: Scalars['String'];
  input: ShopItemAssignmentUpdateInputDTO;
};


export type MutationUpdateOneShopItemGlobalResponsibleArgsDTO = {
  data: ShopItemGlobalResponsibleUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneShopItemSpecificResponsibleArgsDTO = {
  data: ShopItemSpecificResponsibleUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneShopItemVoucherArgsDTO = {
  data: ShopItemVoucherUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneShopItemVoucherCodeArgsDTO = {
  data: ShopItemVoucherCodeUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneShopItemVoucherCodeTicketArgsDTO = {
  data: ShopItemVoucherCodeTicketUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneUserArgsDTO = {
  data?: InputMaybe<UserUpdateInputDTO>;
  id: Scalars['String'];
};


export type MutationUpdateOneUserCommunicationPreferenceArgsDTO = {
  data: UserCommunicationPreferenceUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneUserResourceAccessArgsDTO = {
  data: UserResourceAccessUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneUserRoleArgsDTO = {
  data: UserRoleUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdateOneZoneArgsDTO = {
  data: ZoneUpdateInputDTO;
  id: Scalars['String'];
};


export type MutationUpdatePasswordArgsDTO = {
  data: PasswordUpdateInputDTO;
};


export type MutationUpdatePasswordAnonymousArgsDTO = {
  data: PasswordAnonymousUpdateInputDTO;
};


export type MutationUpdatePupilPasswordArgsDTO = {
  data: PupilPasswordUpdateInputDTO;
};


export type MutationUpdatePupilPasswordAnonymousArgsDTO = {
  data: PupilPasswordAnonymousUpdateInputDTO;
};


export type MutationUploadAssetArgsDTO = {
  data: AssetUploadInputDTO;
};


export type MutationUsernamePupilLoginArgsDTO = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationValidatePupilClassNumberArgsDTO = {
  data: ValidatePupilClassNumberInputDTO;
};


export type MutationValidatePupilTagArgsDTO = {
  data: ValidatePupilTagInputDTO;
};

export enum NotificationChannelDTO {
  CustomNotificationDTO = 'CUSTOM_NOTIFICATION',
  EmailDTO = 'EMAIL',
  LocalNotificationDTO = 'LOCAL_NOTIFICATION',
  PlatformNotificationDTO = 'PLATFORM_NOTIFICATION',
  PushNotificationDTO = 'PUSH_NOTIFICATION'
}

export enum NotificationSuppressionReasonDTO {
  CommunicationNotAllowedDTO = 'COMMUNICATION_NOT_ALLOWED',
  ConfigurationInvalidDTO = 'CONFIGURATION_INVALID',
  ConfigurationNotEnabledDTO = 'CONFIGURATION_NOT_ENABLED',
  InternalErrorDTO = 'INTERNAL_ERROR',
  TransmitterNotFoundDTO = 'TRANSMITTER_NOT_FOUND'
}

export enum NotificationTypeDTO {
  AchievementLevelCompletedDTO = 'ACHIEVEMENT_LEVEL_COMPLETED',
  FitfestAvatarElementsDTO = 'FITFEST_AVATAR_ELEMENTS',
  ForgotPupilDataDTO = 'FORGOT_PUPIL_DATA',
  MilestoneAvatarElementUnlockedDTO = 'MILESTONE_AVATAR_ELEMENT_UNLOCKED',
  MilestoneCoinsGiftedDTO = 'MILESTONE_COINS_GIFTED',
  MilestoneContentItemUnlockedDTO = 'MILESTONE_CONTENT_ITEM_UNLOCKED',
  MilestoneGameAttributeGiftedDTO = 'MILESTONE_GAME_ATTRIBUTE_GIFTED',
  ResetPasswordDTO = 'RESET_PASSWORD',
  ResetPupilPasswordDTO = 'RESET_PUPIL_PASSWORD',
  RewardsAndUpdatesForChildDTO = 'REWARDS_AND_UPDATES_FOR_CHILD',
  RewardRequestParentDTO = 'REWARD_REQUEST_PARENT',
  RewardRequestSchoolDTO = 'REWARD_REQUEST_SCHOOL',
  ShopitemorderCanceledDTO = 'SHOPITEMORDER_CANCELED',
  ShopitemorderConfirmedDTO = 'SHOPITEMORDER_CONFIRMED',
  WelcomePupilParentDTO = 'WELCOME_PUPIL_PARENT'
}

export type NotificationTypeChannelConfigurationDTO = {
  __typename?: 'NotificationTypeChannelConfiguration';
  body: Scalars['String'];
  channel: NotificationChannelDTO;
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  optIn: Scalars['Boolean'];
  title: Scalars['String'];
  type: NotificationTypeDTO;
};

export type NotificationTypeChannelConfigurationCreateInputDTO = {
  bodies: Array<MultiLanguageTextInputDTO>;
  channel: NotificationChannelDTO;
  enabled: Scalars['Boolean'];
  optIn: Scalars['Boolean'];
  titles: Array<MultiLanguageTextInputDTO>;
  type: NotificationTypeDTO;
};

export type NotificationTypeChannelConfigurationFilterDTO = {
  AND?: InputMaybe<Array<NotificationTypeChannelConfigurationFilterDTO>>;
  OR?: InputMaybe<Array<NotificationTypeChannelConfigurationFilterDTO>>;
  body?: InputMaybe<StringFilterDTO>;
  channel?: InputMaybe<StringFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  enabled?: InputMaybe<BooleanFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  optIn?: InputMaybe<BooleanFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export type NotificationTypeChannelConfigurationUpdateInputDTO = {
  bodies?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  channel?: InputMaybe<NotificationChannelDTO>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  optIn?: InputMaybe<Scalars['Boolean']>;
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  type?: InputMaybe<NotificationTypeDTO>;
};

export type PagingDTO = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PasswordAnonymousUpdateInputDTO = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type PasswordResetDTO = {
  __typename?: 'PasswordReset';
  createdAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['String'];
  resetToken: Scalars['String'];
  usedAt?: Maybe<Scalars['DateTime']>;
  user: UserDTO;
};

export type PasswordResetFilterDTO = {
  AND?: InputMaybe<Array<PasswordResetFilterDTO>>;
  OR?: InputMaybe<Array<PasswordResetFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  expiresAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  resetToken?: InputMaybe<StringFilterDTO>;
  usedAt?: InputMaybe<DateTimeFilterDTO>;
  user?: InputMaybe<UserFilterDTO>;
};

export type PasswordResetForPupilInputDTO = {
  pupilId: Scalars['String'];
  refererHostname: Scalars['String'];
};

export type PasswordResetForUserInputDTO = {
  refererHostname: Scalars['String'];
  userId: Scalars['String'];
};

export type PasswordUpdateInputDTO = {
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};

export type PolicyDTO = {
  __typename?: 'Policy';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  required: Scalars['Boolean'];
  type: PolicyTypeDTO;
  url: Scalars['String'];
  version: Scalars['String'];
};

export type PolicyCreateInputDTO = {
  active: Scalars['Boolean'];
  required: Scalars['Boolean'];
  type: PolicyTypeDTO;
  url: Scalars['String'];
  version: Scalars['String'];
};

export type PolicyFilterDTO = {
  AND?: InputMaybe<Array<PolicyFilterDTO>>;
  OR?: InputMaybe<Array<PolicyFilterDTO>>;
  active?: InputMaybe<BooleanFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  required?: InputMaybe<BooleanFilterDTO>;
  type?: InputMaybe<PolicyTypeFilterDTO>;
  version?: InputMaybe<StringFilterDTO>;
};

export enum PolicyTypeDTO {
  DataProcessingAgreementDTO = 'DATA_PROCESSING_AGREEMENT',
  PrivacyPolicyDTO = 'PRIVACY_POLICY',
  UnknownDTO = 'UNKNOWN'
}

export type PolicyTypeFilterDTO = {
  equals?: InputMaybe<PolicyTypeDTO>;
  in?: InputMaybe<Array<InputMaybe<PolicyTypeDTO>>>;
  not?: InputMaybe<PolicyTypeDTO>;
  notIn?: InputMaybe<Array<InputMaybe<PolicyTypeDTO>>>;
};

export type PolicyUpdateInputDTO = {
  active?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type ProfileDTO = {
  __typename?: 'Profile';
  consecutiveDays: Scalars['Int'];
  pupil: PupilDTO;
  showDailyLoginPopup: Scalars['Boolean'];
  showZoneForm: Scalars['Boolean'];
};

export type ProjectDTO = {
  __typename?: 'Project';
  activeSchoolyear?: Maybe<SchoolyearDTO>;
  bikeRegistrationsEnabled: Scalars['Boolean'];
  challengeBackground: AssetDTO;
  challengeForeground: AssetDTO;
  challengeIcon: AssetDTO;
  challengesEnabled: Scalars['Boolean'];
  classNumberEnabled: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  fluoRegistrationsEnabled: Scalars['Boolean'];
  highFiveRegistrationsEnabled: Scalars['Boolean'];
  id: Scalars['String'];
  image: AssetDTO;
  kioskUrl?: Maybe<Scalars['String']>;
  registrationUrl?: Maybe<Scalars['String']>;
  rewardsEnabled: Scalars['Boolean'];
  schoolyears?: Maybe<Array<SchoolyearDTO>>;
  title: Scalars['String'];
  url: Scalars['String'];
  zonesEnabled: Scalars['Boolean'];
};

export type ProjectCreateInputDTO = {
  bikeRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  challengeBackground: Scalars['String'];
  challengeForeground: Scalars['String'];
  challengeIcon: Scalars['String'];
  challengesEnabled?: InputMaybe<Scalars['Boolean']>;
  classNumberEnabled?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  fluoRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  image: Scalars['String'];
  kioskUrl?: InputMaybe<Scalars['String']>;
  registrationUrl?: InputMaybe<Scalars['String']>;
  rewardsEnabled?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  url: Scalars['String'];
  zonesEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type ProjectFilterDTO = {
  AND?: InputMaybe<Array<ProjectFilterDTO>>;
  OR?: InputMaybe<Array<ProjectFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type ProjectUpdateInputDTO = {
  bikeRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  challengeBackground?: InputMaybe<Scalars['String']>;
  challengeForeground?: InputMaybe<Scalars['String']>;
  challengeIcon?: InputMaybe<Scalars['String']>;
  challengesEnabled?: InputMaybe<Scalars['Boolean']>;
  classNumberEnabled?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  fluoRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['String']>;
  kioskUrl?: InputMaybe<Scalars['String']>;
  registrationUrl?: InputMaybe<Scalars['String']>;
  rewardsEnabled?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  zonesEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type PupilDTO = {
  __typename?: 'Pupil';
  avatar?: Maybe<AvatarDTO>;
  avatarElements: Array<PupilAvatarElementDTO>;
  class: ClassDTO;
  classNumber?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gamePupilTotal?: Maybe<GamePupilTotalDTO>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  moduleRegistrations?: Maybe<Array<Maybe<ModuleRegistrationDTO>>>;
  moduleRegistrationsCount: Scalars['Int'];
  noRegistrations: Scalars['Boolean'];
  pupilTags?: Maybe<Array<Maybe<PupilTagDTO>>>;
  pupilTotal?: Maybe<PupilTotalDTO>;
  username?: Maybe<UsernameDTO>;
  zone?: Maybe<ZoneDTO>;
};


export type PupilModuleRegistrationsArgsDTO = {
  filter?: InputMaybe<ModuleRegistrationReferenceFilterDTO>;
};


export type PupilModuleRegistrationsCountArgsDTO = {
  filter?: InputMaybe<ModuleRegistrationReferenceFilterDTO>;
};

export type PupilAvatarElementDTO = {
  __typename?: 'PupilAvatarElement';
  avatarElement: AvatarElementDTO;
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PupilAvatarElementFilterDTO = {
  AND?: InputMaybe<Array<PupilAvatarElementFilterDTO>>;
  OR?: InputMaybe<Array<PupilAvatarElementFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export type PupilCommunicationPreferenceDTO = {
  __typename?: 'PupilCommunicationPreference';
  allow: Scalars['Boolean'];
  channel: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  pupil: PupilDTO;
  type: Scalars['String'];
};

export type PupilCommunicationPreferenceFilterDTO = {
  AND?: InputMaybe<Array<PupilCommunicationPreferenceFilterDTO>>;
  OR?: InputMaybe<Array<PupilCommunicationPreferenceFilterDTO>>;
  allow?: InputMaybe<BooleanFilterDTO>;
  channel?: InputMaybe<StringFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export type PupilCreateInputDTO = {
  avatar?: InputMaybe<AvatarCreateInputDTO>;
  class?: InputMaybe<Scalars['String']>;
  classNumber?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  noRegistrations?: InputMaybe<Scalars['Boolean']>;
  parents?: InputMaybe<Array<PupilParentInputDTO>>;
  password?: InputMaybe<Scalars['String']>;
  pupilTags?: InputMaybe<Array<InputMaybe<PupilTagInputDTO>>>;
  usernameId?: InputMaybe<Scalars['String']>;
};

export type PupilCreditDTO = {
  __typename?: 'PupilCredit';
  createdAt: Scalars['DateTime'];
  credit: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  pupil: PupilDTO;
  registration?: Maybe<ModuleRegistrationDTO>;
  type: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type PupilCreditCreateInputDTO = {
  comment?: InputMaybe<Scalars['String']>;
  credit: Scalars['Int'];
  pupil: Scalars['String'];
};

export type PupilCreditFilterDTO = {
  AND?: InputMaybe<Array<PupilCreditFilterDTO>>;
  OR?: InputMaybe<Array<PupilCreditFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  credit?: InputMaybe<IntFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  metadata?: InputMaybe<PupilCreditMetadataFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  valid?: InputMaybe<BooleanFilterDTO>;
};

export type PupilCreditMetadataFilterDTO = {
  id?: InputMaybe<StringFilterDTO>;
};

export enum PupilCreditTimeSeriesGroupByDTO {
  ClassDTO = 'CLASS',
  PupilDTO = 'PUPIL',
  SchoolDTO = 'SCHOOL'
}

export type PupilCreditUpdateInputDTO = {
  credit?: InputMaybe<Scalars['Int']>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type PupilExerciseAnswerDTO = {
  __typename?: 'PupilExerciseAnswer';
  answer: ExerciseAnswerDTO;
  createdAt?: Maybe<Scalars['DateTime']>;
  exercise: ExerciseDTO;
  id: Scalars['String'];
  pupil: PupilDTO;
  question: ExerciseQuestionDTO;
};

export type PupilExerciseResultDTO = {
  __typename?: 'PupilExerciseResult';
  answers?: Maybe<Array<Maybe<PupilExerciseAnswerDTO>>>;
  createdAt: Scalars['DateTime'];
  exercise?: Maybe<ExerciseDTO>;
  id: Scalars['String'];
  maxScore: Scalars['Int'];
  pupil: PupilDTO;
  score: Scalars['Int'];
};

export type PupilExerciseResultFilterDTO = {
  AND?: InputMaybe<Array<PupilExerciseResultFilterDTO>>;
  OR?: InputMaybe<Array<PupilExerciseResultFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  exercise?: InputMaybe<ExerciseFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  maxScore?: InputMaybe<IntFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  score?: InputMaybe<IntFilterDTO>;
};

export type PupilFilterDTO = {
  AND?: InputMaybe<Array<PupilFilterDTO>>;
  OR?: InputMaybe<Array<PupilFilterDTO>>;
  class?: InputMaybe<ClassFilterDTO>;
  classNumber?: InputMaybe<IntFilterDTO>;
  distance?: InputMaybe<IntFilterDTO>;
  firstName?: InputMaybe<StringFilterDTO>;
  fullName?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  lastName?: InputMaybe<StringFilterDTO>;
  noRegistrations?: InputMaybe<BooleanFilterDTO>;
  pupilTag?: InputMaybe<PupilTagFilterDTO>;
  username?: InputMaybe<StringFilterDTO>;
  zone?: InputMaybe<BasicStringFilterDTO>;
};

export type PupilGameScoreInputDTO = {
  gameId: Scalars['String'];
  score: Scalars['Int'];
};

export type PupilLoginLogDTO = {
  __typename?: 'PupilLoginLog';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  pupil?: Maybe<PupilDTO>;
  reason?: Maybe<FailedPupilLoginReasonDTO>;
  referer?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  type: PupilLoginTypeDTO;
  userAgent?: Maybe<Scalars['String']>;
};

export type PupilLoginLogFilterDTO = {
  AND?: InputMaybe<Array<PupilLoginLogFilterDTO>>;
  OR?: InputMaybe<Array<PupilLoginLogFilterDTO>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  reason?: InputMaybe<StringFilterDTO>;
  referer?: InputMaybe<StringFilterDTO>;
  success?: InputMaybe<BooleanFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
  userAgent?: InputMaybe<StringFilterDTO>;
};

export enum PupilLoginTypeDTO {
  ClassIdClassNumberDTO = 'CLASS_ID_CLASS_NUMBER',
  NfcDTO = 'NFC',
  NfcPasswordDTO = 'NFC_PASSWORD',
  UsernamePasswordDTO = 'USERNAME_PASSWORD'
}

export type PupilNotificationLogDTO = {
  __typename?: 'PupilNotificationLog';
  archivedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  channel: NotificationChannelDTO;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  pupil: PupilDTO;
  readAt?: Maybe<Scalars['DateTime']>;
  suppressedAt?: Maybe<Scalars['DateTime']>;
  suppressionReason?: Maybe<NotificationSuppressionReasonDTO>;
  title?: Maybe<Scalars['String']>;
  type: NotificationTypeDTO;
};

export type PupilNotificationLogFilterDTO = {
  AND?: InputMaybe<Array<PupilNotificationLogFilterDTO>>;
  OR?: InputMaybe<Array<PupilNotificationLogFilterDTO>>;
  archivedAt?: InputMaybe<DateTimeFilterDTO>;
  channel?: InputMaybe<StringFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
  readAt?: InputMaybe<DateTimeFilterDTO>;
  suppressedAt?: InputMaybe<DateTimeFilterDTO>;
  suppressionReason?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export type PupilNotificationLogUpdateInputDTO = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  readAt?: InputMaybe<Scalars['DateTime']>;
};

export type PupilParentDTO = {
  __typename?: 'PupilParent';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  parent: UserDTO;
  pupil: PupilDTO;
};

export type PupilParentCreateInputDTO = {
  parentUserId: Scalars['String'];
  pupilId: Scalars['String'];
};

export type PupilParentFilterDTO = {
  AND?: InputMaybe<Array<PupilParentFilterDTO>>;
  OR?: InputMaybe<Array<PupilParentFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  parent?: InputMaybe<UserFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
};

export type PupilParentInputDTO = {
  acceptedPolicies?: InputMaybe<Array<AcceptedPolicyInputDTO>>;
  communicationPreferences: Array<UserCommunicationPreferenceInputDTO>;
  email: Scalars['String'];
};

export type PupilParentUpdateInputDTO = {
  parentUserId?: InputMaybe<Scalars['String']>;
  pupilId?: InputMaybe<Scalars['String']>;
};

export type PupilPasswordAnonymousUpdateInputDTO = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type PupilPasswordResetDTO = {
  __typename?: 'PupilPasswordReset';
  createdAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['String'];
  pupil: PupilDTO;
  resetToken: Scalars['String'];
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type PupilPasswordResetFilterDTO = {
  AND?: InputMaybe<Array<PupilPasswordResetFilterDTO>>;
  OR?: InputMaybe<Array<PupilPasswordResetFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  expiresAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  resetToken?: InputMaybe<StringFilterDTO>;
  usedAt?: InputMaybe<DateTimeFilterDTO>;
};

export type PupilPasswordUpdateInputDTO = {
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};

export type PupilReferenceFilterDTO = {
  AND?: InputMaybe<Array<PupilReferenceFilterDTO>>;
  OR?: InputMaybe<Array<PupilReferenceFilterDTO>>;
  class?: InputMaybe<ClassReferenceFilterDTO>;
  classNumber?: InputMaybe<IntFilterDTO>;
  distance?: InputMaybe<IntFilterDTO>;
  firstName?: InputMaybe<StringFilterDTO>;
  fullName?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  lastName?: InputMaybe<StringFilterDTO>;
  noRegistrations?: InputMaybe<BooleanFilterDTO>;
  username?: InputMaybe<StringFilterDTO>;
  zone?: InputMaybe<BasicStringFilterDTO>;
};

export type PupilSearchItemDTO = SearchItemDTO & {
  __typename?: 'PupilSearchItem';
  pupil: PupilDTO;
  type: SearchTypeDTO;
};

export type PupilTagDTO = {
  __typename?: 'PupilTag';
  tag: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type PupilTagFilterDTO = {
  tag?: InputMaybe<StringArrayFilterDTO>;
  type: Scalars['String'];
};

export type PupilTagInputDTO = {
  tag: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type PupilTotalDTO = {
  __typename?: 'PupilTotal';
  id: Scalars['String'];
  pupil: PupilDTO;
  totalCredits: Scalars['Int'];
  totalDistance: Scalars['Float'];
  totalRegistrations: Scalars['Int'];
  updateAt: Scalars['DateTime'];
};

export type PupilTotalFilterDTO = {
  AND?: InputMaybe<Array<PupilTotalFilterDTO>>;
  OR?: InputMaybe<Array<PupilTotalFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  totalCredits?: InputMaybe<IntFilterDTO>;
  totalRegistrations?: InputMaybe<IntFilterDTO>;
};

export type PupilUpdateInputDTO = {
  avatar?: InputMaybe<AvatarCreateInputDTO>;
  class?: InputMaybe<Scalars['String']>;
  classNumber?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  noRegistrations?: InputMaybe<Scalars['Boolean']>;
  parents?: InputMaybe<Array<PupilParentInputDTO>>;
  password?: InputMaybe<Scalars['String']>;
  pupilTags?: InputMaybe<Array<InputMaybe<PupilTagInputDTO>>>;
};

export type PupilVisibilityDTO = {
  __typename?: 'PupilVisibility';
  avatarElement?: Maybe<AvatarElementDTO>;
  contentItem?: Maybe<ContentItemDTO>;
  elementId: Scalars['String'];
  elementType: PupilVisibilityElementTypeDTO;
  id: Scalars['String'];
  pupil: PupilDTO;
  visible: Scalars['Boolean'];
};

export enum PupilVisibilityElementTypeDTO {
  AvatarElementDTO = 'AVATAR_ELEMENT',
  ContentItemDTO = 'CONTENT_ITEM'
}

export type PupilVisibilityFilterDTO = {
  AND?: InputMaybe<Array<PupilVisibilityFilterDTO>>;
  OR?: InputMaybe<Array<PupilVisibilityFilterDTO>>;
  elementId?: InputMaybe<StringFilterDTO>;
  elementType?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  visible?: InputMaybe<BooleanFilterDTO>;
};

export type QueryDTO = {
  __typename?: 'Query';
  acceptedPolicies: Array<AcceptedPolicyDTO>;
  acceptedPoliciesCount: Scalars['Int'];
  achievementLevelBackgrounds: Array<AchievementLevelBackgroundDTO>;
  achievementLevelBackgroundsCount: Scalars['Int'];
  achievementLevelPupilStatuses: Array<AchievementLevelPupilStatusDTO>;
  achievementLevelPupilStatusesCount: Scalars['Int'];
  achievementLevelStepStones: Array<AchievementLevelBackgroundDTO>;
  achievementLevelStepStonesCount: Scalars['Int'];
  achievementLevels: Array<AchievementLevelDTO>;
  achievementLevelsCount: Scalars['Int'];
  achievementMilestoneGiftRewards: Array<AchievementMilestoneGiftRewardDTO>;
  achievementMilestoneGiftRewardsCount: Scalars['Int'];
  achievementMilestonePupilStatuses: Array<AchievementMilestonePupilStatusDTO>;
  achievementMilestonePupilStatusesCount: Scalars['Int'];
  achievementMilestoneRewards: Array<AchievementMilestoneRewardDTO>;
  achievementMilestoneRewardsCount: Scalars['Int'];
  achievementMilestoneUnlockableRewards: Array<AchievementMilestoneUnlockableRewardDTO>;
  achievementMilestoneUnlockableRewardsCount: Scalars['Int'];
  achievementMilestones: Array<AchievementMilestoneDTO>;
  achievementMilestonesCount: Scalars['Int'];
  achievementProgresses: Array<AchievementProgressDTO>;
  achievementProgressesCount: Scalars['Int'];
  achievementUnlockeds: Array<AchievementUnlockedDTO>;
  achievementUnlockedsCount: Scalars['Int'];
  achievements: Array<AchievementDTO>;
  achievementsCount: Scalars['Int'];
  airQualities: Array<AirQualityDTO>;
  airQualitiesCount: Scalars['Int'];
  assignedShopItems: Array<AssignedShopItemDTO>;
  avatarElements: Array<AvatarElementDTO>;
  avatarElementsCount: Scalars['Int'];
  campaigns: Array<CampaignDTO>;
  campaignsCount: Scalars['Int'];
  challenges: Array<ChallengeDTO>;
  challengesCount: Scalars['Int'];
  classExercises: Array<ClassExerciseDTO>;
  classExercisesCount: Scalars['Int'];
  classLibraries: Array<ClassLibraryDTO>;
  classLibrariesCount: Scalars['Int'];
  classTotals: Array<ClassTotalDTO>;
  classTotalsCount: Scalars['Int'];
  classes: Array<ClassDTO>;
  classesCount: Scalars['Int'];
  contentBlocks: Array<ContentBlockDTO>;
  contentBlocksCount: Scalars['Int'];
  contentItems: Array<ContentItemDTO>;
  contentItemsCount: Scalars['Int'];
  contentTags: Array<ContentTagDTO>;
  contentTagsCount: Scalars['Int'];
  contentTypes: Array<ContentTypeDTO>;
  contentTypesCount: Scalars['Int'];
  deviceBatteryLogs: Array<DeviceBatteryLogDTO>;
  deviceBatteryLogsCount: Scalars['Int'];
  deviceTotals: Array<DeviceTotalDTO>;
  deviceTotalsCount: Scalars['Int'];
  devices: Array<DeviceDTO>;
  devicesCount: Scalars['Int'];
  exerciseCourses: Array<ExerciseCourseDTO>;
  exerciseCoursesCount: Scalars['Int'];
  exerciseGrades: Array<ExerciseGradeDTO>;
  exerciseGradesCount: Scalars['Int'];
  exerciseTags: Array<ExerciseTagDTO>;
  exerciseTagsCount: Scalars['Int'];
  exercises: Array<ExerciseDTO>;
  exercisesCount: Scalars['Int'];
  forgotPupilDataRequests: Array<ForgotPupilDataRequestDTO>;
  forgotPupilDataRequestsCount: Scalars['Int'];
  gameAttributePupilLevelLogs: Array<GameAttributePupilLevelLogDTO>;
  gameAttributePupilLevelLogsCount: Scalars['Int'];
  gameAttributePupilLevels: Array<GameAttributePupilLevelDTO>;
  gameAttributePupilLevelsCount: Scalars['Int'];
  gameAttributePupilStatusLogs: Array<GameAttributePupilStatusLogDTO>;
  gameAttributePupilStatusLogsCount: Scalars['Int'];
  gameAttributePupilStatuses: Array<GameAttributePupilStatusDTO>;
  gameAttributePupilStatusesCount: Scalars['Int'];
  gameAttributes: Array<GameAttributeDTO>;
  gameAttributesCount: Scalars['Int'];
  gameHighScores: Array<GameHighScoreDTO>;
  gamePupilCoinLogs: Array<GamePupilCoinLogDTO>;
  gamePupilCoinLogsCount: Scalars['Int'];
  gamePupilEnergyLogs: Array<GamePupilEnergyLogDTO>;
  gamePupilEnergyLogsCount: Scalars['Int'];
  gamePupilHighScores: Array<GamePupilHighScoreDTO>;
  gamePupilHighScoresCount: Scalars['Int'];
  gamePupilScoreLogs: Array<GamePupilScoreLogDTO>;
  gamePupilScoreLogsCount: Scalars['Int'];
  gamePupilTotal?: Maybe<GamePupilTotalDTO>;
  gamePupilTotals: Array<GamePupilTotalDTO>;
  gamePupilTotalsCount: Scalars['Int'];
  gameScoreClassLeaderboard: Array<GameLeaderboardEntryDTO>;
  gameScoreClassLeaderboardCount: Scalars['Int'];
  gameScoreSchoolLeaderboard: Array<GameLeaderboardEntryDTO>;
  gameScoreSchoolLeaderboardCount: Scalars['Int'];
  games: Array<GameDTO>;
  gamesCount: Scalars['Int'];
  hazardousLocationReasons: Array<HazardousLocationReasonDTO>;
  hazardousLocationReasonsCount: Scalars['Int'];
  hazardousLocations: Array<HazardousLocationDTO>;
  hazardousLocationsCount: Scalars['Int'];
  lastReportedDeviceInfos: Array<LastReportedDeviceInfoDTO>;
  lastReportedDeviceInfosCount: Scalars['Int'];
  locations: Array<LocationDTO>;
  locationsCount: Scalars['Int'];
  mailTemplates: Array<MailTemplateDTO>;
  mailTemplatesCount: Scalars['Int'];
  moduleLocationLogs: Array<ModuleLocationLogDTO>;
  moduleLocationLogsCount: Scalars['Int'];
  moduleReferenceLogs: Array<ModuleReferenceLogDTO>;
  moduleReferenceLogsCount: Scalars['Int'];
  moduleRegistrationTimeSeries: Array<TimeSeriesDTO>;
  moduleRegistrations: Array<ModuleRegistrationDTO>;
  moduleRegistrationsCount: Scalars['Int'];
  moduleTotals: Array<ModuleTotalDTO>;
  moduleTotalsCount: Scalars['Int'];
  modules: Array<ModuleDTO>;
  modulesCount: Scalars['Int'];
  multipliers: Array<MultiplierDTO>;
  multipliersCount: Scalars['Int'];
  notificationTypeChannelConfigurations: Array<NotificationTypeChannelConfigurationDTO>;
  notificationTypeChannelConfigurationsCount: Scalars['Int'];
  passwordResets: Array<PasswordResetDTO>;
  passwordResetsCount: Scalars['Int'];
  policies: Array<PolicyDTO>;
  policiesCount: Scalars['Int'];
  profile: ProfileDTO;
  projects: Array<ProjectDTO>;
  projectsCount: Scalars['Int'];
  pupilAvatarElements: Array<PupilAvatarElementDTO>;
  pupilAvatarElementsCount: Scalars['Int'];
  pupilCommunicationPreferences: Array<PupilCommunicationPreferenceDTO>;
  pupilCommunicationPreferencesCount: Scalars['Int'];
  pupilCreditTimeSeries: Array<TimeSeriesDTO>;
  pupilCredits: Array<PupilCreditDTO>;
  pupilCreditsCount: Scalars['Int'];
  pupilExerciseResults: Array<PupilExerciseResultDTO>;
  pupilExerciseResultsCount: Scalars['Int'];
  pupilLoginLogs: Array<PupilLoginLogDTO>;
  pupilLoginLogsCount: Scalars['Int'];
  pupilNotificationLogs: Array<PupilNotificationLogDTO>;
  pupilNotificationLogsCount: Scalars['Int'];
  pupilParents: Array<PupilParentDTO>;
  pupilParentsCount: Scalars['Int'];
  pupilPasswordResets: Array<PupilPasswordResetDTO>;
  pupilPasswordResetsCount: Scalars['Int'];
  pupilTotals: Array<PupilTotalDTO>;
  pupilTotalsCount: Scalars['Int'];
  pupilVisibilities: Array<PupilVisibilityDTO>;
  pupilVisibilitiesCount: Scalars['Int'];
  pupils: Array<PupilDTO>;
  pupilsCount: Scalars['Int'];
  quizAnswerRewards: Array<QuizAnswerRewardDTO>;
  quizAnswerRewardsCount: Scalars['Int'];
  quizAnswers: Array<QuizAnswerDTO>;
  quizAnswersCount: Scalars['Int'];
  quizPupilAnswerRewardStatuses: Array<QuizPupilAnswerRewardStatusDTO>;
  quizPupilAnswerRewardStatusesCount: Scalars['Int'];
  quizPupilAnswers: Array<QuizPupilAnswerDTO>;
  quizPupilAnswersCount: Scalars['Int'];
  quizPupilRewardStatuses: Array<QuizPupilRewardStatusDTO>;
  quizPupilRewardStatusesCount: Scalars['Int'];
  quizPupilStatuses: Array<QuizPupilStatusDTO>;
  quizPupilStatusesCount: Scalars['Int'];
  quizQuestions: Array<QuizQuestionDTO>;
  quizQuestionsCount: Scalars['Int'];
  quizRewards: Array<QuizRewardDTO>;
  quizRewardsCount: Scalars['Int'];
  quizzes: Array<QuizDTO>;
  quizzesCount: Scalars['Int'];
  rawModuleMessages: Array<RawModuleMessageDTO>;
  rawModuleMessagesCount: Scalars['Int'];
  regimes: Array<RegimeDTO>;
  regimesCount: Scalars['Int'];
  rewardQuantities: Array<RewardQuantityDTO>;
  rewardQuantitiesCount: Scalars['Int'];
  rewardRequests: Array<RewardRequestDTO>;
  rewardRequestsCount: Scalars['Int'];
  rewards: Array<RewardDTO>;
  rewardsCount: Scalars['Int'];
  schoolStatistics: Array<SchoolStatisticsDTO>;
  schoolTotals: Array<SchoolTotalDTO>;
  schoolTotalsCount: Scalars['Int'];
  schoolTrophies: Array<SchoolTrophyDTO>;
  schoolTrophiesCount: Scalars['Int'];
  schools: Array<SchoolDTO>;
  schoolsCount: Scalars['Int'];
  schoolyears: Array<SchoolyearDTO>;
  schoolyearsCount: Scalars['Int'];
  search: SearchResultDTO;
  shopItemAssignments: Array<ShopItemAssignmentDTO>;
  shopItemGlobalResponsibles: Array<ShopItemGlobalResponsibleDTO>;
  shopItemGlobalResponsiblesCount: Scalars['Int'];
  shopItemOrders: Array<ShopItemOrderDTO>;
  shopItemSpecificResponsibles: Array<ShopItemSpecificResponsibleDTO>;
  shopItemSpecificResponsiblesCount: Scalars['Int'];
  shopItemStockLogs: Array<ShopItemStockLogDTO>;
  shopItemStockLogsCount: Scalars['Int'];
  shopItemTypes: Array<ShopItemTypeDTO>;
  shopItemVoucherCodeTickets: Array<ShopItemVoucherCodeTicketDTO>;
  shopItemVoucherCodeTicketsCount: Scalars['Int'];
  shopItemVoucherCodes: Array<ShopItemVoucherCodeDTO>;
  shopItemVoucherCodesCount: Scalars['Int'];
  shopItemVouchers: Array<ShopItemVoucherDTO>;
  shopItemVouchersCount: Scalars['Int'];
  shopItems: Array<ShopItemDTO>;
  userCommunicationPreferences: Array<UserCommunicationPreferenceDTO>;
  userCommunicationPreferencesCount: Scalars['Int'];
  userCount: Scalars['Int'];
  userLoginLogs: Array<UserLoginLogDTO>;
  userLoginLogsCount: Scalars['Int'];
  userNotificationLogs: Array<UserNotificationLogDTO>;
  userNotificationLogsCount: Scalars['Int'];
  userResourceAccesses: Array<UserResourceAccessDTO>;
  userResourceAccessesCount: Scalars['Int'];
  userRoles: Array<UserRoleDTO>;
  userRolesCount: Scalars['Int'];
  usernames: Array<UsernameDTO>;
  usernamesCount: Scalars['Int'];
  users: Array<UserDTO>;
  zones: Array<ZoneDTO>;
  zonesCount: Scalars['Int'];
};


export type QueryAcceptedPoliciesArgsDTO = {
  filter?: InputMaybe<AcceptedPolicyFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAcceptedPoliciesCountArgsDTO = {
  filter?: InputMaybe<AcceptedPolicyFilterDTO>;
};


export type QueryAchievementLevelBackgroundsArgsDTO = {
  filter?: InputMaybe<AchievementLevelBackgroundFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementLevelBackgroundsCountArgsDTO = {
  filter?: InputMaybe<AchievementLevelBackgroundFilterDTO>;
};


export type QueryAchievementLevelPupilStatusesArgsDTO = {
  filter?: InputMaybe<AchievementLevelPupilStatusFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementLevelPupilStatusesCountArgsDTO = {
  filter?: InputMaybe<AchievementLevelPupilStatusFilterDTO>;
};


export type QueryAchievementLevelStepStonesArgsDTO = {
  filter?: InputMaybe<AchievementLevelStepStoneFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementLevelStepStonesCountArgsDTO = {
  filter?: InputMaybe<AchievementLevelStepStoneFilterDTO>;
};


export type QueryAchievementLevelsArgsDTO = {
  filter?: InputMaybe<AchievementLevelFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementLevelsCountArgsDTO = {
  filter?: InputMaybe<AchievementLevelFilterDTO>;
};


export type QueryAchievementMilestoneGiftRewardsArgsDTO = {
  filter?: InputMaybe<AchievementMilestoneGiftRewardFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementMilestoneGiftRewardsCountArgsDTO = {
  filter?: InputMaybe<AchievementMilestoneGiftRewardFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementMilestonePupilStatusesArgsDTO = {
  filter?: InputMaybe<AchievementMilestonePupilStatusFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementMilestonePupilStatusesCountArgsDTO = {
  filter?: InputMaybe<AchievementMilestonePupilStatusFilterDTO>;
};


export type QueryAchievementMilestoneRewardsArgsDTO = {
  filter?: InputMaybe<AchievementMilestoneRewardFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementMilestoneRewardsCountArgsDTO = {
  filter?: InputMaybe<AchievementMilestoneRewardFilterDTO>;
};


export type QueryAchievementMilestoneUnlockableRewardsArgsDTO = {
  filter?: InputMaybe<AchievementMilestoneUnlockableRewardFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementMilestoneUnlockableRewardsCountArgsDTO = {
  filter?: InputMaybe<AchievementMilestoneUnlockableRewardFilterDTO>;
};


export type QueryAchievementMilestonesArgsDTO = {
  filter?: InputMaybe<AchievementMilestoneFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementMilestonesCountArgsDTO = {
  filter?: InputMaybe<AchievementMilestoneFilterDTO>;
};


export type QueryAchievementProgressesArgsDTO = {
  filter?: InputMaybe<AchievementProgressFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementProgressesCountArgsDTO = {
  filter?: InputMaybe<AchievementProgressFilterDTO>;
};


export type QueryAchievementUnlockedsArgsDTO = {
  filter?: InputMaybe<AchievementUnlockedFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementUnlockedsCountArgsDTO = {
  filter?: InputMaybe<AchievementUnlockedFilterDTO>;
};


export type QueryAchievementsArgsDTO = {
  filter?: InputMaybe<AchievementFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAchievementsCountArgsDTO = {
  filter?: InputMaybe<AchievementFilterDTO>;
};


export type QueryAirQualitiesArgsDTO = {
  filter?: InputMaybe<AirQualityFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAirQualitiesCountArgsDTO = {
  filter?: InputMaybe<AirQualityFilterDTO>;
};


export type QueryAssignedShopItemsArgsDTO = {
  filter?: InputMaybe<AssignedShopItemFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAvatarElementsArgsDTO = {
  filter?: InputMaybe<AvatarElementFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryAvatarElementsCountArgsDTO = {
  filter?: InputMaybe<AvatarElementFilterDTO>;
};


export type QueryCampaignsArgsDTO = {
  filter?: InputMaybe<CampaignFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryCampaignsCountArgsDTO = {
  filter?: InputMaybe<CampaignFilterDTO>;
};


export type QueryChallengesArgsDTO = {
  filter?: InputMaybe<ChallengeFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryChallengesCountArgsDTO = {
  filter?: InputMaybe<ChallengeFilterDTO>;
};


export type QueryClassExercisesArgsDTO = {
  filter?: InputMaybe<ClassExerciseFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryClassExercisesCountArgsDTO = {
  filter?: InputMaybe<ClassExerciseFilterDTO>;
};


export type QueryClassLibrariesArgsDTO = {
  filter?: InputMaybe<ClassLibraryFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryClassLibrariesCountArgsDTO = {
  filter?: InputMaybe<ClassLibraryFilterDTO>;
};


export type QueryClassTotalsArgsDTO = {
  filter?: InputMaybe<ClassTotalFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryClassTotalsCountArgsDTO = {
  filter?: InputMaybe<ClassTotalFilterDTO>;
};


export type QueryClassesArgsDTO = {
  filter?: InputMaybe<ClassFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryClassesCountArgsDTO = {
  filter?: InputMaybe<ClassFilterDTO>;
};


export type QueryContentBlocksArgsDTO = {
  filter?: InputMaybe<ContentBlockFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryContentBlocksCountArgsDTO = {
  filter?: InputMaybe<ContentBlockFilterDTO>;
};


export type QueryContentItemsArgsDTO = {
  filter?: InputMaybe<ContentItemFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryContentItemsCountArgsDTO = {
  filter?: InputMaybe<ContentItemFilterDTO>;
};


export type QueryContentTagsArgsDTO = {
  filter?: InputMaybe<ContentTagFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryContentTagsCountArgsDTO = {
  filter?: InputMaybe<ContentTagFilterDTO>;
};


export type QueryContentTypesArgsDTO = {
  filter?: InputMaybe<ContentTypeFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryContentTypesCountArgsDTO = {
  filter?: InputMaybe<ContentTypeFilterDTO>;
};


export type QueryDeviceBatteryLogsArgsDTO = {
  filter?: InputMaybe<DeviceBatteryLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryDeviceBatteryLogsCountArgsDTO = {
  filter?: InputMaybe<DeviceBatteryLogFilterDTO>;
};


export type QueryDeviceTotalsArgsDTO = {
  filter?: InputMaybe<DeviceTotalFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryDeviceTotalsCountArgsDTO = {
  filter?: InputMaybe<DeviceTotalFilterDTO>;
};


export type QueryDevicesArgsDTO = {
  filter?: InputMaybe<DeviceFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryDevicesCountArgsDTO = {
  filter?: InputMaybe<DeviceFilterDTO>;
};


export type QueryExerciseCoursesArgsDTO = {
  filter?: InputMaybe<ExerciseCourseFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryExerciseCoursesCountArgsDTO = {
  filter?: InputMaybe<ExerciseCourseFilterDTO>;
};


export type QueryExerciseGradesArgsDTO = {
  filter?: InputMaybe<ExerciseGradeFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryExerciseGradesCountArgsDTO = {
  filter?: InputMaybe<ExerciseGradeFilterDTO>;
};


export type QueryExerciseTagsArgsDTO = {
  filter?: InputMaybe<ExerciseTagFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryExerciseTagsCountArgsDTO = {
  filter?: InputMaybe<ExerciseTagFilterDTO>;
};


export type QueryExercisesArgsDTO = {
  filter?: InputMaybe<ExerciseFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryExercisesCountArgsDTO = {
  filter?: InputMaybe<ExerciseFilterDTO>;
};


export type QueryForgotPupilDataRequestsArgsDTO = {
  filter?: InputMaybe<ForgotPupilDataRequestFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryForgotPupilDataRequestsCountArgsDTO = {
  filter?: InputMaybe<ForgotPupilDataRequestFilterDTO>;
};


export type QueryGameAttributePupilLevelLogsArgsDTO = {
  filter?: InputMaybe<GameAttributePupilLevelLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGameAttributePupilLevelLogsCountArgsDTO = {
  filter?: InputMaybe<GameAttributePupilLevelLogFilterDTO>;
};


export type QueryGameAttributePupilLevelsArgsDTO = {
  filter?: InputMaybe<GameAttributePupilLevelFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGameAttributePupilLevelsCountArgsDTO = {
  filter?: InputMaybe<GameAttributePupilLevelFilterDTO>;
};


export type QueryGameAttributePupilStatusLogsArgsDTO = {
  filter?: InputMaybe<GameAttributePupilStatusLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGameAttributePupilStatusLogsCountArgsDTO = {
  filter?: InputMaybe<GameAttributePupilStatusLogFilterDTO>;
};


export type QueryGameAttributePupilStatusesArgsDTO = {
  filter?: InputMaybe<GameAttributePupilStatusFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGameAttributePupilStatusesCountArgsDTO = {
  filter?: InputMaybe<GameAttributePupilStatusFilterDTO>;
};


export type QueryGameAttributesArgsDTO = {
  filter?: InputMaybe<GameAttributeFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGameAttributesCountArgsDTO = {
  filter?: InputMaybe<GameAttributeFilterDTO>;
};


export type QueryGameHighScoresArgsDTO = {
  filter?: InputMaybe<GameHighScoreFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGamePupilCoinLogsArgsDTO = {
  filter?: InputMaybe<GamePupilCoinLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGamePupilCoinLogsCountArgsDTO = {
  filter?: InputMaybe<GamePupilCoinLogFilterDTO>;
};


export type QueryGamePupilEnergyLogsArgsDTO = {
  filter?: InputMaybe<GamePupilEnergyLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGamePupilEnergyLogsCountArgsDTO = {
  filter?: InputMaybe<GamePupilEnergyLogFilterDTO>;
};


export type QueryGamePupilHighScoresArgsDTO = {
  filter?: InputMaybe<GamePupilHighScoreFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGamePupilHighScoresCountArgsDTO = {
  filter?: InputMaybe<GamePupilHighScoreFilterDTO>;
};


export type QueryGamePupilScoreLogsArgsDTO = {
  filter?: InputMaybe<GamePupilScoreLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGamePupilScoreLogsCountArgsDTO = {
  filter?: InputMaybe<GamePupilScoreLogFilterDTO>;
};


export type QueryGamePupilTotalArgsDTO = {
  filter?: InputMaybe<GamePupilTotalFilterDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGamePupilTotalsArgsDTO = {
  filter?: InputMaybe<GamePupilTotalFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGamePupilTotalsCountArgsDTO = {
  filter?: InputMaybe<GamePupilTotalFilterDTO>;
};


export type QueryGameScoreClassLeaderboardArgsDTO = {
  filter?: InputMaybe<GameScoreClassLeaderboardFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGameScoreClassLeaderboardCountArgsDTO = {
  filter?: InputMaybe<GameScoreClassLeaderboardFilterDTO>;
};


export type QueryGameScoreSchoolLeaderboardArgsDTO = {
  filter?: InputMaybe<GameScoreSchoolLeaderboardFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGameScoreSchoolLeaderboardCountArgsDTO = {
  filter?: InputMaybe<GameScoreSchoolLeaderboardFilterDTO>;
};


export type QueryGamesArgsDTO = {
  filter?: InputMaybe<GameFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryGamesCountArgsDTO = {
  filter?: InputMaybe<GameFilterDTO>;
};


export type QueryHazardousLocationReasonsArgsDTO = {
  filter?: InputMaybe<HazardousLocationReasonFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryHazardousLocationReasonsCountArgsDTO = {
  filter?: InputMaybe<HazardousLocationReasonFilterDTO>;
};


export type QueryHazardousLocationsArgsDTO = {
  filter?: InputMaybe<HazardousLocationFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryHazardousLocationsCountArgsDTO = {
  filter?: InputMaybe<HazardousLocationFilterDTO>;
};


export type QueryLastReportedDeviceInfosArgsDTO = {
  filter?: InputMaybe<LastReportedDeviceInfoFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryLastReportedDeviceInfosCountArgsDTO = {
  filter?: InputMaybe<LastReportedDeviceInfoFilterDTO>;
};


export type QueryLocationsArgsDTO = {
  filter?: InputMaybe<LocationFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryLocationsCountArgsDTO = {
  filter?: InputMaybe<LocationFilterDTO>;
};


export type QueryMailTemplatesArgsDTO = {
  filter?: InputMaybe<MailTemplateFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryMailTemplatesCountArgsDTO = {
  filter?: InputMaybe<MailTemplateFilterDTO>;
};


export type QueryModuleLocationLogsArgsDTO = {
  filter?: InputMaybe<ModuleLocationLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryModuleLocationLogsCountArgsDTO = {
  filter?: InputMaybe<ModuleLocationLogFilterDTO>;
};


export type QueryModuleReferenceLogsArgsDTO = {
  filter?: InputMaybe<ModuleReferenceLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryModuleReferenceLogsCountArgsDTO = {
  filter?: InputMaybe<ModuleReferenceLogFilterDTO>;
};


export type QueryModuleRegistrationTimeSeriesArgsDTO = {
  bucketWidth: Scalars['String'];
  filter?: InputMaybe<ModuleRegistrationFilterDTO>;
  from: Scalars['DateTime'];
  groupBy: ModuleRegistrationTimeSeriesGroupByDTO;
  to: Scalars['DateTime'];
};


export type QueryModuleRegistrationsArgsDTO = {
  filter?: InputMaybe<ModuleRegistrationFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryModuleRegistrationsCountArgsDTO = {
  filter?: InputMaybe<ModuleRegistrationFilterDTO>;
};


export type QueryModuleTotalsArgsDTO = {
  filter?: InputMaybe<ModuleTotalFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryModuleTotalsCountArgsDTO = {
  filter?: InputMaybe<ModuleTotalFilterDTO>;
};


export type QueryModulesArgsDTO = {
  filter?: InputMaybe<ModuleFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryModulesCountArgsDTO = {
  filter?: InputMaybe<ModuleFilterDTO>;
};


export type QueryMultipliersArgsDTO = {
  filter?: InputMaybe<MultiplierFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryMultipliersCountArgsDTO = {
  filter?: InputMaybe<MultiplierFilterDTO>;
};


export type QueryNotificationTypeChannelConfigurationsArgsDTO = {
  filter?: InputMaybe<NotificationTypeChannelConfigurationFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryNotificationTypeChannelConfigurationsCountArgsDTO = {
  filter?: InputMaybe<NotificationTypeChannelConfigurationFilterDTO>;
};


export type QueryPasswordResetsArgsDTO = {
  filter?: InputMaybe<PasswordResetFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPasswordResetsCountArgsDTO = {
  filter?: InputMaybe<PasswordResetFilterDTO>;
};


export type QueryPoliciesArgsDTO = {
  filter?: InputMaybe<PolicyFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPoliciesCountArgsDTO = {
  filter?: InputMaybe<PolicyFilterDTO>;
};


export type QueryProjectsArgsDTO = {
  filter?: InputMaybe<ProjectFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryProjectsCountArgsDTO = {
  filter?: InputMaybe<ProjectFilterDTO>;
};


export type QueryPupilAvatarElementsArgsDTO = {
  filter?: InputMaybe<AvatarElementFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilAvatarElementsCountArgsDTO = {
  filter?: InputMaybe<AvatarElementFilterDTO>;
};


export type QueryPupilCommunicationPreferencesArgsDTO = {
  filter?: InputMaybe<PupilCommunicationPreferenceFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilCommunicationPreferencesCountArgsDTO = {
  filter?: InputMaybe<PupilCommunicationPreferenceFilterDTO>;
};


export type QueryPupilCreditTimeSeriesArgsDTO = {
  bucketWidth: Scalars['String'];
  filter?: InputMaybe<PupilCreditFilterDTO>;
  from: Scalars['DateTime'];
  groupBy: PupilCreditTimeSeriesGroupByDTO;
  to: Scalars['DateTime'];
};


export type QueryPupilCreditsArgsDTO = {
  filter?: InputMaybe<PupilCreditFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilCreditsCountArgsDTO = {
  filter?: InputMaybe<PupilCreditFilterDTO>;
};


export type QueryPupilExerciseResultsArgsDTO = {
  filter?: InputMaybe<PupilExerciseResultFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilExerciseResultsCountArgsDTO = {
  filter?: InputMaybe<PupilExerciseResultFilterDTO>;
};


export type QueryPupilLoginLogsArgsDTO = {
  filter?: InputMaybe<PupilLoginLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilLoginLogsCountArgsDTO = {
  filter?: InputMaybe<PupilLoginLogFilterDTO>;
};


export type QueryPupilNotificationLogsArgsDTO = {
  filter?: InputMaybe<PupilNotificationLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilNotificationLogsCountArgsDTO = {
  filter?: InputMaybe<PupilNotificationLogFilterDTO>;
};


export type QueryPupilParentsArgsDTO = {
  filter?: InputMaybe<PupilParentFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilParentsCountArgsDTO = {
  filter?: InputMaybe<PupilParentFilterDTO>;
};


export type QueryPupilPasswordResetsArgsDTO = {
  filter?: InputMaybe<PupilPasswordResetFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilPasswordResetsCountArgsDTO = {
  filter?: InputMaybe<PupilPasswordResetFilterDTO>;
};


export type QueryPupilTotalsArgsDTO = {
  filter?: InputMaybe<PupilTotalFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilTotalsCountArgsDTO = {
  filter?: InputMaybe<PupilTotalFilterDTO>;
};


export type QueryPupilVisibilitiesArgsDTO = {
  filter?: InputMaybe<PupilVisibilityFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilVisibilitiesCountArgsDTO = {
  filter?: InputMaybe<PupilVisibilityFilterDTO>;
};


export type QueryPupilsArgsDTO = {
  filter?: InputMaybe<PupilFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryPupilsCountArgsDTO = {
  filter?: InputMaybe<PupilFilterDTO>;
};


export type QueryQuizAnswerRewardsArgsDTO = {
  filter?: InputMaybe<QuizAnswerRewardFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryQuizAnswerRewardsCountArgsDTO = {
  filter?: InputMaybe<QuizAnswerRewardFilterDTO>;
};


export type QueryQuizAnswersArgsDTO = {
  filter?: InputMaybe<QuizAnswerFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryQuizAnswersCountArgsDTO = {
  filter?: InputMaybe<QuizAnswerFilterDTO>;
};


export type QueryQuizPupilAnswerRewardStatusesArgsDTO = {
  filter?: InputMaybe<QuizPupilAnswerRewardStatusFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryQuizPupilAnswerRewardStatusesCountArgsDTO = {
  filter?: InputMaybe<QuizPupilAnswerRewardStatusFilterDTO>;
};


export type QueryQuizPupilAnswersArgsDTO = {
  filter?: InputMaybe<GameFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryQuizPupilAnswersCountArgsDTO = {
  filter?: InputMaybe<QuizPupilAnswerFilterDTO>;
};


export type QueryQuizPupilRewardStatusesArgsDTO = {
  filter?: InputMaybe<QuizPupilRewardStatusFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryQuizPupilRewardStatusesCountArgsDTO = {
  filter?: InputMaybe<QuizPupilRewardStatusFilterDTO>;
};


export type QueryQuizPupilStatusesArgsDTO = {
  filter?: InputMaybe<QuizPupilStatusFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryQuizPupilStatusesCountArgsDTO = {
  filter?: InputMaybe<QuizPupilStatusFilterDTO>;
};


export type QueryQuizQuestionsArgsDTO = {
  filter?: InputMaybe<QuizQuestionFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryQuizQuestionsCountArgsDTO = {
  filter?: InputMaybe<QuizQuestionFilterDTO>;
};


export type QueryQuizRewardsArgsDTO = {
  filter?: InputMaybe<QuizRewardFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryQuizRewardsCountArgsDTO = {
  filter?: InputMaybe<QuizRewardFilterDTO>;
};


export type QueryQuizzesArgsDTO = {
  filter?: InputMaybe<QuizFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryQuizzesCountArgsDTO = {
  filter?: InputMaybe<QuizFilterDTO>;
};


export type QueryRawModuleMessagesArgsDTO = {
  filter?: InputMaybe<RawModuleMessageFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryRawModuleMessagesCountArgsDTO = {
  filter?: InputMaybe<RawModuleMessageFilterDTO>;
};


export type QueryRegimesArgsDTO = {
  filter?: InputMaybe<RegimeFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryRegimesCountArgsDTO = {
  filter?: InputMaybe<RegimeFilterDTO>;
};


export type QueryRewardQuantitiesArgsDTO = {
  filter?: InputMaybe<RewardQuantityFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryRewardQuantitiesCountArgsDTO = {
  filter?: InputMaybe<RewardQuantityFilterDTO>;
};


export type QueryRewardRequestsArgsDTO = {
  filter?: InputMaybe<RewardRequestFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryRewardRequestsCountArgsDTO = {
  filter?: InputMaybe<RewardRequestFilterDTO>;
};


export type QueryRewardsArgsDTO = {
  filter?: InputMaybe<RewardFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryRewardsCountArgsDTO = {
  filter?: InputMaybe<RewardFilterDTO>;
};


export type QuerySchoolStatisticsArgsDTO = {
  filter?: InputMaybe<SchoolStatisticsFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QuerySchoolTotalsArgsDTO = {
  filter?: InputMaybe<SchoolTotalFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QuerySchoolTotalsCountArgsDTO = {
  filter?: InputMaybe<SchoolTotalFilterDTO>;
};


export type QuerySchoolTrophiesArgsDTO = {
  filter?: InputMaybe<SchoolTrophyFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QuerySchoolTrophiesCountArgsDTO = {
  filter?: InputMaybe<SchoolTrophyFilterDTO>;
};


export type QuerySchoolsArgsDTO = {
  filter?: InputMaybe<SchoolFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QuerySchoolsCountArgsDTO = {
  filter?: InputMaybe<SchoolFilterDTO>;
};


export type QuerySchoolyearsArgsDTO = {
  filter?: InputMaybe<SchoolyearFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QuerySchoolyearsCountArgsDTO = {
  filter?: InputMaybe<SchoolyearFilterDTO>;
};


export type QuerySearchArgsDTO = {
  filter?: InputMaybe<SearchFilterDTO>;
  search: Scalars['String'];
};


export type QueryShopItemAssignmentsArgsDTO = {
  filter?: InputMaybe<ShopItemAssignmentFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryShopItemGlobalResponsiblesArgsDTO = {
  filter?: InputMaybe<ShopItemGlobalResponsibleFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryShopItemGlobalResponsiblesCountArgsDTO = {
  filter?: InputMaybe<ShopItemGlobalResponsibleFilterDTO>;
};


export type QueryShopItemOrdersArgsDTO = {
  filter?: InputMaybe<ShopItemOrderFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryShopItemSpecificResponsiblesArgsDTO = {
  filter?: InputMaybe<ShopItemSpecificResponsibleFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryShopItemSpecificResponsiblesCountArgsDTO = {
  filter?: InputMaybe<ShopItemSpecificResponsibleFilterDTO>;
};


export type QueryShopItemStockLogsArgsDTO = {
  filter?: InputMaybe<ShopItemStockLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryShopItemStockLogsCountArgsDTO = {
  filter?: InputMaybe<ShopItemStockLogFilterDTO>;
};


export type QueryShopItemTypesArgsDTO = {
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryShopItemVoucherCodeTicketsArgsDTO = {
  filter?: InputMaybe<ShopItemVoucherCodeTicketFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryShopItemVoucherCodeTicketsCountArgsDTO = {
  filter?: InputMaybe<ShopItemVoucherCodeTicketFilterDTO>;
};


export type QueryShopItemVoucherCodesArgsDTO = {
  filter?: InputMaybe<ShopItemVoucherCodeFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryShopItemVoucherCodesCountArgsDTO = {
  filter?: InputMaybe<ShopItemVoucherCodeFilterDTO>;
};


export type QueryShopItemVouchersArgsDTO = {
  filter?: InputMaybe<ShopItemVoucherFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryShopItemVouchersCountArgsDTO = {
  filter?: InputMaybe<ShopItemVoucherFilterDTO>;
};


export type QueryShopItemsArgsDTO = {
  filter?: InputMaybe<ShopItemFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryUserCommunicationPreferencesArgsDTO = {
  filter?: InputMaybe<UserCommunicationPreferenceFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryUserCommunicationPreferencesCountArgsDTO = {
  filter?: InputMaybe<UserCommunicationPreferenceFilterDTO>;
};


export type QueryUserCountArgsDTO = {
  filter?: InputMaybe<UserFilterDTO>;
};


export type QueryUserLoginLogsArgsDTO = {
  filter?: InputMaybe<UserLoginLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryUserLoginLogsCountArgsDTO = {
  filter?: InputMaybe<UserLoginLogFilterDTO>;
};


export type QueryUserNotificationLogsArgsDTO = {
  filter?: InputMaybe<UserNotificationLogFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryUserNotificationLogsCountArgsDTO = {
  filter?: InputMaybe<UserNotificationLogFilterDTO>;
};


export type QueryUserResourceAccessesArgsDTO = {
  filter?: InputMaybe<UserResourceAccessFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryUserResourceAccessesCountArgsDTO = {
  filter?: InputMaybe<UserResourceAccessFilterDTO>;
};


export type QueryUserRolesArgsDTO = {
  filter?: InputMaybe<UserRoleFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryUserRolesCountArgsDTO = {
  filter?: InputMaybe<UserRoleFilterDTO>;
};


export type QueryUsernamesArgsDTO = {
  filter?: InputMaybe<UsernameFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryUsernamesCountArgsDTO = {
  filter?: InputMaybe<UsernameFilterDTO>;
};


export type QueryUsersArgsDTO = {
  filter?: InputMaybe<UserFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryZonesArgsDTO = {
  filter?: InputMaybe<ZoneFilterDTO>;
  paging?: InputMaybe<PagingDTO>;
  sort?: InputMaybe<Array<InputMaybe<SortDTO>>>;
};


export type QueryZonesCountArgsDTO = {
  filter?: InputMaybe<ZoneFilterDTO>;
};

export type QuizDTO = {
  __typename?: 'Quiz';
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<AssetDTO>;
  questions: Array<QuizQuestionDTO>;
  rewards: Array<QuizRewardDTO>;
  tags: Array<ContentTagDTO>;
  title: Scalars['String'];
};

export type QuizAnswerDTO = {
  __typename?: 'QuizAnswer';
  answer?: Maybe<Scalars['String']>;
  correct?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<AssetDTO>;
  order?: Maybe<Scalars['Int']>;
  question: QuizQuestionDTO;
  rewards: Array<QuizAnswerRewardDTO>;
};

export type QuizAnswerCreateInputDTO = {
  answers?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  correct: Scalars['Boolean'];
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  imageId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  quizQuestionId: Scalars['String'];
  rewards?: InputMaybe<Array<QuizAnswerQuizRewardCreateInputDTO>>;
};

export type QuizAnswerFilterDTO = {
  AND?: InputMaybe<Array<QuizAnswerFilterDTO>>;
  OR?: InputMaybe<Array<QuizAnswerFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  quizQuestion?: InputMaybe<QuizQuestionFilterDTO>;
};

export type QuizAnswerQuizRewardCreateInputDTO = {
  avatarElementId?: InputMaybe<Scalars['String']>;
  coins?: InputMaybe<Scalars['Int']>;
  contentItemId?: InputMaybe<Scalars['String']>;
  type: QuizAnswerRewardTypeDTO;
};

export type QuizAnswerRewardDTO = {
  __typename?: 'QuizAnswerReward';
  avatarElement?: Maybe<AvatarElementDTO>;
  coins?: Maybe<Scalars['Int']>;
  contentItem?: Maybe<ContentItemDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  quizAnswer: QuizAnswerDTO;
  type: QuizAnswerRewardTypeDTO;
};

export type QuizAnswerRewardCreateInputDTO = {
  avatarElementId?: InputMaybe<Scalars['String']>;
  coins?: InputMaybe<Scalars['Int']>;
  contentItemId?: InputMaybe<Scalars['String']>;
  quizAnswerId: Scalars['String'];
  type: QuizAnswerRewardTypeDTO;
};

export type QuizAnswerRewardFilterDTO = {
  AND?: InputMaybe<Array<QuizAnswerRewardFilterDTO>>;
  OR?: InputMaybe<Array<QuizAnswerRewardFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  quizAnswer?: InputMaybe<QuizAnswerFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export enum QuizAnswerRewardTypeDTO {
  GiftDTO = 'GIFT',
  UnlockableDTO = 'UNLOCKABLE'
}

export type QuizAnswerRewardUpdateInputDTO = {
  avatarElementId?: InputMaybe<Scalars['String']>;
  coins?: InputMaybe<Scalars['Int']>;
  contentItemId?: InputMaybe<Scalars['String']>;
  quizAnswerId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<QuizAnswerRewardTypeDTO>;
};

export type QuizAnswerUpdateInputDTO = {
  answers?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  correct?: InputMaybe<Scalars['Boolean']>;
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  imageId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  quizQuestionId?: InputMaybe<Scalars['String']>;
};

export type QuizCreateInputDTO = {
  descriptions: Array<MultiLanguageTextInputDTO>;
  imageId?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<QuizQuizQuestionCreateInputDTO>>;
  rewards?: InputMaybe<Array<QuizQuizRewardCreateInputDTO>>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  titles: Array<MultiLanguageTextInputDTO>;
};

export type QuizFilterDTO = {
  AND?: InputMaybe<Array<QuizFilterDTO>>;
  OR?: InputMaybe<Array<QuizFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  description?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  tag?: InputMaybe<ContentTagFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type QuizPupilAnswerDTO = {
  __typename?: 'QuizPupilAnswer';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  pupil: PupilDTO;
  quizAnswer: QuizAnswerDTO;
  quizQuestion: QuizQuestionDTO;
};

export type QuizPupilAnswerFilterDTO = {
  AND?: InputMaybe<Array<QuizPupilAnswerFilterDTO>>;
  OR?: InputMaybe<Array<QuizPupilAnswerFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
  quizAnswer?: InputMaybe<QuizAnswerFilterDTO>;
  quizQuestion?: InputMaybe<QuizQuestionFilterDTO>;
};

export type QuizPupilAnswerRewardStatusDTO = {
  __typename?: 'QuizPupilAnswerRewardStatus';
  createdAt?: Maybe<Scalars['DateTime']>;
  gifted: Scalars['Boolean'];
  id: Scalars['String'];
  quizAnswerReward: QuizAnswerRewardDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type QuizPupilAnswerRewardStatusFilterDTO = {
  AND?: InputMaybe<Array<QuizPupilAnswerRewardStatusFilterDTO>>;
  OR?: InputMaybe<Array<QuizPupilAnswerRewardStatusFilterDTO>>;
  gifted?: InputMaybe<BooleanFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
  quizAnswerReward?: InputMaybe<QuizAnswerRewardFilterDTO>;
};

export type QuizPupilRewardStatusDTO = {
  __typename?: 'QuizPupilRewardStatus';
  createdAt: Scalars['DateTime'];
  gifted: Scalars['Boolean'];
  id: Scalars['String'];
  pupil: PupilDTO;
  quizReward: QuizRewardDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type QuizPupilRewardStatusFilterDTO = {
  AND?: InputMaybe<Array<QuizPupilRewardStatusFilterDTO>>;
  OR?: InputMaybe<Array<QuizPupilRewardStatusFilterDTO>>;
  gifted?: InputMaybe<BooleanFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
  quizReward?: InputMaybe<QuizRewardFilterDTO>;
};

export type QuizPupilStatusDTO = {
  __typename?: 'QuizPupilStatus';
  createdAt: Scalars['DateTime'];
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  pupil?: Maybe<PupilDTO>;
  quiz: QuizDTO;
  startedAt?: Maybe<Scalars['DateTime']>;
};

export type QuizPupilStatusFilterDTO = {
  AND?: InputMaybe<Array<QuizPupilStatusFilterDTO>>;
  OR?: InputMaybe<Array<QuizPupilStatusFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  endedAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  pupilId?: InputMaybe<StringFilterDTO>;
  quiz?: InputMaybe<QuizFilterDTO>;
  startedAt?: InputMaybe<DateTimeFilterDTO>;
};

export type QuizQuestionDTO = {
  __typename?: 'QuizQuestion';
  answers: Array<QuizAnswerDTO>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<AssetDTO>;
  order?: Maybe<Scalars['Int']>;
  pupilAnswer?: Maybe<QuizPupilAnswerDTO>;
  question: Scalars['String'];
  quiz: QuizDTO;
  type: QuizQuestionTypeDTO;
};

export type QuizQuestionCreateInputDTO = {
  answers?: InputMaybe<Array<QuizQuestionQuizAnswerCreateInputDTO>>;
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  imageId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  questions: Array<MultiLanguageTextInputDTO>;
  quizId: Scalars['String'];
  type: QuizQuestionTypeDTO;
};

export type QuizQuestionFilterDTO = {
  AND?: InputMaybe<Array<QuizQuestionFilterDTO>>;
  OR?: InputMaybe<Array<QuizQuestionFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  description?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  order?: InputMaybe<IntFilterDTO>;
  question?: InputMaybe<StringFilterDTO>;
  quiz?: InputMaybe<QuizFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export type QuizQuestionQuizAnswerCreateInputDTO = {
  answers?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  correct: Scalars['Boolean'];
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  imageId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  rewards?: InputMaybe<Array<QuizAnswerQuizRewardCreateInputDTO>>;
};

export enum QuizQuestionTypeDTO {
  ImageDTO = 'IMAGE',
  TextDTO = 'TEXT'
}

export type QuizQuestionUpdateInputDTO = {
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  imageId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  questions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  quizId?: InputMaybe<Scalars['String']>;
};

export type QuizQuizQuestionCreateInputDTO = {
  answers?: InputMaybe<Array<QuizQuestionQuizAnswerCreateInputDTO>>;
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  imageId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  questions: Array<MultiLanguageTextInputDTO>;
  type: QuizQuestionTypeDTO;
};

export type QuizQuizRewardCreateInputDTO = {
  avatarElementId?: InputMaybe<Scalars['String']>;
  coins?: InputMaybe<Scalars['Int']>;
  contentItemId?: InputMaybe<Scalars['String']>;
  type: QuizRewardTypeDTO;
};

export type QuizRewardDTO = {
  __typename?: 'QuizReward';
  avatarElement?: Maybe<AvatarElementDTO>;
  coins?: Maybe<Scalars['Int']>;
  contentItem?: Maybe<ContentItemDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  quiz: QuizDTO;
  type: QuizRewardTypeDTO;
};

export type QuizRewardCreateInputDTO = {
  avatarElementId?: InputMaybe<Scalars['String']>;
  coins?: InputMaybe<Scalars['Int']>;
  contentItemId?: InputMaybe<Scalars['String']>;
  quizId: Scalars['String'];
  type: QuizRewardTypeDTO;
};

export type QuizRewardFilterDTO = {
  AND?: InputMaybe<Array<QuizRewardFilterDTO>>;
  OR?: InputMaybe<Array<QuizRewardFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  quiz?: InputMaybe<QuizFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export enum QuizRewardTypeDTO {
  GiftDTO = 'GIFT',
  UnlockableDTO = 'UNLOCKABLE'
}

export type QuizRewardUpdateInputDTO = {
  avatarElementId?: InputMaybe<Scalars['String']>;
  coins?: InputMaybe<Scalars['Int']>;
  contentItemId?: InputMaybe<Scalars['String']>;
  quizId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<QuizRewardTypeDTO>;
};

export type QuizUpdateInputDTO = {
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  imageId?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
};

export type RawModuleMessageDTO = {
  __typename?: 'RawModuleMessage';
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  deviceId: Scalars['String'];
  id: Scalars['String'];
  receivedAt: Scalars['DateTime'];
};

export type RawModuleMessageDataFilterDTO = {
  nfcId?: InputMaybe<StringFilterDTO>;
};

export type RawModuleMessageFilterDTO = {
  AND?: InputMaybe<Array<RawModuleMessageFilterDTO>>;
  OR?: InputMaybe<Array<RawModuleMessageFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  data?: InputMaybe<RawModuleMessageDataFilterDTO>;
  deviceId?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  receivedAt?: InputMaybe<DateTimeFilterDTO>;
};

export type RegimeDTO = {
  __typename?: 'Regime';
  days: Array<RegimeDayDTO>;
  id: Scalars['String'];
  inactivities: Array<RegimeInactivityDTO>;
  overrideTemplateDays?: Maybe<Scalars['Boolean']>;
  project?: Maybe<ProjectDTO>;
  template?: Maybe<RegimeDTO>;
  title: Scalars['String'];
  type: RegimeTypeDTO;
};

export type RegimeCreateInputDTO = {
  days: Array<RegimeDayInputDTO>;
  inactivities: Array<RegimeInactivityInputDTO>;
  overrideTemplateDays?: InputMaybe<Scalars['Boolean']>;
  project?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type RegimeDayDTO = {
  __typename?: 'RegimeDay';
  hours: Array<RegimeHourDTO>;
  id: Scalars['String'];
  weekDay: Scalars['Int'];
};

export type RegimeDayInputDTO = {
  hours: Array<RegimeHourInputDTO>;
  id?: InputMaybe<Scalars['String']>;
  weekDay: Scalars['Int'];
};

export type RegimeFilterDTO = {
  AND?: InputMaybe<Array<RegimeFilterDTO>>;
  OR?: InputMaybe<Array<RegimeFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  project?: InputMaybe<BasicStringFilterDTO>;
  template?: InputMaybe<BasicStringFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<RegimeTypeFilterDTO>;
};

export type RegimeHourDTO = {
  __typename?: 'RegimeHour';
  fromHours: Scalars['Int'];
  id: Scalars['String'];
  toHours: Scalars['Int'];
};

export type RegimeHourInputDTO = {
  fromHours: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  toHours: Scalars['Int'];
};

export type RegimeInactivityDTO = {
  __typename?: 'RegimeInactivity';
  appliedForAllSchools: Scalars['Boolean'];
  from: Scalars['DateTime'];
  id: Scalars['String'];
  schools?: Maybe<Array<SchoolDTO>>;
  title: Scalars['String'];
  to: Scalars['DateTime'];
};

export type RegimeInactivityInputDTO = {
  appliedForAllSchools?: InputMaybe<Scalars['Boolean']>;
  from: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  to: Scalars['DateTime'];
};

export enum RegimeTypeDTO {
  FromTemplateDTO = 'FROM_TEMPLATE',
  GlobalDTO = 'GLOBAL'
}

export type RegimeTypeFilterDTO = {
  equals?: InputMaybe<RegimeTypeDTO>;
  in?: InputMaybe<Array<InputMaybe<RegimeTypeDTO>>>;
  not?: InputMaybe<RegimeTypeDTO>;
  notIn?: InputMaybe<Array<InputMaybe<RegimeTypeDTO>>>;
};

export type RegimeUpdateInputDTO = {
  days?: InputMaybe<Array<RegimeDayInputDTO>>;
  inactivities?: InputMaybe<Array<RegimeInactivityInputDTO>>;
  overrideTemplateDays?: InputMaybe<Scalars['Boolean']>;
  template?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type RewardDTO = {
  __typename?: 'Reward';
  availableFrom: Scalars['DateTime'];
  availableTo: Scalars['DateTime'];
  collectInstructions: Scalars['String'];
  id: Scalars['String'];
  image: AssetDTO;
  isRewardedMultipleTimes: Scalars['Boolean'];
  rewardAssignments: Array<RewardAssignmentDTO>;
  rewardQuantities: Array<RewardQuantityDTO>;
  rewardRequests?: Maybe<Array<Maybe<RewardRequestDTO>>>;
  rewardRequestsCount: Scalars['Int'];
  schoolSpecific: Scalars['Boolean'];
  schoolyear: SchoolyearDTO;
  title: Scalars['String'];
  valueInCredits: Scalars['Int'];
};


export type RewardRewardRequestsArgsDTO = {
  filter?: InputMaybe<RewardRequestReferenceFilterDTO>;
};


export type RewardRewardRequestsCountArgsDTO = {
  filter?: InputMaybe<RewardRequestReferenceFilterDTO>;
};

export type RewardAssignmentDTO = {
  __typename?: 'RewardAssignment';
  amount: Scalars['Int'];
  collectInstructions?: Maybe<Scalars['String']>;
  email: Array<Scalars['String']>;
  id: Scalars['String'];
  isRewardedMultipleTimes?: Maybe<Scalars['Boolean']>;
  overrideCollectInstructions: Scalars['Boolean'];
  overrideRewardedMultipleTimes: Scalars['Boolean'];
  overrideValueInCredits: Scalars['Boolean'];
  reward: RewardDTO;
  school: SchoolDTO;
  userId: Array<Scalars['String']>;
  valueInCredits?: Maybe<Scalars['Int']>;
};

export type RewardAssignmentCreateInputDTO = {
  amount: Scalars['Int'];
  collectInstructions?: InputMaybe<Scalars['String']>;
  email: Array<Scalars['String']>;
  isRewardedMultipleTimes?: InputMaybe<Scalars['Boolean']>;
  overrideCollectInstructions: Scalars['Boolean'];
  overrideRewardedMultipleTimes: Scalars['Boolean'];
  overrideValueInCredits: Scalars['Boolean'];
  school: Scalars['String'];
  userId: Array<Scalars['String']>;
  valueInCredits?: InputMaybe<Scalars['Int']>;
};

export type RewardCreateInputDTO = {
  availableFrom: Scalars['DateTime'];
  availableTo: Scalars['DateTime'];
  collectInstructions: Scalars['String'];
  image: Scalars['String'];
  isRewardedMultipleTimes: Scalars['Boolean'];
  rewardAssignments?: InputMaybe<Array<InputMaybe<RewardAssignmentCreateInputDTO>>>;
  schoolyear: Scalars['String'];
  title: Scalars['String'];
  valueInCredits: Scalars['Int'];
};

export type RewardFilterDTO = {
  AND?: InputMaybe<Array<RewardFilterDTO>>;
  OR?: InputMaybe<Array<RewardFilterDTO>>;
  availableFrom?: InputMaybe<DateTimeFilterDTO>;
  availableTo?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  school?: InputMaybe<BasicStringFilterDTO>;
  schoolSpecific?: InputMaybe<BooleanFilterDTO>;
  schoolyear?: InputMaybe<SchoolyearFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type RewardQuantityDTO = {
  __typename?: 'RewardQuantity';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  reward: RewardDTO;
  school: SchoolDTO;
  type: RewardQuantityTypeDTO;
};

export type RewardQuantityChangeCreateInputDTO = {
  amount: Scalars['Int'];
  type: RewardQuantityChangeTypeDTO;
};

export enum RewardQuantityChangeTypeDTO {
  AdditionalOrderDTO = 'ADDITIONAL_ORDER',
  RecountDTO = 'RECOUNT'
}

export type RewardQuantityCreateInputDTO = {
  amount: Scalars['Int'];
  reward: Scalars['String'];
  school: Scalars['String'];
  type: RewardQuantityTypeDTO;
};

export type RewardQuantityFilterDTO = {
  AND?: InputMaybe<Array<RewardQuantityFilterDTO>>;
  OR?: InputMaybe<Array<RewardQuantityFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  reward?: InputMaybe<BasicStringFilterDTO>;
  school?: InputMaybe<BasicStringFilterDTO>;
};

export enum RewardQuantityTypeDTO {
  AdditionalOrderDTO = 'ADDITIONAL_ORDER',
  InitialDTO = 'INITIAL',
  RecountDTO = 'RECOUNT'
}

export type RewardQuantityUpdateInputDTO = {
  amount?: InputMaybe<Scalars['Int']>;
};

export type RewardRequestDTO = {
  __typename?: 'RewardRequest';
  id: Scalars['String'];
  issuedBy?: Maybe<Scalars['String']>;
  issuedByName?: Maybe<Scalars['String']>;
  pupil: PupilDTO;
  receivedAt?: Maybe<Scalars['DateTime']>;
  requestedAt: Scalars['DateTime'];
  reward: RewardDTO;
};

export type RewardRequestFilterDTO = {
  AND?: InputMaybe<Array<RewardRequestFilterDTO>>;
  OR?: InputMaybe<Array<RewardRequestFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  receivedAt?: InputMaybe<DateTimeFilterDTO>;
  reward?: InputMaybe<BasicStringFilterDTO>;
};

export type RewardRequestReferenceFilterDTO = {
  AND?: InputMaybe<Array<RewardRequestReferenceFilterDTO>>;
  OR?: InputMaybe<Array<RewardRequestReferenceFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  pupil?: InputMaybe<PupilReferenceFilterDTO>;
  receivedAt?: InputMaybe<DateTimeFilterDTO>;
  reward?: InputMaybe<BasicStringFilterDTO>;
};

export type RewardSearchItemDTO = SearchItemDTO & {
  __typename?: 'RewardSearchItem';
  reward: RewardDTO;
  type: SearchTypeDTO;
};

export type RewardUpdateInputDTO = {
  availableFrom?: InputMaybe<Scalars['DateTime']>;
  availableTo?: InputMaybe<Scalars['DateTime']>;
  collectInstructions?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  isRewardedMultipleTimes?: InputMaybe<Scalars['Boolean']>;
  rewardAssignments?: InputMaybe<Array<InputMaybe<RewardAssignmentCreateInputDTO>>>;
  title?: InputMaybe<Scalars['String']>;
  valueInCredits?: InputMaybe<Scalars['Int']>;
};

export enum RoleDTO {
  AdminDTO = 'ADMIN',
  InstallerDTO = 'INSTALLER',
  ParentDTO = 'PARENT',
  PrincipalDTO = 'PRINCIPAL',
  ProjectManagerDTO = 'PROJECT_MANAGER',
  TeacherDTO = 'TEACHER'
}

export type SchoolDTO = {
  __typename?: 'School';
  bikeRegistrationsEnabled: Scalars['Boolean'];
  classNumberEnabled: Scalars['Boolean'];
  classes: Array<ClassDTO>;
  code?: Maybe<Scalars['String']>;
  fluoRegistrationsEnabled: Scalars['Boolean'];
  highFiveRegistrationsEnabled: Scalars['Boolean'];
  id: Scalars['String'];
  location: LocationDTO;
  logo?: Maybe<AssetDTO>;
  moduleRegistrationsCount: Scalars['Int'];
  pupilsCount: Scalars['Int'];
  radius: Scalars['Int'];
  schoolTotal: SchoolTotalDTO;
  schoolyear: SchoolyearDTO;
  title: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  zonesEnabled: Scalars['Boolean'];
};


export type SchoolModuleRegistrationsCountArgsDTO = {
  filter?: InputMaybe<ModuleRegistrationReferenceFilterDTO>;
};

export type SchoolCreateInputDTO = {
  bikeRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  classNumberEnabled?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  fluoRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  location: LocationCreateInputDTO;
  logo?: InputMaybe<Scalars['String']>;
  radius: Scalars['Int'];
  schoolyear: Scalars['String'];
  title: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
  zonesEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolFilterDTO = {
  AND?: InputMaybe<Array<SchoolFilterDTO>>;
  OR?: InputMaybe<Array<SchoolFilterDTO>>;
  code?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  schoolyear?: InputMaybe<SchoolyearFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type SchoolReferenceFilterDTO = {
  AND?: InputMaybe<Array<SchoolReferenceFilterDTO>>;
  OR?: InputMaybe<Array<SchoolReferenceFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  schoolyear?: InputMaybe<SchoolyearReferenceFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type SchoolSearchItemDTO = SearchItemDTO & {
  __typename?: 'SchoolSearchItem';
  school: SchoolDTO;
  type: SearchTypeDTO;
};

export type SchoolStatisticsDTO = {
  __typename?: 'SchoolStatistics';
  pupilParticipationRate: Scalars['Float'];
  school: SchoolDTO;
};

export type SchoolStatisticsFilterDTO = {
  AND?: InputMaybe<Array<SchoolStatisticsFilterDTO>>;
  OR?: InputMaybe<Array<SchoolStatisticsFilterDTO>>;
  school?: InputMaybe<SchoolFilterDTO>;
};

export type SchoolTotalDTO = {
  __typename?: 'SchoolTotal';
  id: Scalars['String'];
  school: SchoolDTO;
  totalCO2: Scalars['Float'];
  totalCredits: Scalars['Int'];
  totalDistance: Scalars['Float'];
  totalFuel: Scalars['Float'];
  totalRegistrations: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type SchoolTotalFilterDTO = {
  AND?: InputMaybe<Array<SchoolTotalFilterDTO>>;
  OR?: InputMaybe<Array<SchoolTotalFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
  totalCredits?: InputMaybe<IntFilterDTO>;
  totalDistance?: InputMaybe<IntFilterDTO>;
  totalRegistrations?: InputMaybe<IntFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilterDTO>;
};

export type SchoolTrophyDTO = {
  __typename?: 'SchoolTrophy';
  endsAt: Scalars['DateTime'];
  id: Scalars['String'];
  school: SchoolDTO;
  startsAt: Scalars['DateTime'];
  statistics: Array<SchoolTrophyStatisticsDTO>;
  title: Scalars['String'];
};

export type SchoolTrophyCreateInputDTO = {
  endsAt: Scalars['DateTime'];
  school: Scalars['String'];
  startsAt: Scalars['DateTime'];
  title: Scalars['String'];
};

export type SchoolTrophyFilterDTO = {
  AND?: InputMaybe<Array<SchoolTrophyFilterDTO>>;
  OR?: InputMaybe<Array<SchoolTrophyFilterDTO>>;
  endsAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
  startsAt?: InputMaybe<DateTimeFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type SchoolTrophyStatisticsDTO = {
  __typename?: 'SchoolTrophyStatistics';
  averageModuleRegistrations: Scalars['Float'];
  class: ClassDTO;
};

export type SchoolTrophyUpdateInputDTO = {
  endsAt?: InputMaybe<Scalars['DateTime']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SchoolUpdateInputDTO = {
  bikeRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  classNumberEnabled?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  fluoRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<LocationCreateInputDTO>;
  logo?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<Scalars['Int']>;
  schoolyear?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zonesEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolyearDTO = {
  __typename?: 'Schoolyear';
  active: Scalars['Boolean'];
  contentBlocks?: Maybe<Array<Maybe<ContentBlockDTO>>>;
  endsAt: Scalars['DateTime'];
  id: Scalars['String'];
  project: ProjectDTO;
  projectGoal: Scalars['Int'];
  pupilGoal: Scalars['Int'];
  schools: Array<SchoolDTO>;
  schoolyearTotal: SchoolyearTotalDTO;
  startsAt: Scalars['DateTime'];
  title: Scalars['String'];
};

export type SchoolyearCreateInputDTO = {
  endsAt: Scalars['DateTime'];
  project: Scalars['String'];
  projectGoal: Scalars['Int'];
  pupilGoal: Scalars['Int'];
  startsAt: Scalars['DateTime'];
  title: Scalars['String'];
};

export type SchoolyearFilterDTO = {
  AND?: InputMaybe<Array<SchoolyearFilterDTO>>;
  OR?: InputMaybe<Array<SchoolyearFilterDTO>>;
  active?: InputMaybe<BooleanFilterDTO>;
  endsAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  project?: InputMaybe<BasicStringFilterDTO>;
  startsAt?: InputMaybe<DateTimeFilterDTO>;
};

export type SchoolyearReferenceFilterDTO = {
  AND?: InputMaybe<Array<SchoolyearReferenceFilterDTO>>;
  OR?: InputMaybe<Array<SchoolyearReferenceFilterDTO>>;
  active?: InputMaybe<BooleanFilterDTO>;
  endsAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  project?: InputMaybe<BasicStringFilterDTO>;
  startsAt?: InputMaybe<DateTimeFilterDTO>;
};

export type SchoolyearTotalDTO = {
  __typename?: 'SchoolyearTotal';
  averageFuelPerPupilPerMonth: Scalars['Float'];
  id: Scalars['String'];
  schoolyear: SchoolyearDTO;
  totalCO2: Scalars['Float'];
  totalCredits: Scalars['Int'];
  totalDistance: Scalars['Float'];
  totalFuel: Scalars['Float'];
  totalRegistrations: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type SchoolyearUpdateInputDTO = {
  endsAt?: InputMaybe<Scalars['DateTime']>;
  project?: InputMaybe<Scalars['String']>;
  projectGoal?: InputMaybe<Scalars['Int']>;
  pupilGoal?: InputMaybe<Scalars['Int']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SearchFilterDTO = {
  types?: InputMaybe<Array<SearchTypeDTO>>;
};

export type SearchItemDTO = {
  type: SearchTypeDTO;
};

export type SearchResultDTO = {
  __typename?: 'SearchResult';
  countByType: Array<SearchResultCountByTypeDTO>;
  items: Array<SearchItemDTO>;
};

export type SearchResultCountByTypeDTO = {
  __typename?: 'SearchResultCountByType';
  count: Scalars['Int'];
  type: SearchTypeDTO;
};

export enum SearchTypeDTO {
  ChallengeDTO = 'CHALLENGE',
  ClassDTO = 'CLASS',
  ExerciseDTO = 'EXERCISE',
  PupilDTO = 'PUPIL',
  RewardDTO = 'REWARD',
  SchoolDTO = 'SCHOOL'
}

export type SetPupilAvatarElementInputDTO = {
  color?: InputMaybe<Scalars['String']>;
  elementId: Scalars['String'];
  type: Scalars['String'];
};

export type ShopItemDTO = {
  __typename?: 'ShopItem';
  assignments: Array<ShopItemAssignmentDTO>;
  availableStock: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deliveredStock: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image: AssetDTO;
  instructions?: Maybe<Scalars['String']>;
  openRequestCount: Scalars['Int'];
  orderRestrictions: ShopItemOrderRestrictionsDTO;
  physicalStock?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  publishOn?: Maybe<Scalars['DateTime']>;
  publishUntil?: Maybe<Scalars['DateTime']>;
  purchasedAmount?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type: ShopItemTypeDTO;
};

export type ShopItemAssignmentDTO = {
  __typename?: 'ShopItemAssignment';
  availableStock?: Maybe<Scalars['Int']>;
  class?: Maybe<ClassDTO>;
  createdAt: Scalars['DateTime'];
  deliveredStock: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<AssetDTO>;
  instructions?: Maybe<Scalars['String']>;
  openRequestCount: Scalars['Int'];
  orderRestrictions?: Maybe<ShopItemOrderRestrictionsDTO>;
  physicalStock?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  project: ProjectDTO;
  publishOn?: Maybe<Scalars['DateTime']>;
  publishUntil?: Maybe<Scalars['DateTime']>;
  purchasedAmount?: Maybe<Scalars['Int']>;
  school?: Maybe<SchoolDTO>;
  shopItem: ShopItemDTO;
  title?: Maybe<Scalars['String']>;
};

export type ShopItemAssignmentCreateInputDTO = {
  availableStock?: InputMaybe<Scalars['Int']>;
  classId?: InputMaybe<Scalars['String']>;
  deliveredStock?: InputMaybe<Scalars['Int']>;
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  image?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  openRequestCount?: InputMaybe<Scalars['Int']>;
  orderRestrictions?: InputMaybe<ShopItemOrderRestrictionsInputDTO>;
  physicalStock?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['String'];
  publishOn?: InputMaybe<Scalars['DateTime']>;
  publishUntil?: InputMaybe<Scalars['DateTime']>;
  purchasedAmount?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  shopItemId: Scalars['String'];
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
};

export type ShopItemAssignmentFilterDTO = {
  AND?: InputMaybe<Array<ShopItemFilterDTO>>;
  OR?: InputMaybe<Array<ShopItemFilterDTO>>;
  class?: InputMaybe<ClassFilterDTO>;
  description?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  instructions?: InputMaybe<StringFilterDTO>;
  price?: InputMaybe<IntFilterDTO>;
  project?: InputMaybe<ProjectFilterDTO>;
  publishOn?: InputMaybe<DateTimeFilterDTO>;
  publishUntil?: InputMaybe<DateTimeFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
  shopItem?: InputMaybe<StringFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type ShopItemAssignmentUpdateInputDTO = {
  availableStock?: InputMaybe<Scalars['Int']>;
  classId?: InputMaybe<Scalars['String']>;
  deliveredStock?: InputMaybe<Scalars['Int']>;
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  image?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  openRequestCount?: InputMaybe<Scalars['Int']>;
  orderRestrictions?: InputMaybe<ShopItemOrderRestrictionsInputDTO>;
  physicalStock?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['String']>;
  publishOn?: InputMaybe<Scalars['DateTime']>;
  publishUntil?: InputMaybe<Scalars['DateTime']>;
  purchasedAmount?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  shopItemId?: InputMaybe<Scalars['String']>;
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
};

export type ShopItemCreateInputDTO = {
  availableStock: Scalars['Int'];
  deliveredStock: Scalars['Int'];
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  image: Scalars['String'];
  instructions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  openRequestCount?: InputMaybe<Scalars['Int']>;
  orderRestrictions: ShopItemOrderRestrictionsInputDTO;
  physicalStock?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  publishOn?: InputMaybe<Scalars['DateTime']>;
  publishUntil?: InputMaybe<Scalars['DateTime']>;
  purchasedAmount?: InputMaybe<Scalars['Int']>;
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  type: Scalars['String'];
};

export type ShopItemFilterDTO = {
  AND?: InputMaybe<Array<ShopItemFilterDTO>>;
  OR?: InputMaybe<Array<ShopItemFilterDTO>>;
  class?: InputMaybe<ClassFilterDTO>;
  description?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  instructions?: InputMaybe<StringFilterDTO>;
  price?: InputMaybe<IntFilterDTO>;
  project?: InputMaybe<ProjectFilterDTO>;
  publishOn?: InputMaybe<DateTimeFilterDTO>;
  publishUntil?: InputMaybe<DateTimeFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
};

export type ShopItemGlobalResponsibleDTO = {
  __typename?: 'ShopItemGlobalResponsible';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  school: SchoolDTO;
};

export type ShopItemGlobalResponsibleCreateInputDTO = {
  email: Scalars['String'];
  schoolId: Scalars['String'];
};

export type ShopItemGlobalResponsibleFilterDTO = {
  AND?: InputMaybe<Array<ShopItemGlobalResponsibleFilterDTO>>;
  OR?: InputMaybe<Array<ShopItemGlobalResponsibleFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  email?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
};

export type ShopItemGlobalResponsibleUpdateInputDTO = {
  email?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type ShopItemOrderDTO = {
  __typename?: 'ShopItemOrder';
  canceledAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<ShopItemVoucherCodeDTO>;
  createdAt: Scalars['DateTime'];
  deliveredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  orderedAt: Scalars['DateTime'];
  price?: Maybe<Scalars['Int']>;
  pupil?: Maybe<PupilDTO>;
  shopItem?: Maybe<AssignedShopItemDTO>;
  voucher?: Maybe<ShopItemVoucherDTO>;
};

export type ShopItemOrderFilterDTO = {
  AND?: InputMaybe<Array<ShopItemOrderFilterDTO>>;
  OR?: InputMaybe<Array<ShopItemOrderFilterDTO>>;
  assignedShopItemId?: InputMaybe<StringFilterDTO>;
  canceledAt?: InputMaybe<DateTimeFilterDTO>;
  class?: InputMaybe<ClassFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  deliveredAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  orderedAt?: InputMaybe<DateTimeFilterDTO>;
  price?: InputMaybe<IntFilterDTO>;
  project?: InputMaybe<ProjectFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
  shopItem?: InputMaybe<ShopItemFilterDTO>;
  shopItemAssignment?: InputMaybe<ShopItemAssignmentFilterDTO>;
};

export type ShopItemOrderRestrictionsDTO = {
  __typename?: 'ShopItemOrderRestrictions';
  enableMaxItemsPerPerson: Scalars['Boolean'];
  id: Scalars['String'];
  maxItemsPerPerson?: Maybe<Scalars['Int']>;
};

export type ShopItemOrderRestrictionsInputDTO = {
  enableMaxItemsPerPerson: Scalars['Boolean'];
  maxItemsPerPerson?: InputMaybe<Scalars['Int']>;
};

export type ShopItemSpecificResponsibleDTO = {
  __typename?: 'ShopItemSpecificResponsible';
  assignment: ShopItemAssignmentDTO;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
};

export type ShopItemSpecificResponsibleCreateInputDTO = {
  assignmentId: Scalars['String'];
  email: Scalars['String'];
};

export type ShopItemSpecificResponsibleFilterDTO = {
  AND?: InputMaybe<Array<ShopItemSpecificResponsibleFilterDTO>>;
  OR?: InputMaybe<Array<ShopItemSpecificResponsibleFilterDTO>>;
  assignment?: InputMaybe<ShopItemAssignmentFilterDTO>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
};

export type ShopItemSpecificResponsibleUpdateInputDTO = {
  assignmentId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type ShopItemStockLogDTO = {
  __typename?: 'ShopItemStockLog';
  availableStock?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy: UserDTO;
  deliveredStock?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  physicalStock?: Maybe<Scalars['Int']>;
  shopItem?: Maybe<ShopItemDTO>;
  shopItemAssignment?: Maybe<ShopItemAssignmentDTO>;
  type: ShopItemStockLogTypeDTO;
};

export type ShopItemStockLogFilterDTO = {
  AND?: InputMaybe<Array<ShopItemStockLogFilterDTO>>;
  OR?: InputMaybe<Array<ShopItemStockLogFilterDTO>>;
  availableStock?: InputMaybe<IntFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  createdBy?: InputMaybe<UserFilterDTO>;
  deliveredStock?: InputMaybe<IntFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  physicalStock?: InputMaybe<IntFilterDTO>;
  shopItem?: InputMaybe<ShopItemFilterDTO>;
  shopItemAssignment?: InputMaybe<ShopItemAssignmentFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export enum ShopItemStockLogTypeDTO {
  InitialDTO = 'INITIAL',
  RecountDTO = 'RECOUNT'
}

export type ShopItemTypeDTO = {
  __typename?: 'ShopItemType';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  type: ShopItemTypeTypeDTO;
};

export enum ShopItemTypeTypeDTO {
  PhysicalDTO = 'PHYSICAL',
  VoucherDTO = 'VOUCHER'
}

export type ShopItemUpdateInputDTO = {
  availableStock?: InputMaybe<Scalars['Int']>;
  deliveredStock?: InputMaybe<Scalars['Int']>;
  descriptions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  image?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  openRequestCount?: InputMaybe<Scalars['Int']>;
  orderRestrictions?: InputMaybe<ShopItemOrderRestrictionsInputDTO>;
  physicalStock?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  publishOn?: InputMaybe<Scalars['DateTime']>;
  publishUntil?: InputMaybe<Scalars['DateTime']>;
  purchasedAmount?: InputMaybe<Scalars['Int']>;
  titles?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  type?: InputMaybe<Scalars['String']>;
};

export type ShopItemVoucherDTO = {
  __typename?: 'ShopItemVoucher';
  backTicketImage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  frontTicketImage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  shopItem: ShopItemDTO;
  type: ShopItemVoucherTypeDTO;
};

export type ShopItemVoucherCodeDTO = {
  __typename?: 'ShopItemVoucherCode';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order?: Maybe<ShopItemOrderDTO>;
  ticket?: Maybe<ShopItemVoucherCodeTicketDTO>;
  used: Scalars['Boolean'];
  voucher: ShopItemVoucherDTO;
};

export type ShopItemVoucherCodeCreateInputDTO = {
  code?: InputMaybe<Scalars['String']>;
  shopItemVoucherId: Scalars['String'];
  ticketId?: InputMaybe<Scalars['String']>;
};

export type ShopItemVoucherCodeFilterDTO = {
  AND?: InputMaybe<Array<ShopItemVoucherCodeFilterDTO>>;
  OR?: InputMaybe<Array<ShopItemVoucherCodeFilterDTO>>;
  code?: InputMaybe<StringFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  order?: InputMaybe<ShopItemOrderFilterDTO>;
  ticket?: InputMaybe<ShopItemVoucherCodeTicketFilterDTO>;
  used?: InputMaybe<BooleanFilterDTO>;
  voucher?: InputMaybe<ShopItemVoucherFilterDTO>;
};

export type ShopItemVoucherCodeTicketDTO = {
  __typename?: 'ShopItemVoucherCodeTicket';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  url: Scalars['String'];
};

export type ShopItemVoucherCodeTicketCreateInputDTO = {
  url: Scalars['String'];
};

export type ShopItemVoucherCodeTicketFilterDTO = {
  AND?: InputMaybe<Array<ShopItemVoucherCodeTicketFilterDTO>>;
  OR?: InputMaybe<Array<ShopItemVoucherCodeTicketFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  url?: InputMaybe<StringFilterDTO>;
};

export type ShopItemVoucherCodeTicketUpdateInputDTO = {
  url?: InputMaybe<Scalars['String']>;
};

export type ShopItemVoucherCodeUpdateInputDTO = {
  code?: InputMaybe<Scalars['String']>;
  shopItemVoucherId?: InputMaybe<Scalars['String']>;
  ticketId?: InputMaybe<Scalars['String']>;
};

export type ShopItemVoucherCreateInputDTO = {
  backTicketImages: Array<MultiLanguageTextInputDTO>;
  frontTicketImages: Array<MultiLanguageTextInputDTO>;
  instructions: Array<MultiLanguageTextInputDTO>;
  shopItemId: Scalars['String'];
  type: ShopItemVoucherTypeDTO;
};

export type ShopItemVoucherFilterDTO = {
  AND?: InputMaybe<Array<ShopItemVoucherFilterDTO>>;
  OR?: InputMaybe<Array<ShopItemVoucherFilterDTO>>;
  backTicketImage?: InputMaybe<StringFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  frontTicketImage?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  instructions?: InputMaybe<StringFilterDTO>;
  shopItem?: InputMaybe<ShopItemFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
};

export enum ShopItemVoucherTypeDTO {
  PremadeDTO = 'PREMADE',
  SystemGeneratedDTO = 'SYSTEM_GENERATED'
}

export type ShopItemVoucherUpdateInputDTO = {
  backTicketImages?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  frontTicketImages?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  instructions?: InputMaybe<Array<MultiLanguageTextInputDTO>>;
  shopItemId?: InputMaybe<Scalars['String']>;
};

export type SortDTO = {
  field: Scalars['String'];
  order: SortOrderDTO;
};

export enum SortOrderDTO {
  AscDTO = 'ASC',
  DescDTO = 'DESC'
}

export type StringArrayFilterDTO = {
  allContains?: InputMaybe<Scalars['String']>;
  allEndsWith?: InputMaybe<Scalars['String']>;
  allStartsWith?: InputMaybe<Scalars['String']>;
  containsAll?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  containsSome?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  someContains?: InputMaybe<Scalars['String']>;
  someEndsWith?: InputMaybe<Scalars['String']>;
  someStartsWith?: InputMaybe<Scalars['String']>;
};

export type StringFilterDTO = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SubmitResultDTO = {
  __typename?: 'SubmitResult';
  amountCorrect: Scalars['Int'];
  amountOfQuestions: Scalars['Int'];
  coinsEarned: Scalars['Int'];
};

export type SubscriptionDTO = {
  __typename?: 'Subscription';
  quizPupilAnswerRewardStatusCreated?: Maybe<QuizPupilAnswerRewardStatusDTO>;
  quizPupilAnswerRewardStatusUpdated?: Maybe<QuizPupilAnswerRewardStatusDTO>;
  quizPupilRewardStatusCreated?: Maybe<QuizPupilRewardStatusDTO>;
  quizPupilRewardStatusUpdated?: Maybe<QuizPupilRewardStatusDTO>;
  rawModuleMessageCreated?: Maybe<RawModuleMessageDTO>;
};


export type SubscriptionQuizPupilAnswerRewardStatusCreatedArgsDTO = {
  filter?: InputMaybe<QuizPupilAnswerRewardStatusFilterDTO>;
};


export type SubscriptionQuizPupilAnswerRewardStatusUpdatedArgsDTO = {
  filter?: InputMaybe<QuizPupilAnswerRewardStatusFilterDTO>;
};


export type SubscriptionQuizPupilRewardStatusCreatedArgsDTO = {
  filter?: InputMaybe<QuizPupilRewardStatusFilterDTO>;
};


export type SubscriptionQuizPupilRewardStatusUpdatedArgsDTO = {
  filter?: InputMaybe<QuizPupilRewardStatusFilterDTO>;
};


export type SubscriptionRawModuleMessageCreatedArgsDTO = {
  filter?: InputMaybe<RawModuleMessageFilterDTO>;
};

export type SuccessDTO = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export type TimeSeriesDTO = {
  __typename?: 'TimeSeries';
  columns: Array<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  points: Array<TimeSeriesPointDTO>;
};

export type TimeSeriesPointDTO = {
  __typename?: 'TimeSeriesPoint';
  values: Array<Scalars['String']>;
};

export type TimeSeriesResultDTO = {
  __typename?: 'TimeSeriesResult';
  series?: Maybe<Array<Maybe<TimeSeriesDTO>>>;
  timestamps?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TokenResultDTO = {
  __typename?: 'TokenResult';
  token?: Maybe<Scalars['String']>;
};

export type UserDTO = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailValidated?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  lockedFrom?: Maybe<Scalars['DateTime']>;
  lockedUntil?: Maybe<Scalars['DateTime']>;
  resourceAccess?: Maybe<Array<UserResourceAccessDTO>>;
  roles?: Maybe<Array<UserRoleDTO>>;
};

export enum UserCommunicationChannelDTO {
  EmailDTO = 'EMAIL'
}

export type UserCommunicationPreferenceDTO = {
  __typename?: 'UserCommunicationPreference';
  allow: Scalars['Boolean'];
  channel: UserCommunicationChannelDTO;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  type: UserCommunicationPreferenceCommunicationTypeDTO;
  user: UserDTO;
};

export enum UserCommunicationPreferenceCommunicationTypeDTO {
  RewardsAndUpdatesForChildDTO = 'REWARDS_AND_UPDATES_FOR_CHILD'
}

export type UserCommunicationPreferenceCreateInputDTO = {
  allow: Scalars['Boolean'];
  channel: UserCommunicationChannelDTO;
  type: UserCommunicationPreferenceCommunicationTypeDTO;
  userId: Scalars['String'];
};

export type UserCommunicationPreferenceFilterDTO = {
  AND?: InputMaybe<Array<UserCommunicationPreferenceFilterDTO>>;
  OR?: InputMaybe<Array<UserCommunicationPreferenceFilterDTO>>;
  allow?: InputMaybe<BooleanFilterDTO>;
  channel?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
  user?: InputMaybe<UserFilterDTO>;
};

export type UserCommunicationPreferenceInputDTO = {
  allow: Scalars['Boolean'];
  channel: UserCommunicationChannelDTO;
  type: UserCommunicationPreferenceCommunicationTypeDTO;
};

export type UserCommunicationPreferenceUpdateInputDTO = {
  allow?: InputMaybe<Scalars['Boolean']>;
  channel?: InputMaybe<UserCommunicationChannelDTO>;
  type?: InputMaybe<UserCommunicationPreferenceCommunicationTypeDTO>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserCreateInputDTO = {
  email: Scalars['String'];
  emailValidated: Scalars['Boolean'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lockedFrom?: InputMaybe<Scalars['DateTime']>;
  lockedUntil?: InputMaybe<Scalars['DateTime']>;
  resourceAccess: Array<UserResourceAccessCreateInputDTO>;
  roles: Array<UserRoleCreateInputDTO>;
};

export type UserFilterDTO = {
  AND?: InputMaybe<Array<UserFilterDTO>>;
  OR?: InputMaybe<Array<UserFilterDTO>>;
  email?: InputMaybe<StringFilterDTO>;
  emailValidated?: InputMaybe<BooleanFilterDTO>;
  firstName?: InputMaybe<StringFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  lastName?: InputMaybe<StringFilterDTO>;
  lockedFrom?: InputMaybe<Scalars['DateTime']>;
  lockedUntil?: InputMaybe<DateTimeFilterDTO>;
};

export type UserLoginLogDTO = {
  __typename?: 'UserLoginLog';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  reason?: Maybe<FailedUserLoginReasonDTO>;
  referer?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  type: UserLoginTypeDTO;
  user?: Maybe<UserDTO>;
  userAgent?: Maybe<Scalars['String']>;
};

export type UserLoginLogFilterDTO = {
  AND?: InputMaybe<Array<UserLoginLogFilterDTO>>;
  OR?: InputMaybe<Array<UserLoginLogFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  reason?: InputMaybe<StringFilterDTO>;
  referer?: InputMaybe<StringFilterDTO>;
  success?: InputMaybe<BooleanFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
  user?: InputMaybe<UserFilterDTO>;
  userAgent?: InputMaybe<StringFilterDTO>;
};

export enum UserLoginTypeDTO {
  EmailPasswordDTO = 'EMAIL_PASSWORD'
}

export type UserNotificationLogDTO = {
  __typename?: 'UserNotificationLog';
  archivedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  channel: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  metadata: Scalars['JSON'];
  readAt?: Maybe<Scalars['DateTime']>;
  suppressedAt?: Maybe<Scalars['DateTime']>;
  suppressionReason?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  user: UserDTO;
};

export type UserNotificationLogFilterDTO = {
  AND?: InputMaybe<Array<UserNotificationLogFilterDTO>>;
  OR?: InputMaybe<Array<UserNotificationLogFilterDTO>>;
  archivedAt?: InputMaybe<DateTimeFilterDTO>;
  body?: InputMaybe<StringFilterDTO>;
  channel?: InputMaybe<StringFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  readAt?: InputMaybe<DateTimeFilterDTO>;
  suppressedAt?: InputMaybe<DateTimeFilterDTO>;
  suppressionReason?: InputMaybe<StringFilterDTO>;
  title?: InputMaybe<StringFilterDTO>;
  type?: InputMaybe<StringFilterDTO>;
  user?: InputMaybe<UserFilterDTO>;
};

export type UserResourceAccessDTO = {
  __typename?: 'UserResourceAccess';
  class?: Maybe<ClassDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  project: ProjectDTO;
  pupil?: Maybe<PupilDTO>;
  school?: Maybe<SchoolDTO>;
  user: UserDTO;
};

export type UserResourceAccessCreateInputDTO = {
  classId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  pupilId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserResourceAccessFilterDTO = {
  AND?: InputMaybe<Array<UserResourceAccessFilterDTO>>;
  OR?: InputMaybe<Array<UserResourceAccessFilterDTO>>;
  class?: InputMaybe<ClassFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  project?: InputMaybe<ProjectFilterDTO>;
  pupil?: InputMaybe<PupilFilterDTO>;
  school?: InputMaybe<SchoolFilterDTO>;
  user?: InputMaybe<UserFilterDTO>;
};

export type UserResourceAccessUpdateInputDTO = {
  classId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  pupilId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserRoleDTO = {
  __typename?: 'UserRole';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  project?: Maybe<ProjectDTO>;
  projectId?: Maybe<Scalars['String']>;
  role: RoleDTO;
  user: UserDTO;
};

export type UserRoleCreateInputDTO = {
  projectId: Scalars['String'];
  role: RoleDTO;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserRoleFilterDTO = {
  AND?: InputMaybe<Array<UserRoleFilterDTO>>;
  OR?: InputMaybe<Array<UserRoleFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  project?: InputMaybe<ProjectFilterDTO>;
  projectId?: InputMaybe<StringFilterDTO>;
  role?: InputMaybe<UserRoleRoleFilterDTO>;
  user?: InputMaybe<UserFilterDTO>;
};

export type UserRoleRoleFilterDTO = {
  equals?: InputMaybe<RoleDTO>;
  in?: InputMaybe<Array<InputMaybe<RoleDTO>>>;
};

export type UserRoleUpdateInputDTO = {
  projectId: Scalars['String'];
  role: RoleDTO;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserUpdateInputDTO = {
  email?: InputMaybe<Scalars['String']>;
  emailValidated?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lockedFrom?: InputMaybe<Scalars['DateTime']>;
  lockedUntil?: InputMaybe<Scalars['DateTime']>;
  resourceAccess?: InputMaybe<Array<UserResourceAccessUpdateInputDTO>>;
  roles?: InputMaybe<Array<UserRoleUpdateInputDTO>>;
};

export type UsernameDTO = {
  __typename?: 'Username';
  algorithm: Scalars['String'];
  claimedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  seed: Scalars['String'];
  username: Scalars['String'];
};

export type UsernameFilterDTO = {
  AND?: InputMaybe<Array<UsernameFilterDTO>>;
  OR?: InputMaybe<Array<UsernameFilterDTO>>;
  algorithm?: InputMaybe<StringFilterDTO>;
  claimedAt?: InputMaybe<DateTimeFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilterDTO>;
  id?: InputMaybe<StringFilterDTO>;
  seed?: InputMaybe<StringFilterDTO>;
  username?: InputMaybe<StringFilterDTO>;
};

export type ValidatePupilClassNumberInputDTO = {
  class: Scalars['String'];
  classNumber: Scalars['Int'];
};

export type ValidatePupilTagInputDTO = {
  schoolyearIds?: InputMaybe<Array<Scalars['String']>>;
  tag: PupilTagInputDTO;
};

export enum VisibilityTypeDTO {
  HiddenDTO = 'HIDDEN',
  UnlockableDTO = 'UNLOCKABLE',
  VisibleDTO = 'VISIBLE'
}

export type ZoneDTO = {
  __typename?: 'Zone';
  color: Scalars['String'];
  geoFencePoints: Array<ZoneGeoFencePointDTO>;
  id: Scalars['String'];
  school: SchoolDTO;
  title: Scalars['String'];
};

export type ZoneCreateInputDTO = {
  color: Scalars['String'];
  geoFencePoints: Array<ZoneGeoFencePointInputDTO>;
  school: Scalars['String'];
  title: Scalars['String'];
};

export type ZoneFilterDTO = {
  AND?: InputMaybe<Array<ZoneFilterDTO>>;
  OR?: InputMaybe<Array<ZoneFilterDTO>>;
  id?: InputMaybe<StringFilterDTO>;
  school?: InputMaybe<BasicStringFilterDTO>;
};

export type ZoneGeoFencePointDTO = {
  __typename?: 'ZoneGeoFencePoint';
  id: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type ZoneGeoFencePointInputDTO = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type ZoneUpdateInputDTO = {
  color?: InputMaybe<Scalars['String']>;
  geoFencePoints?: InputMaybe<Array<ZoneGeoFencePointInputDTO>>;
  title?: InputMaybe<Scalars['String']>;
};
