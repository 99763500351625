import { FormEvent } from "react";
import {
	FieldErrors, FieldPath, UseFormRegisterReturn, useForm as useReactHookForm,
} from "react-hook-form";
import { HTMLValidationSchema } from "./html-validation-schema";
import { HTMLValidationSchemaResolver } from "./html-validation-schema/compat/HTMLValidationSchemaResolver";
import { HTMLValidationSchemaGenerator } from "./html-validation-schema/models/validationSchema";
import { useEvent } from "./useEvent";

interface Common {
	isLoading?: boolean;
}

interface Props<Input> {
	submit: (input: Input) => void;
	schema: HTMLValidationSchema<Input> | HTMLValidationSchemaGenerator<Input>;
	defaultValues: Input;
}

interface Result<Input> {
	submit: (event: FormEvent<HTMLFormElement>) => void;
	register: (field: FieldPath<Input>) => UseFormRegisterReturn;
	errors: FieldErrors<Input>;
}

export type FormProps<Input> = Common & Props<Input>;
export type FormLayoutProps<Input> = Common & Result<Input>;

export const useForm = <Input>(props: Props<Input>): Result<Input> => {
	const {
		handleSubmit, register, formState, setError,
	} = useReactHookForm({
		mode: "onBlur",
		defaultValues: props.defaultValues as any,
		resolver: HTMLValidationSchemaResolver(props.schema),
	});

	/**
	 * In theory, the UI layer should not need direct access to Domain Events. This line is temporary until we have a more
	 * robust and permanent solution to centralized async form validation on the Interaction layer.
	*/
	useEvent("validationErrorOcurred", (field, message) => setError(field, { message }));

	return {
		submit: handleSubmit(props.submit),
		register,
		errors: formState.errors,
	};
};
