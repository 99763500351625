import { UILogic } from "@aptus/frontend-core";
import { t } from "i18next";
import {
	Children, ReactNode, RefObject, useEffect, useRef,
} from "react";

export interface StepProps {
	icon: string;
	href: string;
	isActive?: boolean;
	isDone?: boolean;
	children: ReactNode;
}

interface CommonProps {
	mobileClassName?: string;
	desktopClassName?: string;
}

interface Props {
	children: ReactNode;
}

interface Result {
	children: ReactNode;
	activeStep: string;
	nextStep: string;
	stepsCount: number;
	activeStepIndex: number;
	progressElementRef: RefObject<SVGCircleElement>;
}

export type StepperProps = CommonProps & Props;
export type StepperLayoutProps = CommonProps & Result;

export const useStepper: UILogic<Props, Result> = ({ children }) => {
	const progressElementRef = useRef<SVGCircleElement>(null);
	const isActive = (child: ReactNode): boolean => (child as any).props.isActive;
	const activeStepIndex: Result["activeStepIndex"] = Children.toArray(children).findIndex(isActive);
	const stepsCount: Result["stepsCount"] = Children.count(children);

	const getProps = (index: number): StepProps | undefined => Children.toArray(children)[index] && (Children.toArray(children)[index] as any).props;

	useEffect(() => {
		if (progressElementRef.current) {
			progressElementRef.current.style.setProperty("--progress", `${1 / (stepsCount / (activeStepIndex + 1))}`);
		}
	}, [progressElementRef.current, activeStepIndex, stepsCount]);

	return {
		children,
		activeStep: getProps(activeStepIndex)?.children as string || t("stepper.noActiveStep"),
		nextStep: getProps(activeStepIndex + 1)?.children as string || t("stepper.completed"),
		stepsCount: Children.count(children),
		activeStepIndex,
		progressElementRef,
	};
};
