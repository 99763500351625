import { UIComponent, UILayout } from "@aptus/frontend-core";
import { A } from "components/a";
import { useTranslation } from "react-i18next";
import styles from "./confirmationLink.module.css";
import { ConfirmationLinkLayoutProps, ConfirmationLinkProps, useConfirmationLink } from "./useConfirmationLink";

const Layout: UILayout<ConfirmationLinkLayoutProps> = ({
	confirmationText, children, type, askForConfirmation, comply, isInitialRender, isVisible, ...rest
}) => {
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<div className={`${styles.tooltip} tooltip ${isInitialRender ? "initial" : ""} ${isVisible ? "" : "hidden"}`}>
				<p className={styles.body}>{confirmationText}</p>
				<div className={styles.buttons}>
					<A {...rest} aria-disabled="false" className="secondary">{t("confirmationLink.no")}</A>
					<button type="button" className="text" onClick={comply}>{t("confirmationLink.yes")}</button>
				</div>
			</div>
			{askForConfirmation
				? (
					<button type="button" disabled={!!rest["aria-disabled"]} onClick={askForConfirmation}>
						{children}
					</button>
				)
				: <A {...rest} type="button" aria-disabled="false">{children}</A>}
		</div>
	);
};

export const ConfirmationLink: UIComponent<ConfirmationLinkProps> = ({ children, ...props }) => (
	<Layout {...props} {...useConfirmationLink(props)}>{children}</Layout>
);
