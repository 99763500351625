import { PageComponent } from "@aptus/frontend-core";
import { Select } from "components/input/input";
import { MiddleStepFooter, StepSection } from "components/stepSection/stepSection";
import {
	useAuthentication,
	useProjects,
	useRoutes,
	useSchools,
} from "domains";
import { RegisterInput } from "hooks/authentication/models/authenticationInput";
import { Project } from "hooks/projects/models/project";
import { School } from "hooks/schools/models/school";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetProjects } from "./models/getProjects";
import { useGetSchoolsByProjectId } from "./models/getSchoolsByProjectId";

export const SchoolStep: PageComponent = () => {
	/*
	const {
		formState, watch,
	} = useFormContext<RegisterInput>();
	*/

	const { t } = useTranslation();

	const { routes } = useRoutes();
	const {
		isRegistrationStepValid,
	} = useAuthentication();

	const {
		register, formState, getValues, watch,
	} = useFormContext<RegisterInput>();

	const { projects, isLoading: isProjectsLoading } = useProjects(useGetProjects());
	const { schools, isLoading: isSchoolsLoading } = useSchools(useGetSchoolsByProjectId({
		variables: { projectId: getValues("project") },
		skip: !getValues("project"),
	}));

	const projectToOption = ({ id, title }: Project) => (
		<option key={id} value={id} selected={getValues("project") === id}>{title}</option>
	);

	const schoolToOption = ({ id, title }: School) => (
		<option key={id} value={id} selected={getValues("school") === id}>{title}</option>
	);

	return (
		<StepSection>
			<Select
				{...register("project")}
				id="project"
				placeholder={t("projectInput.placeholder")}
				disabled={isProjectsLoading}
				label={t("projectInput.label")}
				error={formState.errors.project?.message}
			>
				<option selected style={{ display: "none" }}>{t("projectInput.defaultOption")}</option>
				{projects.map(projectToOption)}
			</Select>
			<Select
				{...register("school")}
				id="school"
				placeholder={t("schoolInput.placeholder")}
				disabled={isSchoolsLoading || !getValues("project") || !schools.length}
				label={t("schoolInput.label")}
				defaultValue=""
			>
				<option selected style={{ display: "none" }}>{t("schoolInput.defaultOption")}</option>
				{schools.map(schoolToOption)}
			</Select>
			<MiddleStepFooter
				previousStepHref={routes.register.stepTwo}
				currentStepHref={routes.register.stepThree}
				nextStepHref={routes.register.stepFour}
				isValid={isRegistrationStepValid(["project", "school"], watch())}
			/>
		</StepSection>
	);
};
