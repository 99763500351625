import { UIComponent, UILayout } from "@aptus/frontend-core";
import { A } from "components/a";
import { Input } from "components/input/input";
import { LoadingButton } from "components/loadingButton/loadingButton";
import { useRoutes } from "domains";
import { useTranslation } from "react-i18next";
import { RequestPasswordResetFormLayoutProps, RequestPasswordResetFormProps, useRequestPasswordResetForm } from "./useRequestPasswordResetForm";

const Layout: UILayout<RequestPasswordResetFormLayoutProps> = ({
	errors, register, submit, isLoading,
}) => {
	const { t } = useTranslation();
	const { routes } = useRoutes();

	return (
		<form onSubmit={submit}>
			<Input
				{...register("nfcTagId")}
				id="wristband"
				placeholder={t("requestPasswordResetForm.wristbandPlaceholder")}
				label={t("requestPasswordResetForm.wristbandLabel")}
				error={errors.nfcTagId?.message}
			/>
			<LoadingButton type="submit" isLoading={isLoading} disabled={false}>{t("requestPasswordResetForm.submit")}</LoadingButton>
			<A href={routes.forgotTagId}>{t("forgotTagId")}</A>
		</form>
	);
};

export const RequestPasswordResetForm: UIComponent<RequestPasswordResetFormProps> = (props) => (
	<Layout {...props} {...useRequestPasswordResetForm(props)} />
);
