import { PageComponent } from "@aptus/frontend-core";
import { FirstStepFooter, StepSection } from "components/stepSection/stepSection";
import { TagInput } from "components/tagInput/tagInput";
import { useAuthentication, useRoutes } from "domains";
import { RegisterInput } from "hooks/authentication/models/authenticationInput";
import { TagType } from "hooks/authentication/models/tagType";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formatImageUrl } from "utils/formatImageUrl";
import styles from "./wristbandStep.module.css";

export const WristbandStep: PageComponent = () => {
	const { routes } = useRoutes();
	const {
		isRegistrationStepValid, validateTag, isLoading, registerSchemaGenerator,
	} = useAuthentication();
	const {
		watch, formState, register, setError, clearErrors, getValues,
	} = useFormContext<RegisterInput>();
	const { t } = useTranslation();

	return (
		<StepSection className={styles.section}>
			<TagInput
				{...register("wristband", {
					onBlur: async (event) => {
						if (event.currentTarget.value.length === 8) {
							const error = await validateTag({ tag: event.currentTarget.value, ...getValues(), type: TagType.Wristband });

							if (error) {
								setError("wristband", { message: error });
							} else {
								clearErrors("wristband");
							}
						}
					},
					onChange: (event) => {
						if (event.currentTarget.value.length === 8) {
							event.currentTarget.blur();
						}
					},
				})}
				id="wristband"
				placeholder={t("wristbandInput.placeholder")}
				label={t("wristbandInput.label")}
				error={formState.errors.wristband?.message}
				{...registerSchemaGenerator(watch()).wristband}
			/>
			<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/medaillon.png")} alt={t("wristband")} width="279" height="216" />
			<FirstStepFooter
				cancelHref={routes.index}
				currentStepHref={routes.register.stepOne}
				nextStepHref={routes.register.stepTwo}
				isValid={!isLoading && !formState.errors.wristband && isRegistrationStepValid(["wristband"], watch())}
			/>
		</StepSection>
	);
};
