import { DeepPartial, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { ProjectDTO, QueryDTO } from "models/schema";
import { Project } from "./models/project";
import { ProjectsAPI } from "./useProjectsUseCases";

export type ProjectsAPIDTO = ApolloAPI<QueryDTO, "projects">;

interface Mapper {
	toAPI: ToAPI<ProjectsAPIDTO, ProjectsAPI>;
	toProject: ToInternal<DeepPartial<ProjectDTO>, Project>;
}

export class ProjectMapper implements Mapper {
	public toProject: Mapper["toProject"] = (project) => ({
		id: project.id || "",
		url: project.url || "",
		title: project.title || "",
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: ProjectsAPIDTO["data"]): DeepPartial<ProjectDTO>[] => {
			if (data?.projects) return data?.projects;
			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toProject),
		};
	};
}
