/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TriggerForgotTagIdEmailVariablesDTO = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type TriggerForgotTagIdEmailDTO = { __typename?: 'Mutation', forgotPupilData: { __typename?: 'Success', success: boolean } };


export const TriggerForgotTagIdEmailDocumentDTO = gql`
    mutation triggerForgotTagIdEmail($email: String!) {
  forgotPupilData(email: $email) {
    success
  }
}
    `;
export type TriggerForgotTagIdEmailMutationFnDTO = Apollo.MutationFunction<TriggerForgotTagIdEmailDTO, TriggerForgotTagIdEmailVariablesDTO>;

/**
 * __useTriggerForgotTagIdEmail__
 *
 * To run a mutation, you first call `useTriggerForgotTagIdEmail` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerForgotTagIdEmail` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerForgotTagIdEmail, { data, loading, error }] = useTriggerForgotTagIdEmail({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useTriggerForgotTagIdEmail(baseOptions?: Apollo.MutationHookOptions<TriggerForgotTagIdEmailDTO, TriggerForgotTagIdEmailVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerForgotTagIdEmailDTO, TriggerForgotTagIdEmailVariablesDTO>(TriggerForgotTagIdEmailDocumentDTO, options);
      }
export type TriggerForgotTagIdEmailHookResult = ReturnType<typeof useTriggerForgotTagIdEmail>;
export type TriggerForgotTagIdEmailMutationResultDTO = Apollo.MutationResult<TriggerForgotTagIdEmailDTO>;
export type TriggerForgotTagIdEmailMutationOptionsDTO = Apollo.BaseMutationOptions<TriggerForgotTagIdEmailDTO, TriggerForgotTagIdEmailVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    