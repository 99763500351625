import { API, UseCases } from "@aptus/frontend-core";
import { School } from "./models/school";

export type SchoolsAPI = API<School[]>;

interface Props {
	api: SchoolsAPI;
	defaultSchool: School;
}

interface Result {
	schools: School[];
	school: School;
	isLoading: boolean;
	error?: Error;
}

export const useSchoolsUseCases: UseCases<Props, Result> = ({ api, defaultSchool }) => ({
	...api,
	schools: api.data,
	school: api.data?.[0] ?? defaultSchool,
});
