import { UIComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { Hamburger } from "components/hamburger/hamburger";
import { ReactNode } from "react";
import { formatImageUrl } from "utils/formatImageUrl";
import styles from "./header.module.css";

interface Props {
	href: string;
	children: ReactNode;
	applyOwnMargin?: boolean;
}

export const Header: UIComponent<Props> = ({ href, children, applyOwnMargin }) => (
	<header>
		<nav className={`${styles.container} ${applyOwnMargin ? styles.ownMargin : ""}`}>
			<A href={href} className={styles.logo}>
				<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/logo.png")} alt="High-Five" width="137" height="45" />
			</A>
			{children}
			<Hamburger>{children}</Hamburger>
		</nav>
	</header>
);
