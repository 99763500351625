import { UILogic } from "@aptus/frontend-core";
import { useReducer } from "react";
import { SingleInputFieldProps } from "./models/inputProps";

type Common = SingleInputFieldProps;

interface Result {
	isVisible: boolean;
	toggleVisibility: () => void;
	type: "password" | "text";
}

export type PasswordInputLayoutProps = Common & Result;

export const usePasswordInput: UILogic<{}, Result> = () => {
	const [isVisible, toggleVisibility] = useReducer((v) => !v, false);
	const type: Result["type"] = isVisible ? "text" : "password";

	return {
		isVisible,
		toggleVisibility,
		type,
	};
};
