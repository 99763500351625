import { UIComponent, UILayout } from "@aptus/frontend-core";
import { A } from "components/a";
import { Input } from "components/input/input";
import { LoadingButton } from "components/loadingButton/loadingButton";
import { useRoutes } from "domains";
import { useTranslation } from "react-i18next";
import { LoginFormLayoutProps, LoginFormProps, useLoginForm } from "./useLoginForm";
import styles from "../../loginPage.module.css";

const Layout: UILayout<LoginFormLayoutProps> = ({
	errors, isLoading, register, submit, registerUrl, requestPasswordResetHref,
}) => {
	const { t } = useTranslation();
	const { routes } = useRoutes();

	return (
		<form onSubmit={submit}>
			<Input
				{...register("nfcTagId")}
				type="text"
				id="tagId"
				placeholder={t("startFrom.placeholder")}
				label={t("startFrom.tagId")}
				error={errors.nfcTagId?.message}
				onChange={(event) => {
					if (event.currentTarget.value.indexOf("O") >= 0 || event.currentTarget.value.indexOf("o") >= 0) {
						// eslint-disable-next-line no-param-reassign
						event.currentTarget.value = event.currentTarget.value.toUpperCase().replace(/O/g, "0");
					}

					if (event.currentTarget.value.length === 8) {
						event.currentTarget.blur();
						document.getElementById("submitButton")?.focus();
					}
				}}
			/>
			<Input
				{...register("password")}
				type="password"
				id="password"
				placeholder={t("loginForm.passwordPlaceholder")}
				label={t("loginForm.passwordLabel")}
				error={errors.password?.message}
			/>
			<LoadingButton type="submit" isLoading={isLoading} disabled={false}>{t("loginForm.submit")}</LoadingButton>
			<A type="button" className="secondary" href={registerUrl}>{t("loginForm.register")}</A>
			<div className={styles.forgotButtons}>
				<A href={routes.forgotTagId}>{t("forgotTagId")}</A>
				<A href={requestPasswordResetHref}>{t("forgotPassword")}</A>
			</div>
		</form>
	);
};

export const LoginForm: UIComponent<LoginFormProps> = (props) => (
	<Layout {...props} {...useLoginForm(props)} />
);
