import { UIComponent } from "@aptus/frontend-core";
import { A } from "components/a";
import { useTranslation } from "react-i18next";
import styles from "./listSectionItem.module.css";

interface Props {
	title: string;
	value?: string;
	href: string;
}

export const ListSectionItem: UIComponent<Props> = ({ title, value, href }) => {
	const { t } = useTranslation();

	return (
		<li className={styles.container}>
			<p className="medium">{title}</p>
			<A href={href} className={`${!value && styles.light}`}>
				{value || t("listSectionItem.noValue")}
			</A>
		</li>
	);
};
