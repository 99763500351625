import { UILogic } from "@aptus/frontend-core";
import { RequestPasswordResetInput } from "hooks/authentication/models/authenticationInput";
import { FormEvent } from "react";
import {
	FieldErrors, FieldPath,
	UseFormRegisterReturn,
	useForm,
} from "react-hook-form";
import { HTMLValidationSchema } from "utils/html-validation-schema";
import { HTMLValidationSchemaResolver } from "utils/html-validation-schema/compat/HTMLValidationSchemaResolver";

interface Common {
	isLoading: boolean;
}

interface Props {
	submit: (input: RequestPasswordResetInput) => void;
	schema: HTMLValidationSchema<RequestPasswordResetInput>;
	defaultValues: RequestPasswordResetInput;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>) => void;
	register: (field: FieldPath<RequestPasswordResetInput>) => UseFormRegisterReturn;
	errors: FieldErrors<RequestPasswordResetInput>;
}

export type RequestPasswordResetFormProps = Common & Props;
export type RequestPasswordResetFormLayoutProps = Common & Result;

export const useRequestPasswordResetForm: UILogic<Props, Result> = ({
	submit, schema, defaultValues,
}) => {
	const {
		handleSubmit, register, formState,
	} = useForm<RequestPasswordResetInput>({
		mode: "onBlur",
		defaultValues,
		resolver: HTMLValidationSchemaResolver(schema),
	});

	return {
		submit: handleSubmit(submit),
		register,
		errors: formState.errors,
	};
};
