/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetPasswordVariablesDTO = Types.Exact<{
  password: Types.Scalars['String'];
  token: Types.Scalars['String'];
}>;


export type ResetPasswordDTO = { __typename?: 'Mutation', updatePupilPasswordAnonymous: { __typename?: 'Success', success: boolean } };


export const ResetPasswordDocumentDTO = gql`
    mutation resetPassword($password: String!, $token: String!) {
  updatePupilPasswordAnonymous(data: {password: $password, token: $token}) {
    success
  }
}
    `;
export type ResetPasswordMutationFnDTO = Apollo.MutationFunction<ResetPasswordDTO, ResetPasswordVariablesDTO>;

/**
 * __useResetPassword__
 *
 * To run a mutation, you first call `useResetPassword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPassword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPassword, { data, loading, error }] = useResetPassword({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPassword(baseOptions?: Apollo.MutationHookOptions<ResetPasswordDTO, ResetPasswordVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordDTO, ResetPasswordVariablesDTO>(ResetPasswordDocumentDTO, options);
      }
export type ResetPasswordHookResult = ReturnType<typeof useResetPassword>;
export type ResetPasswordMutationResultDTO = Apollo.MutationResult<ResetPasswordDTO>;
export type ResetPasswordMutationOptionsDTO = Apollo.BaseMutationOptions<ResetPasswordDTO, ResetPasswordVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    