import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Input } from "components/input/input";
import { LoadingButton } from "components/loadingButton/loadingButton";
import { Tooltip } from "components/tooltip/tooltip";
import { ResetPasswordInput } from "hooks/authentication/models/authenticationInput";
import { useTranslation } from "react-i18next";
import { FormLayoutProps, FormProps, useForm } from "utils/useForm";

const Layout: UILayout<FormLayoutProps<ResetPasswordInput>> = ({
	errors, register, submit, isLoading,
}) => {
	const { t } = useTranslation();

	return (
		<form onSubmit={submit}>
			<Tooltip
				title={t("resetPasswordForm.passwordInput.tooltipTitle")}
				body={t("resetPasswordForm.passwordInput.tooltipText")}
				id="password"
			>
				<Input
					{...register("password")}
					id="password"
					type="password"
					placeholder={t("resetPasswordForm.passwordInput.placeholder")}
					label={t("resetPasswordForm.passwordInput.label")}
					error={errors.password?.message}
				/>
			</Tooltip>
			<Input
				{...register("passwordConfirmation")}
				id="passwordConfirmation"
				type="password"
				placeholder={t("resetPasswordForm.passwordConfirmationInput.placeholder")}
				label={t("resetPasswordForm.passwordConfirmationInput.label")}
				error={errors.passwordConfirmation?.message}
			/>
			<LoadingButton type="submit" isLoading={isLoading} disabled={false}>{t("resetPasswordForm.submit")}</LoadingButton>
		</form>
	);
};

export const ResetPasswordForm: UIComponent<FormProps<ResetPasswordInput>> = (props) => (
	<Layout {...props} {...useForm(props)} />
);
