/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestPasswordResetVariablesDTO = Types.Exact<{
  nfcTagId: Types.Scalars['String'];
  nfcTagType: Types.Scalars['String'];
}>;


export type RequestPasswordResetDTO = { __typename?: 'Mutation', pupilNfcPasswordReset: { __typename?: 'Success', success: boolean } };


export const RequestPasswordResetDocumentDTO = gql`
    mutation requestPasswordReset($nfcTagId: String!, $nfcTagType: String!) {
  pupilNfcPasswordReset(nfcTagId: $nfcTagId, nfcTagType: $nfcTagType) {
    success
  }
}
    `;
export type RequestPasswordResetMutationFnDTO = Apollo.MutationFunction<RequestPasswordResetDTO, RequestPasswordResetVariablesDTO>;

/**
 * __useRequestPasswordReset__
 *
 * To run a mutation, you first call `useRequestPasswordReset` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordReset` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordReset, { data, loading, error }] = useRequestPasswordReset({
 *   variables: {
 *      nfcTagId: // value for 'nfcTagId'
 *      nfcTagType: // value for 'nfcTagType'
 *   },
 * });
 */
export function useRequestPasswordReset(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetDTO, RequestPasswordResetVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetDTO, RequestPasswordResetVariablesDTO>(RequestPasswordResetDocumentDTO, options);
      }
export type RequestPasswordResetHookResult = ReturnType<typeof useRequestPasswordReset>;
export type RequestPasswordResetMutationResultDTO = Apollo.MutationResult<RequestPasswordResetDTO>;
export type RequestPasswordResetMutationOptionsDTO = Apollo.BaseMutationOptions<RequestPasswordResetDTO, RequestPasswordResetVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    