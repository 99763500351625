import { UseCases } from "@aptus/frontend-core";
import { Events } from "event";
import { useEffect, useState } from "react";
import { tracker } from "utils/Tracker";
import { useEvent } from "utils/useEvent";
import { Notification } from "./models/notification";

interface Result {
	notification?: Notification;
	showSuccessMessage: Events["mutationSucceeded"];
	showErrorMessage: Events["mutationFailed"];
	closeNotification: () => void;
}

export const useNotificationsUseCases: UseCases<undefined, Result> = () => {
	const [notification, setNotification] = useState<Result["notification"]>(undefined);
	const [autocloseDelay, setAutocloseDelay] = useState<number | undefined>(undefined);

	const showSuccessMessage: Events["mutationSucceeded"] = (message) => {
		setNotification({ message, type: "success" });
		setAutocloseDelay(5000);
	};

	const showErrorMessage: Events["mutationFailed"] = (error) => {
		setNotification({ message: error.message, type: "error" });
		setAutocloseDelay(5000);
	};

	const closeNotification: Result["closeNotification"] = () => {
		setNotification(undefined);
		setAutocloseDelay(undefined);
	};

	useEffect(() => {
		if (autocloseDelay) setTimeout(closeNotification, autocloseDelay);
	}, [autocloseDelay]);

	return {
		notification,
		showSuccessMessage,
		showErrorMessage,
		closeNotification,
	};
};

export const useNotificationsEffects = (notifications: ReturnType<typeof useNotificationsUseCases>) => {
	useEvent("mutationSucceeded", notifications.showSuccessMessage);
	useEvent("mutationFailed", notifications.showErrorMessage);
	useEvent("mutationFailed", tracker.captureException);
};
