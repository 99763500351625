import { DeepPartial, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { t } from "i18next";
import { ClassDTO, QueryDTO } from "models/schema";
import { ClassRoom } from "./models/classRoom";
import { ClassRoomsAPI } from "./useClassRoomUseCases";

export type ClassRoomsAPIDTO = ApolloAPI<QueryDTO, "classes">;

interface Mapper {
	toAPI: ToAPI<ClassRoomsAPIDTO, ClassRoomsAPI>;
	toClassRoom: ToInternal<DeepPartial<ClassDTO>, ClassRoom>;
}

export class ClassRoomMapper implements Mapper {
	public toClassRoom = (classroom: DeepPartial<ClassDTO>): ClassRoom => ({
		id: classroom.id || "",
		title: classroom.title || t("domain.school.noClassroom"),
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: ClassRoomsAPIDTO["data"]): DeepPartial<ClassDTO>[] => {
			if (data?.classes) return data?.classes;
			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toClassRoom),
		};
	};
}
