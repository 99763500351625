import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";
import { getCookie } from "utils/GetCookie";
import Hotjar from "@hotjar/browser";

export const FUNCTIONAL_COOKIES_KEY = "accept-functional-cookies";
export const GOOGLE_ANALYTICS_COOKIES_KEY = "accept-google-analytical-cookies";
export const HOTJAR_COOKIES_KEY = "accept-hotjar-cookies";

interface Tracker {
	init: () => void;
	captureException: (error: Error) => void;
	set: (fieldsObject: Record<string, unknown>) => void;
	pageView: (path: string) => void;
}

const isGoogleAnalyticsAllowed: () => boolean = () => (getCookie(GOOGLE_ANALYTICS_COOKIES_KEY) === true);
const isHotJarAllowed: () => boolean = () => (getCookie(HOTJAR_COOKIES_KEY) === true);

const init: Tracker["init"] = () => {
	const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || window?.appEnvironment?.SENTRY_DSN;
	if (SENTRY_DSN) {
		Sentry.init({
			dsn: SENTRY_DSN,
			integrations: [new BrowserTracing()],
			tracesSampleRate: 1.0,
		});
	}

	const GOOGLE_ANALYTICS_KEY = process.env.GOOGLE_ANALYTICS_KEY || window?.appEnvironment?.GOOGLE_ANALYTICS_KEY;
	if (isGoogleAnalyticsAllowed() && GOOGLE_ANALYTICS_KEY) {
		ReactGA.initialize(GOOGLE_ANALYTICS_KEY, { testMode: process.env.NODE_ENV === "test" });
	}

	const HOTJAR_SITE_ID = (process.env.HOTJAR_SITE_ID || window?.appEnvironment?.HOTJAR_SITE_ID) as number;
	const HOTJAR_VERSION = (process.env.HOTJAR_VERSION || window?.appEnvironment?.HOTJAR_VERSION) as number;
	if (isHotJarAllowed() && HOTJAR_SITE_ID && HOTJAR_VERSION) {
		Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION);
	}
};

const set: Tracker["set"] = (fieldsObject: Record<string, unknown>) => {
	if (isGoogleAnalyticsAllowed()) {
		ReactGA.set(fieldsObject);
	}
};

const pageView: Tracker["pageView"] = (path: string) => {
	if (isGoogleAnalyticsAllowed()) {
		ReactGA.send({ hitType: "pageview", page: path });
	}
};

export const tracker: Tracker = {
	init,
	captureException: Sentry.captureException,
	set,
	pageView,
};
