import { PageComponent } from "@aptus/frontend-core";
import { Input } from "components/input/input";
import { MiddleStepFooter, StepSection } from "components/stepSection/stepSection";
import { TagInput } from "components/tagInput/tagInput";
import { useAuthentication, useRoutes } from "domains";
import { RegisterInput } from "hooks/authentication/models/authenticationInput";
import { TagType } from "hooks/authentication/models/tagType";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formatImageUrl } from "utils/formatImageUrl";
import styles from "./biketagStep.module.css";

export const BiketagStep: PageComponent = () => {
	const { routes } = useRoutes();
	const {
		isRegistrationStepValid, validateTag, isLoading, registerSchemaGenerator,
	} = useAuthentication();
	const {
		watch, formState, register, setError, clearErrors, getValues, trigger,
	} = useFormContext<RegisterInput>();
	const { t } = useTranslation();

	return (
		<StepSection className={styles.section}>
			<TagInput
				{...register("biketag", {
					onBlur: async (event) => {
						const schema = registerSchemaGenerator(event.currentTarget).biketag;
						const shouldValidateTag = event.currentTarget.value.length >= (schema.minLength || 0) && event.currentTarget.value.length <= (schema.maxLength || 0);

						if (shouldValidateTag) {
							const error = await validateTag({ tag: event.currentTarget.value, ...getValues(), type: TagType.BikeTag });

							if (error) {
								setError("biketag", { message: error });
							} else {
								clearErrors("biketag");
							}
						}
					},
					onChange: (event) => {
						const schema = registerSchemaGenerator(event.currentTarget).biketag;
						const shouldValidateTag = event.currentTarget.value.length >= (schema.minLength || 0)
							&& event.currentTarget.value.length <= (schema.maxLength || 0)
							&& event.currentTarget.value.match(schema.pattern);

						if (shouldValidateTag) {
							event.currentTarget.blur();
						}
					},
				})}
				id="biketag"
				placeholder={t("biketagInput.placeholder")}
				className={styles.input}
				label={t("biketagInput.label")}
				error={(!watch("hasNoBiketag") && formState.errors.biketag?.message) || undefined}
				disabled={watch("hasNoBiketag")}
				{...registerSchemaGenerator(watch()).biketag}
			/>
			<Input
				{...register("hasNoBiketag", {
					onChange: () => trigger("biketag"),
				})}
				id="hasNoBiketag"
				type="checkbox"
				className={`${styles.checkbox} ${formState.errors.biketag ? styles.hidden : ""}`}
				label={t("hasNoBiketagInput.label")}
				error={formState.errors.hasNoBiketag?.message}
			/>
			<img src={formatImageUrl("https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/biketagv2.png")} alt={t("biketag")} className={styles.image} width="460" height="175" />
			<MiddleStepFooter
				previousStepHref={routes.register.stepOne}
				currentStepHref={routes.register.stepTwo}
				nextStepHref={routes.register.stepThree}
				isValid={!isLoading && !formState.errors.biketag && isRegistrationStepValid(["biketag", "hasNoBiketag"], watch())}
			/>
		</StepSection>
	);
};
